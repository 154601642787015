/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #1e2b8e;
  --indigo: #6610f2;
  --purple: #800080;
  --pink: #e83e8c;
  --red: #d0021b;
  --orange: #ff9500;
  --yellow: #fabd05;
  --green: #417505;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #417505;
  --info: #17a2b8;
  --warning: #f5a623;
  --danger: #d0021b;
  --light: #fafafa;
  --dark: #8b959e;
  --brown: #a79779;
  --dark-grey: #8b959e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(12, 12, 12, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0c0c0c;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(12, 12, 12, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #0c0c0c; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(12, 12, 12, 0.05); }

.table-hover tbody tr:hover {
  color: #0c0c0c;
  background-color: rgba(12, 12, 12, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cad8b9; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9cb77d; }

.table-hover .table-success:hover {
  background-color: #becfa9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #becfa9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce6c1; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fad18d; }

.table-hover .table-warning:hover {
  background-color: #fbdca9; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbdca9; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2b8bf; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e77b88; }

.table-hover .table-danger:hover {
  background-color: #eea2ac; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eea2ac; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #dfe1e4; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #c3c8cd; }

.table-hover .table-dark:hover {
  background-color: #d1d4d8; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #d1d4d8; }

.table-brown,
.table-brown > th,
.table-brown > td {
  background-color: #e6e2d9; }

.table-brown th,
.table-brown td,
.table-brown thead th,
.table-brown tbody + tbody {
  border-color: #d1c9b9; }

.table-hover .table-brown:hover {
  background-color: #dcd6ca; }
  .table-hover .table-brown:hover > td,
  .table-hover .table-brown:hover > th {
    background-color: #dcd6ca; }

.table-dark-grey,
.table-dark-grey > th,
.table-dark-grey > td {
  background-color: #dfe1e4; }

.table-dark-grey th,
.table-dark-grey td,
.table-dark-grey thead th,
.table-dark-grey tbody + tbody {
  border-color: #c3c8cd; }

.table-hover .table-dark-grey:hover {
  background-color: #d1d4d8; }
  .table-hover .table-dark-grey:hover > td,
  .table-hover .table-dark-grey:hover > th {
    background-color: #d1d4d8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(12, 12, 12, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #ffffff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #0c0c0c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #417505; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(65, 117, 5, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #417505;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23417505' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #417505;
    box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #417505;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23417505' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #417505;
    box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #417505; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #417505; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #417505; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5ca607;
  background-color: #5ca607; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #417505; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #417505; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #417505;
  box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d0021b; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(208, 2, 27, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d0021b;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d0021b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d0021b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d0021b;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d0021b' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d0021b' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d0021b;
    box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d0021b; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d0021b; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d0021b; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd0826;
  background-color: #fd0826; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d0021b; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d0021b; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d0021b;
  box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #0c0c0c;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #0c0c0c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #417505;
  border-color: #417505; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #2d5003;
    border-color: #264403; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #2d5003;
    border-color: #264403;
    box-shadow: 0 0 0 0.2rem rgba(94, 138, 43, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #417505;
    border-color: #417505; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #264403;
    border-color: #1f3802; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 138, 43, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f5a623;
  border-color: #f5a623; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e7940b;
    border-color: #db8c0a; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e7940b;
    border-color: #db8c0a;
    box-shadow: 0 0 0 0.2rem rgba(213, 147, 36, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f5a623;
    border-color: #f5a623; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #db8c0a;
    border-color: #cf8509; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 147, 36, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #d0021b;
  border-color: #d0021b; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #aa0216;
    border-color: #9d0214; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #aa0216;
    border-color: #9d0214;
    box-shadow: 0 0 0 0.2rem rgba(215, 40, 61, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #d0021b;
    border-color: #d0021b; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #9d0214;
    border-color: #910113; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 40, 61, 0.5); }

.btn-light {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e7e7e7;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e7e7e7;
    border-color: #e1e1e1;
    box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #8b959e;
  border-color: #8b959e; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #76828d;
    border-color: #707c86; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #76828d;
    border-color: #707c86;
    box-shadow: 0 0 0 0.2rem rgba(156, 165, 173, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #8b959e;
    border-color: #8b959e; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #707c86;
    border-color: #6a757f; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 165, 173, 0.5); }

.btn-brown {
  color: #212529;
  background-color: #a79779;
  border-color: #a79779; }
  .btn-brown:hover {
    color: #ffffff;
    background-color: #978563;
    border-color: #8f7e5e; }
  .btn-brown:focus, .btn-brown.focus {
    color: #ffffff;
    background-color: #978563;
    border-color: #8f7e5e;
    box-shadow: 0 0 0 0.2rem rgba(147, 134, 109, 0.5); }
  .btn-brown.disabled, .btn-brown:disabled {
    color: #212529;
    background-color: #a79779;
    border-color: #a79779; }
  .btn-brown:not(:disabled):not(.disabled):active, .btn-brown:not(:disabled):not(.disabled).active,
  .show > .btn-brown.dropdown-toggle {
    color: #ffffff;
    background-color: #8f7e5e;
    border-color: #877759; }
    .btn-brown:not(:disabled):not(.disabled):active:focus, .btn-brown:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 134, 109, 0.5); }

.btn-dark-grey {
  color: #ffffff;
  background-color: #8b959e;
  border-color: #8b959e; }
  .btn-dark-grey:hover {
    color: #ffffff;
    background-color: #76828d;
    border-color: #707c86; }
  .btn-dark-grey:focus, .btn-dark-grey.focus {
    color: #ffffff;
    background-color: #76828d;
    border-color: #707c86;
    box-shadow: 0 0 0 0.2rem rgba(156, 165, 173, 0.5); }
  .btn-dark-grey.disabled, .btn-dark-grey:disabled {
    color: #ffffff;
    background-color: #8b959e;
    border-color: #8b959e; }
  .btn-dark-grey:not(:disabled):not(.disabled):active, .btn-dark-grey:not(:disabled):not(.disabled).active,
  .show > .btn-dark-grey.dropdown-toggle {
    color: #ffffff;
    background-color: #707c86;
    border-color: #6a757f; }
    .btn-dark-grey:not(:disabled):not(.disabled):active:focus, .btn-dark-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 165, 173, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #417505;
  border-color: #417505; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #417505;
    border-color: #417505; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #417505;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #417505;
    border-color: #417505; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #f5a623;
  border-color: #f5a623; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f5a623;
    border-color: #f5a623; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f5a623;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f5a623;
    border-color: #f5a623; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, 0.5); }

.btn-outline-danger {
  color: #d0021b;
  border-color: #d0021b; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #d0021b;
    border-color: #d0021b; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d0021b;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #d0021b;
    border-color: #d0021b; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5); }

.btn-outline-light {
  color: #fafafa;
  border-color: #fafafa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fafafa;
    border-color: #fafafa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #8b959e;
  border-color: #8b959e; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #8b959e;
    border-color: #8b959e; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(139, 149, 158, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #8b959e;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #8b959e;
    border-color: #8b959e; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 149, 158, 0.5); }

.btn-outline-brown {
  color: #a79779;
  border-color: #a79779; }
  .btn-outline-brown:hover {
    color: #212529;
    background-color: #a79779;
    border-color: #a79779; }
  .btn-outline-brown:focus, .btn-outline-brown.focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 151, 121, 0.5); }
  .btn-outline-brown.disabled, .btn-outline-brown:disabled {
    color: #a79779;
    background-color: transparent; }
  .btn-outline-brown:not(:disabled):not(.disabled):active, .btn-outline-brown:not(:disabled):not(.disabled).active,
  .show > .btn-outline-brown.dropdown-toggle {
    color: #212529;
    background-color: #a79779;
    border-color: #a79779; }
    .btn-outline-brown:not(:disabled):not(.disabled):active:focus, .btn-outline-brown:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-brown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(167, 151, 121, 0.5); }

.btn-outline-dark-grey {
  color: #8b959e;
  border-color: #8b959e; }
  .btn-outline-dark-grey:hover {
    color: #ffffff;
    background-color: #8b959e;
    border-color: #8b959e; }
  .btn-outline-dark-grey:focus, .btn-outline-dark-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(139, 149, 158, 0.5); }
  .btn-outline-dark-grey.disabled, .btn-outline-dark-grey:disabled {
    color: #8b959e;
    background-color: transparent; }
  .btn-outline-dark-grey:not(:disabled):not(.disabled):active, .btn-outline-dark-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-grey.dropdown-toggle {
    color: #ffffff;
    background-color: #8b959e;
    border-color: #8b959e; }
    .btn-outline-dark-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 149, 158, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #0c0c0c;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(12, 12, 12, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(12, 12, 12, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(12, 12, 12, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(12, 12, 12, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(12, 12, 12, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(12, 12, 12, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(12, 12, 12, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(12, 12, 12, 0.5);
  border-color: rgba(12, 12, 12, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2812, 12, 12, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(12, 12, 12, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(12, 12, 12, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(12, 12, 12, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(12, 12, 12, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(12, 12, 12, 0.03);
  border-bottom: 1px solid rgba(12, 12, 12, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(12, 12, 12, 0.03);
  border-top: 1px solid rgba(12, 12, 12, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #ffffff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #417505; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #264403; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(65, 117, 5, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f5a623; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #db8c0a; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 166, 35, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #d0021b; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #9d0214; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(208, 2, 27, 0.5); }

.badge-light {
  color: #212529;
  background-color: #fafafa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e1e1e1; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #8b959e; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #707c86; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(139, 149, 158, 0.5); }

.badge-brown {
  color: #212529;
  background-color: #a79779; }
  a.badge-brown:hover, a.badge-brown:focus {
    color: #212529;
    background-color: #8f7e5e; }
  a.badge-brown:focus, a.badge-brown.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(167, 151, 121, 0.5); }

.badge-dark-grey {
  color: #ffffff;
  background-color: #8b959e; }
  a.badge-dark-grey:hover, a.badge-dark-grey:focus {
    color: #ffffff;
    background-color: #707c86; }
  a.badge-dark-grey:focus, a.badge-dark-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(139, 149, 158, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #06468a;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #042d59; }

.alert-secondary {
  color: #3e4347;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #26292c; }

.alert-success {
  color: #284308;
  background-color: #d9e3cd;
  border-color: #cad8b9; }
  .alert-success hr {
    border-top-color: #becfa9; }
  .alert-success .alert-link {
    color: #0d1503; }

.alert-info {
  color: #125a65;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #0a333a; }

.alert-warning {
  color: #855c18;
  background-color: #fdedd3;
  border-color: #fce6c1; }
  .alert-warning hr {
    border-top-color: #fbdca9; }
  .alert-warning .alert-link {
    color: #5a3e10; }

.alert-danger {
  color: #720714;
  background-color: #f6ccd1;
  border-color: #f2b8bf; }
  .alert-danger hr {
    border-top-color: #eea2ac; }
  .alert-danger .alert-link {
    color: #42040c; }

.alert-light {
  color: #888888;
  background-color: #fefefe;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: #6f6f6f; }

.alert-dark {
  color: #4e5358;
  background-color: #e8eaec;
  border-color: #dfe1e4; }
  .alert-dark hr {
    border-top-color: #d1d4d8; }
  .alert-dark .alert-link {
    color: #363a3d; }

.alert-brown {
  color: #5d5445;
  background-color: #edeae4;
  border-color: #e6e2d9; }
  .alert-brown hr {
    border-top-color: #dcd6ca; }
  .alert-brown .alert-link {
    color: #403a2f; }

.alert-dark-grey {
  color: #4e5358;
  background-color: #e8eaec;
  border-color: #dfe1e4; }
  .alert-dark-grey hr {
    border-top-color: #d1d4d8; }
  .alert-dark-grey .alert-link {
    color: #363a3d; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #0c0c0c;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(12, 12, 12, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #06468a;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #06468a;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #06468a;
    border-color: #06468a; }

.list-group-item-secondary {
  color: #3e4347;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3e4347;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #3e4347;
    border-color: #3e4347; }

.list-group-item-success {
  color: #284308;
  background-color: #cad8b9; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #284308;
    background-color: #becfa9; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #284308;
    border-color: #284308; }

.list-group-item-info {
  color: #125a65;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #125a65;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #125a65;
    border-color: #125a65; }

.list-group-item-warning {
  color: #855c18;
  background-color: #fce6c1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #855c18;
    background-color: #fbdca9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #855c18;
    border-color: #855c18; }

.list-group-item-danger {
  color: #720714;
  background-color: #f2b8bf; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #720714;
    background-color: #eea2ac; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #720714;
    border-color: #720714; }

.list-group-item-light {
  color: #888888;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #888888;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #888888;
    border-color: #888888; }

.list-group-item-dark {
  color: #4e5358;
  background-color: #dfe1e4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #4e5358;
    background-color: #d1d4d8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #4e5358;
    border-color: #4e5358; }

.list-group-item-brown {
  color: #5d5445;
  background-color: #e6e2d9; }
  .list-group-item-brown.list-group-item-action:hover, .list-group-item-brown.list-group-item-action:focus {
    color: #5d5445;
    background-color: #dcd6ca; }
  .list-group-item-brown.list-group-item-action.active {
    color: #ffffff;
    background-color: #5d5445;
    border-color: #5d5445; }

.list-group-item-dark-grey {
  color: #4e5358;
  background-color: #dfe1e4; }
  .list-group-item-dark-grey.list-group-item-action:hover, .list-group-item-dark-grey.list-group-item-action:focus {
    color: #4e5358;
    background-color: #d1d4d8; }
  .list-group-item-dark-grey.list-group-item-action.active {
    color: #ffffff;
    background-color: #4e5358;
    border-color: #4e5358; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #0c0c0c;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #0c0c0c;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(12, 12, 12, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #0c0c0c; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #0c0c0c; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #0c0c0c; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #0c0c0c; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #0c0c0c; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #0c0c0c;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(12, 12, 12, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(12, 12, 12, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(12, 12, 12, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(12, 12, 12, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #0c0c0c; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #417505 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #264403 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #f5a623 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #db8c0a !important; }

.bg-danger {
  background-color: #d0021b !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9d0214 !important; }

.bg-light {
  background-color: #fafafa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #8b959e !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #707c86 !important; }

.bg-brown {
  background-color: #a79779 !important; }

a.bg-brown:hover, a.bg-brown:focus,
button.bg-brown:hover,
button.bg-brown:focus {
  background-color: #8f7e5e !important; }

.bg-dark-grey {
  background-color: #8b959e !important; }

a.bg-dark-grey:hover, a.bg-dark-grey:focus,
button.bg-dark-grey:hover,
button.bg-dark-grey:focus {
  background-color: #707c86 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #417505 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #f5a623 !important; }

.border-danger {
  border-color: #d0021b !important; }

.border-light {
  border-color: #fafafa !important; }

.border-dark {
  border-color: #8b959e !important; }

.border-brown {
  border-color: #a79779 !important; }

.border-dark-grey {
  border-color: #8b959e !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(12, 12, 12, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(12, 12, 12, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(12, 12, 12, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #417505 !important; }

a.text-success:hover, a.text-success:focus {
  color: #182c02 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #f5a623 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c37d09 !important; }

.text-danger {
  color: #d0021b !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #840111 !important; }

.text-light {
  color: #fafafa !important; }

a.text-light:hover, a.text-light:focus {
  color: #d4d4d4 !important; }

.text-dark {
  color: #8b959e !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #646f78 !important; }

.text-brown {
  color: #a79779 !important; }

a.text-brown:hover, a.text-brown:focus {
  color: #807054 !important; }

.text-dark-grey {
  color: #8b959e !important; }

a.text-dark-grey:hover, a.text-dark-grey:focus {
  color: #646f78 !important; }

.text-body {
  color: #0c0c0c !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(12, 12, 12, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #0c0c0c; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: AvenirNext;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/AvenirNext/AvenirNextLTPro-Regular.otf") format("opentype"); }

@font-face {
  font-family: AvenirNext-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/AvenirNext/AvenirNextLTPro-Medium.ttf"); }

@font-face {
  font-family: AvenirNext-Demi;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/AvenirNext/AvenirNextLTPro-Demi.otf") format("opentype"); }

@font-face {
  font-family: AvenirNext-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/AvenirNext/AvenirNextLTPro-Bold.otf") format("opentype"); }

body {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 15px;
  font-weight: 400;
  margin: 0; }

pre {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

::-webkit-full-page-media,
_:future,
:root body,
input {
  caret-color: #000;
  letter-spacing: -1px; }

::-webkit-full-page-media,
_:future,
:root input.form-control {
  caret-color: #000;
  letter-spacing: -1px; }

:root input.form-control:disabled {
  background-color: rgba(139, 149, 158, 0.1) !important; }

::-webkit-full-page-media,
_:future,
:root input.form-control:hover,
input.form-control:focus {
  border: none !important;
  outline-color: #0c0c0c !important;
  outline-width: 1px !important;
  outline-style: solid !important; }

::-webkit-full-page-media,
_:future,
:root .vehicle-card .card-body .sub-title {
  letter-spacing: -0.2px !important; }

::-webkit-full-page-media,
_:future,
:root label {
  letter-spacing: 0px !important; }

::-webkit-full-page-media,
_:future,
:root .btn-anchor {
  letter-spacing: -1px !important; }

::-webkit-full-page-media,
_:future,
:root .btn-brown {
  letter-spacing: -1px !important; }

:root .btn-black {
  background-color: #8b959e; }

:root .btn-green {
  letter-spacing: -1px !important;
  color: #212529;
  background-color: #417505;
  border-color: #417505; }

* {
  box-sizing: border-box; }

.active-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: #d0021b; }
  .active-indicator.active {
    background-color: #417505; }

.monitoring-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: #d0021b; }
  .monitoring-indicator.high {
    background-color: #d0021b; }
  .monitoring-indicator.medium {
    background-color: #f5a623; }
  .monitoring-indicator.low {
    background-color: #417505; }

.horizontal-images-list {
  height: 100px;
  width: auto;
  max-width: 100%;
  object-fit: contain; }

.add-link .add-link-icon {
  width: 18px;
  height: 18px;
  object-fit: contain; }

.add-link .add-link-icon-svg {
  width: 30px;
  height: 30px;
  object-fit: contain; }

.change-link {
  font-size: 12px; }

.profile-default-logo {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(139, 149, 158, 0.25);
  border-radius: 50%;
  color: #8b959e;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 3px 0 3px; }

.profile-default-logo-top {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(139, 149, 158, 0.25);
  border-radius: 50%;
  color: #8b959e;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 3px 0 3px; }

.form-group {
  position: relative; }

.company-logo-top {
  border-radius: 50%;
  height: 45px !important;
  width: 45px !important;
  border: 1px solid rgba(139, 149, 158, 0.25); }

.shared-users-default {
  display: inline-flex;
  position: absolute;
  right: 45px;
  top: 20px; }

.shared-users-default-logo {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0c0c0c;
  border-radius: 50%;
  color: #0c0c0c;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  padding: 4px 3px 0 3px; }

.chat-users-default-logo {
  height: 34px;
  width: 34px;
  min-width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0c0c0c;
  border-radius: 50%;
  color: #0c0c0c;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  padding: 4px 3px 0 3px; }

.default-logo {
  height: 34px;
  width: 34px;
  min-width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(139, 149, 158, 0.25);
  border-radius: 50%;
  color: #8b959e;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 3px 0 3px; }

.credit-card-img {
  height: 30px;
  width: auto; }

.form-row .credit-card-img {
  margin-top: 28px; }

.radius-eligibility {
  margin-bottom: 40px; }

.create-rate-card {
  margin-bottom: 20px;
  cursor: pointer; }

.sub-header {
  font-size: 20px;
  font-weight: lighter;
  margin-bottom: 50px; }

.sub-header-quote {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 18px;
  font-weight: lighter; }

.sub-header-rates {
  font-size: 20px;
  font-weight: lighter;
  margin-bottom: 10px; }

.small-header {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #8b959e; }

hr {
  width: 100%;
  border-color: rgba(139, 149, 158, 0.25); }

.tooltip {
  font-size: 12px; }

.height150 {
  height: 50px;
  max-height: 50px;
  overflow: hidden; }

.device-icons {
  width: 10px;
  margin-top: -5px;
  margin-right: 10px; }

.modal-padding {
  padding-top: 30px; }

.hidden {
  display: none; }

.booking-notes {
  white-space: pre-wrap;
  word-break: break-all; }

.test-modal .modal-header {
  border-bottom: none !important;
  padding-bottom: 0px; }
  .test-modal .modal-header .modal-title {
    width: 100%; }
    .test-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .test-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .test-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .test-modal .modal-header .modal-title .btn-link {
      margin-right: 5%;
      margin-top: 3%; }
      .test-modal .modal-header .modal-title .btn-link img {
        width: 60px; }

.test-modal .title {
  font-size: 20px;
  color: #0c0c0c;
  text-align: center;
  font-weight: 400;
  margin-top: 2%;
  padding-bottom: 2%; }

.test-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 50px !important; }
  .test-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.test-modal .modal-description {
  font-size: 14px;
  color: #636d77;
  text-align: center; }

.test-modal .modal-description-draft {
  font-size: 14px;
  color: #636d77;
  text-align: center;
  margin: 0px; }

.test-modal .SharedBooking-modal-description {
  font-size: 15px;
  color: #8b959e;
  text-align: center; }

.filter-flex-23-9 {
  flex: 0 0 23.9% !important; }

.vehicle-options-drop {
  width: 149px !important; }

span.slideInner span {
  position: absolute;
  display: block;
  top: 30px;
  right: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer; }
  span.slideInner span img {
    width: 10px;
    height: 10px; }

span.slideInner input {
  padding-right: 16px;
  box-sizing: border-box; }

.stop-remove {
  font-size: 11.5px !important;
  padding-bottom: 3.5px !important;
  line-height: 1px !important;
  padding-left: 3px !important; }

span.slideInnerDropOff span {
  position: absolute;
  display: block;
  top: 30px;
  right: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer; }
  span.slideInnerDropOff span img {
    width: 10px;
    height: 10px; }

span.slideInnerDropOff input {
  padding-right: 16px;
  box-sizing: border-box; }

.minWith6rem {
  min-width: 6.3rem; }

.minWith7rem {
  min-width: 7rem; }

.minWith8rem {
  min-width: 8rem; }

.minWith10rem {
  min-width: 10rem; }

.minWith13rem {
  min-width: 13rem; }

.minWith14rem {
  min-width: 14rem; }

.minWith15rem {
  min-width: 15rem; }

.displayBlock {
  display: block; }

.toUpperCase {
  text-transform: uppercase; }

.payment-field {
  border: none !important;
  outline-color: #0c0c0c !important;
  outline-width: 1px !important;
  outline-style: solid !important; }

.payment-field-right {
  width: 49%; }

.payment-field-accepted {
  background-color: rgba(139, 149, 158, 0.1) !important;
  border: none !important; }

.payment-field-mismatch {
  border: none !important;
  outline-color: #d0021b !important;
  outline-width: 1px !important;
  outline-style: solid !important; }

.payment-field-dispatch {
  border: none !important;
  outline-color: #0c0c0c !important;
  outline-width: 1px !important;
  outline-style: solid !important; }

.company-name-dispatch {
  font-weight: bold;
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
  white-space: initial;
  padding-top: 0 !important;
  text-align: left;
  padding: 10px;
  padding-bottom: 25px;
  padding-left: 0; }
  .company-name-dispatch .invisible {
    visibility: hidden; }

.payment-field-hover {
  border: none !important; }

.marginBottom-4 {
  margin-bottom: 40px; }

.warning-banner {
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid black;
  border-radius: 2px;
  height: auto;
  display: flex; }
  .warning-banner .imageContainer {
    float: left;
    align-self: center;
    margin-left: 15px; }
    .warning-banner .imageContainer img {
      width: 17px; }
  .warning-banner .warningContainer {
    font-size: 14px;
    margin: 15px;
    color: #0c0c0c; }
    .warning-banner .warningContainer .mobile {
      font-size: 13px !important; }
    .warning-banner .warningContainer .header {
      font-weight: 800 !important; }
    .warning-banner .warningContainer span {
      font-weight: 300; }

.warning-banner-red {
  background-color: rgba(208, 2, 27, 0.1);
  border: 1px solid #d0021b;
  border-radius: 2px;
  height: auto;
  display: flex; }
  .warning-banner-red .imageContainer {
    float: left;
    align-self: center;
    margin-left: 15px; }
    .warning-banner-red .imageContainer img {
      width: 17px; }
  .warning-banner-red .warningContainer {
    font-size: 14px;
    margin: 15px;
    color: #0c0c0c; }
    .warning-banner-red .warningContainer .mobile {
      font-size: 13px !important; }
    .warning-banner-red .warningContainer .header {
      font-weight: 800 !important; }
    .warning-banner-red .warningContainer span {
      font-weight: 300; }
  .warning-banner-red .warningContainer-dispute {
    font-size: 14px;
    margin: 15px 10px 15px;
    padding-top: 0.2rem;
    color: #0c0c0c; }
    .warning-banner-red .warningContainer-dispute .mobile {
      font-size: 13px !important; }
    .warning-banner-red .warningContainer-dispute .header {
      text-shadow: 0.3px 0 #0c0c0c; }

.warning-banner-dark {
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid black;
  border-radius: 2px;
  height: auto;
  display: flex; }
  .warning-banner-dark .imageContainer {
    float: left;
    align-self: center;
    margin-left: 15px; }
    .warning-banner-dark .imageContainer img {
      width: 17px; }
  .warning-banner-dark .warningContainer {
    font-size: 14px;
    margin: 15px;
    color: #0c0c0c; }
    .warning-banner-dark .warningContainer .mobile {
      font-size: 13px !important; }
    .warning-banner-dark .warningContainer .header {
      font-weight: 800 !important; }
    .warning-banner-dark .warningContainer span {
      font-weight: 500; }

.goldenBorder {
  border: 1px solid #a79779 !important; }

.quote-continue-btn {
  display: flex;
  flex-direction: column;
  align-items: end; }

.error-msg {
  color: red;
  font-size: 14px;
  margin: 0; }

.error-msg-client {
  color: #d0021b;
  font-size: 12px;
  margin: 0;
  margin-top: 1rem;
  width: 85%; }

.error-msg-partner {
  color: red;
  font-size: 12px;
  text-align: end;
  margin-bottom: 1%;
  margin-right: 6%; }

.masqurade-banner {
  height: 40px;
  bottom: 0%;
  width: 10%;
  background-color: #8b959e;
  opacity: 1;
  position: fixed;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, 5%);
  margin: 0 auto; }
  .masqurade-banner .inner-masqurader {
    display: flex;
    justify-content: center; }
    .masqurade-banner .inner-masqurader span {
      color: #ffffff;
      font-size: 14px;
      margin-right: 15px;
      padding-top: 1px; }
    .masqurade-banner .inner-masqurader .download-link {
      color: #ffffff;
      font-size: 14px;
      text-decoration-line: underline; }
      .masqurade-banner .inner-masqurader .download-link:hover {
        text-decoration: none; }

.useless-class .paymentsRow {
  border-bottom: 1px solid rgba(139, 149, 158, 0.2);
  margin-left: 0.2rem;
  margin-right: 0.4rem; }

.useless-class .NoBorderBottom {
  border-bottom: none !important; }

.useless-class .paymentsLinks {
  display: block;
  font-size: 16px;
  margin-bottom: 0.8rem !important;
  margin-top: 0.8rem !important;
  color: #0c0c0c !important;
  padding-left: 0% !important; }

.driverBottomMenu {
  border: 0px;
  margin: 0px;
  border-left: 1px solid rgba(139, 149, 158, 0.2);
  height: 200px; }

.tableWidth {
  max-width: 8rem;
  white-space: pre; }

.tableWidthQuotes {
  max-width: 6rem; }

.smallWidthQuotes {
  max-width: 4rem; }

.smallTableWidth {
  max-width: 7rem; }

.clock-img {
  width: 19px !important; }

.location-img {
  width: 17px !important;
  margin-left: 2px;
  height: 24px;
  margin-top: 3px; }

.partner-duration {
  margin-left: 13px !important; }

.partner-arrival {
  margin-left: 17px !important; }

.pickup-block {
  display: flex;
  margin-top: 1rem; }
  .pickup-block img {
    width: 11px !important;
    height: 11px;
    margin-left: 4px;
    margin-top: 5px; }

.pdf-board {
  color: #a79779;
  margin-left: 1.5rem !important;
  font-weight: 900;
  text-shadow: 0.25px 0 #a79779; }

.driver-booking-container {
  margin: 5%; }
  .driver-booking-container .driver-booking-card {
    margin-top: 1.5rem; }
  .driver-booking-container .driver-booking-header {
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 1em 1em;
    margin-top: 1em;
    padding-bottom: 0.5em;
    border-bottom: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
    .driver-booking-container .driver-booking-header .driver-title {
      text-align: center;
      text-transform: uppercase; }
    .driver-booking-container .driver-booking-header .driver-title-partner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      text-transform: uppercase; }
  .driver-booking-container .driver-border-bottom {
    border-bottom: 1.5px solid rgba(139, 149, 158, 0.2) !important; }
  .driver-booking-container .card-top-indicator {
    top: 1.3em;
    height: 6px;
    position: relative;
    background-color: #417505;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .driver-booking-container .driver-booking-body {
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 1em 1em;
    font-size: 16px;
    border-bottom: none; }
    .driver-booking-container .driver-booking-body .passenger-name {
      font-size: 22px; }
    .driver-booking-container .driver-booking-body .date {
      margin-top: 1.5rem; }
      .driver-booking-container .driver-booking-body .date span {
        margin-left: 6px; }
    .driver-booking-container .driver-booking-body .date-daily {
      margin-top: 1.5rem;
      display: flex; }
      .driver-booking-container .driver-booking-body .date-daily span {
        margin-left: 10px; }
    .driver-booking-container .driver-booking-body .vehicle {
      margin-top: 1rem; }
      .driver-booking-container .driver-booking-body .vehicle span {
        margin-left: 10px; }
    .driver-booking-container .driver-booking-body .vehicle-drop {
      margin-top: 1rem;
      display: flex; }
      .driver-booking-container .driver-booking-body .vehicle-drop span {
        margin-left: 14px;
        display: block; }
    .driver-booking-container .driver-booking-body img {
      width: 22px;
      margin-bottom: 5px; }
    .driver-booking-container .driver-booking-body .passenger-details {
      margin-top: 0.5rem; }
      .driver-booking-container .driver-booking-body .passenger-details span {
        margin-left: 5px; }
    .driver-booking-container .driver-booking-body .to-from-chauffeur {
      margin-top: 1rem;
      display: flex; }
      .driver-booking-container .driver-booking-body .to-from-chauffeur span {
        margin-left: 15px;
        display: block; }
      .driver-booking-container .driver-booking-body .to-from-chauffeur img {
        width: 16px;
        height: 20px;
        margin-left: 2px;
        margin-top: 2px; }
    .driver-booking-container .driver-booking-body .stop-partner {
      margin-top: 1rem;
      display: flex; }
      .driver-booking-container .driver-booking-body .stop-partner span {
        margin-left: 15px;
        display: block; }
      .driver-booking-container .driver-booking-body .stop-partner img {
        width: 14px;
        height: 14px;
        margin-top: 12px;
        margin-left: 3px; }
    .driver-booking-container .driver-booking-body .flight {
      margin-top: 1rem; }
      .driver-booking-container .driver-booking-body .flight span {
        margin-left: 12px; }
      .driver-booking-container .driver-booking-body .flight img {
        width: 21px;
        transform: rotate(90deg);
        margin-bottom: 2px !important; }
    .driver-booking-container .driver-booking-body .airport {
      margin-top: 1rem; }
      .driver-booking-container .driver-booking-body .airport span {
        margin-left: 12px; }
      .driver-booking-container .driver-booking-body .airport img {
        width: 21px;
        margin-bottom: 2px !important; }
    .driver-booking-container .driver-booking-body .train {
      margin-top: 1rem; }
      .driver-booking-container .driver-booking-body .train span {
        margin-left: 12px; }
      .driver-booking-container .driver-booking-body .train img {
        width: 18px;
        height: 23px;
        margin-left: 1.5px;
        margin-bottom: 0px !important; }
    .driver-booking-container .driver-booking-body .nameboard {
      margin-top: 1rem; }
      .driver-booking-container .driver-booking-body .nameboard span {
        margin-left: 11px;
        color: #a79779;
        font-weight: 900;
        text-shadow: 0.25px 0 #a79779; }
      .driver-booking-container .driver-booking-body .nameboard img {
        padding-top: 2px;
        width: 23px;
        height: 17px; }
  .driver-booking-container .image-container {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    align-items: center;
    margin-right: 0.8rem;
    width: 2rem;
    padding-top: 2.5%;
    padding-left: 5%;
    height: 65px;
    max-height: 65px; }
  .driver-booking-container .driver-booking-cta {
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 0.5em 0.5em;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    .driver-booking-container .driver-booking-cta img {
      width: 10%;
      margin: 0.5em; }
  .driver-booking-container .driver-booking-price {
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 0.5em 0.5em;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
  .driver-booking-container .driver-booking-price-card {
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 0.5em 0.5em;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    justify-content: center; }
  .driver-booking-container .partner-location-container {
    display: flex;
    column-gap: 1em; }
    .driver-booking-container .partner-location-container .partner-indicator {
      display: flex;
      flex-direction: column;
      margin-left: 7px; }
      .driver-booking-container .partner-location-container .partner-indicator .circle-empty {
        height: 8px;
        width: 8px;
        border: 1.5px solid #8b959e;
        border-radius: 50%;
        display: inline-block;
        z-index: 0;
        background-color: white; }
      .driver-booking-container .partner-location-container .partner-indicator .circle-filled-pu {
        height: 8px;
        width: 8px;
        background-color: #8c969f;
        border-radius: 50%;
        display: inline-block;
        z-index: 0; }
      .driver-booking-container .partner-location-container .partner-indicator .circle-filled {
        height: 8px;
        width: 8px;
        background-color: #0c0c0c;
        border-radius: 50%;
        display: inline-block;
        z-index: 0; }
      .driver-booking-container .partner-location-container .partner-indicator .dotted-container {
        position: relative;
        width: 8px;
        height: 56px;
        overflow: hidden; }
      .driver-booking-container .partner-location-container .partner-indicator .dotted-container-stop {
        position: relative;
        width: 8px;
        height: 85px;
        overflow: hidden;
        top: 6px;
        z-index: -1; }
      .driver-booking-container .partner-location-container .partner-indicator .dotted-line {
        transform: rotate(90deg);
        position: absolute;
        top: -9999px;
        left: -9999px;
        right: -9999px;
        bottom: -9999px;
        margin: auto; }
    .driver-booking-container .partner-location-container .partner-fields {
      width: 100%;
      margin-left: 3px; }
      .driver-booking-container .partner-location-container .partner-fields p:last-child {
        margin-bottom: 0 !important; }
  .driver-booking-container .partner-countdown span:nth-child(odd) {
    font-size: 18px;
    font-weight: 400;
    padding-right: 1px; }
  .driver-booking-container .partner-countdown span:nth-child(even) {
    font-size: 10px;
    font-weight: 500;
    padding-right: 10px; }
  .driver-booking-container .partner-countdown span:last-child {
    padding-right: 0; }
  .driver-booking-container .tabs-container .custom-tabs-nav-driver {
    background: rgba(139, 149, 158, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px; }
    .driver-booking-container .tabs-container .custom-tabs-nav-driver .nav-link {
      background-color: transparent;
      color: rgba(139, 149, 158, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;
      opacity: inherit !important; }
      .driver-booking-container .tabs-container .custom-tabs-nav-driver .nav-link:hover {
        color: rgba(139, 149, 158, 0.7); }
      .driver-booking-container .tabs-container .custom-tabs-nav-driver .nav-link.active {
        background-color: #ffffff;
        color: #323c47;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs {
      border-bottom: 1px solid rgba(139, 149, 158, 0.3);
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0; }
      .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 15px;
        font-weight: 700;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none; }
        .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid #0c0c0c;
          box-shadow: none;
          color: #303030;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 700;
          font-size: 15px; }
        .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link:focus {
          box-shadow: none; }
        .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link:not(:last-child) {
          margin-right: 24px; }
  .driver-booking-container .tabs-container .card-body {
    padding: 0;
    padding-left: 6px; }
    .driver-booking-container .tabs-container .card-body label.expand-title {
      margin-bottom: 10px;
      font-size: 10px; }
    .driver-booking-container .tabs-container .card-body label.expand-title-service {
      font-size: 10px; }
    .driver-booking-container .tabs-container .card-body p {
      margin-bottom: 5px;
      font-size: 14px; }
  .driver-booking-container .driver-info-layout {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10rem); }
    .driver-booking-container .driver-info-layout .driver-info-column {
      width: 49%; }
  .driver-booking-container .driver-info-layout-mobile {
    margin-top: 2rem; }
  .driver-booking-container .driver-info-layout-banner {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: calc(100% - 10rem); }
    .driver-booking-container .driver-info-layout-banner .driver-info-column {
      width: 49%; }
  .driver-booking-container .driver-service-card {
    border-radius: 10px;
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 1.5em 1.5em;
    font-size: 15px;
    margin-top: 1em; }
    .driver-booking-container .driver-service-card .driver-service-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px; }
      .driver-booking-container .driver-service-card .driver-service-card-header .select-title {
        font-size: 15px;
        font-weight: 700; }
    .driver-booking-container .driver-service-card .driver-service-card-header-app {
      display: flex;
      flex-direction: column;
      font-weight: bold; }
      .driver-booking-container .driver-service-card .driver-service-card-header-app img {
        width: 8%;
        margin-bottom: 1rem; }
    .driver-booking-container .driver-service-card .name-board {
      display: flex;
      justify-content: end;
      margin-top: 5%; }
    .driver-booking-container .driver-service-card .driver-docs-pending {
      margin-bottom: 0.5em;
      margin-top: 2em !important;
      text-align: end;
      font-weight: 700; }
      .driver-booking-container .driver-service-card .driver-docs-pending span {
        color: #f5a623; }
    .driver-booking-container .driver-service-card .partner-app-para {
      margin-top: 0.5rem; }
    .driver-booking-container .driver-service-card .caution {
      color: #d0021b; }
      .driver-booking-container .driver-service-card .caution .caution-symbol {
        border: 1px solid #d0021b;
        padding: 2px 5px 0px 5px;
        border-radius: 50%;
        font-size: small;
        margin-right: 1em; }
    .driver-booking-container .driver-service-card .driver-service-card-body {
      margin-top: 1.5em; }
      .driver-booking-container .driver-service-card .driver-service-card-body div:not(:first-child) {
        margin-top: 0.5em; }
    .driver-booking-container .driver-service-card .driver-service-card-body-container {
      margin-bottom: 50px;
      margin-top: 20px; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .step {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 20px; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .v-stepper {
        position: relative; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .update-circle-filled {
        position: absolute;
        height: 8px;
        width: 8px;
        background-color: #0c0c0c;
        border-radius: 50%;
        display: inline-block; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .update-circle {
        position: absolute;
        height: 8px;
        width: 8px;
        border: 2px solid black;
        border-radius: 50%;
        display: inline-block; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .vertical-filled {
        border: 0;
        border-left: 1px solid #0c0c0c;
        height: 100%;
        margin-top: 14px;
        margin-bottom: 0;
        position: absolute;
        margin-left: 3px; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .vertical-dashed {
        border: 0;
        border-left: 1px dashed #0c0c0c;
        height: 100%;
        margin-top: 14px;
        margin-bottom: 0;
        position: absolute;
        margin-left: 3px; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .driver-service-card-container {
        width: 100%; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .driver-service-card-item-updates {
        display: grid;
        grid-template-columns: 10% 70%;
        grid-auto-flow: column;
        background-color: rgba(139, 149, 158, 0.1);
        padding: 1em 1em;
        border-radius: 2px; }
    .driver-booking-container .driver-service-card .important-info {
      padding-top: 1.5em;
      font-size: 16px; }
      .driver-booking-container .driver-service-card .important-info div {
        background: url("/images/icons/icon-checklist-black.png");
        background-position: top 4px left 4px;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        padding-left: 28px;
        margin-bottom: 4px; }
    .driver-booking-container .driver-service-card .driver-service-card-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(139, 149, 158, 0.1);
      padding: 1em 1em;
      font-size: 15px; }
      .driver-booking-container .driver-service-card .driver-service-card-item .from-to-content {
        flex: 0 1 100%;
        text-align: right; }
      .driver-booking-container .driver-service-card .driver-service-card-item .from-to-title {
        flex: 1 0 50%; }
      .driver-booking-container .driver-service-card .driver-service-card-item span:nth-child(odd) {
        color: #8b959e; }
      .driver-booking-container .driver-service-card .driver-service-card-item span.daily-items {
        color: #0c0c0c !important; }
    .driver-booking-container .driver-service-card .driver-service-card-item-seats {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: rgba(139, 149, 158, 0.1);
      padding: 0.5em 1em 1em 1em;
      font-size: 15px; }
      .driver-booking-container .driver-service-card .driver-service-card-item-seats img {
        width: 20px; }
    .driver-booking-container .driver-service-card .driver-service-card-item-left {
      display: flex;
      align-items: flex-start; }
    .driver-booking-container .driver-service-card .driver-service-card-item-left img {
      width: 22px;
      margin-top: 5px; }
    .driver-booking-container .driver-service-card .driver-service-card-item-seat {
      display: flex;
      align-items: flex-start;
      margin-top: 15px; }
    .driver-booking-container .driver-service-card .driver-service-card-item-seat img {
      width: 22px; }
    .driver-booking-container .driver-service-card .driver-service-card-item-with-desc-mob {
      margin-left: 10px; }
      .driver-booking-container .driver-service-card .driver-service-card-item-with-desc-mob span {
        display: block;
        font-size: 14px;
        color: #636d77; }
      .driver-booking-container .driver-service-card .driver-service-card-item-with-desc-mob span:first-child {
        font-size: 15px;
        margin-bottom: 5px;
        color: black; }
    .driver-booking-container .driver-service-card .driver-service-card-item-with-desc {
      margin-left: 10px; }
      .driver-booking-container .driver-service-card .driver-service-card-item-with-desc span {
        display: block;
        font-size: 12px;
        color: #303030; }
      .driver-booking-container .driver-service-card .driver-service-card-item-with-desc span:first-child {
        color: #8b959e;
        font-size: 15px;
        margin-bottom: 5px; }
    .driver-booking-container .driver-service-card .driver-service-card-item-quantity {
      margin-top: 7px; }
    .driver-booking-container .driver-service-card .status-update-timestamp {
      float: right;
      clear: both;
      padding-top: 3px;
      font-size: 12px;
      color: #8b959e; }
    .driver-booking-container .driver-service-card .driver-service-card-item-updates-complete {
      display: grid;
      grid-template-columns: 10% 62%;
      grid-auto-flow: column;
      background-color: rgba(139, 149, 158, 0.1);
      padding: 1em 1em;
      border-radius: 2px; }
    .driver-booking-container .driver-service-card .updates-complete {
      grid-template-columns: 10% 80% !important; }
    .driver-booking-container .driver-service-card .indicator-black {
      border-top: 3px solid #0c0c0c !important; }
    .driver-booking-container .driver-service-card .indicator-red {
      border-top: 3px solid #d0021b !important; }
  .driver-booking-container .partner-accept-container {
    display: flex;
    flex-direction: column;
    align-items: end; }
  .driver-booking-container .partner-dispatch-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 1em; }
  .driver-booking-container .partner-terms {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 2rem; }
    .driver-booking-container .partner-terms a {
      color: #a79779; }
  .driver-booking-container .partner-next-container-form {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em; }
  .driver-booking-container .partner-next-container {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em; }
  .driver-booking-container .partner-next-container-assign-multi {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em;
    width: calc(100vw - 60%); }
  .driver-booking-container .partner-next-container-assign {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em;
    width: calc(100vw - 80%); }
  .driver-booking-container .card-message {
    line-height: 12px;
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    font-weight: 400;
    color: #8b959e;
    display: block;
    margin-bottom: 0.3rem; }
  .driver-booking-container .newOffer-message {
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #8b959e;
    display: block;
    margin-bottom: 0.3rem; }
  .driver-booking-container .counter {
    font-size: 18px;
    font-weight: 400; }
  .driver-booking-container .offer-date {
    font-size: 14px;
    font-weight: 700; }
  .driver-booking-container .partner-action-title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 0.4em; }
  .driver-booking-container .partner-action-counter {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #d0021b; }
  .driver-booking-container .partner-action-message {
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #8b959e;
    display: block;
    margin-bottom: 0.3rem; }

.partner-container {
  background-color: black;
  font-size: 16px;
  height: fit-content;
  color: white;
  border-radius: 15px;
  padding: 6px 15px 5px 15px;
  margin-right: 15px; }

.partner-card {
  width: calc(100vw - 80%);
  padding: 1.5em 1.5em;
  font-size: 15px;
  border: none; }
  .partner-card input.custom-datepicker-control {
    background-color: transparent !important;
    background-image: url("/images/icons/calendar.png");
    background-size: 17px 17px;
    background-position: center right 13px !important;
    background-repeat: no-repeat;
    padding-right: 30px;
    color: rgba(12, 12, 12, 0.3); }
    .partner-card input.custom-datepicker-control.active {
      background-image: url("/images/icons/calendar_active.png");
      color: #0c0c0c; }
  .partner-card .custom-datepicker-calendar {
    background-color: transparent !important; }
  .partner-card .react-datepicker__header {
    background-color: transparent !important; }

.driver-booking-cta-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  background-color: #ffffff;
  padding: 1em 1.5em 1em 1.5em; }

.booking-filters-cta-footer {
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  background-color: #ffffff;
  padding: 1em 1em 1em 1.5em;
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vh - 42rem);
  margin-bottom: 5rem; }
  .booking-filters-cta-footer span {
    font-size: 16px;
    color: #a5977c;
    display: flex;
    align-self: center;
    text-shadow: #a5977c 0.25px 0px;
    font-weight: 900; }

.partner-filters-cta-footer {
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  background-color: #ffffff;
  padding: 1em 1em 1em 1.5em;
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vh - 34rem);
  margin-bottom: 4rem; }
  .partner-filters-cta-footer span {
    font-size: 16px;
    color: #a5977c;
    display: flex;
    align-self: center;
    text-shadow: #a5977c 0.25px 0px;
    font-weight: 900; }

.driver-booking-cta-footer-acc {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  background-color: #ffffff;
  padding: 0em 1.5em 0em 1.5em; }

.driver-booking-cta-footer-no-border {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  padding: 1em 1.5em; }

.driver-booking-cta-footer-assign {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em; }

.driver-booking-container-header {
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  background-color: #ffffff;
  padding: 1em 1.5em;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bolder; }

.driver-booking-container-header-booking {
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  background-color: #ffffff;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bolder;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }
  .driver-booking-container-header-booking img {
    width: 6%;
    height: 6%;
    display: flex; }

.osm-maps-marker-img {
  height: 26px;
  width: 26px; }

.osm-maps-marker-stops-img {
  height: 22px;
  width: 22px; }

.osm-maps-marker-span {
  position: absolute;
  top: 50%;
  left: 40%;
  color: white; }

.osm-maps-marker-stops-span {
  position: absolute;
  top: 3px;
  left: 8px;
  font-size: 11px;
  color: white; }

.osm-trip-duration-container {
  border: 1px solid black;
  background-color: white;
  border-radius: 2px;
  padding: 3px;
  width: 240px !important;
  height: auto !important;
  margin-left: 30px !important;
  margin-top: 30px !important; }
  .osm-trip-duration-container img {
    display: inline-block;
    vertical-align: middle;
    width: 22px !important;
    height: 30px;
    margin-left: 5px; }
  .osm-trip-duration-container span {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 36px);
    margin-left: 7px; }

.osm-trip-duration-container-b2c {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1000;
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  padding: 3px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .osm-trip-duration-container-b2c img {
    display: inline-block;
    vertical-align: middle;
    width: 20px !important;
    height: 25px;
    margin-left: 5px; }
  .osm-trip-duration-container-b2c span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-right: 4px; }

.osm-popup .leaflet-popup-content-wrapper {
  border: 1px solid black;
  background-color: white;
  border-radius: 2px;
  width: 240px !important;
  height: auto !important;
  margin-left: 15px !important;
  box-shadow: none; }
  .osm-popup .leaflet-popup-content-wrapper span {
    display: inline-block;
    vertical-align: middle; }

.osm-popup .leaflet-popup-tip {
  border: 1px solid black; }

.driver-booking-container-header-booking-support {
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  background-color: #ffffff;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bolder; }
  .driver-booking-container-header-booking-support img {
    width: 6%;
    height: 6%;
    display: flex; }

.text-no-border {
  border-bottom: none !important; }

.driver-booking-details-container {
  padding: 1em 1.5em;
  font-size: 20px; }

.driver-back-icon {
  top: 4px;
  right: 5px;
  position: relative; }

.driver-back-icon-assign {
  position: absolute;
  top: 19px;
  width: 22px;
  left: 23px; }

.driver-back-icon-account {
  position: absolute;
  top: 19px;
  width: 22px;
  left: 30px; }

.alert-countdown span:nth-child(odd) {
  font-size: 14px;
  font-weight: 400;
  padding-right: 1px; }

.alert-countdown span:nth-child(even) {
  font-size: 12px;
  font-weight: 500;
  padding-right: 5px; }

.alert-countdown span:last-child {
  padding-right: 0; }

.driver-add-card {
  display: flex;
  border-radius: 5px;
  border: 1px solid rgba(139, 149, 158, 0.5);
  background-color: #ffffff;
  padding: 1em 1em;
  justify-content: space-between;
  align-items: center; }
  .driver-add-card .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #8b959e;
    justify-content: center;
    border-radius: 50%; }
  .driver-add-card .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black; }
  .driver-add-card .car-img {
    width: 70%; }
  .driver-add-card .car-img-chauffeur {
    width: 55%; }
  .driver-add-card .car-edit-img {
    width: 25%;
    margin-right: 10px;
    align-self: flex-start;
    padding-top: 2px; }
  .driver-add-card .profile-img {
    width: 40px;
    height: 40px; }
  .driver-add-card .add-img {
    width: 28px;
    padding-right: 5px; }
  .driver-add-card .add-img-car {
    width: 23px; }
  .driver-add-card .edit-img img {
    width: 23px; }
  .driver-add-card .add-span {
    flex: 0 0 65%;
    font-size: 16px;
    padding-top: 5px;
    color: #a5977c;
    font-weight: 700; }
  .driver-add-card .info-span {
    flex: 0 0 65%;
    font-size: 16px; }

.dispatch-list-partner {
  display: flex;
  border-radius: 5px;
  border: 1px solid rgba(139, 149, 158, 0.5);
  background-color: #ffffff;
  padding: 1em 1em;
  justify-content: space-between;
  align-items: center; }
  .dispatch-list-partner .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #8b959e;
    justify-content: center;
    border-radius: 50%; }
  .dispatch-list-partner .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black; }
  .dispatch-list-partner .car-img {
    width: 70%; }
  .dispatch-list-partner .car-img-chauffeur {
    width: 55%; }
  .dispatch-list-partner .car-edit-img {
    width: 25%;
    margin-right: 10px;
    align-self: flex-start;
    padding-top: 2px; }
  .dispatch-list-partner .profile-img {
    width: 40px;
    height: 40px; }
  .dispatch-list-partner .add-img {
    width: 28px;
    padding-right: 5px; }
  .dispatch-list-partner .add-img-car {
    width: 23px; }
  .dispatch-list-partner .edit-img img {
    width: 23px; }
  .dispatch-list-partner .add-span {
    flex: 0 0 65%;
    font-size: 16px;
    padding-top: 5px;
    color: #a5977c;
    font-weight: 700; }
  .dispatch-list-partner .info-span {
    flex: 0 0 65%;
    font-size: 16px; }

.add-border {
  border: 1.5px solid #0c0c0c; }

.driver-edit-container h1 {
  font-size: 25px;
  margin-left: 2rem;
  margin-top: 2em; }

.driver-edit-container .image-container {
  margin-top: 3em; }
  .driver-edit-container .image-container .chauffeur-pic {
    width: 110px;
    height: 110px; }
  .driver-edit-container .image-container .chauffeur-pic-not-uploaded {
    width: 110px;
    height: 110px;
    padding: 20%; }
  .driver-edit-container .image-container .chauffeur-pic-container {
    width: fit-content;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid black; }
  .driver-edit-container .image-container .chauffeur-pic-container-border {
    width: fit-content;
    border: 1px solid #8b959e;
    border-radius: 50%;
    overflow: hidden; }
  .driver-edit-container .image-container label {
    font-size: 16px;
    padding-top: 1.5em;
    color: #a5977c;
    align-self: center;
    text-transform: none;
    font-weight: bolder; }

.driver-edit-container .driver-edit-wrap {
  min-width: 160px;
  max-width: unset;
  margin-top: 20px;
  width: 100vw; }
  .driver-edit-container .driver-edit-wrap .driver-fields {
    padding: 1em 2em;
    margin-bottom: 6em; }
    .driver-edit-container .driver-edit-wrap .driver-fields input {
      box-shadow: none !important;
      border: 1px solid rgba(139, 149, 158, 0.2) !important;
      background-color: #ffffff !important;
      font-size: 15px !important; }
      .driver-edit-container .driver-edit-wrap .driver-fields input:hover, .driver-edit-container .driver-edit-wrap .driver-fields input:focus, .driver-edit-container .driver-edit-wrap .driver-fields input:active {
        border: 0.5px solid #000000 !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields input#firstName {
      text-transform: capitalize !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields input#lastName {
      text-transform: capitalize !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields input#plateNumber {
      text-transform: uppercase !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields input#make {
      border: none !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields input#model {
      border: none !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields .selected-flag {
      box-shadow: none !important;
      border: 1px solid rgba(139, 149, 158, 0.1) !important;
      background-color: #ffffff !important; }
    .driver-edit-container .driver-edit-wrap .driver-fields .react-select-container {
      font-size: 15px !important; }

.driver-edit-container .car-container {
  display: flex;
  justify-content: center; }
  .driver-edit-container .car-container img {
    width: 60%;
    padding-top: 25px; }

.driver-edit-container-desktop .image-container-desktop {
  margin: 2em;
  display: flex;
  column-gap: 1em;
  margin-left: 1em; }
  .driver-edit-container-desktop .image-container-desktop .chauffeur-pic {
    width: 70px;
    height: 70px;
    padding: 26%; }
  .driver-edit-container-desktop .image-container-desktop .chauffeur-uploaded-pic {
    width: 70px;
    height: 70px; }
  .driver-edit-container-desktop .image-container-desktop .chauffeur-pic-container {
    width: fit-content;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid black; }
  .driver-edit-container-desktop .image-container-desktop .chauffeur-pic-container-border {
    width: fit-content;
    border: 1px solid rgba(139, 149, 158, 0.5);
    border-radius: 50%;
    overflow: hidden; }
  .driver-edit-container-desktop .image-container-desktop label {
    color: #a5977c;
    align-self: center;
    text-transform: none;
    font-size: 14px;
    margin: 0;
    letter-spacing: 0; }

.driver-edit-container-desktop .driver-edit-wrap {
  padding: 1em; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input {
    box-shadow: none !important;
    border: 1px solid rgba(139, 149, 158, 0.1) !important;
    font-size: 15px !important; }
    .driver-edit-container-desktop .driver-edit-wrap .driver-fields input:hover, .driver-edit-container-desktop .driver-edit-wrap .driver-fields input:focus, .driver-edit-container-desktop .driver-edit-wrap .driver-fields input:active {
      border: 0.5px solid #000000 !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input#firstName {
    text-transform: capitalize !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input#lastName {
    text-transform: capitalize !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input#plateNumber {
    text-transform: uppercase !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input#make {
    border: none !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input#make {
    border: none !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields input#model {
    border: none !important; }
  .driver-edit-container-desktop .driver-edit-wrap .driver-fields .selected-flag {
    box-shadow: none !important;
    border: 1px solid rgba(139, 149, 158, 0.1) !important;
    background-color: #ffffff !important; }

.driver-edit-container-desktop .car-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100px;
  max-height: 120px;
  position: relative;
  justify-content: flex-start; }
  .driver-edit-container-desktop .car-container img {
    width: 40%;
    display: block;
    height: auto;
    position: absolute;
    bottom: 0; }

.driver-booking-assign-body {
  padding: 1.5em;
  height: calc(100vh - 9.5em);
  overflow: scroll; }
  .driver-booking-assign-body h1 {
    font-size: 25px;
    padding-top: 5%;
    padding-bottom: 5%; }

.client-logo-container {
  display: flex;
  column-gap: 3em; }

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  background-color: #a79779;
  border: none;
  position: absolute;
  right: 0;
  margin: 2.5em; }
  .btn-circle img {
    width: 20px;
    height: 20px; }

.partner-banner {
  background-color: rgba(208, 2, 27, 0.1);
  border: 1px solid #d0021b;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: center; }
  .partner-banner .imageContainer {
    float: left;
    position: relative;
    align-self: center;
    margin-left: 10px; }
    .partner-banner .imageContainer img {
      width: 17px; }
  .partner-banner .partnerContainer {
    font-size: 16px;
    margin: 10px;
    color: #0c0c0c;
    display: flex;
    text-align: center; }
    .partner-banner .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner .partnerContainer span {
      font-weight: 300; }
  .partner-banner .partnerContainerComplete {
    font-size: 16px;
    margin: 15px;
    color: #0c0c0c; }
    .partner-banner .partnerContainerComplete .mobile {
      font-size: 13px !important; }
    .partner-banner .partnerContainerComplete .header {
      font-weight: 800 !important; }
    .partner-banner .partnerContainerComplete span {
      font-weight: 300; }
    .partner-banner .partnerContainerComplete .partnerContainerHead {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between; }
    .partner-banner .partnerContainerComplete .text-red {
      color: #d0021b;
      margin-bottom: 0;
      align-self: center; }

.partner-banner-grey {
  background-color: rgba(139, 149, 158, 0.1);
  border: 1px solid #8b959e;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 16px; }
  .partner-banner-grey .driven-by {
    display: flex;
    gap: 1em; }
  .partner-banner-grey .imageContainer {
    margin-right: 10px;
    align-self: center;
    border: 1px solid black;
    border-radius: 60%; }
    .partner-banner-grey .imageContainer img {
      width: 32px;
      padding: 5px; }
  .partner-banner-grey .partnerContainer {
    font-size: 16px;
    color: #0c0c0c; }
    .partner-banner-grey .partnerContainer p {
      margin-bottom: 0;
      color: black; }
    .partner-banner-grey .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-grey .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-grey .partnerContainer span {
      font-weight: 300; }

.partner-banner-grey-assign {
  background-color: rgba(165, 151, 124, 0.05);
  border: 1px solid #a79779;
  border-radius: 10px;
  height: auto;
  display: flex;
  padding: 16px; }
  .partner-banner-grey-assign .imageContainer {
    margin-right: 10px;
    align-self: center;
    border: 1px solid #a5977c;
    border-radius: 60%; }
    .partner-banner-grey-assign .imageContainer img {
      width: 33px;
      height: 34px;
      padding: 6.5px; }
  .partner-banner-grey-assign .partnerContainer {
    font-size: 16px;
    color: #0c0c0c;
    display: flex; }
    .partner-banner-grey-assign .partnerContainer p {
      margin-bottom: 0;
      color: black; }
    .partner-banner-grey-assign .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-grey-assign .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-grey-assign .partnerContainer span {
      font-weight: 300; }

.partner-banner-mobile-grey {
  background-color: rgba(139, 149, 158, 0.1);
  border: 1px solid #000000;
  border-radius: 10px;
  height: auto;
  display: flex;
  padding: 16px; }
  .partner-banner-mobile-grey .imageContainer {
    margin-right: 5px;
    align-self: center;
    border: 1px solid #a5977c;
    border-radius: 60%; }
    .partner-banner-mobile-grey .imageContainer img {
      width: 33px;
      height: 34px;
      padding: 6.5px; }
  .partner-banner-mobile-grey .partnerContainer {
    font-size: 16px;
    color: #0c0c0c;
    display: flex; }
    .partner-banner-mobile-grey .partnerContainer p {
      margin-bottom: 0;
      color: black; }
    .partner-banner-mobile-grey .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-mobile-grey .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-mobile-grey .partnerContainer span {
      font-weight: 300; }

.partner-banner-mobile-warning {
  background-color: rgba(245, 166, 35, 0.05);
  border: 1px solid #f5a623;
  border-radius: 10px;
  height: auto;
  display: flex;
  padding: 16px; }
  .partner-banner-mobile-warning .imageContainer {
    margin-right: 5px;
    align-self: center;
    border: 1px solid #a5977c;
    border-radius: 60%; }
    .partner-banner-mobile-warning .imageContainer img {
      width: 33px;
      height: 34px;
      padding: 6.5px; }
  .partner-banner-mobile-warning .partnerContainer {
    font-size: 16px;
    color: #0c0c0c;
    display: flex; }
    .partner-banner-mobile-warning .partnerContainer p {
      margin-bottom: 0;
      color: black; }
    .partner-banner-mobile-warning .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-mobile-warning .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-mobile-warning .partnerContainer span {
      font-weight: 300; }

.partner-checkbox-mobile {
  margin-top: 1.3rem;
  /* padding: 0px; */
  padding-left: 1.2rem; }

.partner-banner-desktop {
  width: calc(100% - 10rem);
  background-color: rgba(208, 2, 27, 0.1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d0021b;
  padding: 16px; }
  .partner-banner-desktop .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px; }
    .partner-banner-desktop .select-chauffeur:active, .partner-banner-desktop .select-chauffeur:focus {
      box-shadow: none !important; }
  .partner-banner-desktop .imageContainer {
    float: left;
    align-self: center;
    margin: 0px; }
    .partner-banner-desktop .imageContainer img {
      width: 17px; }
  .partner-banner-desktop .warning-imageContainer {
    float: left;
    align-self: flex-start;
    margin: 0px; }
    .partner-banner-desktop .warning-imageContainer img {
      width: 17px; }
  .partner-banner-desktop .partnerContainer {
    font-size: 14px;
    margin: 0px 0px 0px 24px;
    color: #0c0c0c; }
    .partner-banner-desktop .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-desktop .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-desktop .partnerContainer span {
      display: inline-block;
      font-weight: 300; }
    .partner-banner-desktop .partnerContainer p {
      margin-bottom: 0;
      color: #d0021b;
      font-weight: bold; }

.payout-banner {
  width: calc(100%);
  background-color: rgba(208, 2, 27, 0.1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d0021b; }
  .payout-banner .imageContainer {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 5px; }
    .payout-banner .imageContainer img {
      width: 17px; }
  .payout-banner .partnerContainer {
    font-size: 14px;
    margin: 20px;
    color: #0c0c0c; }
    .payout-banner .partnerContainer .mobile {
      font-size: 13px !important; }
    .payout-banner .partnerContainer .header {
      font-weight: 800 !important; }
    .payout-banner .partnerContainer span {
      display: inline-block;
      font-weight: 300; }
    .payout-banner .partnerContainer p {
      margin-bottom: 0;
      color: #d0021b;
      font-weight: bold; }

.partner-banner-desktop-grey {
  width: calc(100% - 10rem);
  background-color: rgba(139, 149, 158, 0.1);
  border: 1px solid #000000;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; }
  .partner-banner-desktop-grey .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px; }
    .partner-banner-desktop-grey .select-chauffeur:active, .partner-banner-desktop-grey .select-chauffeur:focus {
      box-shadow: none !important; }
  .partner-banner-desktop-grey .imageContainer {
    float: left;
    align-self: center;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 60%; }
    .partner-banner-desktop-grey .imageContainer img {
      width: 36px;
      padding: 6px; }
  .partner-banner-desktop-grey .imageContainerGold {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid #a5977c;
    border-radius: 60%; }
    .partner-banner-desktop-grey .imageContainerGold img {
      height: 28px;
      width: 27px;
      padding: 6px;
      margin: 2px; }
  .partner-banner-desktop-grey .partnerContainer {
    font-size: 14px;
    color: #0c0c0c; }
    .partner-banner-desktop-grey .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-desktop-grey .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-desktop-grey .partnerContainer span {
      font-weight: 300; }
    .partner-banner-desktop-grey .partnerContainer p {
      margin-bottom: 0;
      font-weight: bold; }

.partner-banner-desktop-gold {
  width: calc(100% - 10rem);
  background-color: rgba(165, 151, 124, 0.05);
  border: 1px solid #a79779;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; }
  .partner-banner-desktop-gold .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px; }
    .partner-banner-desktop-gold .select-chauffeur:active, .partner-banner-desktop-gold .select-chauffeur:focus {
      box-shadow: none !important; }
  .partner-banner-desktop-gold .imageContainer {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 60%; }
    .partner-banner-desktop-gold .imageContainer img {
      width: 36px;
      padding: 6px; }
  .partner-banner-desktop-gold .imageContainerGold {
    float: left;
    align-self: center;
    margin-right: 1em;
    border: 1px solid #a5977c;
    border-radius: 60%; }
    .partner-banner-desktop-gold .imageContainerGold img {
      height: 1.75rem;
      width: 1.75rem;
      padding: 6px;
      margin: 2px; }
  .partner-banner-desktop-gold .partnerContainer {
    font-size: 14px;
    color: #0c0c0c;
    display: flex;
    align-items: center; }
    .partner-banner-desktop-gold .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-desktop-gold .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-desktop-gold .partnerContainer span {
      font-weight: 300; }
    .partner-banner-desktop-gold .partnerContainer p {
      margin-bottom: 0;
      font-weight: bold; }

.partner-banner-desktop-warning {
  width: calc(100% - 10rem);
  background-color: rgba(245, 166, 35, 0.05);
  border: 1px solid #f5a623;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; }
  .partner-banner-desktop-warning .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px; }
    .partner-banner-desktop-warning .select-chauffeur:active, .partner-banner-desktop-warning .select-chauffeur:focus {
      box-shadow: none !important; }
  .partner-banner-desktop-warning .imageContainer {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 60%; }
    .partner-banner-desktop-warning .imageContainer img {
      width: 36px;
      padding: 6px; }
  .partner-banner-desktop-warning .imageContainerGold {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid #a5977c;
    border-radius: 60%; }
    .partner-banner-desktop-warning .imageContainerGold img {
      height: 37px;
      width: 36px;
      padding: 6px;
      margin: 2px; }
  .partner-banner-desktop-warning .partnerContainer {
    font-size: 14px;
    color: #0c0c0c; }
    .partner-banner-desktop-warning .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-desktop-warning .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-desktop-warning .partnerContainer span {
      font-weight: 300; }
    .partner-banner-desktop-warning .partnerContainer p {
      margin-bottom: 0;
      font-weight: bold; }

.partner-header {
  padding: 0px 40px 0px 60px !important;
  display: flex;
  margin-top: 0; }

.partner-header-home {
  padding: 50px 10% 0px 60px !important;
  margin: 0px 15px 0px 15px !important;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  max-width: none !important; }

.partner-btn-desktop {
  max-width: 90px;
  min-width: 90px;
  height: 42px !important;
  font-size: 14px !important;
  border: 1px solid #a79779;
  background-color: #a79779;
  font-weight: 600; }
  .partner-btn-desktop:active, .partner-btn-desktop:focus {
    box-shadow: none !important; }

.partner-btn-desktop-red {
  min-width: 12.5em !important;
  font-weight: 500 !important;
  max-width: 90px;
  min-width: 90px;
  height: 42px !important;
  font-size: 14px !important;
  border: 1px solid #d0021b !important;
  background-color: transparent !important;
  color: #d0021b !important; }
  .partner-btn-desktop-red:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 1px solid #d0021b !important;
    color: #d0021b !important;
    cursor: pointer; }

.partner-sentDate {
  font-size: 14px; }

.partner-btn-desktop-decline {
  max-width: 95px;
  min-width: 95px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba(139, 149, 158, 0); }
  .partner-btn-desktop-decline:active, .partner-btn-desktop-decline:focus, .partner-btn-desktop-decline:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important; }

.partner-btn-decline-red {
  max-width: 95px;
  min-width: 95px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #d0021b;
  color: #d0021b !important;
  background-color: rgba(139, 149, 158, 0); }
  .partner-btn-decline-red:active, .partner-btn-decline-red:focus, .partner-btn-decline-red:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: rgba(208, 2, 27, 0.1) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #d0021b !important; }

.partner-cancel-dispatch {
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba(139, 149, 158, 0); }
  .partner-cancel-dispatch:active, .partner-cancel-dispatch:focus, .partner-cancel-dispatch:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important; }

.confirmation-modal-decline-btn {
  min-width: 175px;
  height: 40px !important;
  font-size: 15px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba(139, 149, 158, 0) !important; }
  .confirmation-modal-decline-btn:active, .confirmation-modal-decline-btn:focus, .confirmation-modal-decline-btn:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important; }

.partner-btn-desktop-primary {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #a79779;
  background-color: #a79779;
  font-size: 14px !important; }
  .partner-btn-desktop-primary:active, .partner-btn-desktop-primary:focus, .partner-btn-desktop-primary:hover {
    box-shadow: none !important;
    background-color: #968669 !important;
    border: #968669 !important; }
  .partner-btn-desktop-primary:disabled {
    box-shadow: none !important;
    background-color: 1px solid #a79779;
    border: 1px solid #a79779;
    color: #ffffff;
    pointer-events: none; }

.partner-btn-mobile-primary {
  font-size: 16px !important;
  font-weight: 900 !important;
  text-shadow: 0.25px 0 #ffffff !important; }

.partner-btn-desktop-primary-sm {
  min-width: 95px;
  height: 35px !important;
  font-weight: 500;
  margin: 0 !important;
  border: 1px solid #a79779;
  background-color: #a79779;
  font-size: 14px !important; }
  .partner-btn-desktop-primary-sm:active, .partner-btn-desktop-primary-sm:focus, .partner-btn-desktop-primary-sm:hover {
    box-shadow: none !important;
    background-color: #968669 !important;
    border: #968669 !important; }
  .partner-btn-desktop-primary-sm:disabled {
    box-shadow: none !important;
    background-color: 1px solid #a79779;
    border: 1px solid #a79779;
    color: #ffffff; }

.partner-btn-desktop-tertiary {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #a79779;
  font-size: 14px !important;
  color: #a79779 !important;
  background-color: rgba(139, 149, 158, 0); }
  .partner-btn-desktop-tertiary:active, .partner-btn-desktop-tertiary:focus, .partner-btn-desktop-tertiary:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important; }

.outsource-btn-partner {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #a79779;
  font-size: 14px !important;
  color: #a79779 !important;
  background-color: rgba(139, 149, 158, 0); }
  .outsource-btn-partner:active, .outsource-btn-partner:focus, .outsource-btn-partner:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important; }

.partner-link-desktop {
  display: flex;
  align-items: center;
  text-decoration: none !important; }

.partner-btn-desktop-small {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  font-size: 14px !important;
  border: 1px solid #a79779;
  background-color: #a79779; }
  .partner-btn-desktop-small:active, .partner-btn-desktop-small:focus, .partner-btn-desktop-small:hover {
    box-shadow: none !important;
    background-color: #968669 !important;
    border: 1px solid #968669 !important; }

.partner-btn-desktop-secondary {
  height: 30px !important;
  font-weight: bold !important;
  background-color: rgba(165, 151, 124, 0.15) !important;
  border: none;
  color: #a79779 !important;
  padding: 10px 20px 25px 20px;
  font-size: 14px !important;
  font-weight: 600 !important; }
  .partner-btn-desktop-secondary:active, .partner-btn-desktop-secondary:focus, .partner-btn-desktop-secondary:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.4) !important; }

.partner-btn-desktop-secondary-assign {
  font-weight: bold !important;
  background-color: rgba(165, 151, 124, 0.15) !important;
  border: none;
  color: #a79779 !important;
  padding: 10px 20px 25px 20px;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 50px !important;
  display: flex;
  padding-top: 16px; }
  .partner-btn-desktop-secondary-assign:active, .partner-btn-desktop-secondary-assign:focus, .partner-btn-desktop-secondary-assign:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.4) !important; }

.child-seat-sub-header {
  margin-bottom: 0px !important;
  width: 82%;
  max-width: 505px; }
  .child-seat-sub-header.expandable {
    cursor: pointer;
    padding-right: 40px;
    position: relative; }
    .child-seat-sub-header.expandable:after {
      content: '';
      position: absolute;
      right: 5px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url("/images/icons/caret-down.svg");
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat; }
    .child-seat-sub-header.expandable.expanded:after {
      transform: rotate(180deg); }

.child-seat-desc {
  font-size: 14px;
  color: #8b959e;
  padding-top: 2px;
  margin-bottom: 2rem;
  margin-top: 1rem; }

.child-seat-div {
  margin-bottom: 10px;
  font-size: 15px; }

.child-seat-img {
  width: 20px;
  margin-left: 15px;
  margin-right: 7px; }

.alert-avatar {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  width: fit-content;
  margin-left: 10px; }
  .alert-avatar.user-online {
    border: 1px solid #417505; }
  .alert-avatar img {
    display: block;
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center center; }

.assign-stepper {
  padding-top: 1em;
  display: flex; }
  .assign-stepper .completed {
    background: url("/images/icons/icon-checklist-black.png");
    background-position: top 9px left 4px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    padding-left: 28px;
    margin-bottom: 4px;
    border-bottom: 0; }
  .assign-stepper .not-completed {
    background: url("/images/icons/icon-checklist-grey.png");
    background-position: top 9px left 4px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    padding-left: 28px;
    margin-bottom: 4px;
    color: #8b959e;
    border-bottom: 0; }

.assign-card {
  width: calc(100vw - 80%);
  margin-top: 2em !important;
  border-radius: 10px !important; }

.assign-select-card {
  width: calc(100vw - 80%);
  margin-top: 2em !important;
  border-radius: 10px !important; }

.assign-select-card-multiple {
  width: calc(100vw - 60%);
  margin-top: 2em !important;
  border-radius: 10px !important; }

.assign-select-card-confirm {
  width: calc(100vw - 0%);
  margin-top: 2em !important;
  border-radius: 10px !important; }

.assign-confirm-container {
  width: calc(100vw - 35%);
  column-gap: 1em; }

.partner-grid {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
  overflow-y: scroll;
  max-height: 18em; }

.partner-grid-acc {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em; }

.assign-card-top-half {
  width: calc(100vw - 80%);
  margin-top: 2em !important;
  border-radius: 10px 10px 0px 0px !important; }

.assign-card-half {
  width: calc(100vw - 80%);
  margin-top: 0 !important;
  border-radius: 0px 0px 10px 10px !important;
  border-top: none !important; }

.setWidth {
  width: 10px; }

.chauffeur-card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(139, 149, 158, 0.1);
  padding: 1em 1em;
  margin-top: 1em;
  max-height: 70px; }
  .chauffeur-card-item .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #8b959e;
    justify-content: center;
    border-radius: 50%; }
  .chauffeur-card-item .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black; }
  .chauffeur-card-item .car-img {
    width: 70%; }
  .chauffeur-card-item .car-img-chauffeur {
    width: 55%; }
  .chauffeur-card-item .car-edit-img {
    width: 17%;
    margin-right: 15px;
    align-self: flex-start; }
  .chauffeur-card-item .shift-image {
    transform: translateY(-0.8rem); }
  .chauffeur-card-item .profile-img {
    width: 40px;
    height: 40px; }
  .chauffeur-card-item .add-img {
    width: 9%; }
  .chauffeur-card-item .edit-img img {
    width: 23px; }
  .chauffeur-card-item .add-span {
    flex: 0 0 50%;
    padding-top: 5px;
    color: #a5977c;
    font-weight: 700; }
  .chauffeur-card-item .info-span-small {
    white-space: pre !important;
    flex: 0 0 50%;
    padding-top: 5px; }
  .chauffeur-card-item .info-span-chauffeur {
    flex: 0 0 75%; }
  .chauffeur-card-item .info-span {
    flex: 0 0 65%; }
  .chauffeur-card-item .info-span-company {
    flex: 0 0 75%; }

.selected-item {
  border-radius: 5px;
  border: 1px solid black; }

.assigned-item {
  border-radius: 5px;
  border: 1px solid #a79779; }

.chauffeur-card-item-confirm {
  display: flex;
  align-items: center;
  background-color: rgba(139, 149, 158, 0.1);
  padding: 1em 1em;
  margin-top: 1em;
  max-height: 70px;
  grid-gap: 1em;
  justify-content: center; }
  .chauffeur-card-item-confirm .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #8b959e;
    justify-content: center;
    border-radius: 50%; }
  .chauffeur-card-item-confirm .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black; }
  .chauffeur-card-item-confirm .car-img {
    width: 70%; }
  .chauffeur-card-item-confirm .car-img-chauffeur {
    width: 55%; }
  .chauffeur-card-item-confirm .car-edit-img {
    width: 17%;
    margin-right: 15px;
    align-self: flex-start; }
  .chauffeur-card-item-confirm .profile-img {
    width: 140%; }
  .chauffeur-card-item-confirm .add-img {
    width: 9%; }
  .chauffeur-card-item-confirm .edit-img img {
    width: 23px; }
  .chauffeur-card-item-confirm .add-span {
    flex: 0 0 50%;
    padding-top: 5px;
    color: #a5977c;
    font-weight: 700; }
  .chauffeur-card-item-confirm .info-span-small {
    white-space: 'pre' !important;
    flex: 0 0 50%;
    padding-top: 5px; }
  .chauffeur-card-item-confirm .info-span {
    flex: 0 0 74%; }

.chauffeur-title {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0; }

.extra-spacing {
  margin-bottom: 10rem; }

.grey-background {
  padding: 1em 1em;
  margin-top: 1em;
  background-color: rgba(139, 149, 158, 0.1); }

.partner-bookingType {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 12px;
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 0.3rem; }

.support-card {
  border-radius: 8px;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  background-color: #fbfbfb;
  padding: 1.5em 1.5em 1.5em 1.5em;
  font-size: 14px;
  margin-top: 1em; }
  .support-card .support-icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #a5977c;
    justify-content: center;
    border-radius: 50%; }
    .support-card .support-icon-container .support-img {
      width: 60%; }
  .support-card .support-title {
    font-size: 16px;
    font-weight: 700; }
  .support-card .support-message {
    display: inline-block;
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 400;
    color: rgba(139, 149, 158, 0.8);
    display: block;
    margin-bottom: 0.3rem; }

.partner-search-container {
  display: flex;
  justify-content: space-between; }

.client-search-container {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available; }

.driverApp-notifications-wrap {
  display: flex;
  justify-content: end;
  align-items: flex-start;
  z-index: 9; }
  .driverApp-notifications-wrap .vehicle-title {
    max-width: max-content; }

.partner-sidebar {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: left; }

.partner-number-field {
  min-width: 7em; }

.partner-ellipsis-field {
  text-overflow: ellipsis;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  display: block; }

.client-ellipsis-field {
  text-overflow: ellipsis;
  max-width: 9rem;
  overflow: hidden;
  white-space: nowrap; }

.partner-field-extended {
  white-space: nowrap; }

.partner-action {
  display: flex;
  justify-content: center; }

.driver-panel-titlebar {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 10px;
  height: 51px;
  border-bottom: 1px solid rgba(139, 149, 158, 0.2); }

.chauffeur-count-container {
  display: flex;
  column-gap: 2em;
  margin-left: 1em; }
  .chauffeur-count-container span {
    display: block;
    color: #8b959e;
    font-size: 13px;
    font-weight: 400; }
  .chauffeur-count-container span:first-child {
    display: block;
    font-size: 24px;
    height: 28px;
    font-weight: bold;
    color: #0c0c0c;
    margin-bottom: 1px; }

.noWrap-whiteSpace {
  white-space: nowrap !important; }

.admin-users-default-logo {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid rgba(12, 12, 12, 0.1);
  overflow: hidden; }

.admin-users-booking {
  width: 34px !important;
  height: 34px !important;
  border-radius: 50%;
  overflow: hidden; }

.admin-users-booking {
  width: 34px !important;
  height: 34px !important;
  border-radius: 50%;
  overflow: hidden; }

.partner-accepted-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: calc(100vh - 20rem);
  justify-content: center; }
  .partner-accepted-modal p {
    margin-top: 1rem; }

.mobile-logout {
  padding-top: 1.4rem !important;
  padding-right: 2.1rem; }
  .mobile-logout img {
    width: 28px; }

.partner-delete-btn {
  display: block;
  color: #d0021b;
  margin-bottom: 5px; }
  .partner-delete-btn:hover {
    text-decoration: none;
    color: #d0021b; }

.partner-dot {
  width: 4px;
  height: 4px;
  background: rgba(139, 149, 158, 0.1);
  border-radius: 50%; }

.delete-icon-desktop {
  max-width: 17px;
  margin-right: 0.6rem !important; }
  .delete-icon-desktop:hover {
    cursor: pointer; }

.partner-banner-mobile {
  width: 100%;
  background-color: rgba(208, 2, 27, 0.1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid #d0021b;
  padding: 16px; }
  .partner-banner-mobile .select-chauffeur {
    align-self: center;
    width: 170px; }
    .partner-banner-mobile .select-chauffeur:active, .partner-banner-mobile .select-chauffeur:focus {
      box-shadow: none !important; }
  .partner-banner-mobile .imageContainer {
    align-self: center;
    margin-right: 10px; }
    .partner-banner-mobile .imageContainer img {
      width: 17px; }
  .partner-banner-mobile .partnerContainer {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 16px;
    color: #0c0c0c; }
    .partner-banner-mobile .partnerContainer .mobile {
      font-size: 13px !important; }
    .partner-banner-mobile .partnerContainer .header {
      font-weight: 800 !important; }
    .partner-banner-mobile .partnerContainer span {
      display: inline-block;
      font-weight: 300; }
    .partner-banner-mobile .partnerContainer p {
      margin-bottom: 0;
      color: #d0021b;
      font-weight: bold; }

.switch-client-banner {
  display: flex;
  justify-content: space-between;
  background-color: rgba(167, 151, 121, 0.05);
  border: 1px solid #a79779;
  border-radius: 2px;
  margin: 20px 15px;
  padding: 20px;
  align-items: center; }
  .switch-client-banner .banner-header {
    display: flex;
    align-items: center; }
  .switch-client-banner p:first-child {
    margin-bottom: 2px;
    font-size: 20px;
    color: black; }
  .switch-client-banner p:last-child {
    margin-bottom: 0;
    color: #636d77;
    max-width: 635px;
    width: 95%; }
  .switch-client-banner .right-div {
    display: flex;
    align-items: center; }
  .switch-client-banner .close-icon {
    width: 12px;
    height: 12px;
    margin-left: 2rem;
    cursor: pointer; }
  .switch-client-banner .globe-icon {
    width: 48px;
    height: 48px;
    margin-right: 20px; }

.switch-client-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #a79779 !important;
  background-color: transparent;
  color: #a79779 !important;
  height: 35px !important;
  width: 9rem;
  font-size: 14px !important;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px !important;
  box-shadow: none !important; }
  .switch-client-btn:hover {
    background-color: rgba(167, 151, 121, 0.2) !important;
    text-decoration: none; }
  .switch-client-btn:active, .switch-client-btn:focus, .switch-client-btn:target {
    background-color: rgba(167, 151, 121, 0.3) !important; }
  .switch-client-btn img {
    width: 15px;
    height: 15px;
    padding-bottom: 2px; }

.home .switch-client-btn-mob {
  border-radius: 2px !important;
  width: 100%;
  margin-bottom: 25px;
  justify-content: center;
  gap: 10px; }

.switch-client-btn-mob {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #a79779 !important;
  background-color: transparent;
  color: #a79779 !important;
  height: 38px !important;
  width: 10rem;
  font-size: 14px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  border-radius: 20px !important;
  box-shadow: none !important;
  margin-right: 10px; }
  .switch-client-btn-mob:hover {
    background-color: rgba(167, 151, 121, 0.2) !important;
    text-decoration: none; }
  .switch-client-btn-mob:active, .switch-client-btn-mob:focus, .switch-client-btn-mob:target {
    background-color: rgba(167, 151, 121, 0.3) !important; }
  .switch-client-btn-mob img {
    width: 18px !important;
    height: 20px !important;
    padding-bottom: 3px; }

.partner-btn-mobile-decline {
  width: 100%;
  margin-left: 15px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba(139, 149, 158, 0); }
  .partner-btn-mobile-decline:active, .partner-btn-mobile-decline:focus, .partner-btn-mobile-decline:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important; }

.apiBadgeList {
  color: #ffffff;
  background: #0c0c0c;
  border-radius: 10px;
  max-width: 30px;
  min-width: 30px;
  text-align: center;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 10px;
  font-weight: bold;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center; }

.apiBadgeBooking {
  color: #ffffff;
  background: #0c0c0c;
  border-radius: 10px;
  max-width: 40px;
  min-width: 40px;
  text-align: center;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  padding-top: 2px;
  justify-content: center; }

.alignDraggable {
  position: fixed;
  left: 100px;
  z-index: 99;
  border: 1px solid lightgrey;
  top: 50px;
  min-width: 750px;
  height: 90%;
  overflow: auto;
  overflow-x: hidden;
  background: white;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px; }
  .alignDraggable .form-group {
    margin-bottom: 1rem !important; }

.alignDraggable-model {
  background-color: #ffffff; }
  .alignDraggable-model .modal-header .close {
    outline: none !important; }

.client-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0; }
  .client-filters img {
    width: 30px;
    height: 15px; }

.client-filter-modal {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
  z-index: 2; }

.payment-card-fields {
  max-width: 50.2% !important; }

.new-info-icon {
  width: 13px;
  padding-bottom: 3px;
  margin-left: 12px;
  cursor: pointer; }

.coverage-info-icon {
  width: 13px;
  padding-bottom: 3px;
  margin-left: 9px;
  cursor: pointer;
  margin-top: 3px;
  height: 15px; }

.coverage-info-icon-two {
  width: 13px;
  padding-bottom: 3px;
  margin-left: 9px;
  cursor: pointer;
  margin-top: 5px;
  height: 15px; }

.info-icon {
  width: 12px;
  padding-bottom: 3.5px;
  margin-left: 2px;
  cursor: pointer; }

.info-icon-rentals {
  width: 14.5px;
  padding-bottom: 1.5px;
  margin-left: 7px;
  cursor: pointer; }

.info-icon-vip {
  width: 11.5px;
  padding-bottom: 4px;
  margin-left: 6px;
  cursor: pointer; }

.info-icon-total {
  width: 14px;
  padding-bottom: 4.5px;
  margin-left: 4px;
  cursor: pointer; }

.info-icon-details {
  width: 11.5px;
  padding-bottom: 3.5px;
  margin-left: 5px;
  cursor: pointer; }

.info-icon-multi-stop {
  position: absolute;
  left: 7.3em;
  top: 0%;
  transform: translateX(-50%);
  width: 10px;
  padding-bottom: 3px;
  margin-left: 2px;
  cursor: pointer; }

.info-icon-multi-stop-hourly {
  position: absolute;
  left: 98px;
  bottom: 69.5px;
  width: 10px;
  padding-bottom: 3.5px;
  margin-left: 2px;
  cursor: pointer; }

.info-icon-itinerary {
  position: absolute;
  left: 95px;
  bottom: 5px;
  width: 13px;
  padding-bottom: 3.5px;
  margin-left: 2px;
  cursor: pointer; }

.info-icon-change-req {
  position: absolute;
  left: 15em;
  top: 0%;
  transform: translateX(-50%);
  width: 10px;
  padding-bottom: 3px;
  margin-left: 2px;
  cursor: pointer; }

.partner-star-container {
  display: inline-block;
  margin-left: 1rem; }

.rating {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative; }

.rating-0 {
  filter: grayscale(100%); }

.rating > input {
  display: none; }

.rating > label {
  cursor: pointer;
  width: 35px;
  height: 27px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23bdb9b9' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  transition: 0.3s; }

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"); }

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"); }

.rating-list {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative; }
  @media (max-width: 767.98px) {
    .rating-list {
      justify-content: center; } }

.rating-0 {
  filter: grayscale(100%); }

.rating-list > input {
  display: none; }

.rating-list > label {
  width: 24px;
  height: 24px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23bdb9b9' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 65%;
  transition: 0.3s;
  margin-bottom: 0; }

.rating-list > input:checked ~ label,
.rating-list > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"); }

.rating-dispatch {
  display: flex;
  width: unset;
  justify-content: center;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative;
  margin-left: 0.5rem; }

.rating-0 {
  filter: grayscale(100%); }

.rating-dispatch > input {
  display: none; }

.rating-dispatch > label {
  width: 22px;
  height: 25px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23bdb9b9' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 69%;
  transition: 0.3s;
  margin-bottom: 0.5rem; }

.rating-dispatch > input:checked ~ label,
.rating-dispatch > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"); }

.supplier-icons {
  width: 2rem;
  margin-left: 10px;
  margin-right: 5px;
  padding-bottom: 10px;
  cursor: pointer; }

.quote-follow-up {
  width: 2rem;
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer; }

.twenty-four-dispatch {
  width: 1.5rem;
  margin-left: 10px;
  padding-bottom: 5px; }

.twenty-four-service-list {
  padding-bottom: 20px;
  margin-left: 5px;
  width: 1.3rem; }

.important-client-list {
  padding-bottom: 3px;
  margin-left: 10px;
  width: 1rem; }

.gm-ui-hover-effect {
  display: none !important; }

.edit-company {
  border-bottom: 1.5px solid rgba(139, 149, 158, 0.2);
  background-color: #fbfbfb;
  padding: 0em 1.5em 1em 1.5em;
  font-size: 15px; }

.fontSize18 {
  font-size: 1.1 rem; }

.tableau-border-bottom {
  border-bottom: 1.5px solid rgba(139, 149, 158, 0.5); }

.ClickOnCall {
  color: #0c0c0c;
  text-decoration: underline; }
  .ClickOnCall:hover {
    color: rgba(139, 149, 158, 0.5); }

fieldNumber::-webkit-inner-spin-button,
fieldNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
  fieldNumber::-webkit-inner-spin-button input[type='number'],
  fieldNumber::-webkit-outer-spin-button input[type='number'] {
    -webkit-appearance: none;
    margin: 0; }

.edit-client-subtitle {
  font-size: 15px;
  margin-top: 0.75rem;
  margin-bottom: 0px; }

@media (max-width: 768px) {
  .edit-client-subtitle {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px; } }

.edit-client-document-name {
  margin-bottom: 1.5rem; }

.agent-list-main {
  width: 100%;
  padding: 0 9px;
  border: 1px solid blue;
  border-radius: 2px;
  display: inline-block;
  text-decoration: none; }

.agent-list-select {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.1rem;
  font-size: 12px;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: black;
  text-decoration: none; }

.agent-list-item {
  padding-left: 0.2rem;
  padding-top: 0.1rem;
  font-size: 12px;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.stepTwoHeight {
  height: calc(100vh - 68px) !important; }

.remove-button-border {
  outline: none !important;
  box-shadow: none !important; }

@media (min-width: 768px) {
  /* Adjust to match Bootstrap's md breakpoint */
  .flex-fill {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333%; } }

@media (max-width: 767.98px) {
  .flex-fill {
    display: none; } }

.small-textarea textarea.form-control {
  min-height: 50px !important; }

.profile-pic-border {
  border: 1px solid rgba(139, 149, 158, 0.25); }

.benefit-text-column {
  padding-left: 0.3rem;
  margin-top: 0.188rem;
  min-width: 280px; }
  .benefit-text-column p {
    color: #636d77;
    font-size: 15px; }

.benefit-icon-column {
  padding-left: 0px; }
  .benefit-icon-column img {
    width: 1.5rem;
    height: 1.5rem; }

.rolzo-first-mobile-tabs .custom-tabs-nav {
  background: rgba(139, 149, 158, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 20px;
  justify-content: space-between; }
  .rolzo-first-mobile-tabs .custom-tabs-nav.light-tabs {
    border-bottom: 1px solid rgba(139, 149, 158, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 15px;
    padding: 0; }
    .rolzo-first-mobile-tabs .custom-tabs-nav.light-tabs .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 500;
      color: #8b959e;
      opacity: 0.55;
      padding: 10px 20px 15px 2%;
      border: none; }
      .rolzo-first-mobile-tabs .custom-tabs-nav.light-tabs .nav-link.active {
        background: transparent;
        border: none;
        border-bottom: 2px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        font-weight: 600; }
      .rolzo-first-mobile-tabs .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
        margin-right: 35px; }
      .rolzo-first-mobile-tabs .custom-tabs-nav.light-tabs .nav-link:focus {
        box-shadow: none; }

.rolzo-first-subheading {
  font-size: 15px;
  color: #636d77; }

.rolzo-first-eligibility {
  height: 15rem;
  background-color: #fafafa;
  margin-bottom: 1.875rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem; }
  .rolzo-first-eligibility p {
    font-size: 15px;
    margin-bottom: 0.938rem;
    font-weight: 400; }
  .rolzo-first-eligibility ul {
    font-size: 15px;
    color: #636d77;
    padding: 0 0 0 1rem; }

.rolzo-first-production {
  height: 23.313rem;
  background-color: #fafafa;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  margin-left: 0.085rem; }
  .rolzo-first-production .heading-text {
    font-weight: 400;
    font-size: 15px;
    color: #636d77;
    margin-bottom: 0; }
  .rolzo-first-production .local-revenue-text {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 0; }
  .rolzo-first-production .usd-revenue-text {
    font-weight: 400;
    font-size: 18px;
    color: #636d77;
    margin-bottom: 2.5rem; }

.rolzo-first-production-tabs .custom-tabs-nav {
  background: rgba(139, 149, 158, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 0px; }
  .rolzo-first-production-tabs .custom-tabs-nav.light-tabs {
    border-bottom: none;
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 15px;
    padding: 0; }
    .rolzo-first-production-tabs .custom-tabs-nav.light-tabs .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 500;
      color: #8b959e;
      opacity: 0.55;
      padding: 10px 20px 15px 2%;
      border: none; }
      .rolzo-first-production-tabs .custom-tabs-nav.light-tabs .nav-link.active {
        background-color: #fafafa;
        box-shadow: 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
        border: none;
        color: #303030;
        opacity: 1;
        font-weight: 600;
        border-radius: 1px; }
      .rolzo-first-production-tabs .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
        margin-right: 0px; }

.referral-box {
  height: 15.25rem;
  background-color: #fafafa;
  margin-bottom: 1.875rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem 1.25rem 2.5rem; }
  .referral-box p {
    font-size: 15px;
    margin-bottom: 0.5rem;
    color: #636d77;
    font-weight: 400; }

.link-invite {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.563rem;
  padding: 0 0.313rem 0 0.625rem;
  justify-content: space-between;
  background-color: white; }
  .link-invite p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .link-invite .copy-btn {
    margin: 5px 0px !important; }
  @media (max-width: 768px) {
    .link-invite .copy-btn {
      margin: 5px 0px !important;
      width: 60%;
      padding: 0 !important; } }
  .link-invite .form-group {
    margin-bottom: 0 !important;
    background-color: white;
    width: 77%; }
  .link-invite input.form-control {
    background-color: white !important;
    box-shadow: none !important;
    padding: 0 !important; }
  .link-invite input.form-control {
    border: 0px !important; }

.email-invite .btn-div {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: white !important; }

.email-invite .submit-btn {
  margin: 5px 0px 5px 10px !important; }

@media (max-width: 768px) {
  .email-invite .submit-btn {
    margin: 5px 0px !important;
    width: 95%;
    padding: 0 !important; } }

.email-invite input.form-control {
  background-color: white !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 0 0.313rem 0 0.625rem !important; }

.email-invite input.form-control {
  border: 0px !important; }

/* Hides the up/down arrows for number inputs in Chrome, Edge, and Safari */
.hide-number-spinners::-webkit-inner-spin-button,
.hide-number-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Hides the up/down arrows for number inputs in Firefox */
.hide-number-spinners {
  -moz-appearance: textfield; }

.remove-member-btn-wrap {
  align-self: center;
  position: absolute;
  right: -34px;
  width: fit-content;
  top: 32px;
  width: 24px;
  height: 24px;
  cursor: pointer; }

.internal-bookingid-container .form-group {
  margin-bottom: 0; }

.h-fit {
  height: fit-content !important; }

.py-12 {
  padding-block: 12px !important; }

input[type='text'].show-pin,
input[type='text'].show-close-icon {
  text-overflow: ellipsis !important;
  background-image: url("/images/mapIcons/Icon_Location.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center right 5px;
  padding: 0 35px 0 10px !important; }

input[type='text'].show-close-icon {
  background-image: none !important; }

.padding-left-partner {
  padding-left: 0.75rem !important; }

.h-0 {
  height: 0px !important; }

.referral-banner {
  display: flex;
  justify-content: space-between;
  background-color: rgba(167, 151, 121, 0.2);
  border-bottom: 2px solid #a79779;
  padding: 10px 60px;
  align-items: center; }
  .referral-banner .banner-header {
    display: flex;
    align-items: center; }
  .referral-banner p {
    margin-bottom: 0;
    margin-top: 0.25rem;
    font-size: 14px; }
  .referral-banner span {
    text-shadow: 0px 0px 0 black; }
  .referral-banner .right-div {
    display: flex;
    align-items: center; }
  .referral-banner .close-icon {
    width: 15px;
    height: 15px;
    margin-left: 2rem;
    cursor: pointer; }
  .referral-banner .referral-icon {
    width: 20px;
    height: 20px;
    margin-right: 20px; }

.referees-table-mobile.expandable {
  cursor: pointer;
  padding-right: 40px;
  position: relative; }
  .referees-table-mobile.expandable:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-image: url("/images/icons/caret-down.svg");
    background-size: contain;
    background-position: right 0 center;
    background-repeat: no-repeat; }
  .referees-table-mobile.expandable.expanded:after {
    transform: rotate(180deg); }

.px-5px {
  padding-inline: 5px !important; }

.rotate-180 {
  rotate: 180deg; }

.combined-container {
  display: flex;
  flex-wrap: nowrap;
  /* Prevent wrapping */
  align-items: center;
  /* Align items vertically in the center */ }

.inline-form-row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 0;
  margin-left: 0; }

.inline-form-row .col {
  flex: 1;
  /* Ensure equal spacing for columns */
  padding: 0 5px;
  /* Maintain spacing between fields */ }

.avg-distance-duration-fields .form-group {
  margin-bottom: 0; }

.ag-theme-alpine .ag-cell {
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important; }

.ag-theme-alpine .ag-header-cell {
  font-size: 14px !important; }

.ag-theme-alpine .ag-row:last-child .ag-cell {
  border-bottom: none; }

.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #ccc !important; }

.ag-theme-alpine .export-import-btns {
  position: absolute;
  right: 25px;
  top: 0; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }

.page-loading {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(250, 250, 250, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #0c0c0c; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #fbfbfb; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #0c0c0c; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #0c0c0c; }

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0; }

.react-datepicker {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 13px;
  background-color: #fff;
  color: #303030;
  border: 1px solid #0c0c0c;
  border-radius: 2px;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px; }
  .react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px; }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #fbfbfb;
  border-bottom: 1px solid #0c0c0c;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding-top: 8px;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #303030;
  font-weight: bold;
  font-size: 15.34px; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc; }
    .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 80px; }
    .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #ccc; }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #ccc; }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__year-container {
  margin: 0.4rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap; }
  .react-datepicker__year-container-text {
    display: inline-block;
    cursor: pointer;
    flex: 1 0 30%;
    width: 12px;
    padding: 2px; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: 85px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #0c0c0c;
  width: 85px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (45px / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #fbfbfb; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: rgba(139, 149, 158, 0.3);
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: rgba(139, 149, 158, 0.3); }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #ccc; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 45px;
  line-height: 45px;
  text-align: center;
  margin: 2px; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 2px;
      background-color: #fbfbfb; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #303030;
  display: inline-block;
  width: 45px;
  line-height: 45px;
  text-align: center;
  margin: 2px; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 2px;
  background-color: rgba(139, 149, 158, 0.3);
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: rgba(125, 136, 146, 0.3); }

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none; }
  .react-datepicker__month--disabled:hover,
  .react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    border-radius: 2px;
    background-color: #fbfbfb; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted {
    border-radius: 2px;
    background-color: #3dcc4a;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover {
      background-color: #32be3f; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    border-radius: 2px;
    background-color: rgba(139, 149, 158, 0.3);
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover {
      background-color: rgba(125, 136, 146, 0.3); }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    border-radius: 2px;
    background-color: rgba(167, 174, 181, 0.3);
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover {
      background-color: rgba(125, 136, 146, 0.3); }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range ,
  .react-datepicker__quarter-text--in-selecting-range {
    background-color: rgba(139, 149, 158, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range {
    background-color: #fbfbfb;
    color: #303030; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled {
    cursor: default;
    color: #ccc; }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: rgba(139, 149, 158, 0.3); }

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #fbfbfb; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 2px; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #fbfbfb;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #0c0c0c; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle; }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: rgba(139, 149, 158, 0.3);
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7"; }

.react-datepicker__today-button {
  background: #fbfbfb;
  border-top: 1px solid #0c0c0c;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 23.4px; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #e6e6e6;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc; }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #b3b3b3; }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #e6e6e6;
      cursor: default; }

.react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }
  .react-datepicker-wrapper .react-datepicker__close-icon::after {
    padding-right: 12px;
    background-color: transparent !important;
    background-image: url("/images/close-icon-round.png");
    background-size: 20px 20px;
    background-position: center right 13px !important;
    background-repeat: no-repeat;
    padding-right: 30px; }

.react-datepicker-popper {
  transform: translate3d(0, 65px, 0) !important;
  width: 100%; }

.partner-grid-container .react-datepicker-popper {
  transform: translate3d(0, 40px, 0) !important; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0; }

.custom-datepicker-calendar {
  width: 100%;
  min-width: 150px;
  background-color: #fbfbfb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 12px;
  border-color: rgba(12, 12, 12, 0.1); }
  .custom-datepicker-calendar .react-datepicker__triangle {
    display: none; }
  .custom-datepicker-calendar .react-datepicker__navigation {
    top: 25px;
    border: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
    .custom-datepicker-calendar .react-datepicker__navigation--previous {
      left: 40px;
      background-image: url("/images/icons/arrow-left.svg"); }
    .custom-datepicker-calendar .react-datepicker__navigation--next {
      right: 40px;
      background-image: url("/images/icons/arrow-right.svg"); }
    .custom-datepicker-calendar .react-datepicker__navigation:hover {
      opacity: 0.7; }
  .custom-datepicker-calendar .react-datepicker__month-container {
    background-color: #ffffff;
    float: none;
    margin: auto;
    width: 100%; }
    .custom-datepicker-calendar .react-datepicker__month-container .react-datepicker__header {
      background-color: #ffffff;
      border-bottom: none;
      margin: 0 0.4rem; }
      .custom-datepicker-calendar .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
        font-size: 13px;
        margin-top: 4px; }
  .custom-datepicker-calendar .react-datepicker__header {
    padding-top: 16px; }
    .custom-datepicker-calendar .react-datepicker__header .react-datepicker__current-month {
      font-weight: 500;
      text-transform: uppercase; }
  .custom-datepicker-calendar .react-datepicker__day-name,
  .custom-datepicker-calendar .react-datepicker__day,
  .custom-datepicker-calendar .react-datepicker__time-name {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    width: calc((100% / 7) - 8px);
    font-size: 14px;
    margin: 1px; }
  .custom-datepicker-calendar .react-datepicker__week,
  .custom-datepicker-calendar .react-datepicker__day-names {
    align-items: center; }
  .custom-datepicker-calendar .react-datepicker__day {
    /*&:hover {
      color: $white !important;
      background-color: $brown;
    }*/
    /*&--keyboard-selected {
      color: $white !important;
      background-color: $brown;
    }*/ }
    @media (min-width: 768px) {
      .custom-datepicker-calendar .react-datepicker__day:hover {
        background-color: rgba(139, 149, 158, 0.2);
        color: #0c0c0c !important;
        opacity: 1 !important;
        font-weight: 900; } }
    .custom-datepicker-calendar .react-datepicker__day--selected {
      background-color: rgba(139, 149, 158, 0.2);
      color: #0c0c0c !important;
      opacity: 1 !important;
      font-weight: 900; }
    .custom-datepicker-calendar .react-datepicker__day--today {
      color: #0c0c0c !important;
      font-weight: 900; }
      .custom-datepicker-calendar .react-datepicker__day--today:hover {
        background-color: rgba(139, 149, 158, 0.2);
        color: #0c0c0c !important;
        opacity: 1 !important;
        font-weight: 900; }
    .custom-datepicker-calendar .react-datepicker__day--disabled:hover {
      background-color: #fbfbfb !important;
      color: #ccc !important;
      opacity: 1 !important; }
  .custom-datepicker-calendar .react-datepicker__day--outside-month {
    opacity: 0.4; }
  .custom-datepicker-calendar .react-datepicker__day-name {
    line-height: 1;
    margin-top: 6px; }
  .custom-datepicker-calendar.react-datepicker--time-only {
    width: 100%; }
    .custom-datepicker-calendar.react-datepicker--time-only .react-datepicker__time-container {
      min-width: 100px;
      width: 100%;
      border: none; }
      .custom-datepicker-calendar.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__header--time {
        display: none; }
      .custom-datepicker-calendar.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time-box {
        border-radius: 0;
        width: 100%;
        text-align: left; }
      .custom-datepicker-calendar.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time-list-item {
        background-color: #ffffff;
        font-weight: 400;
        padding: 10px !important;
        display: flex;
        align-items: center;
        height: auto !important; }
        .custom-datepicker-calendar.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time-list-item:hover {
          background-color: rgba(12, 12, 12, 0.1) !important;
          font-weight: 500; }
        .custom-datepicker-calendar.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time-list-item--selected {
          background-color: #ffffff !important;
          font-weight: 500 !important;
          color: #0c0c0c !important; }

.form-control::-webkit-input-placeholder {
  color: rgba(12, 12, 12, 0.33); }

input.form-control, input.react-phone-number-input__input.react-phone-number-input__phone,
select.form-control,
select.react-phone-number-input__input.react-phone-number-input__phone {
  height: 50px;
  padding: 0 10px;
  background-color: #fafafa;
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid transparent;
  border-radius: 2px;
  box-shadow: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }
  input.form-control.is-invalid, input.react-phone-number-input__input.react-phone-number-input__phone.is-invalid,
  select.form-control.is-invalid,
  select.react-phone-number-input__input.react-phone-number-input__phone.is-invalid {
    background-image: none; }
  input.form-control:hover, input.form-control:focus, input.react-phone-number-input__input.react-phone-number-input__phone:hover, input.react-phone-number-input__input.react-phone-number-input__phone:focus,
  select.form-control:hover,
  select.form-control:focus,
  select.react-phone-number-input__input.react-phone-number-input__phone:hover,
  select.react-phone-number-input__input.react-phone-number-input__phone:focus {
    outline: none;
    border-color: #0c0c0c;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb; }
    input.form-control:hover.is-invalid, input.form-control:focus.is-invalid, input.react-phone-number-input__input.react-phone-number-input__phone:hover.is-invalid, input.react-phone-number-input__input.react-phone-number-input__phone:focus.is-invalid,
    select.form-control:hover.is-invalid,
    select.form-control:focus.is-invalid,
    select.react-phone-number-input__input.react-phone-number-input__phone:hover.is-invalid,
    select.react-phone-number-input__input.react-phone-number-input__phone:focus.is-invalid {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-color: #d0021b; }
  input.form-control:read-only, input.form-control:disabled, input.react-phone-number-input__input.react-phone-number-input__phone:read-only, input.react-phone-number-input__input.react-phone-number-input__phone:disabled,
  select.form-control:read-only,
  select.form-control:disabled,
  select.react-phone-number-input__input.react-phone-number-input__phone:read-only,
  select.react-phone-number-input__input.react-phone-number-input__phone:disabled {
    border: 0.5px solid transparent;
    box-shadow: none;
    background-color: #fbfbfb; }
  input.form-control.light-bg-control, input.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control,
  select.form-control.light-bg-control,
  select.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control {
    background-color: #ffffff;
    font-size: 13px;
    color: rgba(12, 12, 12, 0.6);
    border: 0.5px solid transparent;
    padding: 15px 15px 20px; }
    input.form-control.light-bg-control:hover, input.form-control.light-bg-control:focus, input.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:hover, input.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:focus,
    select.form-control.light-bg-control:hover,
    select.form-control.light-bg-control:focus,
    select.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:hover,
    select.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:focus {
      border-color: #a79779; }
    input.form-control.light-bg-control:read-only, input.form-control.light-bg-control:disabled, input.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:read-only, input.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:disabled,
    select.form-control.light-bg-control:read-only,
    select.form-control.light-bg-control:disabled,
    select.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:read-only,
    select.react-phone-number-input__input.react-phone-number-input__phone.light-bg-control:disabled {
      border: 0.5px solid transparent;
      box-shadow: none;
      pointer-events: none;
      background-color: #fbfbfb; }
  input.form-control.white-field-input, input.react-phone-number-input__input.react-phone-number-input__phone.white-field-input,
  select.form-control.white-field-input,
  select.react-phone-number-input__input.react-phone-number-input__phone.white-field-input {
    background-color: #ffffff;
    border: 0.5px solid rgba(140, 150, 159, 0.3); }
    input.form-control.white-field-input:hover, input.form-control.white-field-input:focus, input.react-phone-number-input__input.react-phone-number-input__phone.white-field-input:hover, input.react-phone-number-input__input.react-phone-number-input__phone.white-field-input:focus,
    select.form-control.white-field-input:hover,
    select.form-control.white-field-input:focus,
    select.react-phone-number-input__input.react-phone-number-input__phone.white-field-input:hover,
    select.react-phone-number-input__input.react-phone-number-input__phone.white-field-input:focus {
      border: 0.5px solid #8c969f !important; }
  input.form-control.black-field-input, input.react-phone-number-input__input.react-phone-number-input__phone.black-field-input,
  select.form-control.black-field-input,
  select.react-phone-number-input__input.react-phone-number-input__phone.black-field-input {
    background-color: #ffffff;
    border: 0.5px solid rgba(140, 150, 159, 0.3); }
    input.form-control.black-field-input::placeholder, input.react-phone-number-input__input.react-phone-number-input__phone.black-field-input::placeholder,
    select.form-control.black-field-input::placeholder,
    select.react-phone-number-input__input.react-phone-number-input__phone.black-field-input::placeholder {
      color: black; }
    input.form-control.black-field-input:hover, input.form-control.black-field-input:focus, input.react-phone-number-input__input.react-phone-number-input__phone.black-field-input:hover, input.react-phone-number-input__input.react-phone-number-input__phone.black-field-input:focus,
    select.form-control.black-field-input:hover,
    select.form-control.black-field-input:focus,
    select.react-phone-number-input__input.react-phone-number-input__phone.black-field-input:hover,
    select.react-phone-number-input__input.react-phone-number-input__phone.black-field-input:focus {
      border: 0.5px solid #8c969f !important; }
  input.form-control.location-autocomplete-control.show-pin, input.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin,
  select.form-control.location-autocomplete-control.show-pin,
  select.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin {
    padding-right: 25px;
    background-image: url("/images/icons/pin.png");
    background-repeat: no-repeat;
    background-size: 12px 15px;
    background-position: center right 7px;
    color: #0c0c0c; }
    input.form-control.location-autocomplete-control.show-pin.is-invalid, input.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin.is-invalid,
    select.form-control.location-autocomplete-control.show-pin.is-invalid,
    select.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin.is-invalid {
      border-color: #d0021b; }
    input.form-control.location-autocomplete-control.show-pin.dis-active, input.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin.dis-active,
    select.form-control.location-autocomplete-control.show-pin.dis-active,
    select.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin.dis-active {
      color: rgba(12, 12, 12, 0.3); }
    input.form-control.location-autocomplete-control.show-pin.active, input.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin.active,
    select.form-control.location-autocomplete-control.show-pin.active,
    select.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.show-pin.active {
      color: #0c0c0c;
      background-image: url("/images/icons/pin_active.png") !important; }
  input.form-control.location-autocomplete-control.flightTracking-loader-register, input.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.flightTracking-loader-register,
  select.form-control.location-autocomplete-control.flightTracking-loader-register,
  select.react-phone-number-input__input.react-phone-number-input__phone.location-autocomplete-control.flightTracking-loader-register {
    padding-right: 30px;
    background-image: url("/images/planeIcons/planeLoader.svg") !important;
    background-repeat: no-repeat;
    background-size: 15px 20px;
    background-position: center right 8px;
    color: #0c0c0c; }
  input.form-control.m-location-autocomplete-control.show-pin, input.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin,
  select.form-control.m-location-autocomplete-control.show-pin,
  select.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin {
    padding-right: 25px;
    background-image: url("/images/icons/pin.png");
    background-repeat: no-repeat;
    background-size: 12px 15px;
    background-position: center left 28px;
    color: #0c0c0c; }
    input.form-control.m-location-autocomplete-control.show-pin.is-invalid, input.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin.is-invalid,
    select.form-control.m-location-autocomplete-control.show-pin.is-invalid,
    select.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin.is-invalid {
      border-color: #d0021b; }
    input.form-control.m-location-autocomplete-control.show-pin.dis-active, input.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin.dis-active,
    select.form-control.m-location-autocomplete-control.show-pin.dis-active,
    select.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin.dis-active {
      color: rgba(12, 12, 12, 0.3); }
    input.form-control.m-location-autocomplete-control.show-pin.active, input.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin.active,
    select.form-control.m-location-autocomplete-control.show-pin.active,
    select.react-phone-number-input__input.react-phone-number-input__phone.m-location-autocomplete-control.show-pin.active {
      color: #0c0c0c;
      background-image: url("/images/icons/pin_active.png") !important; }

input.custom-datepicker-control,
select.custom-datepicker-control {
  background-image: url("/images/icons/calendar.png");
  background-size: 12px 12px;
  background-position: center right 7px !important;
  background-repeat: no-repeat;
  padding-right: 30px;
  color: rgba(12, 12, 12, 0.3); }
  input.custom-datepicker-control:read-only,
  select.custom-datepicker-control:read-only {
    background-color: #fafafa;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    input.custom-datepicker-control:read-only:hover, input.custom-datepicker-control:read-only:focus,
    select.custom-datepicker-control:read-only:hover,
    select.custom-datepicker-control:read-only:focus {
      outline: none;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: #fbfbfb;
      border-color: #0c0c0c; }
    input.custom-datepicker-control:read-only.is-invalid,
    select.custom-datepicker-control:read-only.is-invalid {
      border-width: 0.5px;
      border-color: #d0021b;
      background-image: url("/images/icons/calendar.png");
      background-size: 19px 19px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  input.custom-datepicker-control.active,
  select.custom-datepicker-control.active {
    background-image: url("/images/icons/calendar_active.png");
    color: #0c0c0c; }
  input.custom-datepicker-control:disabled,
  select.custom-datepicker-control:disabled {
    background-image: url("/images/icons/calendar.png"); }
  input.custom-datepicker-control.custom-timepicker-control,
  select.custom-datepicker-control.custom-timepicker-control {
    background-image: url("/images/icon-duration-inactive.svg");
    color: rgba(12, 12, 12, 0.3); }
    input.custom-datepicker-control.custom-timepicker-control.active,
    select.custom-datepicker-control.custom-timepicker-control.active {
      background-image: url("/images/icon-duration-active.png");
      color: #0c0c0c; }
    input.custom-datepicker-control.custom-timepicker-control:disabled,
    select.custom-datepicker-control.custom-timepicker-control:disabled {
      background-image: url("/images/icon-duration-inactive.svg"); }
    input.custom-datepicker-control.custom-timepicker-control.is-invalid,
    select.custom-datepicker-control.custom-timepicker-control.is-invalid {
      background-image: url("/images/icon-duration-inactive.svg"); }
  input.custom-datepicker-control.show-clear,
  select.custom-datepicker-control.show-clear {
    background-image: none; }
    input.custom-datepicker-control.show-clear:hover, input.custom-datepicker-control.show-clear:focus,
    select.custom-datepicker-control.show-clear:hover,
    select.custom-datepicker-control.show-clear:focus {
      background-image: none; }

input.grey-field-input,
select.grey-field-input {
  background-color: #fafafa !important; }

input.grey-field-input-l3,
select.grey-field-input-l3 {
  background-color: #fafafa; }

.phone-number-flag {
  flex: 0 0 auto;
  padding-right: 5px; }

.phone-number-input {
  flex: 0 0 calc(100% - 124px);
  padding-left: 5px; }

.phone-number-select {
  position: absolute !important;
  width: 420px !important; }
  .phone-number-select .react-select__control {
    display: none; }
  .phone-number-select .react-select-filters__control {
    display: none; }

button.dropdown-btn {
  height: 50px;
  color: #0c0c0c;
  background-color: #fbfbfb;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  padding: 2px 9px 2px 10px;
  width: 100%; }
  button.dropdown-btn:hover, button.dropdown-btn:focus, button.dropdown-btn:active {
    background-color: #fbfbfb !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #0c0c0c !important;
    color: #0c0c0c !important; }
  button.dropdown-btn span {
    text-align: left;
    width: 40px;
    padding-left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    position: relative; }

.fixed-top {
  padding-top: 4px;
  z-index: 0; }

img.icon-flag {
  width: 24px;
  height: 18px;
  margin-right: 5px; }

img.dropdown-svg {
  margin-top: 14px !important;
  margin-left: 12px; }

select.form-control {
  -webkit-appearance: none;
  background-image: url("/images/icons/caret-down.svg");
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center right 10px; }

.company-logo-wrap .custom-file {
  background-color: transparent !important; }

.custom-file,
.custom-file-label {
  height: 50px;
  padding: 0 10px 0;
  background-color: #fafafa;
  cursor: pointer; }

.custom-file-input {
  display: none; }

.custom-file-label {
  color: #a79779;
  font-size: 10px;
  font-weight: 400;
  border: 0.5px solid transparent;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  text-transform: none;
  display: flex;
  align-items: center; }
  .custom-file-label img {
    width: 30px;
    height: 30px; }
  .custom-file-label .file-name {
    letter-spacing: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    white-space: nowrap; }
  .custom-file-label:after {
    display: none; }
  .custom-file-label:hover, .custom-file-label:focus {
    outline: none;
    border-color: #a79779;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fafafa; }
    .custom-file-label:hover.is-invalid, .custom-file-label:focus.is-invalid {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.custom-file.disabled .custom-file-label {
  box-shadow: none;
  border: 0.5px solid transparent;
  background-color: #fbfbfb; }

.no-visible {
  display: none; }

.chat-paperclip {
  width: 40px;
  height: 46px;
  position: absolute;
  left: 8px; }
  .chat-paperclip .custom-file-label {
    box-shadow: none;
    border: none;
    height: 42px; }
    .chat-paperclip .custom-file-label:hover {
      border: none;
      background: transparent; }

label {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #636d77;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.checked .custom-control-label {
  color: #0c0c0c; }

.options .custom-control-label {
  color: #0c0c0c;
  padding-top: 3px !important; }

.custom-radio,
.custom-checkbox {
  display: flex;
  min-height: 15px;
  z-index: 0 !important; }
  .custom-radio .custom-control-label,
  .custom-checkbox .custom-control-label {
    flex-grow: 1;
    padding-top: 2px;
    cursor: pointer;
    text-transform: none;
    letter-spacing: initial; }
    .custom-radio .custom-control-label:before, .custom-radio .custom-control-label:after,
    .custom-checkbox .custom-control-label:before,
    .custom-checkbox .custom-control-label:after {
      top: 0;
      width: 15px;
      height: 15px;
      cursor: pointer; }
    .custom-radio .custom-control-label:before,
    .custom-checkbox .custom-control-label:before {
      border: none;
      background-color: transparent; }
    .custom-radio .custom-control-label:after,
    .custom-checkbox .custom-control-label:after {
      background-size: 15px 15px; }
  .custom-radio .custom-control-input,
  .custom-checkbox .custom-control-input {
    display: none; }
    .custom-radio .custom-control-input:checked ~ .custom-control-label::before,
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
      border: none;
      background-color: transparent; }

.custom-radio-partner .custom-control-label:before {
  width: 18px !important;
  height: 18px !important; }

.custom-radio-partner .custom-control-label:after {
  background-size: 18px 18px !important;
  width: 18px !important;
  height: 18px !important; }

.custom-radio {
  margin-bottom: 10px; }
  .custom-radio .custom-control-label:after {
    background-image: url("/images/icons/radio.svg"); }
  .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("/images/icons/radio-checked.svg"); }

.custom-checkbox {
  z-index: 0 !important; }
  .custom-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 15px !important;
    color: #0c0c0c;
    padding-top: 0.2rem; }
    .custom-checkbox .custom-control-label:after {
      border-radius: 2px;
      background-color: #fbfbfb;
      border: 1px solid #8b959e;
      background-size: 8px 11px;
      background-position: center center; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("/images/icons/check.svg"); }

.passenger-dropdown-checkbox {
  white-space: pre; }
  .passenger-dropdown-checkbox .custom-control-label {
    color: #0c0c0c !important;
    white-space: pre; }
  .passenger-dropdown-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    border: 1px solid #0c0c0c; }

.com-popUp .custom-control-label {
  color: #8b959e; }

textarea.form-control {
  border: 0.5px solid transparent;
  background-color: #fafafa;
  min-height: 160px;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 14px;
  font-weight: 400; }
  textarea.form-control:hover, textarea.form-control:focus {
    outline: none;
    border-color: #0c0c0c;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb; }
    textarea.form-control:hover.is-invalid, textarea.form-control:focus.is-invalid {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.textareaMobileFont {
  font-size: 16px; }

.text-muted.textarea-hint {
  margin-top: 7px;
  font-size: 12px;
  color: #b9b9b9 !important;
  line-height: 20px; }

.change-req-field {
  height: 50px;
  padding: 12px;
  padding-top: 14px;
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid transparent;
  border-radius: 2px;
  box-shadow: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: rgba(139, 149, 158, 0.1) !important; }

.react-select-container {
  width: 100%;
  font-size: 14px; }
  .react-select-container.is-invalid .react-select__control {
    border: 1px solid #d0021b; }
    .react-select-container.is-invalid .react-select__control:hover, .react-select-container.is-invalid .react-select__control:focus {
      border-color: #d0021b; }
  .react-select-container .react-select__control {
    height: 50px;
    border-radius: 2px 2px 0 0;
    border: 0.5px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
    padding: 0; }
    .react-select-container .react-select__control--is-focused, .react-select-container .react-select__control--menu-is-open, .react-select-container .react-select__control:hover {
      border-color: #0c0c0c;
      background-color: #fafafa; }
    .react-select-container .react-select__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .react-select-container .react-select__control--menu-is-open img {
        transform: rotate(180deg) !important; }
    .react-select-container .react-select__control--is-disabled {
      background-color: rgba(139, 149, 158, 0.1) !important; }
    .react-select-container .react-select__control .react-select__indicator-separator {
      display: none; }
    .react-select-container .react-select__control .react-select__dropdown-indicator {
      padding: 0 5px; }
      .react-select-container .react-select__control .react-select__dropdown-indicator img {
        width: 17px;
        height: 17px; }
    .react-select-container .react-select__control .react-select__dropdown-indicator-creatable {
      padding: 0 10px !important; }
      .react-select-container .react-select__control .react-select__dropdown-indicator-creatable img {
        width: 10px !important;
        height: 10px !important; }
    .react-select-container .react-select__control .react-select__dropdown-indicator-clearable-filter {
      padding: 0 10px !important; }
      .react-select-container .react-select__control .react-select__dropdown-indicator-clearable-filter img {
        width: 10px !important;
        height: 10px !important; }
    .react-select-container .react-select__control .react-select__dropdown-indicator-carrot-filter {
      padding: 0 10px !important; }
      .react-select-container .react-select__control .react-select__dropdown-indicator-carrot-filter img {
        width: 17px !important;
        height: 17px !important; }
    .react-select-container .react-select__control .react-select__single-value {
      width: 100%;
      color: #0c0c0c; }
    .react-select-container .react-select__control .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .react-select-container .react-select__control .react-select__value-container--is-multi {
      flex-wrap: nowrap; }
      .react-select-container .react-select__control .react-select__value-container--is-multi .select-all-btn {
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer; }
        .react-select-container .react-select__control .react-select__value-container--is-multi .select-all-btn:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-select-container .react-select__control .react-select__value-container--is-multi .select-all-btn.selected-all:before {
          background-image: url("/images/icons/radio-checked.svg"); }
        .react-select-container .react-select__control .react-select__value-container--is-multi .select-all-btn .btn-title {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
    .react-select-container .react-select__control .react-select__value-container .react-select__input input {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 0 !important;
      width: 8.938rem !important; }
    .react-select-container .react-select__control .react-select__placeholder {
      color: rgba(12, 12, 12, 0.3); }
  .react-select-container .react-select__menu {
    margin-top: 7px;
    border: 1px solid rgba(12, 12, 12, 0.1);
    box-shadow: none;
    border-radius: 0;
    border-radius: 0 0 2px 2px !important;
    z-index: 2; }
    .react-select-container .react-select__menu .react-select__option {
      padding: 10px;
      color: #0c0c0c;
      cursor: pointer;
      border: none; }
      .react-select-container .react-select__menu .react-select__option:hover, .react-select-container .react-select__menu .react-select__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
      .react-select-container .react-select__menu .react-select__option--is-selected {
        color: #ffffff;
        background-color: #0c0c0c; }
        .react-select-container .react-select__menu .react-select__option--is-selected:hover {
          background-color: rgba(12, 12, 12, 0.1); }
    .react-select-container .react-select__menu-list {
      padding: 0;
      margin-left: -1px; }
      .react-select-container .react-select__menu-list--is-multi .selectOptionCheckbox .react-select__option {
        display: flex;
        align-items: center; }
        .react-select-container .react-select__menu-list--is-multi .selectOptionCheckbox .react-select__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: none;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px;
          border-radius: 2px;
          background-color: #fbfbfb;
          border: 1px solid #8b959e;
          background-size: 8px 11px;
          background-position: center center;
          margin-bottom: 0.2rem; }
        .react-select-container .react-select__menu-list--is-multi .selectOptionCheckbox .react-select__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-select-container .react-select__menu-list--is-multi .selectOptionCheckbox .react-select__option--is-selected:hover {
            background-color: rgba(12, 12, 12, 0.1); }
          .react-select-container .react-select__menu-list--is-multi .selectOptionCheckbox .react-select__option--is-selected:before {
            background-image: url("/images/icons/check.svg"); }
      .react-select-container .react-select__menu-list--is-multi .react-select__option {
        display: flex;
        align-items: center; }
        .react-select-container .react-select__menu-list--is-multi .react-select__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-select-container .react-select__menu-list--is-multi .react-select__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-select-container .react-select__menu-list--is-multi .react-select__option--is-selected:hover, .react-select-container .react-select__menu-list--is-multi .react-select__option--is-selected--is-focused {
            background-color: rgba(167, 151, 121, 0.3); }
          .react-select-container .react-select__menu-list--is-multi .react-select__option--is-selected:before {
            background-image: url("/images/icons/radio-checked.svg"); }

.react-select-filters-container {
  width: 100%;
  font-size: 14px; }
  .react-select-filters-container.is-invalid .react-select-filters__control {
    border: 1px solid #d0021b; }
    .react-select-filters-container.is-invalid .react-select-filters__control:hover, .react-select-filters-container.is-invalid .react-select-filters__control:focus {
      border-color: #d0021b; }
  .react-select-filters-container.hasValue .react-select-filters__control {
    border-color: #0c0c0c !important; }
  .react-select-filters-container .react-select-filters__control {
    height: 38px;
    border-radius: 2px;
    border: 1px solid rgba(139, 149, 158, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 0; }
    .react-select-filters-container .react-select-filters__control--is-focused, .react-select-filters-container .react-select-filters__control--menu-is-open, .react-select-filters-container .react-select-filters__control:hover {
      border-color: #0c0c0c; }
    .react-select-filters-container .react-select-filters__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .react-select-filters-container .react-select-filters__control--is-disabled {
      background-color: #fafafa; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__value-container--has-value {
      border-color: #0c0c0c; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__clear-indicator {
      border-left: 1px solid black; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__indicator-separator {
      display: none; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator {
      padding: 0 5px; }
      .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator img {
        width: 17px;
        height: 17px; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator-creatable {
      padding: 0 3px !important; }
      .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator-creatable img {
        width: 10px !important;
        height: 10px !important; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator-clearable-filter {
      padding: 0 3px !important; }
      .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator-clearable-filter img {
        width: 8px !important;
        height: 8px !important;
        margin-bottom: 1px; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator-carrot-filter {
      padding: 0 3px !important; }
      .react-select-filters-container .react-select-filters__control .react-select-filters__dropdown-indicator-carrot-filter img {
        width: 17px !important;
        height: 17px !important; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__single-value {
      color: #0c0c0c; }
    .react-select-filters-container .react-select-filters__control .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__value-container--is-multi {
      flex-wrap: nowrap; }
      .react-select-filters-container .react-select-filters__control .react-select-filters__value-container--is-multi .select-all-btn {
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: pointer; }
        .react-select-filters-container .react-select-filters__control .react-select-filters__value-container--is-multi .select-all-btn:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-select-filters-container .react-select-filters__control .react-select-filters__value-container--is-multi .select-all-btn.selected-all:before {
          background-image: url("/images/icons/radio-checked.svg"); }
        .react-select-filters-container .react-select-filters__control .react-select-filters__value-container--is-multi .select-all-btn .btn-title {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
    .react-select-filters-container .react-select-filters__control .react-select-filters__placeholder {
      color: #0c0c0c;
      font-size: 14px;
      padding-top: 2px; }
  .react-select-filters-container .react-select-filters__menu {
    margin-top: 7px;
    border: 1px solid rgba(12, 12, 12, 0.1);
    box-shadow: none;
    border-radius: 0;
    z-index: 2; }
    .react-select-filters-container .react-select-filters__menu .react-select-filters__option {
      padding: 10px;
      color: #0c0c0c;
      cursor: pointer;
      border: none; }
      .react-select-filters-container .react-select-filters__menu .react-select-filters__option:hover, .react-select-filters-container .react-select-filters__menu .react-select-filters__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
      .react-select-filters-container .react-select-filters__menu .react-select-filters__option--is-selected {
        color: #ffffff;
        background-color: #0c0c0c; }
        .react-select-filters-container .react-select-filters__menu .react-select-filters__option--is-selected:hover {
          background-color: rgba(12, 12, 12, 0.1); }
    .react-select-filters-container .react-select-filters__menu-list {
      padding: 0;
      margin-left: -1px; }
      .react-select-filters-container .react-select-filters__menu-list--is-multi .react-select-filters__option {
        display: flex;
        align-items: center; }
        .react-select-filters-container .react-select-filters__menu-list--is-multi .react-select-filters__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-select-filters-container .react-select-filters__menu-list--is-multi .react-select-filters__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-select-filters-container .react-select-filters__menu-list--is-multi .react-select-filters__option--is-selected:hover, .react-select-filters-container .react-select-filters__menu-list--is-multi .react-select-filters__option--is-selected--is-focused {
            background-color: rgba(167, 151, 121, 0.3); }
          .react-select-filters-container .react-select-filters__menu-list--is-multi .react-select-filters__option--is-selected:before {
            background-image: url("/images/icons/radio-checked.svg"); }

.react-currencySelect-container,
.react-currencySelect__control--is-focused {
  background: transparent;
  background-color: transparent !important; }

.react-currencySelect-container {
  width: 100%;
  font-size: 14px;
  background: transparent;
  z-index: 1; }
  .react-currencySelect-container.is-invalid .react-currencySelect__control {
    border: 1px solid #d0021b;
    background: transparent; }
    .react-currencySelect-container.is-invalid .react-currencySelect__control:hover, .react-currencySelect-container.is-invalid .react-currencySelect__control:focus {
      border-color: #d0021b; }
  .react-currencySelect-container .react-currencySelect__control {
    height: 50px;
    border-radius: 2px;
    border: 0.5px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: red;
    padding: 0;
    background: transparent; }
    .react-currencySelect-container .react-currencySelect__control--is-focused, .react-currencySelect-container .react-currencySelect__control--menu-is-open, .react-currencySelect-container .react-currencySelect__control:hover {
      border-color: #0c0c0c;
      background-color: transparent; }
    .react-currencySelect-container .react-currencySelect__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .react-currencySelect-container .react-currencySelect__control--is-disabled {
      background-color: #fafafa; }
    .react-currencySelect-container .react-currencySelect__control .react-currencySelect__indicator-separator {
      display: none; }
    .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator {
      padding: 0 5px; }
      .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator img {
        width: 17px;
        height: 17px; }
    .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator-creatable {
      padding: 0 10px !important; }
      .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator-creatable img {
        width: 10px !important;
        height: 10px !important; }
    .react-currencySelect-container .react-currencySelect__control .react-currencySelect__single-value {
      color: #0c0c0c; }
    .react-currencySelect-container .react-currencySelect__control .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container {
      z-index: -1; }
      .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi {
        flex-wrap: nowrap; }
        .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn {
          display: flex;
          align-items: center;
          overflow: hidden;
          cursor: pointer; }
          .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url("/images/icons/radio.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px; }
          .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn.selected-all:before {
            background-image: url("/images/icons/radio-checked.svg"); }
          .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn .btn-title {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
    .react-currencySelect-container .react-currencySelect__control .react-currencySelect__placeholder {
      color: rgba(12, 12, 12, 0.3);
      z-index: 1; }
  .react-currencySelect-container .react-currencySelect__control--menu-is-open .react-currencySelect__value-container {
    z-index: 0; }
  .react-currencySelect-container .react-currencySelect__menu {
    margin-top: 7px;
    border: 1px solid rgba(12, 12, 12, 0.1);
    box-shadow: none;
    border-radius: 0;
    z-index: -2; }
    .react-currencySelect-container .react-currencySelect__menu .react-select__option {
      padding: 10px;
      color: #0c0c0c;
      cursor: pointer;
      border: none; }
      .react-currencySelect-container .react-currencySelect__menu .react-select__option:hover, .react-currencySelect-container .react-currencySelect__menu .react-select__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
      .react-currencySelect-container .react-currencySelect__menu .react-select__option--is-selected {
        color: #ffffff;
        background-color: #0c0c0c; }
        .react-currencySelect-container .react-currencySelect__menu .react-select__option--is-selected:hover {
          background-color: rgba(12, 12, 12, 0.1); }
    .react-currencySelect-container .react-currencySelect__menu .react-currencySelect__option {
      background-color: #ffffff; }
      .react-currencySelect-container .react-currencySelect__menu .react-currencySelect__option:hover, .react-currencySelect-container .react-currencySelect__menu .react-currencySelect__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
    .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option {
      padding: 10px;
      color: #0c0c0c;
      cursor: pointer;
      border: none; }
      .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option:hover, .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
      .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option--is-selected {
        color: #ffffff;
        background-color: #0c0c0c; }
        .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option--is-selected:hover {
          background-color: rgba(12, 12, 12, 0.1); }
    .react-currencySelect-container .react-currencySelect__menu-list {
      padding: 0;
      margin-left: -1px; }
      .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option {
        display: flex;
        align-items: center; }
        .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected:hover, .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected--is-focused {
            background-color: rgba(167, 151, 121, 0.3); }
          .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected:before {
            background-image: url("/images/icons/radio-checked.svg"); }
      .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option {
        display: flex;
        align-items: center; }
        .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected:hover, .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected--is-focused {
            background-color: rgba(167, 151, 121, 0.3); }
          .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected:before {
            background-image: url("/images/icons/radio-checked.svg"); }
  .react-currencySelect-container.payment-currency-settings {
    z-index: 0 !important; }

.react-countrySelect-container {
  width: 100%;
  font-size: 14px;
  background: transparent;
  z-index: 60; }
  .react-countrySelect-container.is-invalid .react-currencySelect__control {
    border: 1px solid #d0021b;
    background: transparent; }
    .react-countrySelect-container.is-invalid .react-currencySelect__control:hover, .react-countrySelect-container.is-invalid .react-currencySelect__control:focus {
      border-color: #d0021b; }
  .react-countrySelect-container .react-currencySelect__control {
    height: 50px;
    border-radius: 2px;
    border: 0.5px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: red;
    padding: 0;
    background: transparent; }
    .react-countrySelect-container .react-currencySelect__control--is-focused, .react-countrySelect-container .react-currencySelect__control--menu-is-open, .react-countrySelect-container .react-currencySelect__control:hover {
      border-color: #0c0c0c;
      background-color: transparent; }
    .react-countrySelect-container .react-currencySelect__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .react-countrySelect-container .react-currencySelect__control--is-disabled {
      background-color: #fafafa; }
    .react-countrySelect-container .react-currencySelect__control .react-currencySelect__indicator-separator {
      display: none; }
    .react-countrySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator {
      padding: 0 5px; }
      .react-countrySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator img {
        width: 17px;
        height: 17px; }
    .react-countrySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator-creatable {
      padding: 0 10px !important; }
      .react-countrySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator-creatable img {
        width: 10px !important;
        height: 10px !important; }
    .react-countrySelect-container .react-currencySelect__control .react-currencySelect__single-value {
      color: #0c0c0c; }
    .react-countrySelect-container .react-currencySelect__control .select-multiple-value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .react-countrySelect-container .react-currencySelect__control .react-currencySelect__value-container {
      z-index: -1; }
      .react-countrySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi {
        flex-wrap: nowrap; }
        .react-countrySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn {
          display: flex;
          align-items: center;
          overflow: hidden;
          cursor: pointer; }
          .react-countrySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url("/images/icons/radio.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px; }
          .react-countrySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn.selected-all:before {
            background-image: url("/images/icons/radio-checked.svg"); }
          .react-countrySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn .btn-title {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; }
    .react-countrySelect-container .react-currencySelect__control .react-currencySelect__placeholder {
      color: rgba(12, 12, 12, 0.3);
      z-index: 1; }
  .react-countrySelect-container .react-currencySelect__control--menu-is-open .react-currencySelect__value-container {
    z-index: 0; }
  .react-countrySelect-container .react-currencySelect__menu {
    margin-top: 7px;
    border: 1px solid rgba(12, 12, 12, 0.1);
    box-shadow: none;
    border-radius: 0;
    z-index: -2; }
    .react-countrySelect-container .react-currencySelect__menu .react-select__option {
      padding: 10px;
      color: #0c0c0c;
      cursor: pointer;
      border: none; }
      .react-countrySelect-container .react-currencySelect__menu .react-select__option:hover, .react-countrySelect-container .react-currencySelect__menu .react-select__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
      .react-countrySelect-container .react-currencySelect__menu .react-select__option--is-selected {
        color: #ffffff;
        background-color: #0c0c0c; }
        .react-countrySelect-container .react-currencySelect__menu .react-select__option--is-selected:hover {
          background-color: rgba(12, 12, 12, 0.1); }
    .react-countrySelect-container .react-currencySelect__menu .react-currencySelect__option {
      background-color: #ffffff; }
    .react-countrySelect-container .react-currencySelect__menu .react-select-filters__option {
      padding: 10px;
      color: #0c0c0c;
      cursor: pointer;
      border: none; }
      .react-countrySelect-container .react-currencySelect__menu .react-select-filters__option:hover, .react-countrySelect-container .react-currencySelect__menu .react-select-filters__option--is-focused {
        background-color: rgba(12, 12, 12, 0.1); }
      .react-countrySelect-container .react-currencySelect__menu .react-select-filters__option--is-selected {
        color: #ffffff;
        background-color: #0c0c0c; }
        .react-countrySelect-container .react-currencySelect__menu .react-select-filters__option--is-selected:hover {
          background-color: rgba(12, 12, 12, 0.1); }
    .react-countrySelect-container .react-currencySelect__menu-list {
      padding: 0;
      margin-left: -1px; }
      .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select__option {
        display: flex;
        align-items: center; }
        .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected:hover, .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected--is-focused {
            background-color: rgba(167, 151, 121, 0.3); }
          .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected:before {
            background-image: url("/images/icons/radio-checked.svg"); }
      .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option {
        display: flex;
        align-items: center; }
        .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option:before {
          content: '';
          display: inline-block;
          width: 15px;
          min-width: 15px;
          height: 15px;
          background-image: url("/images/icons/radio.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 15px; }
        .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected {
          color: #0c0c0c;
          background-color: transparent; }
          .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected:hover, .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected--is-focused {
            background-color: rgba(167, 151, 121, 0.3); }
          .react-countrySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected:before {
            background-image: url("/images/icons/radio-checked.svg"); }

.location-autocomplete-result,
.autocomplete-result {
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: #ffffff !important;
  font-size: 13px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-top: 6px;
  left: 0; }
  .location-autocomplete-result .location-autocomplete-item,
  .location-autocomplete-result .autocomplete-item,
  .autocomplete-result .location-autocomplete-item,
  .autocomplete-result .autocomplete-item {
    padding: 10px 10px;
    cursor: pointer; }
    .location-autocomplete-result .location-autocomplete-item.active,
    .location-autocomplete-result .autocomplete-item.active,
    .autocomplete-result .location-autocomplete-item.active,
    .autocomplete-result .autocomplete-item.active {
      background-color: #ffffff !important; }

.wrap-autocomplete > div {
  width: 100%; }

.invalid-feedback {
  text-align: right; }

.react-phone-number-input .react-phone-number-input__row {
  position: relative; }

.react-phone-number-input .react-phone-number-input__country {
  padding: 10px; }
  .react-phone-number-input .react-phone-number-input__country .react-phone-number-input__icon {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    .react-phone-number-input .react-phone-number-input__country .react-phone-number-input__icon-image {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .react-phone-number-input .react-phone-number-input__country .react-phone-number-input__icon .international-icon {
      width: 15px;
      height: 15px;
      background-image: url("/images/icons/phone.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }

.react-phone-number-input:hover .react-phone-number-input__country .react-phone-number-input__icon .international-icon {
  background-image: url("/images/icons/phone_active.svg"); }

.react-phone-number-input:hover.is-invalid .react-phone-number-input__input {
  border-color: #d0021b; }

.react-phone-number-input.is-invalid .react-phone-number-input__input {
  border-color: #d0021b; }

.payment-radio-group {
  padding-inline: 0 !important; }
  .payment-radio-group .custom-radio {
    align-items: center;
    padding-left: 0; }
    .payment-radio-group .custom-radio:not(:last-child) {
      margin-bottom: 15px; }
    .payment-radio-group .custom-radio:hover {
      border-color: #a79779;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .payment-radio-group .custom-radio .custom-control-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #0c0c0c;
      border: 1px solid rgba(139, 149, 158, 0.25);
      background-color: #fdfdfd;
      padding: 20px 20px 20px 40px;
      cursor: pointer; }
      .payment-radio-group .custom-radio .custom-control-label:before, .payment-radio-group .custom-radio .custom-control-label:after {
        top: 3px;
        left: 20px; }
      .payment-radio-group .custom-radio .custom-control-label .payment-icon {
        width: 27px;
        height: auto;
        margin: 0 10px; }
      .payment-radio-group .custom-radio .custom-control-label .payment-action {
        color: #8b959e; }
        .payment-radio-group .custom-radio .custom-control-label .payment-action:hover {
          opacity: 0.8; }
  .payment-radio-group_transparent {
    margin-bottom: 20px; }
    .payment-radio-group_transparent .custom-radio {
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(139, 149, 158, 0.25); }
      .payment-radio-group_transparent .custom-radio:hover {
        border-bottom: 1px solid rgba(139, 149, 158, 0.25);
        box-shadow: none; }
      .payment-radio-group_transparent .custom-radio .custom-control-label {
        padding: 0;
        padding-left: 30px;
        border: none;
        background: transparent; }
        .payment-radio-group_transparent .custom-radio .custom-control-label:before, .payment-radio-group_transparent .custom-radio .custom-control-label:after {
          left: 0; }
  .payment-radio-group_no-border .custom-radio {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0; }
    .payment-radio-group_no-border .custom-radio:hover {
      border-bottom: 0; }

.payment-select-label .displayName {
  display: inline-table;
  line-height: 1.5; }
  .payment-select-label .displayName .fontItalic {
    font-style: italic;
    font-size: 12px; }

.payment-select-label .payment-icon {
  width: 27px;
  height: auto;
  margin: 0 10px 0 0; }

.payment-select-label span {
  font-size: 14px;
  vertical-align: middle; }

.payment-link-label {
  display: flex;
  align-items: flex-start;
  margin-left: 0.8rem; }
  .payment-link-label .displayName {
    display: inline-table;
    line-height: 1.5;
    margin-top: 0.125rem; }
    .payment-link-label .displayName .fontItalic {
      font-style: italic;
      font-size: 12px; }
  .payment-link-label .payment-icon {
    width: 1.25rem;
    height: 1.5625rem;
    margin: 0 0.5rem; }
  .payment-link-label span {
    font-size: 14px;
    vertical-align: middle; }

.montly-billing-label {
  align-items: flex-start;
  margin-left: 1.6rem;
  margin-bottom: 1rem; }
  .montly-billing-label .displayName {
    display: inline-table;
    line-height: 1.5;
    margin-bottom: 1rem; }
  .montly-billing-label span {
    font-size: 14px;
    vertical-align: middle; }

.partner-logo-field {
  width: 120px;
  text-align: center;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer; }
  .partner-logo-field .company-logo {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(139, 149, 158, 0.5);
    border-radius: 50%;
    font-size: 45px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 25px;
    text-transform: uppercase;
    padding: 4px 3px 0 3px; }
    .partner-logo-field .company-logo.isCompanyLogoPage {
      padding: 0 !important; }
  .partner-logo-field img.company-logo {
    object-fit: cover;
    opacity: 1; }
  .partner-logo-field:hover .action-btn {
    text-decoration: underline; }

.company-logo-field {
  text-align: center;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer; }
  .company-logo-field .company-logo {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(139, 149, 158, 0.5);
    border-radius: 50%;
    font-size: 45px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 25px;
    text-transform: uppercase;
    padding: 4px 3px 0 3px; }
    .company-logo-field .company-logo.isCompanyLogoPage {
      padding: 0 !important; }
  .company-logo-field img.company-logo {
    object-fit: cover;
    opacity: 1; }
  .company-logo-field:hover .action-btn {
    text-decoration: underline; }

.pdf-preview {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #a79779;
  font-size: 12px; }
  .pdf-preview img {
    margin-right: 5px; }

.testCheckBox .custom-checkbox .custom-control-input {
  margin-left: -23px;
  display: block;
  position: absolute;
  z-index: 1;
  opacity: 0; }

.normalizeText p {
  text-transform: none !important; }

.normalizeText pre {
  text-transform: none !important; }

.normalizeText-link p {
  text-transform: none !important;
  color: #8b959e; }

.normalizeText-link a {
  text-transform: none !important;
  color: #8b959e; }

.max-width-80 {
  max-width: 80%; }

.white-space-nowrap {
  white-space: nowrap; }

.react-tel-input .country-list .country,
.react-tel-input .form-control {
  font-family: 'AvenirNext'; }

.react-tel-input .form-control {
  background: #fbfbfb !important; }

.phone-field-row .react-tel-input .form-control {
  width: calc(75% - 4px) !important;
  margin-left: auto !important;
  padding-left: 16px !important;
  background: #fafafa !important; }

.phone-field-equal .react-tel-input .form-control {
  width: calc(50% - 4px) !important;
  margin-left: auto !important;
  padding-left: 16px !important;
  background: #ffffff !important; }

.phone-field-row .react-tel-input .selected-flag {
  background-color: #fafafa !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100% !important; }
  .phone-field-row .react-tel-input .selected-flag.open {
    width: calc(25% - 6px) !important; }
    .phone-field-row .react-tel-input .selected-flag.open::after {
      top: 32% !important;
      transform: rotate(180deg) !important; }
  .phone-field-row .react-tel-input .selected-flag.open:before {
    box-shadow: none; }
  .phone-field-row .react-tel-input .selected-flag:focus:before, .phone-field-row .react-tel-input .selected-flag.open:before {
    display: none; }
  .phone-field-row .react-tel-input .selected-flag .arrow {
    display: none; }
  .phone-field-row .react-tel-input .selected-flag::after {
    content: '';
    height: 17px;
    width: 17px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: url("/images/icons/caret-down.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px 17px; }

.phone-field-equal .react-tel-input .selected-flag {
  background-color: #fbfbfb !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100% !important; }
  .phone-field-equal .react-tel-input .selected-flag.open {
    width: calc(50% - 8px) !important; }
    .phone-field-equal .react-tel-input .selected-flag.open::after {
      top: 32% !important;
      transform: rotate(180deg) !important; }
  .phone-field-equal .react-tel-input .selected-flag.open:before {
    box-shadow: none; }
  .phone-field-equal .react-tel-input .selected-flag:focus:before, .phone-field-equal .react-tel-input .selected-flag.open:before {
    display: none; }
  .phone-field-equal .react-tel-input .selected-flag .arrow {
    display: none; }
  .phone-field-equal .react-tel-input .selected-flag::after {
    content: '';
    height: 17px;
    width: 17px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: url("/images/icons/caret-down.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px 17px; }

.phone-field-row .react-tel-input .flag-dropdown {
  font-size: 14px;
  width: calc(25% - 6px) !important; }
  .phone-field-row .react-tel-input .flag-dropdown.open {
    width: 100% !important; }
    .phone-field-row .react-tel-input .flag-dropdown.open .country-list {
      width: calc(100% + 2px); }

.phone-field-equal .react-tel-input .flag-dropdown {
  font-size: 14px;
  width: calc(50% - 6px) !important; }
  .phone-field-equal .react-tel-input .flag-dropdown.open {
    width: 100% !important; }
    .phone-field-equal .react-tel-input .flag-dropdown.open .country-list {
      width: 49%; }

.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  box-shadow: none;
  outline: none; }

.react-tel-input .flag-dropdown .selected-flag:hover,
.react-tel-input .flag-dropdown .selected-flag:focus {
  outline: none;
  border: 1px solid #0c0c0c;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb; }

.phone-field-row .change-request-section .react-tel-input .form-control {
  width: calc(50% - 4px) !important;
  margin-left: auto !important;
  padding-left: 16px !important;
  background: #ffffff !important; }

.phone-field-equal .change-request-section .react-tel-input .form-control {
  width: calc(50% - 4px) !important;
  margin-left: auto !important;
  padding-left: 16px !important;
  background: #ffffff !important; }

.change-request-section .react-tel-input .selected-flag {
  background-color: #fbfbfb !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100% !important; }
  .change-request-section .react-tel-input .selected-flag.open {
    width: calc(50% - 8px) !important; }
    .change-request-section .react-tel-input .selected-flag.open::after {
      top: 32% !important;
      transform: rotate(180deg) !important; }
  .change-request-section .react-tel-input .selected-flag.open:before {
    box-shadow: none; }
  .change-request-section .react-tel-input .selected-flag:focus:before, .change-request-section .react-tel-input .selected-flag.open:before {
    display: none; }
  .change-request-section .react-tel-input .selected-flag .arrow {
    display: none; }
  .change-request-section .react-tel-input .selected-flag::after {
    content: '';
    height: 17px;
    width: 17px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: url("/images/icons/caret-down.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px 17px; }

.change-request-section .react-tel-input .flag-dropdown {
  font-size: 14px;
  width: calc(50% - 8px) !important; }
  .change-request-section .react-tel-input .flag-dropdown.open {
    width: 100% !important; }
    .change-request-section .react-tel-input .flag-dropdown.open .country-list {
      width: calc(100% + 2px); }

.change-request-section .react-tel-input .flag-dropdown:hover,
.change-request-section .react-tel-input .flag-dropdown:focus {
  box-shadow: none;
  outline: none; }

.change-request-section .react-tel-input .flag-dropdown .selected-flag:hover,
.change-request-section .react-tel-input .flag-dropdown .selected-flag:focus {
  outline: none;
  border: 1px solid #0c0c0c;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb; }

.phone-field-row .partner-section .react-tel-input .form-control {
  width: calc(73% - 4px) !important;
  margin-left: auto !important;
  padding-left: 16px !important;
  background: #ffffff !important; }

.partner-section .react-tel-input .selected-flag {
  background-color: #fbfbfb !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100% !important; }
  .partner-section .react-tel-input .selected-flag.open {
    width: calc(25% - 6px) !important; }
    .partner-section .react-tel-input .selected-flag.open::after {
      top: 32% !important;
      transform: rotate(180deg) !important; }
  .partner-section .react-tel-input .selected-flag.open:before {
    box-shadow: none; }
  .partner-section .react-tel-input .selected-flag:focus:before, .partner-section .react-tel-input .selected-flag.open:before {
    display: none; }
  .partner-section .react-tel-input .selected-flag .arrow {
    display: none; }
  .partner-section .react-tel-input .selected-flag::after {
    content: '';
    height: 17px;
    width: 17px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: url("/images/icons/caret-down.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px 17px; }

.partner-section .react-tel-input .flag-dropdown {
  font-size: 14px;
  width: calc(28% - 8px) !important; }
  .partner-section .react-tel-input .flag-dropdown.open {
    width: 100% !important; }
    .partner-section .react-tel-input .flag-dropdown.open .country-list {
      width: calc(100% + 2px); }

.partner-section .react-tel-input .flag-dropdown:hover,
.partner-section .react-tel-input .flag-dropdown:focus {
  box-shadow: none;
  outline: none; }

.partner-section .react-tel-input .flag-dropdown .selected-flag:hover,
.partner-section .react-tel-input .flag-dropdown .selected-flag:focus {
  outline: none;
  border: 1px solid #0c0c0c;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb; }

.notes-expandable {
  display: block;
  min-height: 160px;
  border: 0.5px solid transparent;
  background-color: #fbfbfb;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400; }
  .notes-expandable:hover, .notes-expandable:focus {
    outline: none;
    border-color: #0c0c0c;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb; }
  .notes-expandable:focus {
    color: #495057 !important; }

.notes-expandable-dispatch {
  display: block;
  min-height: 100px;
  border: 0.5px solid transparent;
  background-color: #fbfbfb;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400; }
  .notes-expandable-dispatch:hover, .notes-expandable-dispatch:focus {
    outline: none;
    border-color: #0c0c0c;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb; }
  .notes-expandable-dispatch:focus {
    color: #495057 !important; }

.notes-expandable-dispatch-accepted {
  display: block;
  min-height: 100px;
  border: 0.5px solid transparent;
  background-color: rgba(139, 149, 158, 0.1);
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400; }

.notes-expandable-partner {
  display: block;
  min-height: 100px;
  box-shadow: none !important;
  border: 1px solid rgba(139, 149, 158, 0.2) !important;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 400; }
  .notes-expandable-partner:hover, .notes-expandable-partner:focus {
    outline: none;
    border: none; }
  .notes-expandable-partner:focus {
    color: #495057 !important; }

.notes-expandable-client {
  display: block;
  min-height: 100px;
  box-shadow: none !important;
  padding: 10px;
  background-color: #fbfbfb;
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid transparent;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }
  .notes-expandable-client:hover, .notes-expandable-client:focus, .notes-expandable-client:active {
    border-color: #0c0c0c;
    outline: none; }

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: rgba(12, 12, 12, 0.33); }

.notes-expandable-chauffeur {
  display: block;
  border: 0.5px solid transparent;
  background-color: #fbfbfb;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 97%; }
  .notes-expandable-chauffeur:hover, .notes-expandable-chauffeur:focus {
    outline: none;
    border-color: #0c0c0c;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb; }
  .notes-expandable-chauffeur:focus {
    color: #495057 !important; }

.payments-search-bar {
  margin-top: 0.5rem !important;
  margin-bottom: 2.5rem !important;
  padding-left: 0px !important; }

.input-group {
  background-color: #fbfbfb !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }
  .input-group .input-group-prepend {
    border-right: transparent !important; }
    .input-group .input-group-prepend .input-group-text {
      border: 0.5px solid transparent;
      background-color: #fbfbfb !important;
      border-radius: 2px;
      border-right: transparent !important; }
  .input-group input.form-control {
    box-shadow: none !important;
    border-left: transparent !important;
    padding-left: 0px !important;
    background-color: #fafafa !important; }
    .input-group input.form-control:focus, .input-group input.form-control:hover {
      border-radius: 0px !important;
      outline: none !important;
      border-color: transparent !important;
      border-left: transparent !important;
      background-color: #fbfbfb; }
  .input-group .form-control {
    border-left: transparent !important; }
    .input-group .form-control:focus, .input-group .form-control:hover {
      border-radius: 0px !important;
      outline: none !important;
      border-color: transparent !important;
      background-color: #fbfbfb; }
  .input-group:focus, .input-group:hover {
    border: 0.5px solid transparent;
    background-color: #fbfbfb !important;
    border-radius: 1px;
    border-color: #0c0c0c !important; }

.newPayments-UI {
  margin-bottom: 20px; }
  .newPayments-UI .custom-radio {
    padding-bottom: 20px !important;
    border-bottom: 1px solid rgba(139, 149, 158, 0.25); }
    .newPayments-UI .custom-radio:hover {
      border-bottom: 1px solid rgba(139, 149, 158, 0.25);
      box-shadow: none; }
    .newPayments-UI .custom-radio .custom-control-label {
      padding: 0;
      padding-left: 30px;
      border: none;
      background: transparent; }
      .newPayments-UI .custom-radio .custom-control-label:before, .newPayments-UI .custom-radio .custom-control-label:after {
        left: 0; }

.newPayments-UI-one-card {
  margin-top: 1.1rem;
  margin-bottom: 20px; }

.partner-search-bar {
  height: 50px !important; }

.round-input-group {
  box-shadow: none !important;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  border-radius: 20px !important; }
  .round-input-group:hover, .round-input-group:focus, .round-input-group:active, .round-input-group:focus-within {
    border-radius: 20px !important;
    border: 1px solid #0c0c0c;
    border-color: #0c0c0c !important; }
  .round-input-group input.form-control {
    height: 42px !important;
    border-radius: 20px !important; }
    .round-input-group input.form-control:hover, .round-input-group input.form-control:focus, .round-input-group input.form-control:active {
      border-radius: 20px !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important; }

.booking-icon-field:hover, .booking-icon-field:focus, .booking-icon-field:active, .booking-icon-field:focus-within {
  border: 1px solid rgba(139, 149, 158, 0.2); }

.extend-calender .react-datepicker-popper .custom-datepicker-calendar {
  width: 125% !important; }

.extend-calender .custom-datepicker-calendar .react-datepicker__navigation--previous {
  left: 30px;
  top: 23px; }

.extend-calender .custom-datepicker-calendar .react-datepicker__navigation--next {
  right: 30px;
  top: 23px; }

.extend-calender-payout .custom-datepicker-calendar .react-datepicker__navigation--previous {
  left: 30px;
  top: 23px; }

.extend-calender-payout .custom-datepicker-calendar .react-datepicker__navigation--next {
  right: 30px;
  top: 23px; }

.round-date-group {
  border-radius: 10px; }
  .round-date-group input.custom-datepicker-control {
    background-image: url("/images/icons/calendar.png");
    background-size: 17px 17px;
    background-position: center right 13px !important;
    background-repeat: no-repeat;
    padding-right: 30px;
    color: rgba(12, 12, 12, 0.3); }
    .round-date-group input.custom-datepicker-control.active {
      background-image: url("/images/icons/calendar_active.png");
      color: #0c0c0c; }
  .round-date-group input.form-control {
    box-shadow: none !important;
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    border-radius: 20px !important;
    height: 45px !important; }
    .round-date-group input.form-control:hover, .round-date-group input.form-control:focus, .round-date-group input.form-control:active {
      border-radius: 20px !important;
      border: 1px solid #0c0c0c;
      border-color: #0c0c0c !important; }
  .round-date-group .react-datepicker-popper .custom-datepicker-calendar .react-datepicker__navigation--previous {
    left: 35px; }
  .round-date-group .react-datepicker-popper .custom-datepicker-calendar .react-datepicker__navigation--next {
    right: 35px; }
  .round-date-group .react-datepicker-popper .custom-datepicker-calendar .react-datepicker__month-container {
    background-color: #ffffff; }
    .round-date-group .react-datepicker-popper .custom-datepicker-calendar .react-datepicker__month-container .react-datepicker__header {
      background-color: #ffffff;
      margin: 0 !important; }
    .round-date-group .react-datepicker-popper .custom-datepicker-calendar .react-datepicker__month-container .react-datepicker__month {
      margin: 0 !important;
      margin-bottom: 0.4rem !important; }

.time-zone-container .time-zone-select__control {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
  border: 0px solid transparent;
  border-radius: 2px !important;
  height: 50px !important;
  font-size: 14px;
  background-color: #fbfbfb; }
  .time-zone-container .time-zone-select__control:hover, .time-zone-container .time-zone-select__control:focus, .time-zone-container .time-zone-select__control:active, .time-zone-container .time-zone-select__control:focus-within {
    border-radius: 2px !important;
    border: 0.5px solid transparent;
    border-color: #0c0c0c !important; }

.time-zone-container .time-zone-select__indicator-separator {
  width: 0px !important;
  background-color: none; }

.time-zone-container .time-zone-select__dropdown-indicator {
  padding: 0px 5px !important; }

.time-zone-container .time-zone-select__indicators {
  width: 24px; }

.round-select-group .react-select-container .react-select__control {
  box-shadow: none !important;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  border-radius: 20px !important;
  height: 46px !important; }
  .round-select-group .react-select-container .react-select__control:hover, .round-select-group .react-select-container .react-select__control:focus, .round-select-group .react-select-container .react-select__control:active, .round-select-group .react-select-container .react-select__control:focus-within {
    border-radius: 20px !important;
    border: 1px solid #0c0c0c;
    border-color: #0c0c0c !important; }

.round-input-span {
  border-radius: 20px !important; }

.payout-radio .custom-control-label {
  flex-grow: 1;
  padding-top: 2px;
  cursor: pointer;
  text-transform: none;
  letter-spacing: initial; }
  .payout-radio .custom-control-label:before, .payout-radio .custom-control-label:after {
    top: 13px;
    width: 15px;
    height: 15px;
    cursor: pointer; }
  .payout-radio .custom-control-label:after {
    background-size: 15px 15px; }

.payout-radio-label {
  font-size: 14px;
  display: inline-table;
  line-height: 1.5; }

.payout-radio-info {
  font-size: 14px;
  display: inline-table;
  line-height: 1.5;
  color: #8b959e; }

.InovuaReactDataGrid__row:not(.InovuaReactDataGrid__row--virtualize-columns) {
  position: sticky !important; }

.height-add {
  height: 300px !important;
  min-height: 300px !important; }
  .height-add .InovuaReactDataGrid__cell.InovuaReactDataGrid__cell {
    height: 300px !important;
    min-height: 300px !important; }

.height-add-vip {
  height: 150px !important;
  min-height: 150px !important; }
  .height-add-vip .InovuaReactDataGrid__cell.InovuaReactDataGrid__cell {
    height: 150px !important;
    min-height: 150px !important; }

.payout-radio-label-partner {
  font-size: 15px;
  display: inline-table;
  line-height: 1.5;
  margin-left: 0.3rem;
  margin-top: 0.5rem; }

.bank-info-partner {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 0.3rem;
  padding: 1rem 7rem 1rem 1rem;
  background-color: rgba(139, 149, 158, 0.05);
  width: fit-content; }

.badge.badge-outlined.badge-paid-partner {
  font-size: 10px;
  border-color: #0c0c0c;
  color: #0c0c0c;
  padding: 6px 10px 6px 10px !important;
  background-color: rgba(12, 12, 12, 0.1); }

.badge.badge-outlined.badge-not_paid-partner {
  font-size: 10px;
  border-color: #f5a623;
  color: #f5a623;
  padding: 6px 10px 6px 10px !important;
  background-color: rgba(245, 166, 35, 0.1); }

.partner-payout-card {
  border-radius: 3px !important;
  border: 1px solid rgba(139, 149, 158, 0.2);
  background-color: #fbfbfb;
  font-size: 15px;
  margin-top: 1em;
  width: calc(100vw - 80%);
  margin-top: 0em !important; }

.partner-payout-details {
  display: block;
  padding: 0.8rem 2rem 0rem 2rem; }
  .partner-payout-details .expanded .caret-black {
    position: absolute;
    top: calc(50% - 13px);
    width: 49px;
    height: 20px; }

.bank-info-client {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1.5rem 7rem 1.5rem 1.5rem;
  background-color: rgba(139, 149, 158, 0.05);
  width: fit-content; }

.client-payout-card {
  border-radius: 3px !important;
  border: 1px solid rgba(139, 149, 158, 0.2);
  font-size: 15px;
  margin-top: 1em;
  width: calc(95vw - 80%);
  margin-top: 0em !important; }

.btn-help {
  font-size: 15px !important;
  color: #a79779;
  cursor: pointer; }
  .btn-help:hover {
    color: #96866a !important; }

.payout-text-black {
  color: #0c0c0c;
  font-size: 14px; }

.payout-text-gold {
  color: #a79779;
  font-size: 14px; }

.payout-border-bottom {
  border-bottom: 1px solid rgba(139, 149, 158, 0.2); }

.newPayments-label {
  margin-left: 0.9rem;
  margin-bottom: 2rem;
  margin-top: 0.3rem; }

.newPayments-label-one-card {
  margin-left: 0.9rem;
  margin-top: 0.3rem;
  padding-bottom: 20px !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25);
  margin-bottom: 18px; }

.newPayments-label-monthly {
  margin-left: 0.9rem;
  margin-top: 0.3rem;
  padding-bottom: 20px !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25);
  margin-bottom: 18px; }

.grey-field-input input,
.grey-field-input .react-select__control,
.grey-field-input .react-tel-input.form-control {
  background-color: #fafafa !important; }

.grey-field-input-l3 input,
.grey-field-input-l3 label,
.grey-field-input-l3 .react-select__control,
.grey-field-input-l3 .react-tel-input.form-control {
  background-color: #fafafa !important; }

.input.form-control.grey-field-input {
  background-color: #fafafa !important; }

.input.form-control.grey-field-input-l3 {
  background-color: #fafafa !important; }

.react-currencySelect-container.grey-field-input {
  background-color: #fafafa !important; }

.react-currencySelect-container.grey-field-input-l3 {
  background-color: #fafafa !important; }

.custom-white-background input,
.custom-white-background .react-select__control,
.custom-white-background .custom-file-label,
.custom-white-background .react-tel-input .form-control {
  background-color: #ffffff !important; }

.custom-white-background .custom-file-label:hover {
  background-color: #ffffff !important; }

.react-currencySelect-container.custom-white-background {
  background-color: #ffffff !important; }

.partner-grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px; }

.form-select-icon {
  border-radius: 2px;
  border-color: #0c0c0c !important;
  border: 0.5px solid transparent; }
  .form-select-icon .input-group {
    flex-wrap: nowrap;
    box-shadow: none !important;
    border-radius: 2px;
    border-color: #0c0c0c !important;
    border: 0.5px solid transparent; }
    .form-select-icon .input-group .input-group-text {
      color: rgba(12, 12, 12, 0.3); }
    .form-select-icon .input-group .black-text {
      color: #0c0c0c; }
  .form-select-icon .react-select__control {
    border-radius: 0px !important;
    outline: none !important;
    border-color: transparent !important;
    border-left: transparent !important;
    box-shadow: none; }
    .form-select-icon .react-select__control:hover {
      cursor: pointer; }
  .form-select-icon .react-select__placeholder {
    right: 0;
    padding-right: 0.5em; }
  .form-select-icon .react-select__single-value {
    text-align: right;
    padding-right: 0.5em; }
  .form-select-icon .react-select__option {
    font-size: 14px !important; }

.form-label-div {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .form-label-div .form-label-icon {
    margin-right: 5px;
    width: 20px;
    height: 20px; }
  .form-label-div .form-label-counter {
    color: black;
    text-transform: none;
    font-size: 15px !important;
    margin-bottom: 0; }
  .form-label-div .form-counter-tooltip {
    width: 14px;
    margin-left: 6px;
    padding-bottom: 3px;
    cursor: pointer; }

.form-field-counter {
  position: relative;
  display: inline-block; }
  .form-field-counter .form-field-minus {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
    .form-field-counter .form-field-minus img {
      width: 13px;
      padding-bottom: 2px; }
  .form-field-counter .form-field {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px; }
  .form-field-counter .form-field-plus {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer; }
    .form-field-counter .form-field-plus img {
      width: 13px;
      padding-bottom: 2px; }

.company-logo-white {
  background-color: transparent; }

.automation-time-select .react-select__control {
  box-shadow: none;
  height: auto; }

.data-table-wrap {
  margin-bottom: 30px; }

.data-table-admin td,
.data-table-admin th {
  padding: 5px !important; }

.quotes-list .data-table {
  max-width: none; }

.data-table {
  border-spacing: 0 8px;
  border-collapse: separate;
  line-height: 20px;
  margin-bottom: 0; }
  .data-table.wide {
    max-width: 970px; }
  .data-table .border-label {
    border: 1px solid rgba(12, 12, 12, 0.6);
    padding: 5px 12px;
    border-radius: 2px;
    font-size: 13px; }
  .data-table th,
  .data-table td {
    padding: 16px 20px 20px;
    font-size: 14px;
    vertical-align: middle; }
  .data-table thead th {
    font-weight: 500;
    color: #0c0c0c;
    border-bottom: 1px solid #8b959e;
    border-top: 0;
    white-space: nowrap;
    font-size: 15px; }
  .data-table td {
    font-weight: 400;
    border-top: 0;
    background-color: #fafafa;
    border-top: 0.5px solid transparent;
    border-bottom: 0.5px solid transparent;
    white-space: nowrap; }
    .data-table td:first-child {
      border-left: 0.5px solid rgba(139, 149, 158, 0.5); }
    .data-table td:last-child {
      border-right: 0.5px solid rgba(139, 149, 158, 0.5); }
  .data-table tbody:before {
    content: '';
    display: block;
    height: 5px; }
  .data-table tbody tr td {
    border-color: rgba(139, 149, 158, 0.5); }

.light-theme-table .data-table tr {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .light-theme-table .data-table tr td {
    background-color: #fbfbfb;
    border-color: transparent;
    white-space: nowrap; }
    .light-theme-table .data-table tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .light-theme-table .data-table tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
  .light-theme-table .data-table tr:hover td {
    border-color: transparent; }

.light-theme-table .data-table thead tr {
  box-shadow: none; }

.light-theme-table .data-table thead th {
  background: transparent;
  font-weight: 500;
  color: #0c0c0c;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  border-top: 0;
  font-size: 14px; }

.light-theme-table .data-table tbody tr:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1); }

.light-theme-table.no-action tbody tr:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.light-theme-table .minWidth8rem {
  min-width: 8rem; }

.light-theme-table .minWith9rem {
  min-width: 9rem; }

.light-theme-table .minWith12rem {
  min-width: 12rem; }

.light-theme-table .maxWidth10rem {
  max-width: 10rem;
  white-space: pre; }

.light-theme-table .maxWidth9rem {
  max-width: 9rem; }

.light-theme-table .maxWidth8rem {
  max-width: 8rem; }

.light-theme-table-admin-bookings .data-table tr {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .light-theme-table-admin-bookings .data-table tr td {
    background-color: #fafafa;
    border-color: transparent;
    padding: 5px 5px 5px;
    font-size: 14px;
    vertical-align: middle;
    max-width: 10.5rem;
    overflow: hidden;
    text-overflow: ellipsis; }
    .light-theme-table-admin-bookings .data-table tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .light-theme-table-admin-bookings .data-table tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
  .light-theme-table-admin-bookings .data-table tr td.status {
    max-width: 12rem; }
  .light-theme-table-admin-bookings .data-table tr:hover td {
    border-color: transparent; }

.light-theme-table-admin-bookings .data-table thead tr {
  box-shadow: none; }

.light-theme-table-admin-bookings .data-table thead th {
  background: transparent;
  font-weight: 900;
  color: #0c0c0c;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  border-top: 0;
  font-size: 12px;
  padding: 5px 5px 5px; }

.light-theme-table-admin-bookings .data-table tbody tr:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1); }

.light-theme-table-admin-bookings.no-action tbody tr:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.light-theme-table-admin-bookings .minWidth8rem {
  min-width: 8rem; }

.light-theme-table-admin-bookings .minWith9rem {
  min-width: 9rem; }

.light-theme-table-admin-bookings .minWith12rem {
  min-width: 12rem; }

.light-theme-table-admin-bookings .maxWidth10rem {
  max-width: 10rem;
  white-space: pre; }

.light-theme-table-admin-bookings .maxWidth9rem {
  max-width: 9rem; }

.light-theme-table-admin-bookings .maxWidth8rem {
  max-width: 8rem; }

.light-theme-table-partner {
  min-height: 400px; }
  .light-theme-table-partner .data-table {
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background: #fafafa;
    padding: 0rem 1.5rem 1rem 1.5rem;
    max-width: unset;
    border-radius: 10px; }
    .light-theme-table-partner .data-table tr td {
      background-color: rgba(139, 149, 158, 0.05);
      border-color: transparent;
      padding: 16px 12px 16px; }
      .light-theme-table-partner .data-table tr td:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px; }
      .light-theme-table-partner .data-table tr td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px; }
    .light-theme-table-partner .data-table thead tr {
      box-shadow: none; }
    .light-theme-table-partner .data-table thead th {
      background: transparent;
      font-weight: 600;
      color: #000000;
      border-bottom: 0;
      border-top: 0;
      font-size: 13px;
      padding: 16px 20px 0px; }
    .light-theme-table-partner .data-table tbody tr:hover {
      background-color: rgba(139, 149, 158, 0.2);
      cursor: pointer; }
      .light-theme-table-partner .data-table tbody tr:hover td {
        border-color: transparent;
        box-shadow: none; }
  .light-theme-table-partner .minWidth8rem {
    min-width: 8rem; }
  .light-theme-table-partner .minWith12rem {
    min-width: 12rem; }

@media (max-width: 992px) {
  .light-theme-table-partner {
    min-height: 400px; }
    .light-theme-table-partner .data-table {
      border: 1.5px solid rgba(139, 149, 158, 0.2);
      background: #fafafa;
      padding: 0rem 1.5rem 1rem 1.5rem;
      max-width: unset;
      border-radius: 10px; }
      .light-theme-table-partner .data-table tr td {
        background-color: rgba(139, 149, 158, 0.1);
        border-color: transparent;
        padding: 16px 12px 16px; }
        .light-theme-table-partner .data-table tr td:last-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
        .light-theme-table-partner .data-table tr td:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px; }
      .light-theme-table-partner .data-table tr:hover td {
        border-color: transparent;
        box-shadow: none; }
      .light-theme-table-partner .data-table thead tr {
        box-shadow: none; }
      .light-theme-table-partner .data-table thead th {
        background: transparent;
        font-weight: 600;
        color: #000000;
        border-bottom: 0;
        border-top: 0;
        font-size: 13px;
        padding: 16px 20px 0px; }
    .light-theme-table-partner .minWidth8rem {
      min-width: 8rem; }
    .light-theme-table-partner .minWith12rem {
      min-width: 12rem; } }

.light-theme-table-dispatch .data-table tr {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .light-theme-table-dispatch .data-table tr td {
    background-color: #fbfbfb;
    border-color: transparent; }
    .light-theme-table-dispatch .data-table tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .light-theme-table-dispatch .data-table tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
  .light-theme-table-dispatch .data-table tr:hover td {
    border-color: transparent; }

.light-theme-table-dispatch .data-table thead tr {
  box-shadow: none; }

.light-theme-table-dispatch .data-table thead th {
  background: transparent;
  font-weight: 500;
  color: #0c0c0c;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  border-top: 0;
  font-size: 14px; }

.light-theme-table-dispatch .data-table tbody:before {
  display: none; }

.light-theme-table-dispatch .data-table tbody tr:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1); }

.light-theme-table-dispatch.no-action tbody tr:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.invoices-table .data-table th,
.invoices-table .data-table td {
  padding-inline: 20px !important; }

.service-label {
  border-radius: 2px;
  color: #0c0c0c;
  padding: 2px 4px;
  width: fit-content;
  display: block;
  font-size: 14px;
  text-transform: uppercase; }
  .service-label.label-black {
    color: #8b959e;
    border-color: #8b959e; }

.monitoring-Table-td {
  padding: 5px 5px 5px !important;
  font-size: 12px !important;
  vertical-align: middle !important; }

.tests-table-td {
  font-size: 14px !important;
  vertical-align: middle !important; }

.td-width {
  width: 20% !important; }

.light-theme-table-admin-payout .data-table tr {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .light-theme-table-admin-payout .data-table tr td {
    background-color: #fbfbfb;
    border-color: transparent;
    padding: 5px 5px 5px;
    font-size: 14px;
    vertical-align: middle;
    max-width: 10.5rem;
    overflow: hidden;
    text-overflow: ellipsis; }
    .light-theme-table-admin-payout .data-table tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .light-theme-table-admin-payout .data-table tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
  .light-theme-table-admin-payout .data-table tr.expanded {
    box-shadow: none !important; }
    .light-theme-table-admin-payout .data-table tr.expanded tbody tr:hover {
      box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1); }
  .light-theme-table-admin-payout .data-table tr td.status {
    max-width: 12rem; }
  .light-theme-table-admin-payout .data-table tr:hover td {
    border-color: transparent; }

.light-theme-table-admin-payout .data-table thead tr {
  box-shadow: none; }

.light-theme-table-admin-payout .data-table thead th {
  background: transparent;
  font-weight: 900;
  color: #0c0c0c;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  border-top: 0;
  font-size: 12px;
  padding: 5px 5px 5px; }

.light-theme-table-admin-payout .data-table tbody tr:hover {
  box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.1); }

.light-theme-table-admin-payout.no-action tbody tr:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.payout-dropDown {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 5px 5px;
  margin-right: 5px;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  border-spacing: 5px !important; }
  .payout-dropDown span {
    font-size: 14px !important;
    font-weight: 400;
    color: #0c0c0c;
    padding-top: 5px;
    padding-bottom: 5px; }
  .payout-dropDown:first-child {
    border-top: 1px solid rgba(139, 149, 158, 0.3); }
  .payout-dropDown:last-child {
    margin-bottom: 1rem;
    border-bottom: none; }
  .payout-dropDown .status-paid {
    margin-left: 0.3rem;
    font-size: 12px !important;
    color: #417505;
    padding: 0px; }
  .payout-dropDown .status-unpaid {
    font-size: 13px;
    color: #8b959e; }

.payout-details {
  display: flex;
  justify-content: space-between;
  padding: 1em 5px 1em 0px;
  margin-right: 5px;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  border-spacing: 5px !important; }
  .payout-details span {
    font-size: 14px !important;
    font-weight: 400;
    color: #0c0c0c;
    vertical-align: middle;
    padding-top: 5px !important;
    padding-bottom: 5px !important; }

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 2; }

.fixed-column-bg-white {
  position: sticky;
  background-color: white !important;
  left: 0;
  z-index: 2; }

.analyticsSalesTable {
  border-spacing: 0 1px; }
  .analyticsSalesTable .analyticsBorderRight {
    border-right: 1px solid rgba(139, 149, 158, 0.3) !important; }
  .analyticsSalesTable th {
    border-top: 0;
    min-width: 60px; }
  .analyticsSalesTable tbody:before {
    display: none; }

.pagination-parent {
  margin-top: 40px;
  display: flex;
  align-items: flex-end;
  flex-direction: column; }

.pagination-info-container {
  font-size: 12px;
  padding-inline: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  position: relative; }
  .pagination-info-container .pagination-options-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 30px;
    border: 1px solid rgba(139, 149, 158, 0.3);
    width: 105px;
    height: fit-content;
    border-radius: 0 0 2px 2px; }
    .pagination-info-container .pagination-options-container .pagination-option {
      width: 100%;
      font-size: 13px;
      padding: 10px; }
      .pagination-info-container .pagination-options-container .pagination-option.selected, .pagination-info-container .pagination-options-container .pagination-option:hover {
        background-color: #e1e4e5; }

.pagination-container {
  display: flex;
  list-style-type: none; }
  .pagination-container .pagination-item {
    height: 24px;
    width: 24px;
    text-align: center;
    color: #0c0c0c;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    line-height: 1.43;
    font-size: 12px;
    justify-content: center; }
    .pagination-container .pagination-item.width-30px {
      width: 30px; }
    .pagination-container .pagination-item.dots:hover {
      background-color: transparent;
      cursor: default;
      color: black; }
    .pagination-container .pagination-item.arrow:hover {
      background-color: transparent;
      cursor: pointer; }
    .pagination-container .pagination-item:hover {
      background-color: #0c0c0c;
      color: #ffffff;
      cursor: pointer; }
    .pagination-container .pagination-item.selected {
      background-color: #0c0c0c;
      color: #ffffff; }
    .pagination-container .pagination-item.arrow-right {
      margin-left: 12px; }
    .pagination-container .pagination-item.arrow-left {
      margin-right: 12px; }
    .pagination-container .pagination-item.disabled {
      pointer-events: none; }
      .pagination-container .pagination-item.disabled .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43); }
      .pagination-container .pagination-item.disabled:hover {
        background-color: transparent;
        cursor: default; }

.consortiums-input,
.host-agencies-input {
  width: 370px; }
  @media (max-width: 767.98px) {
    .consortiums-input,
    .host-agencies-input {
      width: 270px; } }

.max-width333px {
  max-width: 333px; }

.max-width260px {
  max-width: 260px; }

.width190px {
  width: 190px;
  min-width: 190px; }

.width140px {
  width: 140px;
  min-width: 140px; }

.width160px {
  width: 160px;
  min-width: 160px; }

.width122px {
  width: 122px;
  min-width: 122px; }

.mw-270px {
  max-width: 270px;
  width: 270px; }

.max-w-100px {
  max-width: 100px; }

.max-width-120px {
  max-width: 120px; }

.max-w-158px {
  max-width: 158px; }

.white-space-break {
  white-space: break-spaces !important; }

.automation-table td {
  padding: 0.1rem;
  border: 1px solid rgba(139, 149, 158, 0.5); }

.automation-table th {
  padding-bottom: 0.25rem; }

.dropdown .dropdown-menu.action-dropdown-menu {
  border-radius: 2px;
  border: 0.2px solid rgba(139, 149, 158, 0.25);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0; }
  .dropdown .dropdown-menu.action-dropdown-menu .dropdown-item {
    padding: 10px 10px 6px;
    font-size: 12px;
    font-weight: 400;
    color: #0c0c0c; }
    .dropdown .dropdown-menu.action-dropdown-menu .dropdown-item:not(:last-child) {
      border-bottom: 1px solid #e6e6e6; }
    .dropdown .dropdown-menu.action-dropdown-menu .dropdown-item:active {
      background-color: #ffffff; }

.dropdown .dropdown-menu.action-dropdown-menu-2 {
  border-radius: 2px;
  border: 0.2px solid rgba(139, 149, 158, 0.25);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-left: 100%; }
  .dropdown .dropdown-menu.action-dropdown-menu-2 .dropdown-item {
    padding: 10px 10px 6px;
    font-size: 12px;
    font-weight: 400;
    color: #0c0c0c; }
    .dropdown .dropdown-menu.action-dropdown-menu-2 .dropdown-item:not(:last-child) {
      border-bottom: 1px solid #e6e6e6; }
    .dropdown .dropdown-menu.action-dropdown-menu-2 .dropdown-item:active {
      background-color: #ffffff; }

.dropdown .dots-toggle {
  padding: 0 3px;
  border: 1px solid transparent;
  border-radius: 2px;
  display: inline-block; }
  .dropdown .dots-toggle img {
    width: 17px;
    height: auto; }
  .dropdown .dots-toggle.vertical {
    padding: 5px 8px; }
    .dropdown .dots-toggle.vertical img {
      width: 4px;
      height: auto; }

.dropdown.show .dots-toggle {
  border-color: rgba(139, 149, 158, 0.25);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.titleSelect {
  z-index: unset; }

.bookingStatusDD {
  display: inline-block;
  float: right;
  padding-left: 10px; }
  .bookingStatusDD .dropdown {
    line-height: 20px; }
    .bookingStatusDD .dropdown .dropdown-menu.action-dropdown-menu {
      padding: 6px 10px !important;
      min-width: 0rem !important; }
      .bookingStatusDD .dropdown .dropdown-menu.action-dropdown-menu .dropdown-item {
        font-size: 13px;
        font-weight: 400; }

.cancelDropdown {
  padding: 0px !important; }

@media (max-width: 768px) {
  .bookingStatusDD .dropdown .dropdown-menu.action-dropdown-menu {
    padding: 6px 10px !important;
    min-width: 0rem !important; }
    .bookingStatusDD .dropdown .dropdown-menu.action-dropdown-menu .dropdown-item {
      font-size: 16px;
      font-weight: 400; } }

.dropDownMargin {
  margin-right: 2.188em; }

.grid-dropdown {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer; }

.removable-image {
  display: block; }
  .removable-image .btn.remove-image-btn {
    display: block;
    font-size: 10px;
    line-height: 14px; }
    .removable-image .btn.remove-image-btn:hover {
      text-decoration: none; }
  .removable-image img {
    max-width: 100%;
    height: auto; }

.image-list h5.image-list-header {
  line-height: 12px;
  font-size: 10px;
  font-weight: 500;
  color: #303030;
  text-transform: uppercase;
  margin-bottom: 30px; }

.image-list .image-list-row {
  margin-bottom: 90px; }

.image-list .col.image-list-col {
  max-width: 200px; }
  .image-list .col.image-list-col img:not(:first-child) {
    margin: 0 15px; }
  .image-list .col.image-list-col img:first-child {
    margin-right: 15px; }

.btn.remove-image-btn {
  display: block;
  font-size: 10px;
  line-height: 14px; }
  .btn.remove-image-btn:hover {
    text-decoration: none; }

.btn {
  height: 43px;
  font-size: 12px;
  border-radius: 2px; }
  .btn-brown:not(:disabled) {
    font-size: 14px;
    color: #ffffff; }
    .btn-brown:not(:disabled):hover {
      background-color: #96866a; }
  .btn-brown:disabled {
    font-size: 14px;
    color: #ffffff; }
  .btn-white {
    color: #96866a !important;
    border: 1px solid #96866a !important;
    background-color: #fafafa !important;
    font-weight: 500 !important; }
  .btn-link {
    padding: 0;
    height: auto; }
  .btn.benefits-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-weight: 500;
    padding: 0 2.5rem;
    white-space: nowrap;
    height: 40px; }
    .btn.benefits-btn:disabled, .btn.benefits-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.benefits-btn.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.benefits-btn, .btn.submit-btn, .btn.back-btn-alert-action, .btn.back-btn, .btn.cancel-btn, .btn.book-btn {
    margin: 10px 0;
    font-weight: 500; }
    .btn.benefits-btn.h-40, .btn.submit-btn.h-40, .btn.back-btn-alert-action.h-40, .btn.back-btn.h-40, .btn.cancel-btn.h-40, .btn.book-btn.h-40 {
      height: 40px; }
    .btn.benefits-btn:hover, .btn.submit-btn:hover, .btn.back-btn-alert-action:hover, .btn.back-btn:hover, .btn.cancel-btn:hover, .btn.book-btn:hover {
      text-decoration: none; }
  .btn.back-btn-alert-action {
    margin: 0;
    /* Exclude margin for this specific class */ }
  .btn.submit-btn {
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 0 3rem;
    white-space: nowrap;
    height: 40px; }
    .btn.submit-btn:disabled, .btn.submit-btn.disabled {
      opacity: 1;
      background-color: #e6e0d4;
      pointer-events: none; }
    .btn.submit-btn.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.book-btn {
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 0 3rem;
    white-space: nowrap;
    height: 40px; }
    .btn.book-btn:disabled, .btn.book-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.book-btn.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.cancel-dispatch-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    white-space: nowrap;
    color: #a79779;
    border: 1px solid #a79779;
    background: transparent;
    height: 35px; }
    .btn.cancel-dispatch-btn:disabled, .btn.cancel-dispatch-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.cancel-dispatch-btn:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: rgba(165, 151, 124, 0.15) !important;
      border: rgba(165, 151, 124, 0.15) !important;
      color: #a79779 !important; }
  .btn.cancel-booking-modal-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
    padding: 0 10px;
    min-width: 175px;
    white-space: nowrap;
    color: #a79779;
    border: 1px solid #a79779;
    background: transparent;
    height: 40px; }
    .btn.cancel-booking-modal-btn:disabled, .btn.cancel-booking-modal-btn.disabled {
      opacity: 1;
      cursor: none; }
  .btn.submit-btn-additional-charge {
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 0 2rem !important;
    white-space: nowrap;
    height: 40px; }
    .btn.submit-btn-additional-charge:disabled, .btn.submit-btn-additional-charge.disabled {
      opacity: 1;
      cursor: none; }
    .btn.submit-btn-additional-charge.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.submit-btn-change-form {
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 0 2rem !important;
    white-space: nowrap;
    height: 40px; }
    .btn.submit-btn-change-form:disabled, .btn.submit-btn-change-form.disabled {
      opacity: 1;
      cursor: none; }
    .btn.submit-btn-change-form.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.cancel-payment-btn {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 0 2.5rem;
    white-space: nowrap;
    color: #a79779;
    border: 1px solid #a79779;
    background: transparent;
    height: 40px;
    margin-left: 0.4rem; }
    .btn.cancel-payment-btn:disabled, .btn.cancel-payment-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.cancel-payment-btn:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: rgba(165, 151, 124, 0.15) !important;
      border: rgba(165, 151, 124, 0.15) !important;
      color: #a79779 !important; }
  .btn.sharedBooking-and-Refund-modal-btn {
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    margin-left: 1.5rem;
    white-space: nowrap;
    height: 40px; }
    .btn.sharedBooking-and-Refund-modal-btn:disabled, .btn.sharedBooking-and-Refund-modal-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.sharedBooking-and-Refund-modal-btn.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.refund-modal-cancel-btn {
    border: 1px solid rgba(139, 149, 158, 0.25);
    cursor: pointer;
    color: #8b959e;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    margin-left: 1.5rem;
    white-space: nowrap;
    height: 40px; }
    .btn.refund-modal-cancel-btn:disabled, .btn.refund-modal-cancel-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.refund-modal-cancel-btn.outlined-sbmt-btn {
      color: #8b959e;
      border: 1px solid #8b959e;
      background: transparent; }
    .btn.refund-modal-cancel-btn:hover {
      border: 1px solid #8b959e; }
  .btn.rates-comparison {
    cursor: pointer;
    color: #8b959e;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 30px; }
    .btn.rates-comparison:disabled, .btn.rates-comparison.disabled {
      opacity: 1;
      cursor: none; }
    .btn.rates-comparison.outlined-sbmt-btn {
      color: #8b959e;
      border: 1px solid #8b959e;
      background: transparent; }
    .btn.rates-comparison:hover {
      border: 1px solid #8b959e; }
  .btn.rates-event-card {
    border: 1px solid #8b959e;
    cursor: pointer;
    color: #8b959e;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 30px; }
    .btn.rates-event-card:disabled, .btn.rates-event-card.disabled {
      opacity: 1;
      cursor: none; }
    .btn.rates-event-card.outlined-sbmt-btn {
      color: #8b959e;
      border: 1px solid #8b959e;
      background: transparent; }
  .btn.rates-comparison-back {
    cursor: pointer;
    color: #8b959e;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 30px; }
    .btn.rates-comparison-back:disabled, .btn.rates-comparison-back.disabled {
      opacity: 1;
      cursor: none; }
    .btn.rates-comparison-back.outlined-sbmt-btn {
      color: #8b959e;
      border: 1px solid #8b959e;
      background: transparent; }
  .btn.dispatch-modal-btn {
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    border: 2px solid rgba(167, 151, 121, 0.5);
    border-radius: 9px;
    cursor: pointer;
    color: #8b959e;
    padding: 0.5rem 3rem 0 3rem !important;
    margin-left: 1.5rem;
    white-space: nowrap;
    height: 60px; }
    .btn.dispatch-modal-btn:disabled, .btn.dispatch-modal-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.dispatch-modal-btn.outlined-sbmt-btn {
      color: #8b959e;
      border: 1px solid #8b959e;
      background: transparent; }
  .btn.bookingChangesAdmin-modal-btn {
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 0.2rem 1.5rem 0 1.5rem !important;
    white-space: nowrap;
    height: 40px; }
    .btn.bookingChangesAdmin-modal-btn:disabled, .btn.bookingChangesAdmin-modal-btn.disabled {
      opacity: 1;
      cursor: none; }
    .btn.bookingChangesAdmin-modal-btn.outlined-sbmt-btn {
      color: #a79779;
      border: 1px solid #a79779;
      background: transparent; }
  .btn.select-btn {
    height: 50px;
    min-width: 140px;
    border: 1px solid #a79779;
    background-color: #a79779;
    cursor: pointer;
    color: #fafafa;
    font-weight: 500; }
    .btn.select-btn:disabled, .btn.select-btn.disabled {
      cursor: none; }
    .btn.select-btn:hover, .btn.select-btn:active {
      color: #ffffff !important; }
  .btn.toggle-btn {
    height: auto !important;
    font-size: 12px;
    border-radius: 2px; }
    .btn.toggle-btn input {
      display: none; }
  .btn.back-btn, .btn.cancel-btn {
    display: block;
    line-height: 20px;
    color: #8b959e !important; }
  .btn.back-btn-brown {
    display: block;
    color: #a79779 !important;
    border: 1px solid #a79779;
    width: 130px;
    height: 40px !important; }
  .btn.back-btn {
    width: 169px;
    border: 1px solid #8b959e; }
  .btn.back-btn-alert-action {
    width: 145px;
    border: 1px solid #8b959e; }
  .btn.alerts-delete-btn {
    font-size: 11px;
    width: 70px;
    height: 26px !important;
    border: 1px solid #8b959e;
    float: right; }
  .btn.cancel-btn {
    font-size: 16px; }

.remove-btn {
  display: inline-block;
  width: 27px;
  height: 27px; }
  .remove-btn:hover {
    opacity: 0.8; }
  .remove-btn.disabled {
    opacity: 0.6;
    pointer-events: none; }
  .remove-btn img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.remove-col {
  vertical-align: top !important;
  padding-right: 5px !important;
  padding-top: 5px !important; }

.remove-btn-img {
  width: 10px !important;
  height: 10px !important; }

.btn-search {
  font-size: 15px !important; }

.btn-geo {
  font-size: 14px !important;
  color: #a79779 !important;
  text-decoration: none;
  border-bottom: 0.1em solid; }
  .btn-geo:hover {
    text-decoration: none; }

.btn-anchor {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;
  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: #a79779;
  border-color: #a7977980 !important; }
  .btn-anchor:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: #a79779 !important;
    color: #a79779 !important;
    transition: all ease 0.5s; }
  .btn-anchor:hover {
    color: #a79779 !important; }
  .btn-anchor:hover:after {
    width: 100%; }
  .btn-anchor:focus {
    box-shadow: none;
    text-decoration: none; }

.payouts .btn-anchor {
  margin-top: 4px;
  font-size: 14px !important;
  font-weight: 600; }

.btn-anchor-sharedBooking {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 15px;
  height: 21px;
  margin-top: 3px;
  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: #a79779;
  border-color: #a7977980 !important; }
  .btn-anchor-sharedBooking:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: #a79779 !important;
    color: #a79779 !important;
    transition: all ease 0.5s; }
  .btn-anchor-sharedBooking:hover {
    color: #a79779 !important; }
  .btn-anchor-sharedBooking:hover:after {
    width: 100%; }
  .btn-anchor-sharedBooking:focus {
    box-shadow: none;
    text-decoration: none; }

.btn-anchor-black {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;
  border-radius: 0;
  color: #0c0c0c;
  cursor: default !important; }

.btn-anchor-danger {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;
  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: #d0021b;
  border-color: #d0021b !important; }
  .btn-anchor-danger:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: #d0021b !important;
    color: #d0021b !important;
    transition: all ease 0.5s; }
  .btn-anchor-danger:hover {
    color: #d0021b !important; }
  .btn-anchor-danger:hover:after {
    width: 100%; }
  .btn-anchor-danger:focus {
    box-shadow: none;
    text-decoration: none; }

.btn-anchor-success {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;
  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: #417505;
  border-color: #417505 !important; }
  .btn-anchor-success:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: #417505 !important;
    color: #417505 !important;
    transition: all ease 0.5s; }
  .btn-anchor-success:hover {
    color: #417505 !important; }
  .btn-anchor-success:hover:after {
    width: 100%; }
  .btn-anchor-success:focus {
    box-shadow: none;
    text-decoration: none; }

.btn-anchor-dark {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 14px;
  height: 21px;
  margin-top: 3px;
  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: #0c0c0c;
  border-color: #0c0c0c !important; }
  .btn-anchor-dark:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: #0c0c0c !important;
    color: #0c0c0c !important;
    transition: all ease 0.5s; }
  .btn-anchor-dark:hover {
    color: #0c0c0c !important; }
  .btn-anchor-dark:hover:after {
    width: 100%; }
  .btn-anchor-dark:focus {
    box-shadow: none;
    text-decoration: none; }

.btn-anchor-empty-search {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  min-width: auto;
  font-size: 15px;
  height: 23px;
  margin-top: 0;
  border-bottom: 0.1em solid;
  border-radius: 0;
  cursor: pointer;
  color: #a79779;
  border-color: #a7977980 !important; }
  .btn-anchor-empty-search:after {
    content: '';
    display: block;
    width: 0;
    height: 0.1em;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    border-radius: 50px;
    background-color: #a79779 !important;
    color: #a79779 !important;
    transition: all ease 0.5s; }
  .btn-anchor-empty-search:hover {
    color: #a79779 !important; }
  .btn-anchor-empty-search:hover:after {
    width: 100%; }
  .btn-anchor-empty-search:focus {
    box-shadow: none;
    text-decoration: none; }

.text-decoration-none {
  text-decoration: none; }

a.btn-anchor {
  margin-top: 0px; }
  a.btn-anchor:hover {
    color: #a79779 !important; }
  a.btn-anchor:focus {
    box-shadow: none;
    text-decoration: none; }

.fixed-btn-contain {
  position: fixed;
  bottom: 0px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 15px;
  background: #f9f9f9;
  width: 100%; }

.refresh-matrix {
  font-size: 14px;
  color: #a5977c; }
  .refresh-matrix:hover {
    color: #96866a !important; }

.promoCode-applied .btn-promo {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: 20px;
  border-radius: 0.3rem !important;
  line-height: 1.5; }

.promoCode-applied .black-Button {
  border-radius: 2px;
  background-color: white !important;
  color: #0c0c0c; }

.promoCode-applied .promoCode-applied-span {
  vertical-align: middle !important; }
  .promoCode-applied .promoCode-applied-span img {
    width: 14px;
    vertical-align: initial !important; }
  .promoCode-applied .promoCode-applied-span span {
    font-size: 15px;
    font-weight: 400; }

.benefits-div {
  max-width: 5px;
  padding-top: 2.5%; }

.phone-number-div {
  max-width: 495px; }

.passenger-name-div {
  max-width: 187.5px; }

.capacity-div {
  max-width: 250px; }

.select-agent-btn {
  height: 30px;
  min-width: 60px;
  border: 1px solid #a79779;
  background-color: #a79779;
  cursor: pointer;
  color: #fafafa;
  font-weight: 500; }
  .select-agent-btn:disabled, .select-agent-btn.disabled {
    cursor: none; }
  .select-agent-btn:hover, .select-agent-btn:active {
    color: #a79779 !important; }

.run-btn {
  min-width: 130px;
  border: 1px solid #0c0c0c;
  cursor: pointer;
  font-weight: bolder;
  font-size: 14px;
  border-radius: 2px;
  height: 35px;
  background-color: #ffffff;
  color: #0c0c0c; }
  .run-btn:hover, .run-btn:active {
    color: #ffffff;
    background-color: #a79779;
    border: 1px solid #a79779; }

.chatScrollDown {
  font-size: 13px;
  font-weight: 700;
  margin-left: 40%;
  margin-bottom: 5px;
  height: 30px !important;
  border: 1px solid #d0021b;
  color: #d0021b;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: #ffffff; }
  .chatScrollDown:hover {
    color: #d0021b !important; }

.chatScrollDownFiles {
  font-size: 13px;
  font-weight: 700;
  margin-left: 40%;
  margin-bottom: 5px;
  height: 30px !important;
  border: 1px solid #d0021b;
  color: #d0021b;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: #ffffff; }
  .chatScrollDownFiles:hover {
    color: #d0021b !important; }

.chatScrollDownData img {
  margin-top: -2px;
  margin-right: 5px;
  width: 14px; }

.info-div {
  position: absolute;
  z-index: 1;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: white;
  padding: 7px 10px 7px 10px;
  margin: 10px;
  font-size: 13px;
  max-width: 450px;
  margin-left: -373px; }
  .info-div p {
    margin-top: 5px;
    margin-bottom: 5px; }

.custom-tabs-nav {
  background: rgba(139, 149, 158, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 32px; }
  .custom-tabs-nav .nav-link {
    background-color: transparent;
    color: rgba(139, 149, 158, 0.5);
    font-weight: 500;
    padding: 9px 18px;
    border-radius: 3px; }
    .custom-tabs-nav .nav-link:hover {
      color: rgba(139, 149, 158, 0.7); }
    .custom-tabs-nav .nav-link.active {
      background-color: #ffffff;
      color: #323c47;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .custom-tabs-nav.light-tabs {
    border-bottom: 1px solid rgba(139, 149, 158, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0; }
    .custom-tabs-nav.light-tabs .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: #8b959e;
      opacity: 0.55;
      padding: 7px 7px 14px 7px; }
      .custom-tabs-nav.light-tabs .nav-link.active {
        background: transparent;
        border-bottom: 3px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600; }
      .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
        margin-right: 25px; }
  .custom-tabs-nav.light-tabs-partner {
    border-bottom: 0 !important;
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0; }
    .custom-tabs-nav.light-tabs-partner .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: #8b959e;
      opacity: 0.55;
      padding: 7px 0px 14px 0px !important; }
      .custom-tabs-nav.light-tabs-partner .nav-link.active {
        background: transparent;
        border-bottom: 3px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600; }
      .custom-tabs-nav.light-tabs-partner .nav-link:not(:last-child) {
        margin-right: 25px; }
  .custom-tabs-nav.light-tabs-mobile {
    border-bottom: 1px solid rgba(139, 149, 158, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0; }
    .custom-tabs-nav.light-tabs-mobile .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: #8b959e;
      opacity: 0.55;
      padding: 7px 0px 14px 0px; }
      .custom-tabs-nav.light-tabs-mobile .nav-link.active {
        background: transparent;
        border-bottom: 3px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600; }
      .custom-tabs-nav.light-tabs-mobile .nav-link:not(:last-child) {
        margin-right: 15px; }

.light-tabs-content {
  max-width: 1002px; }
  .light-tabs-content .row {
    max-width: 1028px; }

.display-inline-left {
  display: inline-block; }

.display-inline-right {
  display: inline-block;
  float: right; }

.vehicle-card {
  margin: 6.5px 0;
  padding-right: 30px;
  flex-direction: row;
  border-radius: 2px;
  border: 1px solid rgba(139, 149, 158, 0.1);
  overflow: hidden;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1); }
  .vehicle-card.car-rent .sub-title {
    margin-top: 10px; }
  .vehicle-card.car-rent .card-title {
    margin-top: 0; }
  .vehicle-card.active {
    box-shadow: none;
    border: 1px solid #0c0c0c; }
  .vehicle-card .vehicle-img-wrap {
    height: 100%;
    padding: 25px 0 52px 25px;
    margin: auto;
    width: 230px;
    flex-shrink: 0;
    display: flex;
    justify-content: center; }
    .vehicle-card .vehicle-img-wrap .vehicle-img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .vehicle-card .quote-img {
    width: 11%;
    height: 46%;
    margin-top: 7%; }
  .vehicle-card .quote-internal-wrap {
    padding: 25px 0 15px 25px;
    width: 45%;
    font-size: 17px; }
  .vehicle-card .col {
    flex-grow: 2; }
  .vehicle-card .col:last-child {
    flex-grow: 1; }
  .vehicle-card .card-action {
    align-items: center;
    justify-content: flex-end;
    padding: 42px 0 38px;
    text-align: right; }
  .vehicle-card .card-action-weight {
    padding: 52px 0 36px !important; }
    .vehicle-card .card-action-weight .text-brown {
      font-size: 12px !important;
      display: block !important; }
  .vehicle-card .card-body-mobile {
    justify-content: space-between;
    display: flex; }
    .vehicle-card .card-body-mobile .hint-text {
      line-height: 14px;
      margin-top: 10px; }
  .vehicle-card .card-body {
    padding: 40px 15px;
    width: 100%; }
    .vehicle-card .card-body .sub-title-electric {
      margin-bottom: 6px !important; }
    .vehicle-card .card-body .sub-title {
      font-size: 12px;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: #8b959e;
      letter-spacing: 0.3px; }
      .vehicle-card .card-body .sub-title .hint-text-year {
        font-size: 12px;
        color: #8b959e;
        float: right;
        border: 1px solid #8b959e;
        border-radius: 5px;
        padding-top: 2px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px; }
    .vehicle-card .card-body .vehicle-title {
      font-size: 15px;
      color: #303030;
      font-weight: normal;
      margin-top: -2px;
      width: 100px;
      white-space: nowrap; }
    .vehicle-card .card-body .electric-vehicle-title {
      margin-top: 2px; }
    .vehicle-card .card-body .description {
      font-size: 14px;
      color: #636d77;
      margin-bottom: 11px; }
  .vehicle-card .hint-text-self {
    font-size: 15px;
    color: #636d77; }
  .vehicle-card .hint-text {
    font-size: 14px;
    color: #636d77; }
  .vehicle-card .hint-text-discount {
    text-decoration-line: line-through;
    font-size: 10px;
    color: #636d77;
    text-align: center; }
  .vehicle-card .vehicle-value {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #0c0c0c; }
    .vehicle-card .vehicle-value:not(:last-child) {
      margin-right: 12px; }
    .vehicle-card .vehicle-value img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      margin-bottom: 3px;
      display: inline-block; }
  .vehicle-card .electric-vehicle {
    display: block;
    align-items: center;
    font-size: 12px;
    color: #28ae0f;
    margin-bottom: 6px; }
    .vehicle-card .electric-vehicle:not(:last-child) {
      margin-right: 12px; }
    .vehicle-card .electric-vehicle img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: baseline; }
  .vehicle-card .lead {
    font-size: 15px;
    color: #303030;
    font-weight: normal; }
    .vehicle-card .lead.editable-vehicle-rate {
      display: inline-block;
      align-items: center; }
    .vehicle-card .lead .font-12 {
      font-size: 14px; }
  .vehicle-card .vehicle-selected {
    width: 38px;
    height: 38px;
    object-fit: contain; }
  .vehicle-card .select-btn {
    min-width: 75px;
    height: 30px; }
    .vehicle-card .select-btn:focus {
      box-shadow: none; }
    .vehicle-card .select-btn.select-vehicle {
      height: 40px;
      min-width: 140px;
      font-size: 14px; }
  .vehicle-card .booking-card-footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 20px;
    height: 20px; }
    .vehicle-card .booking-card-footer .expandable:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 20px;
      background-image: url("/images/icons/expand.png");
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat; }
    .vehicle-card .booking-card-footer .expandable.expanded:after {
      transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .vehicle-card {
      padding-right: 0;
      width: 100%; }
      .vehicle-card.active {
        border: 1px solid rgba(12, 12, 12, 0.5); }
      .vehicle-card.car-rent .card-action {
        min-width: 135px; }
      .vehicle-card .vehicle-img-wrap {
        width: 28%;
        padding: 5px 10px 0; }
      .vehicle-card .card-body {
        padding: 20px 8px; }
        .vehicle-card .card-body .vehicle-title {
          font-size: 1rem;
          max-width: 210px; }
        .vehicle-card .card-body .vehicle-value img {
          height: 12px; }
        .vehicle-card .card-body .electric-vehicle img {
          height: 12px; }
      .vehicle-card .card-action {
        justify-content: flex-start;
        padding-right: 20px; }
        .vehicle-card .card-action .lead {
          font-size: 0.8rem; } }
  .vehicle-card.chat-vehicle-card {
    max-width: 360px;
    margin: 5px 0;
    padding: 0 5px; }
    .vehicle-card.chat-vehicle-card .vehicle-img-wrap {
      height: 100%;
      padding: 5px 0 5px 5px;
      margin: auto;
      width: 75px;
      flex-shrink: 0; }
    .vehicle-card.chat-vehicle-card .card-action {
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      text-align: right; }
    .vehicle-card.chat-vehicle-card .hint-text {
      font-size: 10px; }
    .vehicle-card.chat-vehicle-card .card-body {
      padding: 5px 5px;
      width: 100%; }
      .vehicle-card.chat-vehicle-card .card-body .sub-title {
        margin-bottom: 5px;
        font-size: 10px; }
      .vehicle-card.chat-vehicle-card .card-body .booked-sub-title {
        display: flex;
        align-items: center;
        opacity: 1;
        max-width: 110px;
        justify-content: space-between; }
        .vehicle-card.chat-vehicle-card .card-body .booked-sub-title .booked-label {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #a79779; }
          .vehicle-card.chat-vehicle-card .card-body .booked-sub-title .booked-label img {
            width: 15px;
            height: 15px;
            object-fit: contain; }
        .vehicle-card.chat-vehicle-card .card-body .booked-sub-title .service-label {
          padding: 2px;
          font-size: 8px; }
      .vehicle-card.chat-vehicle-card .card-body .vehicle-title {
        font-size: 12px;
        margin-bottom: 7px !important; }
      .vehicle-card.chat-vehicle-card .card-body .description {
        font-size: 10px;
        margin-bottom: 5px; }
    .vehicle-card.chat-vehicle-card .vehicle-value {
      font-size: 10px;
      color: black; }
      .vehicle-card.chat-vehicle-card .vehicle-value:not(:last-child) {
        margin-right: 5px; }
      .vehicle-card.chat-vehicle-card .vehicle-value img {
        height: 10px;
        margin-right: 3px; }
    .vehicle-card.chat-vehicle-card .electric-vehicle {
      font-size: 10px;
      color: #28ae0f; }
      .vehicle-card.chat-vehicle-card .electric-vehicle:not(:last-child) {
        margin-right: 5px; }
      .vehicle-card.chat-vehicle-card .electric-vehicle img {
        height: 10px;
        margin-right: 3px; }
    .vehicle-card.chat-vehicle-card .lead {
      font-size: 12px;
      margin-bottom: 4px !important;
      margin-top: 4px; }
    .vehicle-card.chat-vehicle-card .select-btn {
      min-width: 75px;
      height: 30px;
      padding-top: 4px;
      font-size: 13px; }
      .vehicle-card.chat-vehicle-card .select-btn:focus {
        box-shadow: none; }

.vehicle-detail-card {
  border: 1px solid rgba(139, 149, 158, 0.1);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1); }
  .vehicle-detail-card .card-body .row {
    padding: 7px 30px 0 15px; }
  .vehicle-detail-card .close-icon {
    position: absolute;
    top: 20px;
    right: 22px; }
    .vehicle-detail-card .close-icon img {
      width: 10px;
      height: 10px; }
  .vehicle-detail-card label.expand-title {
    margin-bottom: 14px;
    font-size: 12px; }
  .vehicle-detail-card p {
    margin-bottom: 1.5px;
    font-size: 14px; }

.vehicle-card-selected {
  border: 1.5px solid #0c0c0c !important; }

.booking-col {
  max-width: 290px; }
  .booking-col:not(:first-child) {
    margin-left: -0.75rem; }
  .booking-col.car-rental .booking-img-wrap img {
    width: 48px; }
  .booking-col.chauffeur .booking-img-wrap img {
    width: 36px; }
  .booking-col.transfer .booking-img-wrap img {
    width: 40px; }
  .booking-col.events .booking-img-wrap img {
    width: 32px; }

.booking-card {
  padding: 30px;
  border-radius: 2px;
  border: 1px solid rgba(139, 149, 158, 0.25);
  align-items: center;
  overflow: hidden;
  height: 100%;
  cursor: pointer; }
  .booking-card .service-label {
    margin-bottom: 30px;
    align-self: flex-start; }
  .booking-card .booking-img-wrap {
    height: 95px;
    display: flex; }
    .booking-card .booking-img-wrap .booking-img {
      object-fit: contain; }
    .booking-card .booking-img-wrap .booking-card-tag {
      border: 1px solid #000000;
      padding: 3px 6px;
      border-radius: 2px;
      font-size: 13px; }
  .booking-card .col {
    flex-grow: 2; }
  .booking-card .col:last-child {
    flex-grow: 1;
    margin-top: auto; }
  .booking-card .card-action {
    display: flex;
    align-items: center;
    justify-content: center; }
  .booking-card .card-body {
    padding: 0 10px 20px; }
    .booking-card .card-body .card-logo {
      display: none; }
    .booking-card .card-body .booking-title {
      font-size: 20px;
      color: #303030;
      font-weight: normal; }
    .booking-card .card-body .description {
      font-size: 15px;
      color: #636d77;
      margin-bottom: 11px;
      line-height: 24px; }
  .booking-card .hint-text {
    font-size: 15px;
    color: #8b959e; }
  .booking-card .lead {
    font-size: 25px; }
  .booking-card .booking-selected {
    width: 50px;
    height: 50px;
    object-fit: contain; }
  .booking-card:hover {
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1); }

.vehicle-card-desktop {
  margin: 6.5px 0;
  padding-right: 30px;
  flex-direction: row;
  border-radius: 2px;
  border: 1px solid rgba(139, 149, 158, 0.1);
  overflow: hidden;
  width: 700px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  padding-right: 0;
  width: 100%; }
  .vehicle-card-desktop.car-rent .sub-title {
    margin-top: 10px; }
  .vehicle-card-desktop.car-rent .card-title {
    margin-top: 0; }
  .vehicle-card-desktop .vehicle-img-wrap {
    height: 100%;
    padding: 25px 0 52px 25px;
    margin: auto;
    width: 230px;
    flex-shrink: 0; }
    .vehicle-card-desktop .vehicle-img-wrap .vehicle-img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .vehicle-card-desktop .col {
    flex-grow: 2; }
  .vehicle-card-desktop .col:last-child {
    flex-grow: 1; }
  .vehicle-card-desktop .card-action {
    align-items: center;
    justify-content: flex-end;
    padding: 42px 0 38px;
    text-align: right; }
  .vehicle-card-desktop .card-action-weight {
    padding: 52px 0 36px !important; }
    .vehicle-card-desktop .card-action-weight .text-brown {
      font-size: 12px !important;
      display: block !important; }
  .vehicle-card-desktop .card-body-mobile {
    justify-content: space-between;
    display: flex; }
    .vehicle-card-desktop .card-body-mobile .hint-text {
      line-height: 14px;
      margin-top: 10px; }
  .vehicle-card-desktop .card-body {
    padding: 40px 15px;
    width: 100%; }
    .vehicle-card-desktop .card-body .sub-title-electric {
      margin-bottom: 6px !important; }
    .vehicle-card-desktop .card-body .sub-title {
      font-size: 12px;
      margin-bottom: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: #8b959e;
      letter-spacing: 0.3px; }
      .vehicle-card-desktop .card-body .sub-title .hint-text-year {
        font-size: 12px;
        color: #8b959e;
        float: right;
        border: 1px solid #8b959e;
        border-radius: 5px;
        padding-top: 2px;
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px; }
    .vehicle-card-desktop .card-body .vehicle-title {
      font-size: 15px;
      color: #303030;
      font-weight: normal;
      margin-top: -2px;
      max-width: 210px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .vehicle-card-desktop .card-body .electric-vehicle-title {
      margin-top: 2px; }
    .vehicle-card-desktop .card-body .description {
      font-size: 14px;
      color: #636d77;
      margin-bottom: 11px; }
  .vehicle-card-desktop .hint-text-self {
    font-size: 15px;
    color: #8b959e; }
  .vehicle-card-desktop .hint-text {
    font-size: 14px;
    color: #8b959e; }
  .vehicle-card-desktop .hint-text-discount {
    text-decoration-line: line-through;
    font-size: 10px;
    color: #8b959e;
    text-align: center; }
  .vehicle-card-desktop .vehicle-value {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #0c0c0c; }
    .vehicle-card-desktop .vehicle-value:not(:last-child) {
      margin-right: 12px; }
    .vehicle-card-desktop .vehicle-value img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      margin-bottom: 3px;
      display: inline-block; }
  .vehicle-card-desktop .electric-vehicle {
    display: block;
    align-items: center;
    font-size: 12px;
    color: #28ae0f;
    margin-bottom: 6px; }
    .vehicle-card-desktop .electric-vehicle:not(:last-child) {
      margin-right: 12px; }
    .vehicle-card-desktop .electric-vehicle img {
      width: auto;
      height: 14px;
      margin-right: 5px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: baseline; }
  .vehicle-card-desktop .lead {
    font-size: 15px;
    color: #303030;
    font-weight: normal; }
    .vehicle-card-desktop .lead.editable-vehicle-rate {
      display: inline-block;
      align-items: center; }
    .vehicle-card-desktop .lead .font-12 {
      font-size: 14px; }
  .vehicle-card-desktop .vehicle-selected {
    width: 38px;
    height: 38px;
    object-fit: contain; }
  .vehicle-card-desktop .select-btn {
    min-width: 75px;
    height: 30px; }
    .vehicle-card-desktop .select-btn:focus {
      box-shadow: none; }
    .vehicle-card-desktop .select-btn.select-vehicle {
      height: 40px;
      min-width: 140px;
      font-size: 14px; }
  .vehicle-card-desktop .booking-card-footer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 20px;
    height: 20px; }
    .vehicle-card-desktop .booking-card-footer .expandable:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 20px;
      background-image: url("/images/icons/expand.png");
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat; }
    .vehicle-card-desktop .booking-card-footer .expandable.expanded:after {
      transform: rotate(180deg); }
  .vehicle-card-desktop.active {
    border: 1px solid rgba(12, 12, 12, 0.5); }
  .vehicle-card-desktop.car-rent .card-action {
    min-width: 135px; }
  .vehicle-card-desktop .vehicle-img-wrap {
    width: 50% !important;
    padding: 5px 10px 0; }
  .vehicle-card-desktop .card-body {
    padding: 20px 8px; }
    .vehicle-card-desktop .card-body .vehicle-title {
      font-size: 1rem;
      max-width: 210px; }
    .vehicle-card-desktop .card-body .vehicle-value img {
      height: 12px; }
    .vehicle-card-desktop .card-body .electric-vehicle img {
      height: 12px; }
  .vehicle-card-desktop .card-action {
    justify-content: flex-start;
    padding-right: 20px; }
    .vehicle-card-desktop .card-action .lead {
      font-size: 0.8rem; }
  .vehicle-card-desktop.chat-vehicle-card {
    max-width: 360px;
    margin: 5px 0;
    padding: 0 5px; }
    .vehicle-card-desktop.chat-vehicle-card .vehicle-img-wrap {
      height: 100%;
      padding: 5px 0 5px 5px;
      margin: auto;
      width: 75px;
      flex-shrink: 0; }
    .vehicle-card-desktop.chat-vehicle-card .card-action {
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
      text-align: right; }
    .vehicle-card-desktop.chat-vehicle-card .hint-text {
      font-size: 10px; }
    .vehicle-card-desktop.chat-vehicle-card .card-body {
      padding: 5px 5px;
      width: 100%; }
      .vehicle-card-desktop.chat-vehicle-card .card-body .sub-title {
        margin-bottom: 5px;
        font-size: 10px; }
      .vehicle-card-desktop.chat-vehicle-card .card-body .booked-sub-title {
        display: flex;
        align-items: center;
        opacity: 1;
        max-width: 110px;
        justify-content: space-between; }
        .vehicle-card-desktop.chat-vehicle-card .card-body .booked-sub-title .booked-label {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #a79779; }
          .vehicle-card-desktop.chat-vehicle-card .card-body .booked-sub-title .booked-label img {
            width: 15px;
            height: 15px;
            object-fit: contain; }
        .vehicle-card-desktop.chat-vehicle-card .card-body .booked-sub-title .service-label {
          padding: 2px;
          font-size: 8px; }
      .vehicle-card-desktop.chat-vehicle-card .card-body .vehicle-title {
        font-size: 12px;
        margin-bottom: 7px !important; }
      .vehicle-card-desktop.chat-vehicle-card .card-body .description {
        font-size: 10px;
        margin-bottom: 5px; }
    .vehicle-card-desktop.chat-vehicle-card .vehicle-value {
      font-size: 10px;
      color: black; }
      .vehicle-card-desktop.chat-vehicle-card .vehicle-value:not(:last-child) {
        margin-right: 5px; }
      .vehicle-card-desktop.chat-vehicle-card .vehicle-value img {
        height: 10px;
        margin-right: 3px; }
    .vehicle-card-desktop.chat-vehicle-card .electric-vehicle {
      font-size: 10px;
      color: #28ae0f; }
      .vehicle-card-desktop.chat-vehicle-card .electric-vehicle:not(:last-child) {
        margin-right: 5px; }
      .vehicle-card-desktop.chat-vehicle-card .electric-vehicle img {
        height: 10px;
        margin-right: 3px; }
    .vehicle-card-desktop.chat-vehicle-card .lead {
      font-size: 12px;
      margin-bottom: 4px !important;
      margin-top: 4px; }
    .vehicle-card-desktop.chat-vehicle-card .select-btn {
      min-width: 75px;
      height: 30px;
      padding-top: 4px;
      font-size: 13px; }
      .vehicle-card-desktop.chat-vehicle-card .select-btn:focus {
        box-shadow: none; }

.success-msg-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  background: #ffffff;
  overflow-y: auto;
  flex-wrap: wrap; }
  .success-msg-modal img {
    display: block;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 0 auto 35px auto; }
  .success-msg-modal .success-msg-header {
    font-size: 20px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400; }
  .success-msg-modal .success-msg-description {
    font-size: 15px;
    color: #636d77;
    text-align: center;
    white-space: pre-wrap;
    line-height: 26px; }
  .success-msg-modal .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px; }
  .success-msg-modal .content-wrap {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center; }
  .success-msg-modal .content-div {
    display: flex;
    flex-direction: column;
    align-items: center; }

.success-msg-verification {
  height: 100vh;
  color: #ffffff;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  background: #ffffff;
  overflow-y: auto; }
  .success-msg-verification img {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin: 0 auto 15px auto; }
  .success-msg-verification .success-msg-header {
    font-size: 20px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400;
    margin-bottom: 25px; }
  .success-msg-verification .success-msg-description {
    font-size: 15px;
    color: #636d77;
    text-align: center;
    white-space: pre-wrap;
    line-height: 10px; }
  .success-msg-verification .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px; }
  .success-msg-verification .content-wrap {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center; }

.success-msg-quote {
  color: #ffffff;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  margin-top: 5%;
  background: #ffffff; }
  .success-msg-quote img {
    display: block;
    width: 150px;
    height: 110px;
    object-fit: cover;
    margin: 0 auto 15px auto;
    margin-bottom: 0; }
  .success-msg-quote .success-msg-header {
    font-size: 20px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400; }
  .success-msg-quote .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px; }
  .success-msg-quote .content-wrap {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    justify-content: center; }

.door-to-door-div {
  color: #0c0c0c;
  margin-top: 2rem;
  background-color: rgba(167, 151, 121, 0.1);
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  border-radius: 4px; }
  .door-to-door-div span:first-child {
    font-size: 20px;
    text-align: center; }
  .door-to-door-div .door-to-door-cards {
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem; }
  .door-to-door-div .door-to-door-card {
    background: white;
    padding: 1.5rem 2rem;
    border: 1px solid rgba(139, 149, 158, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    border-radius: 2px; }
    .door-to-door-div .door-to-door-card img {
      width: 30px !important;
      height: 30px !important;
      margin: 0 !important; }
    .door-to-door-div .door-to-door-card span {
      display: block;
      font-weight: bold;
      margin-top: 15px; }
    .door-to-door-div .door-to-door-card span:last-of-type {
      display: block;
      font-weight: normal !important;
      color: #636d77;
      margin-top: 15px;
      text-align: center; }
    .door-to-door-div .door-to-door-card .book-btn {
      width: 100%;
      margin-top: 5px; }
      .door-to-door-div .door-to-door-card .book-btn button {
        width: inherit !important; }

.stepper {
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0; }
  .stepper .step {
    position: relative;
    display: table-cell;
    text-align: center;
    padding: 0.5rem; }
    .stepper .step .stepper-label {
      color: rgba(12, 12, 12, 0.1);
      text-align: left;
      margin-bottom: 8px; }
      .stepper .step .stepper-label.page-title {
        margin-bottom: 5px; }
    .stepper .step .indicator {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .stepper .step .indicator .round-indicator {
        height: 6px;
        width: 6px;
        border: 2px solid rgba(12, 12, 12, 0.1);
        border-radius: 6px;
        margin-right: 10px; }
      .stepper .step .indicator .info {
        margin-right: 10px;
        font-size: 10px;
        color: rgba(12, 12, 12, 0.1);
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap; }
      .stepper .step .indicator:after {
        content: ' ';
        left: 60%;
        width: 85%;
        height: 0.0625rem;
        background-color: rgba(12, 12, 12, 0.1);
        z-index: 1; }
    .stepper .step.active .stepper-label {
      color: #0c0c0c; }
    .stepper .step.active .indicator .round-indicator {
      border-color: #0c0c0c;
      background: #0c0c0c; }
    .stepper .step.active .indicator .info {
      color: #0c0c0c; }
    .stepper .step.active .indicator:after {
      background-color: #0c0c0c; }
    .stepper .step:last-child .indicator:after {
      display: none; }

.no-data-wrap {
  min-height: 50vh;
  margin-top: 60px; }
  .no-data-wrap .no-data-img-wrap {
    margin: 0 auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #8b959e;
    display: flex;
    justify-content: center; }
    .no-data-wrap .no-data-img-wrap img {
      object-fit: contain; }
  .no-data-wrap .left-space img {
    object-fit: contain;
    margin-left: 7px; }
  .no-data-wrap .no-data-header {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 20px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400; }
  .no-data-wrap .no-data-description {
    font-size: 15px;
    color: #8b959e;
    text-align: center; }
    .no-data-wrap .no-data-description:not(:last-child) {
      margin-bottom: 22px; }
  .no-data-wrap .no-data-link {
    font-size: 16px; }
  .no-data-wrap.no-notifications {
    margin-top: 30vh; }

.no-data-partner {
  margin-top: 15vh !important; }

.team-bookings-table {
  max-width: 1000px !important; }

@media (max-width: 768px) {
  .no-data-wrap.no-notifications {
    margin-top: 25vh; } }

.no-payouts {
  margin-top: 9rem; }

.inline-preview-input {
  display: flex;
  justify-content: space-between; }
  .inline-preview-input .nested {
    flex-direction: column; }

.inline-control-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.inline-edit-btn {
  align-self: start; }
  .inline-edit-btn:hover {
    text-decoration: none; }

.notifications-board-wrap {
  position: absolute;
  bottom: 100px;
  right: 25px;
  width: 420px; }
  .notifications-board-wrap .float-btn-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 60px;
    width: 60px; }
    .notifications-board-wrap .float-btn-wrap .unread-indicator {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background: red;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
      top: 10px;
      right: 10px;
      padding-top: 2px; }
  .notifications-board-wrap .notifications-board {
    width: 350px; }
    .notifications-board-wrap .notifications-board .notification-card {
      margin: 5px 0;
      flex-direction: row;
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: 1px solid rgba(139, 149, 158, 0.25);
      overflow: hidden; }
      .notifications-board-wrap .notifications-board .notification-card .card-body {
        padding: 0 10px;
        width: 100%;
        min-width: 350px; }
        .notifications-board-wrap .notifications-board .notification-card .card-body .notification-title {
          font-size: 20px;
          color: #303030;
          font-weight: normal; }
      .notifications-board-wrap .notifications-board .notification-card .hint-text {
        font-size: 15px;
        color: #8b959e; }

@media (max-width: 768px) {
  .notifications-tr {
    top: 30px !important; } }

.selectOptionButton {
  background-color: inherit;
  margin-left: 1px; }
  .selectOptionButton img {
    float: right;
    width: 20px;
    margin-top: 5px;
    margin-right: 5px; }

.selectOptionButtonCurrency {
  margin-left: 1px; }
  .selectOptionButtonCurrency img {
    float: right;
    width: 20px;
    margin-right: 5px; }

.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%; }
  .react-tel-input :disabled {
    cursor: not-allowed; }
  .react-tel-input .flag {
    width: 25px;
    height: 20px;
    background-image: url("/images/high-res-flags.png");
    background-repeat: no-repeat; }
  .react-tel-input .ad {
    background-position: -48px -24px; }
  .react-tel-input .ae {
    background-position: -72px -24px; }
  .react-tel-input .af {
    background-position: -96px -24px; }
  .react-tel-input .ag {
    background-position: -120px -24px; }
  .react-tel-input .ai {
    background-position: -144px -24px; }
  .react-tel-input .al {
    background-position: -168px -24px; }
  .react-tel-input .am {
    background-position: -192px -24px; }
  .react-tel-input .an {
    background-position: -216px -24px; }
  .react-tel-input .ao {
    background-position: -240px -24px; }
  .react-tel-input .aq {
    background-position: -264px -24px; }
  .react-tel-input .ar {
    background-position: -288px -24px; }
  .react-tel-input .as {
    background-position: -312px -24px; }
  .react-tel-input .at {
    background-position: -336px -24px; }
  .react-tel-input .au {
    background-position: -360px -24px; }
  .react-tel-input .aw {
    background-position: -384px -24px; }
  .react-tel-input .ax {
    background-position: -0px -48px; }
  .react-tel-input .az {
    background-position: -24px -48px; }
  .react-tel-input .ba {
    background-position: -48px -48px; }
  .react-tel-input .bb {
    background-position: -72px -48px; }
  .react-tel-input .bd {
    background-position: -96px -48px; }
  .react-tel-input .be {
    background-position: -120px -48px; }
  .react-tel-input .bf {
    background-position: -144px -48px; }
  .react-tel-input .bg {
    background-position: -168px -48px; }
  .react-tel-input .bh {
    background-position: -192px -48px; }
  .react-tel-input .bi {
    background-position: -216px -48px; }
  .react-tel-input .bj {
    background-position: -240px -48px; }
  .react-tel-input .bl {
    background-position: -264px -48px; }
  .react-tel-input .bm {
    background-position: -288px -48px; }
  .react-tel-input .bn {
    background-position: -312px -48px; }
  .react-tel-input .bo {
    background-position: -336px -48px; }
  .react-tel-input .br {
    background-position: -360px -48px; }
  .react-tel-input .bs {
    background-position: -384px -48px; }
  .react-tel-input .bt {
    background-position: -0px -72px; }
  .react-tel-input .bw {
    background-position: -24px -72px; }
  .react-tel-input .by {
    background-position: -48px -72px; }
  .react-tel-input .bz {
    background-position: -72px -72px; }
  .react-tel-input .ca {
    background-position: -96px -72px; }
  .react-tel-input .cc {
    background-position: -120px -72px; }
  .react-tel-input .cd {
    background-position: -144px -72px; }
  .react-tel-input .cf {
    background-position: -168px -72px; }
  .react-tel-input .cg {
    background-position: -192px -72px; }
  .react-tel-input .ch {
    background-position: -216px -72px; }
  .react-tel-input .ci {
    background-position: -240px -72px; }
  .react-tel-input .ck {
    background-position: -264px -72px; }
  .react-tel-input .cl {
    background-position: -288px -72px; }
  .react-tel-input .cm {
    background-position: -312px -72px; }
  .react-tel-input .cn {
    background-position: -336px -72px; }
  .react-tel-input .co {
    background-position: -360px -72px; }
  .react-tel-input .cr {
    background-position: -384px -72px; }
  .react-tel-input .cu {
    background-position: -0px -96px; }
  .react-tel-input .cv {
    background-position: -24px -96px; }
  .react-tel-input .cw {
    background-position: -48px -96px; }
  .react-tel-input .cx {
    background-position: -72px -96px; }
  .react-tel-input .cy {
    background-position: -96px -96px; }
  .react-tel-input .cz {
    background-position: -120px -96px; }
  .react-tel-input .de {
    background-position: -144px -96px; }
  .react-tel-input .dj {
    background-position: -168px -96px; }
  .react-tel-input .dk {
    background-position: -192px -96px; }
  .react-tel-input .dm {
    background-position: -216px -96px; }
  .react-tel-input .do {
    background-position: -240px -96px; }
  .react-tel-input .dz {
    background-position: -264px -96px; }
  .react-tel-input .ec {
    background-position: -288px -96px; }
  .react-tel-input .ee {
    background-position: -312px -96px; }
  .react-tel-input .eg {
    background-position: -336px -96px; }
  .react-tel-input .eh {
    background-position: -360px -96px; }
  .react-tel-input .er {
    background-position: -384px -96px; }
  .react-tel-input .es {
    background-position: -0px -120px; }
  .react-tel-input .et {
    background-position: -24px -120px; }
  .react-tel-input .eu {
    background-position: -48px -120px; }
  .react-tel-input .fi {
    background-position: -72px -120px; }
  .react-tel-input .fj {
    background-position: -96px -120px; }
  .react-tel-input .fk {
    background-position: -120px -120px; }
  .react-tel-input .fm {
    background-position: -144px -120px; }
  .react-tel-input .fo {
    background-position: -168px -120px; }
  .react-tel-input .fr {
    background-position: -192px -120px; }
  .react-tel-input .ga {
    background-position: -216px -120px; }
  .react-tel-input .gb {
    background-position: -240px -120px; }
  .react-tel-input .gd {
    background-position: -264px -120px; }
  .react-tel-input .ge {
    background-position: -288px -120px; }
  .react-tel-input .gg {
    background-position: -312px -120px; }
  .react-tel-input .gh {
    background-position: -336px -120px; }
  .react-tel-input .gi {
    background-position: -360px -120px; }
  .react-tel-input .gl {
    background-position: -384px -120px; }
  .react-tel-input .gm {
    background-position: -0px -144px; }
  .react-tel-input .gn {
    background-position: -24px -144px; }
  .react-tel-input .gq {
    background-position: -48px -144px; }
  .react-tel-input .gr {
    background-position: -72px -144px; }
  .react-tel-input .gs {
    background-position: -96px -144px; }
  .react-tel-input .gt {
    background-position: -120px -144px; }
  .react-tel-input .gu {
    background-position: -144px -144px; }
  .react-tel-input .gw {
    background-position: -168px -144px; }
  .react-tel-input .gy {
    background-position: -192px -144px; }
  .react-tel-input .hk {
    background-position: -216px -144px; }
  .react-tel-input .hn {
    background-position: -240px -144px; }
  .react-tel-input .hr {
    background-position: -264px -144px; }
  .react-tel-input .ht {
    background-position: -288px -144px; }
  .react-tel-input .hu {
    background-position: -312px -144px; }
  .react-tel-input .ic {
    background-position: -336px -144px; }
  .react-tel-input .id {
    background-position: -360px -144px; }
  .react-tel-input .ie {
    background-position: -384px -144px; }
  .react-tel-input .il {
    background-position: -0px -168px; }
  .react-tel-input .im {
    background-position: -24px -168px; }
  .react-tel-input .in {
    background-position: -48px -168px; }
  .react-tel-input .iq {
    background-position: -72px -168px; }
  .react-tel-input .ir {
    background-position: -96px -168px; }
  .react-tel-input .is {
    background-position: -120px -168px; }
  .react-tel-input .it {
    background-position: -144px -168px; }
  .react-tel-input .je {
    background-position: -168px -168px; }
  .react-tel-input .jm {
    background-position: -192px -168px; }
  .react-tel-input .jo {
    background-position: -216px -168px; }
  .react-tel-input .jp {
    background-position: -240px -168px; }
  .react-tel-input .ke {
    background-position: -264px -168px; }
  .react-tel-input .kg {
    background-position: -288px -168px; }
  .react-tel-input .kh {
    background-position: -312px -168px; }
  .react-tel-input .ki {
    background-position: -336px -168px; }
  .react-tel-input .km {
    background-position: -360px -168px; }
  .react-tel-input .kn {
    background-position: -384px -168px; }
  .react-tel-input .kp {
    background-position: -0px -192px; }
  .react-tel-input .kr {
    background-position: -24px -192px; }
  .react-tel-input .kw {
    background-position: -48px -192px; }
  .react-tel-input .ky {
    background-position: -72px -192px; }
  .react-tel-input .kz {
    background-position: -96px -192px; }
  .react-tel-input .la {
    background-position: -120px -192px; }
  .react-tel-input .lb {
    background-position: -144px -192px; }
  .react-tel-input .lc {
    background-position: -168px -192px; }
  .react-tel-input .li {
    background-position: -192px -192px; }
  .react-tel-input .lk {
    background-position: -216px -192px; }
  .react-tel-input .lr {
    background-position: -240px -192px; }
  .react-tel-input .ls {
    background-position: -264px -192px; }
  .react-tel-input .lt {
    background-position: -288px -192px; }
  .react-tel-input .lu {
    background-position: -312px -192px; }
  .react-tel-input .lv {
    background-position: -336px -192px; }
  .react-tel-input .ly {
    background-position: -360px -192px; }
  .react-tel-input .ma {
    background-position: -384px -192px; }
  .react-tel-input .mc {
    background-position: -0px -216px; }
  .react-tel-input .md {
    background-position: -24px -216px; }
  .react-tel-input .me {
    background-position: -48px -216px; }
  .react-tel-input .mf {
    background-position: -72px -216px; }
  .react-tel-input .mg {
    background-position: -96px -216px; }
  .react-tel-input .mh {
    background-position: -120px -216px; }
  .react-tel-input .mk {
    background-position: -144px -216px; }
  .react-tel-input .ml {
    background-position: -168px -216px; }
  .react-tel-input .mm {
    background-position: -192px -216px; }
  .react-tel-input .mn {
    background-position: -216px -216px; }
  .react-tel-input .mo {
    background-position: -240px -216px; }
  .react-tel-input .mp {
    background-position: -264px -216px; }
  .react-tel-input .mq {
    background-position: -288px -216px; }
  .react-tel-input .mr {
    background-position: -312px -216px; }
  .react-tel-input .ms {
    background-position: -336px -216px; }
  .react-tel-input .mt {
    background-position: -360px -216px; }
  .react-tel-input .mu {
    background-position: -384px -216px; }
  .react-tel-input .mv {
    background-position: -0px -240px; }
  .react-tel-input .mw {
    background-position: -24px -240px; }
  .react-tel-input .mx {
    background-position: -48px -240px; }
  .react-tel-input .my {
    background-position: -72px -240px; }
  .react-tel-input .mz {
    background-position: -96px -240px; }
  .react-tel-input .na {
    background-position: -120px -240px; }
  .react-tel-input .nc {
    background-position: -144px -240px; }
  .react-tel-input .ne {
    background-position: -168px -240px; }
  .react-tel-input .nf {
    background-position: -192px -240px; }
  .react-tel-input .ng {
    background-position: -216px -240px; }
  .react-tel-input .ni {
    background-position: -240px -240px; }
  .react-tel-input .nl {
    background-position: -264px -240px; }
  .react-tel-input .no {
    background-position: -288px -240px; }
  .react-tel-input .np {
    background-position: -312px -240px; }
  .react-tel-input .nr {
    background-position: -336px -240px; }
  .react-tel-input .nu {
    background-position: -360px -240px; }
  .react-tel-input .nz {
    background-position: -384px -240px; }
  .react-tel-input .om {
    background-position: -0px -264px; }
  .react-tel-input .pa {
    background-position: -24px -264px; }
  .react-tel-input .pe {
    background-position: -48px -264px; }
  .react-tel-input .pf {
    background-position: -72px -264px; }
  .react-tel-input .pg {
    background-position: -96px -264px; }
  .react-tel-input .ph {
    background-position: -120px -264px; }
  .react-tel-input .pk {
    background-position: -192px -264px; }
  .react-tel-input .pl {
    background-position: -216px -264px; }
  .react-tel-input .pn {
    background-position: -240px -264px; }
  .react-tel-input .pr {
    background-position: -264px -264px; }
  .react-tel-input .ps {
    background-position: -288px -264px; }
  .react-tel-input .pt {
    background-position: -312px -264px; }
  .react-tel-input .pw {
    background-position: -336px -264px; }
  .react-tel-input .py {
    background-position: -360px -264px; }
  .react-tel-input .qa {
    background-position: -384px -264px; }
  .react-tel-input .ro {
    background-position: -0px -288px; }
  .react-tel-input .rs {
    background-position: -24px -288px; }
  .react-tel-input .ru {
    background-position: -48px -288px; }
  .react-tel-input .rw {
    background-position: -72px -288px; }
  .react-tel-input .sa {
    background-position: -96px -288px; }
  .react-tel-input .sb {
    background-position: -120px -288px; }
  .react-tel-input .sc {
    background-position: -144px -288px; }
  .react-tel-input .sd {
    background-position: -168px -288px; }
  .react-tel-input .se {
    background-position: -192px -288px; }
  .react-tel-input .sg {
    background-position: -216px -288px; }
  .react-tel-input .sh {
    background-position: -240px -288px; }
  .react-tel-input .si {
    background-position: -264px -288px; }
  .react-tel-input .sk {
    background-position: -288px -288px; }
  .react-tel-input .sl {
    background-position: -312px -288px; }
  .react-tel-input .sm {
    background-position: -336px -288px; }
  .react-tel-input .sn {
    background-position: -360px -288px; }
  .react-tel-input .so {
    background-position: -384px -288px; }
  .react-tel-input .sr {
    background-position: -0px -312px; }
  .react-tel-input .ss {
    background-position: -24px -312px; }
  .react-tel-input .st {
    background-position: -48px -312px; }
  .react-tel-input .sv {
    background-position: -72px -312px; }
  .react-tel-input .sy {
    background-position: -96px -312px; }
  .react-tel-input .sz {
    background-position: -120px -312px; }
  .react-tel-input .tc {
    background-position: -144px -312px; }
  .react-tel-input .td {
    background-position: -168px -312px; }
  .react-tel-input .tf {
    background-position: -192px -312px; }
  .react-tel-input .tg {
    background-position: -216px -312px; }
  .react-tel-input .th {
    background-position: -240px -312px; }
  .react-tel-input .tj {
    background-position: -264px -312px; }
  .react-tel-input .tk {
    background-position: -288px -312px; }
  .react-tel-input .tl {
    background-position: -312px -312px; }
  .react-tel-input .tm {
    background-position: -336px -312px; }
  .react-tel-input .tn {
    background-position: -360px -312px; }
  .react-tel-input .to {
    background-position: -384px -312px; }
  .react-tel-input .tr {
    background-position: -0px -336px; }
  .react-tel-input .tt {
    background-position: -24px -336px; }
  .react-tel-input .tv {
    background-position: -48px -336px; }
  .react-tel-input .tw {
    background-position: -72px -336px; }
  .react-tel-input .tz {
    background-position: -96px -336px; }
  .react-tel-input .ua {
    background-position: -120px -336px; }
  .react-tel-input .ug {
    background-position: -144px -336px; }
  .react-tel-input .us {
    background-position: -168px -336px; }
  .react-tel-input .uy {
    background-position: -192px -336px; }
  .react-tel-input .uz {
    background-position: -216px -336px; }
  .react-tel-input .va {
    background-position: -240px -336px; }
  .react-tel-input .vc {
    background-position: -264px -336px; }
  .react-tel-input .ve {
    background-position: -288px -336px; }
  .react-tel-input .vg {
    background-position: -312px -336px; }
  .react-tel-input .vi {
    background-position: -336px -336px; }
  .react-tel-input .vn {
    background-position: -360px -336px; }
  .react-tel-input .vu {
    background-position: -384px -336px; }
  .react-tel-input .wf {
    background-position: -0px -360px; }
  .react-tel-input .ws {
    background-position: -24px -360px; }
  .react-tel-input .ye {
    background-position: -48px -360px; }
  .react-tel-input .za {
    background-position: -96px -360px; }
  .react-tel-input .zm {
    background-position: -120px -360px; }
  .react-tel-input .zw {
    background-position: -144px -360px; }

.tooltip {
  background-color: white; }
  .tooltip .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: black;
    text-align: center;
    background-color: white;
    border-radius: 0.25rem; }
  .tooltip .arrow {
    display: none; }

.userInitailsTip .tooltip-inner {
  border: 1px solid black !important; }

.adminInitailsTip .tooltip-inner {
  text-align: left !important;
  max-width: -webkit-fill-available !important;
  border: 1px solid black !important; }

.clientInfoTooltip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .clientInfoTooltip .tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }
  @media (max-width: 768px) {
    .clientInfoTooltip {
      margin-left: 0;
      margin-top: 0.313rem !important;
      padding: 0 !important; }
      .clientInfoTooltip .tooltip-inner {
        text-align: left;
        max-width: 18rem;
        padding: 0.75rem 1rem;
        border: 1px solid rgba(139, 149, 158, 0.25);
        border-radius: 0.2rem;
        font-size: 14px;
        background-color: white; } }

.partnerRegistrationTooltip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .partnerRegistrationTooltip .tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }
  @media (max-width: 768px) {
    .partnerRegistrationTooltip {
      margin-left: 0;
      margin-top: 0.313rem !important;
      padding: 0 !important; }
      .partnerRegistrationTooltip .tooltip-inner {
        text-align: left;
        max-width: 16rem;
        padding: 0.75rem 1rem;
        border: 1px solid rgba(139, 149, 158, 0.25);
        border-radius: 0.2rem;
        font-size: 14px;
        background-color: white; } }

.partner-infoToolTip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .partner-infoToolTip .tooltip-inner {
    text-align: left;
    max-width: 18rem;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }
  @media (max-width: 768px) {
    .partner-infoToolTip .tooltip-inner {
      text-align: left;
      max-width: 19.938rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba(139, 149, 158, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white; } }

.clientInfoTooltip-coverage {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  transform: translate3d(-12px, 274.667px, 0px); }
  .clientInfoTooltip-coverage .tooltip-inner {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
    position: absolute;
    width: 182px;
    right: -51px;
    top: 14px; }

.clientInfoTooltip-terms {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  transform: translate3d(-12px, 274.667px, 0px); }
  .clientInfoTooltip-terms .tooltip-inner {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }

.clientInfoTooltip-coverage-two {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px;
  left: -15% !important; }
  .clientInfoTooltip-coverage-two .tooltip-inner {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white;
    position: absolute;
    width: 182px;
    right: -51px;
    top: 14px; }

.clientInfoTooltip-multi {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .clientInfoTooltip-multi .tooltip-inner {
    text-align: left;
    max-width: 300px;
    padding: 0.75rem 1rem;
    padding-right: 0.5rem;
    padding-left: 0.8rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }

.clientInfoTooltip-childSeat {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .clientInfoTooltip-childSeat .tooltip-inner {
    text-align: left;
    width: 200px;
    padding: 0.75rem 0rem 0.75rem 0.75rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }

.scott-dunn-tool-tip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .scott-dunn-tool-tip .tooltip-inner {
    text-align: left;
    max-width: 31.25rem;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }
  @media (max-width: 768px) {
    .scott-dunn-tool-tip .tooltip-inner {
      text-align: left;
      max-width: 18.125rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba(139, 149, 158, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
      margin-left: -8rem; } }

.referral-tool-tip {
  margin-left: 10px;
  opacity: 1 !important;
  padding-left: 0px;
  padding-right: 0px; }
  .referral-tool-tip .tooltip-inner {
    text-align: left;
    max-width: 25rem;
    padding: 0.75rem 1rem;
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 0.2rem;
    font-size: 14px;
    background-color: white; }
  @media (max-width: 768px) {
    .referral-tool-tip .tooltip-inner {
      text-align: left;
      max-width: 18.125rem;
      padding: 0.75rem 1rem;
      border: 1px solid rgba(139, 149, 158, 0.25);
      border-radius: 0.2rem;
      font-size: 14px;
      background-color: white;
      margin-left: -8rem; } }

.close-icon-btn {
  margin-left: 97%;
  margin-bottom: 1.5%;
  cursor: pointer; }
  .close-icon-btn img {
    width: 10px !important;
    height: 10px !important; }

.heading-benefits-card img {
  max-height: 33px !important; }

.benefits-header-text-with-icon {
  margin-top: 2.5%;
  padding-left: 2%;
  margin-bottom: 5%;
  font-size: 14px !important;
  font-weight: 500;
  word-break: break-word; }

.benefits-header-text-without-icon {
  font-size: 14px !important;
  font-weight: 500;
  word-break: break-word; }

.benefits-list-item {
  margin-left: 4.6rem;
  padding-top: 1%; }
  .benefits-list-item img {
    max-height: 15px;
    margin-top: 1%; }
  .benefits-list-item span {
    margin-top: 1%;
    margin-left: 1%; }

.toggleSwitch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px; }
  .toggleSwitch.mt5px {
    margin-top: 5px; }
  .toggleSwitch.ml30 {
    margin-left: 30px; }
  .toggleSwitch a {
    padding-top: 3px;
    color: #8b959e !important; }
  .toggleSwitch label {
    position: relative;
    display: inline-block;
    margin-top: 4px;
    width: 30px;
    height: 15px; }
  .toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .toggleSwitch span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid;
    border-color: #8b959e;
    transition: 0.3s;
    border-radius: 30px; }
  .toggleSwitch span:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: -2px;
    bottom: -2px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
    border-color: grey;
    border: 2px solid; }
  .toggleSwitch .active {
    color: black !important; }
  .toggleSwitch input:checked + span {
    background-color: black;
    border-color: black; }
  .toggleSwitch input:checked + span:before {
    transform: translateX(15px);
    border-color: black; }

.rolzo-gpt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5; }

.chat-container-gpt {
  width: 75%;
  height: 75%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden; }

.messages-gpt {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column; }

.messageGPT {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  max-width: 70%; }

.messageGPT.user {
  align-self: flex-end;
  background-color: #d3d3d3;
  color: black; }

.messageGPT.bot {
  align-self: flex-start;
  background-color: #a79779;
  color: white; }

.input-container-gpt {
  display: flex;
  border-top: 1px solid #e9ecef;
  padding: 10px; }

.input-gpt {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); }

.button-gpt {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #a79779;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer; }

.button-gpt:hover {
  background-color: #e6be00; }

.icon-container-gpt {
  position: absolute;
  top: 75px;
  right: 193px; }

.info-icon-gpt {
  width: 24px;
  height: 24px;
  cursor: pointer; }

.tooltip-gpt {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap; }

.auth-page {
  background-color: #ffffff;
  height: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: flex-end; }
  .auth-page .image-container {
    width: 50%;
    min-height: 100%;
    background-position: center;
    background-repeat: no-repeat; }
    .auth-page .image-container.image-container-GB {
      background-image: url("/images/GB.png");
      background-size: contain; }
    .auth-page .image-container.image-container-FR {
      background-image: url("/images/FR.png");
      background-size: contain; }
    .auth-page .image-container.image-container-US {
      background-image: url("/images/US.png");
      background-size: contain; }
    .auth-page .image-container.image-container-US-Miami {
      background-image: url("/images/Miami.jpg");
      background-size: contain; }
    .auth-page .image-container.image-container-MX {
      background-image: url("/images/MX.png");
      background-size: contain; }
    .auth-page .image-container.image-container-REST {
      background-image: url("/images/login1.png");
      background-size: contain; }
    .auth-page .image-container.image-container-Partner {
      background-image: url("/images/login.png");
      background-size: cover; }
  .auth-page .image-container-new {
    width: 50%;
    min-height: 100%;
    background-position: center;
    background-repeat: no-repeat; }
    .auth-page .image-container-new.image-container-GB {
      background-image: url("/images/GB.png");
      background-size: contain; }
    .auth-page .image-container-new.image-container-FR {
      background-image: url("/images/FR.png");
      background-size: contain; }
    .auth-page .image-container-new.image-container-US {
      background-image: url("/images/US.png");
      background-size: contain; }
    .auth-page .image-container-new.image-container-US-Miami {
      background-image: url("/images/Miami.jpg");
      background-size: contain; }
    .auth-page .image-container-new.image-container-MX {
      background-image: url("/images/MX.png");
      background-size: contain; }
    .auth-page .image-container-new.image-container-REST {
      background-image: url("/images/login1.png");
      background-size: contain; }
  .auth-page .image-container-new-partner {
    width: 50%;
    min-height: 100%;
    background-image: url("/images/login_partner.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .auth-page .image-container-new-partner {
        display: none; } }
  .auth-page .auth-container-partner {
    width: 50%;
    height: 100vh;
    overflow-y: auto; }
  .auth-page .auth-container {
    width: 50%; }
  .auth-page .auth-container-100 {
    width: 100%; }
  .auth-page .login-img img {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .auth-page .scrollable-page-content {
    height: 100vh;
    overflow: auto; }
  .auth-page .scrollable-page-content.light-theme {
    height: calc(100vh - 60px);
    width: 100%; }
  .auth-page .scrollable-page-content::-webkit-scrollbar {
    width: 0px;
    height: 5px; }
  .auth-page .scrollable-page-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent; }
  .auth-page .scrollable-page-content::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px; }
  .auth-page .scrollable-page-content-without-supportbar {
    height: 100vh !important;
    width: 100%; }
  .auth-page .auth-wrap {
    width: 400px;
    max-width: 400px;
    margin: 0 auto; }
    .auth-page .auth-wrap a {
      color: #a79779; }
    .auth-page .auth-wrap .rolzo-header {
      width: 100%;
      overflow: auto; }
      .auth-page .auth-wrap .rolzo-header:first-child {
        margin-top: 150px; }
      .auth-page .auth-wrap .rolzo-header img {
        max-width: 160px; }
    .auth-page .auth-wrap .btn.login-btn {
      width: 100%;
      font-size: 15px;
      margin-top: 14px; }
  .auth-page form {
    width: 100%;
    margin: 30px 0px 16px 0; }
    .auth-page form .field-wrap:not(:last-child) {
      margin-bottom: 20px; }
  .auth-page .rolzo-footer {
    width: 100%;
    overflow: hidden;
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    text-align: center;
    background: #fbfbfb; }

.client-register-continue {
  width: 100%;
  max-width: 270px;
  margin-left: 9%; }

.marginRight-10 {
  margin-right: 10px !important; }

.continue-right {
  float: right; }

.auth-page .phone-field-row .col-md-3 {
  flex: 0 0 35%;
  max-width: 35%; }

.auth-page .phone-field-row .col-md-9 {
  flex: 0 0 65%;
  max-width: 65%; }

.sign-up-form {
  padding: 25px 0 25px 0 !important; }

.sign-up-form-no-bottom {
  padding: 25px 0 0px 0 !important; }

.signUp-radio-wrapper {
  display: flex;
  justify-content: space-between; }

.sign-up-radio {
  flex: 1;
  text-align: center;
  border: 1px solid #8b959e;
  border-radius: 2px;
  padding: 1rem 0.5rem 1rem 0.5rem; }

.sign-up-radio:first-child {
  margin-right: 4px; }

.sign-up-radio:last-child {
  margin-left: 4px; }

.custom-radio-signUp {
  margin-bottom: 0px !important; }
  .custom-radio-signUp .custom-control-label {
    padding-top: 0.15rem; }

@media (max-width: 768px) {
  .custom-radio-signUp {
    margin-bottom: 0px !important; }
    .custom-radio-signUp .custom-control-label {
      padding-top: 0.15rem;
      font-size: 15px !important; } }

.auth-page .page-title {
  background: #fafafa;
  width: 100%; }
  .auth-page .page-title h1 {
    padding: 70px 48px;
    font-size: 34px;
    align-items: center; }
    .auth-page .page-title h1 img {
      width: 60px; }

.auth-page input {
  background-image: none !important; }

.auth-page .form-block {
  margin: 0 50px;
  padding: 50px 0; }

.register-footer {
  padding: 0 50px 50px; }

.partner-auth {
  font-size: 34px !important;
  width: 100%;
  padding-top: 0.25rem !important;
  padding-left: 6rem !important; }
  .partner-auth h1 {
    padding: 70px 48px;
    font-size: 34px;
    align-items: center; }
    .partner-auth h1 img {
      width: 60px; }

.partner-sub-header {
  font-weight: lighter;
  white-space: pre-wrap;
  line-height: 32px;
  margin-left: 2.1rem !important; }

.sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  font-weight: 200;
  background: #0c0c0c;
  color: #ebebeb;
  background-size: cover;
  background-position: center center;
  -webkit-transition: width 0.28s;
  transition: width 0.28s; }

.sidebar-container-partner {
  width: calc(100vw - 85%);
  min-width: 13rem;
  max-width: 15rem; }
  .sidebar-container-partner .sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .sidebar-container-partner .nav-link {
    font-size: 14px;
    font-weight: 400;
    color: #8b959e;
    padding: 0;
    margin-bottom: 19px;
    display: flex;
    align-items: center; }
    .sidebar-container-partner .nav-link .icon {
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-right: 10px;
      display: block; }
      .sidebar-container-partner .nav-link .icon-active {
        display: none; }
    .sidebar-container-partner .nav-link:hover, .sidebar-container-partner .nav-link.active {
      color: #ffffff;
      font-weight: 500; }
      .sidebar-container-partner .nav-link:hover .icon, .sidebar-container-partner .nav-link.active .icon {
        display: none; }
        .sidebar-container-partner .nav-link:hover .icon-active, .sidebar-container-partner .nav-link.active .icon-active {
          display: block; }
    .sidebar-container-partner .nav-link span {
      padding-top: 15px;
      padding-left: 5px; }
  .sidebar-container-partner.sidebar-white {
    background: #ffffff;
    color: #0c0c0c; }
    .sidebar-container-partner.sidebar-white .nav-link {
      color: #8b959e;
      font-weight: 600;
      font-size: 16px; }
      .sidebar-container-partner.sidebar-white .nav-link:hover, .sidebar-container-partner.sidebar-white .nav-link.active {
        color: #0c0c0c;
        font-weight: 600; }
  .sidebar-container-partner .partner-client-logo-wrap {
    position: absolute;
    bottom: 0;
    padding-bottom: 4rem;
    padding-left: 3rem; }
    .sidebar-container-partner .partner-client-logo-wrap img {
      width: 110px; }

.page-wrap-partner {
  width: calc(100% - 10rem) !important; }
  @media (min-width: 768px) {
    .page-wrap-partner .page-with-panel {
      width: calc(100% - 380px); } }
  .page-wrap-partner .page-with-panel main {
    padding-right: 20px !important; }
  .page-wrap-partner .scrollable-page-content {
    height: 100vh;
    overflow: auto; }
  .page-wrap-partner .scrollable-page-content.light-theme {
    height: calc(100vh - 60px); }
  .page-wrap-partner header {
    background-color: #e6e6e6;
    padding: 40px 40px 40px 40px;
    position: relative;
    margin: 15px 17px 0; }
    .page-wrap-partner header .partner-page-title {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 0;
      height: 40px; }
    @media (max-width: 991.98px) {
      .page-wrap-partner header {
        padding: 30px;
        padding-left: 30px;
        margin: 0; } }
  .page-wrap-partner main {
    padding: 75px 10% 60px 10%; }
  .page-wrap-partner .grey-theme {
    min-height: 100vh;
    background-color: rgba(139, 149, 158, 0.1); }
    .page-wrap-partner .grey-theme header {
      background-color: transparent;
      font-size: 40px;
      padding-bottom: 0;
      padding-left: 60px;
      margin-left: 0px; }
      @media (max-width: 1199.98px) {
        .page-wrap-partner .grey-theme header {
          padding-left: 40px; } }
      @media (max-width: 991.98px) {
        .page-wrap-partner .grey-theme header {
          padding-left: 30px; } }
    .page-wrap-partner .grey-theme main {
      padding-top: 15px;
      padding-left: 60px; }
      .page-wrap-partner .grey-theme main.hide-sidebar.no-left-padding {
        padding-left: 40px; }
      @media (min-width: 765px) and (max-width: 1330px) {
        .page-wrap-partner .grey-theme main.hide-sidebar {
          padding-left: 11vw; } }
      @media (max-width: 1199.98px) {
        .page-wrap-partner .grey-theme main {
          padding-left: 40px; } }
      @media (max-width: 991.98px) {
        .page-wrap-partner .grey-theme main {
          padding-left: 30px; } }
    .page-wrap-partner .grey-theme.nested-layout.reset-password-layout h4 {
      white-space: pre-wrap;
      line-height: 32px; }
    .page-wrap-partner .grey-theme.nested-layout.reset-password-layout p.confirm-message {
      font-size: 20px; }
    .page-wrap-partner .grey-theme.nested-layout.reset-password-layout header {
      padding: 95px 90px 100px 260px; }
      .page-wrap-partner .grey-theme.nested-layout.reset-password-layout header .back-btn {
        top: calc(50% - 38px); }
    .page-wrap-partner .grey-theme.nested-layout.reset-password-layout .success-msg-verification {
      height: calc(100vh - 140px);
      color: rgba(139, 149, 158, 0.1);
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      top: 0;
      left: 0;
      background: rgba(139, 149, 158, 0.1);
      overflow-y: auto; }
      .page-wrap-partner .grey-theme.nested-layout.reset-password-layout .success-msg-verification img {
        width: 80px;
        height: 80px;
        margin-bottom: 15px; }
      .page-wrap-partner .grey-theme.nested-layout.reset-password-layout .success-msg-verification .success-msg-header {
        font-size: 16px;
        font-weight: 400; }
      .page-wrap-partner .grey-theme.nested-layout.reset-password-layout .success-msg-verification .success-msg-description {
        font-size: 16px;
        font-weight: 400; }
      .page-wrap-partner .grey-theme.nested-layout.reset-password-layout .success-msg-verification .content-wrap {
        display: flex;
        max-width: 500px;
        flex-direction: column;
        justify-content: center; }

.page-wrap-dark {
  width: calc(100% - 100px); }

.page-wrap-light {
  width: calc(100% - 100px); }

@media (min-width: 768px) {
  .pagewrap-with-menu {
    width: calc(100% - 280px) !important; } }

.page-wrap,
.page-wrap-partner {
  position: relative;
  float: right; }
  .page-wrap > .content,
  .page-wrap-partner > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px); }
  .page-wrap > .footer,
  .page-wrap-partner > .footer {
    border-top: 1px solid #e7e7e7; }
  .page-wrap .navbar,
  .page-wrap-partner .navbar {
    margin-bottom: 0;
    padding: 0; }
    .page-wrap .navbar .navbar-brand,
    .page-wrap-partner .navbar .navbar-brand {
      padding: 0;
      margin: 0;
      display: block;
      width: 100%; }
  .page-wrap .navbar-partner,
  .page-wrap-partner .navbar-partner {
    margin-bottom: 0;
    padding: 0; }
    .page-wrap .navbar-partner .navbar-brand,
    .page-wrap-partner .navbar-partner .navbar-brand {
      padding: 0;
      margin: 0;
      display: block;
      width: 100%; }

.sidebar-container,
.page-wrap {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch; }

.sidebar-minimized {
  width: 115px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s; }
  .sidebar-minimized .client-info .company-logo-text {
    margin-left: -20px; }

.sidebar-minimized-admin {
  width: 100px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s; }
  .sidebar-minimized-admin .client-info .company-logo-text {
    margin-left: -20px; }

.sidebar-expanded {
  width: 280px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s; }

.sidebar-expanded-admin {
  width: 320px;
  -webkit-transition: width 0.28s;
  transition: width 0.28s; }

.admin-sideBar-icons {
  margin-right: 10px;
  margin-top: 1.5rem; }

.admin-sideBar-icons-withIndecator {
  margin-right: 10px;
  margin-top: 1.5rem; }

.admin-sideBar-group {
  margin-top: 1.5rem; }

.admin-notes-border {
  border-color: rgba(139, 149, 158, 0.25) !important; }

.nav-link-header {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff !important;
  display: flex;
  justify-content: space-between; }
  .nav-link-header:hover {
    cursor: pointer; }

.operationsTab {
  background: url("/images/icons/icon-operations.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.networkTab {
  background: url("/images/icons/icon-network.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.fleetTab {
  background: url("/images/icons/icon-fleet.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.ratesTab {
  background: url("/images/icons/icon-rates.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.financeTab {
  background: url("/images/icons/icon-finance.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.accountTab {
  background: url("/images/icons/icon-account.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.automationTab {
  background: url("/images/icons/icon-automation.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.payoutTab {
  background: url("/images/icons/icon-payout.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.analyticsTab {
  background: url("/images/icons/analytics.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.rotateArrow {
  transform: rotate(90deg); }

.rolzoLocationsTab {
  background: url("/images/icons/icon-locations.png");
  background-position: top 6px left 0px;
  background-size: 21px 21px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 2.1rem !important;
  font-weight: 300;
  margin-bottom: 4px; }

.app-content {
  position: relative;
  top: 0;
  height: 100vh; }
  .app-content .sidebar-container {
    overflow: hidden;
    z-index: 2; }
    .app-content .sidebar-container .sidebar-content {
      height: 100%;
      width: fit-content;
      padding: 20px 60px 20px 75px;
      overflow-x: hidden;
      overflow-y: scroll; }
    .app-content .sidebar-container .sidebar-content::-webkit-scrollbar {
      display: none; }
    .app-content .sidebar-container .sidebar-content-minimized {
      padding: 20px 20px 20px 45px !important; }
    .app-content .sidebar-container .sidebar-content-minimized-new {
      padding: 20px 20px 20px 35px !important; }
    .app-content .sidebar-container .logo-wrap {
      display: block;
      text-align: center;
      margin: 0 auto 110px;
      padding-right: 25px; }
      .app-content .sidebar-container .logo-wrap img {
        width: 100%;
        height: auto; }
    .app-content .sidebar-container .client-logo-wrap {
      display: block;
      text-align: center;
      padding-right: 25px; }
      .app-content .sidebar-container .client-logo-wrap img {
        width: 140px; }
    .app-content .sidebar-container .partner-client-logo-wrap {
      display: block;
      text-align: center;
      padding-right: 100px;
      padding-top: 40px; }
      .app-content .sidebar-container .partner-client-logo-wrap img {
        width: 110px; }
    .app-content .sidebar-container .client-logo-wrap-minimized {
      opacity: 0; }
      .app-content .sidebar-container .client-logo-wrap-minimized img {
        margin-left: -36px; }
    .app-content .sidebar-container .client-logo-wrap-expanded {
      opacity: 0; }
      .app-content .sidebar-container .client-logo-wrap-expanded img {
        margin-left: -6px; }
    .app-content .sidebar-container .rolzo-logo-wrap-minimized {
      opacity: 0; }
    .app-content .sidebar-container .client-info {
      padding-top: 50px;
      margin-bottom: 35px;
      font-weight: 500;
      font-size: 16px; }
      .app-content .sidebar-container .client-info .company-logo {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #8b959e;
        color: #ffffff;
        opacity: 0.5;
        text-transform: uppercase;
        padding: 4px 3px 0 3px; }
        .app-content .sidebar-container .client-info .company-logo.isCompanyLogoPage {
          padding: 0 !important; }
        .app-content .sidebar-container .client-info .company-logo.has-logo {
          background-color: #fafafa;
          opacity: 1; }
        .app-content .sidebar-container .client-info .company-logo img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid rgba(139, 149, 158, 0.25);
          margin-top: 1em; }
      .app-content .sidebar-container .client-info .company-logo-minimized {
        margin-left: -20px;
        transition: all 0.28s ease-in-out;
        width: 60px;
        height: 60px; }
        .app-content .sidebar-container .client-info .company-logo-minimized img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-top: -1em; }
      .app-content .sidebar-container .client-info .company-logo-expanded {
        transition: all 0.28s ease-in-out; }
        .app-content .sidebar-container .client-info .company-logo-expanded img {
          margin-right: -1em;
          margin-top: -1em; }
      .app-content .sidebar-container .client-info .user-name {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 2px;
        white-space: initial;
        padding-top: 5px; }
      .app-content .sidebar-container .client-info .company-name {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        white-space: initial;
        padding-top: 0 !important; }
      .app-content .sidebar-container .client-info .size-zero {
        font-size: 0px; }
    .app-content .sidebar-container .nav-link {
      font-size: 14px;
      font-weight: 400;
      color: #8b959e;
      padding: 0;
      margin-bottom: 19px;
      display: flex;
      align-items: center; }
      .app-content .sidebar-container .nav-link .icon {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 10px;
        display: block; }
        .app-content .sidebar-container .nav-link .icon-active {
          display: none; }
      .app-content .sidebar-container .nav-link:hover, .app-content .sidebar-container .nav-link.active {
        color: #ffffff;
        font-weight: 500; }
        .app-content .sidebar-container .nav-link:hover .icon, .app-content .sidebar-container .nav-link.active .icon {
          display: none; }
          .app-content .sidebar-container .nav-link:hover .icon-active, .app-content .sidebar-container .nav-link.active .icon-active {
            display: block; }
      .app-content .sidebar-container .nav-link span {
        padding-top: 10px;
        padding-left: 5px; }
    .app-content .sidebar-container.sidebar-light {
      background: #fafafa;
      color: #0c0c0c; }
      .app-content .sidebar-container.sidebar-light .nav-link {
        color: #8b959e;
        font-weight: 600;
        font-size: 16px; }
        .app-content .sidebar-container.sidebar-light .nav-link:hover, .app-content .sidebar-container.sidebar-light .nav-link.active {
          color: #0c0c0c;
          font-weight: 600; }
      .app-content .sidebar-container.sidebar-light .business_logo {
        position: fixed;
        bottom: 50px;
        left: 70px;
        pointer-events: none; }
        .app-content .sidebar-container.sidebar-light .business_logo img {
          width: 125px; }
    .app-content .sidebar-container.sidebar-white {
      background: #ffffff;
      color: #0c0c0c; }
      .app-content .sidebar-container.sidebar-white .nav-link {
        color: #8b959e;
        font-weight: 600;
        font-size: 16px; }
        .app-content .sidebar-container.sidebar-white .nav-link:hover, .app-content .sidebar-container.sidebar-white .nav-link.active {
          color: #0c0c0c;
          font-weight: 600; }
      .app-content .sidebar-container.sidebar-white .business_logo {
        position: fixed;
        bottom: 50px;
        left: 70px;
        pointer-events: none; }
        .app-content .sidebar-container.sidebar-white .business_logo img {
          width: 125px; }
  @media (min-width: 768px) {
    .app-content .page-wrap .page-with-panel {
      width: calc(100% - 380px); } }
  .app-content .page-wrap .page-with-panel main {
    padding-right: 20px !important; }
  .app-content .page-wrap .scrollable-page-content {
    height: 100vh;
    overflow: auto; }
    .app-content .page-wrap .scrollable-page-content.no-title {
      align-items: flex-start; }
    .app-content .page-wrap .scrollable-page-content.booking-detail-layout {
      overflow: hidden; }
      .app-content .page-wrap .scrollable-page-content.booking-detail-layout main {
        padding-right: 40px; }
      .app-content .page-wrap .scrollable-page-content.booking-detail-layout .card-body .vehicle-title {
        max-width: 160px; }
        @media (max-width: 1180px) {
          .app-content .page-wrap .scrollable-page-content.booking-detail-layout .card-body .vehicle-title {
            max-width: 100px; } }
        @media (max-width: 1070px) {
          .app-content .page-wrap .scrollable-page-content.booking-detail-layout .card-body .vehicle-title {
            max-width: 80px; } }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout main {
      padding-left: 40px;
      padding-right: 40px; }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .input-message-block {
      background: white;
      border-top: 1px solid rgba(139, 149, 158, 0.3); }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .preview-files-block {
      margin-top: 10px !important;
      padding-left: 60px !important;
      padding-right: 90px !important; }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .file-preview .form-group {
      margin-bottom: 0; }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .input-message-block .input-message-area .input-message-wrap textarea {
      padding-left: 50px !important; }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .chat-paperclip .custom-file-label {
      padding-left: 25px; }
    .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .card-body .vehicle-title {
      max-width: 160px; }
      @media (max-width: 1180px) {
        .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .card-body .vehicle-title {
          max-width: 100px; } }
      @media (max-width: 1070px) {
        .app-content .page-wrap .scrollable-page-content.system-booking-detail-layout .card-body .vehicle-title {
          max-width: 80px; } }
  .app-content .page-wrap .scrollable-page-content.light-theme {
    height: calc(100vh - 60px); }
  .app-content .page-wrap .scrollable-page-content.lightThemeHeight {
    height: calc(100vh - 10px); }
  .app-content .page-wrap .scrollable-page-content::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .app-content .page-wrap .scrollable-page-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent; }
  .app-content .page-wrap .scrollable-page-content::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px; }
  .app-content .page-wrap .scrollable-page-content-without-supportbar {
    height: 100vh !important; }
  .app-content .page-wrap header {
    background-color: #e6e6e6;
    padding: 40px;
    position: relative;
    margin: 15px 17px 0; }
    .app-content .page-wrap header.no-title-header {
      padding: 0 !important;
      background-color: transparent !important; }
      .app-content .page-wrap header.no-title-header.support-admin {
        padding: 4px 10px 4px 20px !important;
        border-right: 1px solid rgba(12, 12, 12, 0.05);
        width: 431px; }
        @media (max-width: 767.98px) {
          .app-content .page-wrap header.no-title-header.support-admin {
            border-right: none;
            width: fit-content; } }
      .app-content .page-wrap header.no-title-header .no-title-back-btn-wrap {
        padding: 0; }
        .app-content .page-wrap header.no-title-header .no-title-back-btn-wrap.only-btn {
          padding: 0 !important;
          height: 120px; }
      @media (max-width: 765px) {
        .app-content .page-wrap header.no-title-header {
          width: 100px; }
          .app-content .page-wrap header.no-title-header a {
            position: static;
            padding-left: 30px; } }
    .app-content .page-wrap header .parent-title {
      color: #8b959e;
      font-size: 11px;
      margin-bottom: 55px;
      text-transform: uppercase; }
    .app-content .page-wrap header .page-title {
      font-size: 34px;
      font-weight: 400;
      margin-bottom: 0;
      height: 40px; }
    .app-content .page-wrap header .partner-page-title {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 0;
      height: 40px; }
    .app-content .page-wrap header .statementOfAccount {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 0;
      height: 40px; }
    .app-content .page-wrap header .company-info-title {
      float: right;
      margin-top: 15px; }
      .app-content .page-wrap header .company-info-title h1 {
        margin-bottom: 1rem; }
      .app-content .page-wrap header .company-info-title h4 {
        text-align: end; }
    .app-content .page-wrap header .company-info-title-admin {
      display: flex;
      justify-content: flex-end; }
      .app-content .page-wrap header .company-info-title-admin h1 {
        bottom: 90px;
        position: absolute;
        font-size: 32px;
        margin-bottom: 1rem; }
      .app-content .page-wrap header .company-info-title-admin h4 {
        bottom: 60px;
        position: absolute; }
    .app-content .page-wrap header .company-info-title-bookings {
      display: flex;
      justify-content: flex-end; }
      .app-content .page-wrap header .company-info-title-bookings h1 {
        margin-bottom: 1rem; }
      .app-content .page-wrap header .company-info-title-bookings h4 {
        text-align: end; }
    .app-content .page-wrap header .add-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 30px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .app-content .page-wrap header .add-btn img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
      .app-content .page-wrap header .add-btn:hover {
        opacity: 0.9; }
    .app-content .page-wrap header .clone-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 45px;
      right: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .app-content .page-wrap header .clone-btn img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
      .app-content .page-wrap header .clone-btn:hover {
        opacity: 0.9; }
    .app-content .page-wrap header .account-header .layout-btn {
      width: fit-content;
      height: fit-content; }
    .app-content .page-wrap header .account-header .back-btn img {
      width: fit-content; }
    .app-content .page-wrap header .layout-btn {
      width: 60px;
      height: 60px;
      position: absolute;
      z-index: 10; }
      .app-content .page-wrap header .layout-btn img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
      .app-content .page-wrap header .layout-btn:hover {
        opacity: 0.8; }
    .app-content .page-wrap header .close-btn {
      top: 45px;
      right: 25px; }
    .app-content .page-wrap header .back-btn {
      top: calc(50% - 30px);
      left: 90px; }
    .app-content .page-wrap header .light-close-btn {
      top: calc(50% - 30px);
      right: 70px; }
    @media (max-width: 991.98px) {
      .app-content .page-wrap header {
        padding: 30px;
        padding-left: 30px;
        margin: 0; } }
  .app-content .page-wrap main {
    padding: 75px 10% 60px 10%; }
  .app-content .page-wrap .isAdminMain {
    padding: 34px 20px 22px 20px !important; }
    .app-content .page-wrap .isAdminMain.isAddClient {
      padding: 34px 20px 120px 20px !important; }
    .app-content .page-wrap .isAdminMain.p-0 {
      padding: 0 !important; }
  .app-content .page-wrap .isAdminPayout {
    padding: 2% 1.5% 20px 0 !important; }
  .app-content .page-wrap .m-17-20 {
    margin: 17px 20px; }
  .app-content .page-wrap .isRatesGrid {
    padding: 0 1.5% 20px 0 !important; }
  .app-content .page-wrap .nested-layout header {
    margin: 20px 17px 0 0;
    padding: 35px 90px 40px 140px; }
    .app-content .page-wrap .nested-layout header .page-title {
      display: flex;
      align-items: center; }
      .app-content .page-wrap .nested-layout header .page-title .layout-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        min-width: 80px;
        background: #8b959e;
        opacity: 0.5;
        border-radius: 50%;
        margin-right: 60px;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-size: 33px; }
      .app-content .page-wrap .nested-layout header .page-title img.layout-logo {
        object-fit: cover;
        opacity: 1; }
    .app-content .page-wrap .nested-layout header .service-label {
      position: absolute;
      bottom: 25px; }
  .app-content .page-wrap .light-theme {
    background-color: #ffffff; }
    @media (min-width: 992px) {
      .app-content .page-wrap .light-theme.reset-password-layout header {
        max-width: 806px !important; } }
    .app-content .page-wrap .light-theme header {
      background-color: #ffffff;
      font-size: 40px !important;
      padding-bottom: 0;
      padding-left: 60px;
      margin-left: 0px; }
      @media (max-width: 1199.98px) {
        .app-content .page-wrap .light-theme header {
          padding-left: 40px; } }
      @media (max-width: 991.98px) {
        .app-content .page-wrap .light-theme header {
          padding-left: 30px; } }
    .app-content .page-wrap .light-theme main {
      padding-top: 34px;
      padding-left: 60px; }
      .app-content .page-wrap .light-theme main.home-page {
        padding-inline: 60px;
        max-width: 1060px; }
        @media (max-width: 1199.98px) {
          .app-content .page-wrap .light-theme main.home-page {
            padding-inline: 40px; } }
      .app-content .page-wrap .light-theme main.hide-sidebar.no-left-padding {
        padding-left: 40px; }
      @media (min-width: 765px) and (max-width: 1330px) {
        .app-content .page-wrap .light-theme main.hide-sidebar {
          padding-left: 11vw; } }
      @media (max-width: 1199.98px) {
        .app-content .page-wrap .light-theme main {
          padding-left: 40px; } }
      @media (max-width: 991.98px) {
        .app-content .page-wrap .light-theme main {
          padding-left: 30px; } }
    .app-content .page-wrap .light-theme .hide-booking-title {
      transform: translateY(100px);
      opacity: 0;
      transition: transform 0.7s ease-in-out, opacity 0.3s ease-in-out;
      /* Delay opacity */ }
    .app-content .page-wrap .light-theme .show-booking-title {
      transform: translateY(0);
      opacity: 1;
      transition: transform 1s ease-in-out, opacity 1s ease-in-out 0.5s; }
    .app-content .page-wrap .light-theme.nested-layout.reset-password-layout h4 {
      white-space: pre-wrap;
      line-height: 32px; }
    .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .confirm-message-container {
      margin-top: 50px; }
      @media (min-width: 992px) {
        .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .confirm-message-container {
          max-width: 806px; } }
    .app-content .page-wrap .light-theme.nested-layout.reset-password-layout p.confirm-message {
      font-size: 20px; }
    .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .navbar {
      background-color: #fafafa;
      padding: 70px 0; }
      @media (max-width: 575.98px) {
        .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .navbar {
          padding: 10px 0 12px;
          background-color: white;
          border-bottom: 1px solid rgba(139, 149, 158, 0.3); } }
    .app-content .page-wrap .light-theme.nested-layout.reset-password-layout header {
      margin: 0;
      padding: 0;
      background-color: inherit !important; }
      @media (max-width: 575.98px) {
        .app-content .page-wrap .light-theme.nested-layout.reset-password-layout header {
          padding-inline: 15px; } }
      .app-content .page-wrap .light-theme.nested-layout.reset-password-layout header .light-close-btn {
        width: 60px;
        height: 60px; }
    .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .success-msg-verification {
      height: calc(100vh - 140px);
      color: #ffffff;
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      top: 0;
      left: 0;
      background: #ffffff;
      overflow-y: auto; }
      .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .success-msg-verification img {
        width: 80px;
        height: 80px;
        margin-bottom: 15px; }
      .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .success-msg-verification .success-msg-header {
        font-size: 16px;
        font-weight: 400; }
      .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .success-msg-verification .success-msg-description {
        font-size: 16px;
        font-weight: 400; }
      .app-content .page-wrap .light-theme.nested-layout.reset-password-layout .success-msg-verification .content-wrap {
        display: flex;
        max-width: 500px;
        flex-direction: column;
        justify-content: center; }
  .app-content .page-wrap .grey-theme {
    min-height: calc(100vh - 4rem);
    background-color: rgba(139, 149, 158, 0.1); }
    .app-content .page-wrap .grey-theme header {
      background-color: transparent;
      font-size: 40px;
      padding-bottom: 0;
      padding-left: 60px;
      margin-left: 0px; }
      @media (min-width: 992px) {
        .app-content .page-wrap .grey-theme header {
          max-width: 806px; } }
      @media (max-width: 1199.98px) {
        .app-content .page-wrap .grey-theme header {
          padding-left: 40px; } }
      @media (max-width: 991.98px) {
        .app-content .page-wrap .grey-theme header {
          padding-left: 30px; } }
    .app-content .page-wrap .grey-theme main {
      padding-top: 34px;
      padding-left: 60px !important; }
      .app-content .page-wrap .grey-theme main.hide-sidebar.no-left-padding {
        padding-left: 40px; }
      @media (min-width: 765px) and (max-width: 1330px) {
        .app-content .page-wrap .grey-theme main.hide-sidebar {
          padding-left: 11vw; } }
      @media (max-width: 1199.98px) {
        .app-content .page-wrap .grey-theme main {
          padding-left: 40px; } }
      @media (max-width: 991.98px) {
        .app-content .page-wrap .grey-theme main {
          padding-left: 30px; } }
    .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout h4 {
      white-space: pre-wrap;
      line-height: 32px; }
    .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .confirm-message-container {
      margin-top: 50px; }
      @media (min-width: 992px) {
        .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .confirm-message-container {
          max-width: 806px; } }
    .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout p.confirm-message {
      font-size: 20px; }
    .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .navbar {
      background-color: #fafafa;
      padding: 70px 0; }
    .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout header {
      margin: 0;
      padding: 0 !important;
      background-color: inherit !important; }
    .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .success-msg-verification {
      height: calc(100vh - 140px);
      color: rgba(139, 149, 158, 0.1);
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      top: 0;
      left: 0;
      background: rgba(139, 149, 158, 0.1);
      overflow-y: auto; }
      .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .success-msg-verification img {
        width: 80px;
        height: 80px;
        margin-bottom: 15px; }
      .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .success-msg-verification .success-msg-header {
        font-size: 16px;
        font-weight: 400; }
      .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .success-msg-verification .success-msg-description {
        font-size: 16px;
        font-weight: 400; }
      .app-content .page-wrap .grey-theme.nested-layout.reset-password-layout .success-msg-verification .content-wrap {
        display: flex;
        max-width: 500px;
        flex-direction: column;
        justify-content: center; }
  .app-content .page-wrap.w-100 main {
    padding-left: 260px; }
  .app-content .remove-overflow {
    overflow: hidden !important; }
  .app-content .supportbar-wrap {
    height: 60px;
    width: 100%;
    background: white;
    border-bottom: 1px solid rgba(139, 149, 158, 0.3);
    display: flex;
    z-index: 9; }
    .app-content .supportbar-wrap .vehicle-title {
      max-width: max-content; }
  .app-content .supportbar-gap {
    display: flex;
    padding-left: 50px;
    width: calc(100% - 100px); }
    @media (max-width: 1199.98px) {
      .app-content .supportbar-gap {
        padding-left: 30px; } }
    @media (max-width: 991.98px) {
      .app-content .supportbar-gap {
        padding-left: 20px; } }
  .app-content .supportbar-btn-wrap {
    margin: 20px 15px;
    cursor: pointer; }
  .app-content .supportbar-back-btn-wrap {
    margin: 20px 10px;
    cursor: pointer; }
  .app-content .supportbar-btn-splitter {
    width: 1px;
    margin: 14px 0 12px 0;
    border-left: 1px solid rgba(139, 149, 158, 0.3); }
  .app-content .supportbar-img {
    width: 20px;
    height: 20px; }
  .app-content .supportbar-back-img {
    width: 18px;
    height: 15px; }
  .app-content .supportbar-back-text {
    font-size: 14px;
    color: #8b959e;
    font-weight: bold;
    margin-left: 9px; }
  .app-content .supportdetails-img {
    height: 20px;
    margin-bottom: 10px; }
  .app-content .event-supportbar-img {
    width: 18px;
    height: 15px; }
  .app-content .event-supportbar-company {
    padding-top: 0.1em;
    margin: 20px 12px;
    width: fit-content;
    white-space: nowrap; }
  .app-content .nav-link-img {
    width: 20px;
    margin-right: 10px; }
  .app-content .nav-link-img-partner {
    width: 18px;
    margin-right: 10px;
    margin-top: 12px; }
  .app-content .nav-link-img-partner-home {
    width: 17px;
    margin-right: 10px;
    margin-top: 11px; }
  .app-content .partner-tabs {
    display: flex;
    align-items: baseline; }
  .app-content .partner-tab {
    font-size: 12px;
    padding: 5px;
    width: 125px;
    text-align: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .app-content .tab-active {
    background-color: black;
    color: white;
    border: 1px solid black; }
  .app-content .tab-inactive {
    background-color: white;
    color: #8b959e;
    border: 1px solid rgba(139, 149, 158, 0.5); }
  .app-content .supportbar-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -23px;
    right: -11px; }
  .app-content .missing-com-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: #f5a623;
    color: #f5a623;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    bottom: 25px;
    left: 11px; }
  .app-content .missing-earning-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: #f5a623;
    color: #f5a623;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    bottom: 22px;
    left: 9px; }
  .app-content .admin-support-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -28px;
    right: -18px; }
  .app-content .admin-bookings-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -26px;
    right: -16px; }
  .app-content .admin-alerts-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -28px;
    right: -12px; }
  .app-content .admin-without-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: transparent;
    color: transparent;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -28px;
    right: -12px; }

.company-sm-logo {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8b959e;
  color: #ffffff;
  opacity: 0.5;
  text-transform: uppercase;
  padding: 4px 3px 0 3px; }

#logo-tooltip {
  top: 100px !important;
  left: 30px !important; }

#logo-tooltip > .tooltip-inner {
  background-color: rgba(139, 149, 158, 0.1);
  color: #000000;
  padding: 15px;
  padding-left: 23px;
  max-width: 190px;
  text-align: left; }

#logo-tooltip .arrow:before {
  display: none !important; }

#logo-tooltip > .tooltip-arrow {
  display: none !important; }

.text-dark {
  color: #636d77 !important; }

.text-brown {
  color: #a79779; }

.text-incl-grey {
  margin-top: 2px;
  color: #8b959e !important; }

.text-incl-light-green {
  margin-top: 2px;
  color: #47dc95 !important; }

.text-incl-grey-2 {
  margin-top: 2px;
  color: #8b959e !important;
  font-size: 0.75rem !important; }

.text-incl-green {
  margin-top: 2px;
  color: #47dc95 !important;
  font-size: 0.75rem !important; }

.text-brown-sub {
  color: #8b959e;
  font-size: 12px !important;
  display: block !important; }

.text-grey-sub {
  color: #8b959e;
  font-size: 12px !important;
  display: block; }

.text-green-sub {
  color: #47dc95;
  font-size: 12px !important; }

.text-green {
  color: #417505 !important; }

.text-brown-sub-left {
  color: #8b959e;
  float: left;
  font-size: 12px !important;
  display: block !important; }

.text-brown-sub-small {
  color: #8b959e;
  font-size: 13px !important;
  display: block !important; }

.text-grey-sub-small {
  color: #8b959e;
  font-size: 0.76rem !important;
  display: block !important; }

.text-green-sub-small {
  color: #47dc95;
  font-size: 0.76rem !important;
  display: block !important; }

.btn-booking-download {
  font-size: 14px; }

.booking-preview-add-payment {
  font-size: 14px; }

.booking-select-card {
  margin-left: 1.5rem;
  margin-bottom: 1rem; }
  .booking-select-card.another-card {
    margin-left: 10px;
    margin-top: 8px; }
  @media (max-width: 768px) {
    .booking-select-card {
      margin-bottom: 0.5rem; } }

.payment-options {
  display: flex;
  align-items: center;
  z-index: 0; }
  .payment-options .custom-control-label {
    color: #0c0c0c;
    font-size: 16px !important; }
  @media (max-width: 768px) {
    .payment-options .custom-control-label {
      color: #0c0c0c;
      font-size: 16px !important; } }

.notification {
  background-color: #fff !important;
  box-shadow: unset !important;
  border: 1px solid #dbdee1;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding: 15px 10px !important; }
  .notification .notification-message {
    color: #000000;
    font-weight: 400;
    font-size: 14px; }
  .notification .notification-dismiss {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.8) !important;
    top: 12px !important;
    font-weight: 100 !important; }

.notification-error .notification-title {
  color: #d0021b !important;
  font-weight: 500 !important;
  font-size: 14px; }

.notification-success .notification-title {
  color: #417505 !important;
  font-weight: 500 !important;
  font-size: 14px; }

.spinner_wrapper {
  position: absolute;
  left: 50%;
  top: 42px;
  margin-left: -17px;
  background-color: #fff;
  border: 1px rgba(139, 149, 158, 0.25) solid;
  border-radius: 50%;
  z-index: 5; }

.main_spinner {
  display: block;
  height: 18px;
  width: 18px;
  border: 2px transparent solid;
  border-top: 2px rgba(139, 149, 158, 0.5) solid;
  border-right: 2px rgba(139, 149, 158, 0.5) solid;
  border-bottom: 2px rgba(139, 149, 158, 0.5) solid;
  border-radius: 50%;
  animation: spin_main 1s 0.5s infinite linear;
  margin: 8px; }

.align-center {
  align-self: center; }

@-webkit-keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.payment-method-page .payment-radio-group .custom-control-label:after {
  top: 50%;
  transform: translateY(-50%); }

.custom-datepicker-calendar .react-datepicker__navigation--previous {
  left: 45px; }

.custom-datepicker-calendar .react-datepicker__navigation--next {
  right: 45px; }

.last-driver-section .custom-file-label .file-name {
  margin-top: 2px; }

.app-content-partner {
  display: flex;
  overflow-x: hidden; }

.warning-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #f5a623;
  border-radius: 2px;
  background: #fff;
  margin-bottom: 1.7rem;
  max-width: 92%; }
  .warning-container .warning-text {
    display: flex;
    margin-left: 10px;
    color: #8b959e;
    font-size: 14px !important;
    margin-right: 20px; }
  .warning-container .pdf-icon {
    margin-right: 10px;
    height: 1.2em; }
  .warning-container span {
    max-width: 75%; }
  @media (max-width: 992px) {
    .warning-container .warning-text {
      margin-right: 15px; } }
  @media (max-width: 768px) {
    .warning-container .warning-text {
      margin-right: 10px; } }

.warning-partner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 0.5em;
  border: 1px solid #f5a623;
  border-radius: 2px;
  background: #fff;
  margin-bottom: 1.7rem;
  max-width: calc(100vw - 80%); }
  .warning-partner-container .warning-text {
    display: flex;
    margin-left: 10px;
    color: #8b959e;
    font-size: 14px !important;
    margin-right: 20px; }
    .warning-partner-container .warning-text span {
      flex-grow: 1; }
  .warning-partner-container .pdf-icon {
    margin-right: 10px;
    height: 1.2em; }
  .warning-partner-container span {
    flex-grow: 1; }
  @media (max-width: 992px) {
    .warning-partner-container .warning-text {
      margin-right: 15px; } }
  @media (max-width: 768px) {
    .warning-partner-container .warning-text {
      margin-right: 10px; } }

.home-calendar-booking-container {
  display: grid;
  gap: 24px;
  grid-template-columns: minmax(343px, 1fr) minmax(343px, 1fr); }
  @media (max-width: 991.98px) {
    .home-calendar-booking-container {
      grid-template-columns: 100%; } }

.home {
  display: flex;
  flex-direction: column;
  gap: 30px; }
  .home .show-booking-modal {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out; }
  .home .hide-booking-modal {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s 0.5s ease-in-out; }
  @media (max-width: 767.98px) {
    .home {
      gap: 0; } }
  .home .warning-container {
    border: none;
    max-width: 100%;
    margin-bottom: 0;
    background-color: #fefaf4;
    padding: 24px; }
    .home .warning-container .warning-text {
      color: #0c0c0c;
      font-size: 15px;
      margin: 0; }
    .home .warning-container .pdf-icon {
      height: 20px;
      width: 20px; }
    @media (max-width: 767.98px) {
      .home .warning-container {
        margin-bottom: 24px; } }
    .home .warning-container .collapsible-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .home .warning-container .collapsible-content span {
        max-width: 100%;
        padding-left: 30px;
        color: #0c0c0c; }
    .home .warning-container .expanded {
      max-height: 500px; }
    .home .warning-container span {
      max-width: 100%; }
      .home .warning-container span.responsive-header {
        font-weight: 500;
        font-size: 15px;
        color: #0c0c0c; }
    .home .warning-container .inline-edit-btn {
      align-self: flex-end;
      margin-left: 30px; }
  .home .api-integration-banner {
    position: relative;
    overflow: hidden;
    z-index: 0;
    padding: 24px;
    padding-right: 30px;
    background: #a797790d;
    font-size: 14px;
    border: 1px solid #a79779;
    border-radius: 2px;
    background-image: url(/images/api_integration_banner_dots.png);
    background-repeat: no-repeat;
    background-position: center right 0; }
    .home .api-integration-banner.hide {
      display: none; }
    .home .api-integration-banner.show {
      display: block; }
    @media (max-width: 767.98px) {
      .home .api-integration-banner {
        margin-bottom: 38px; } }
    .home .api-integration-banner .banner-close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 12px;
      height: 12px; }
    .home .api-integration-banner .header {
      margin-bottom: 5px;
      font-size: 20px;
      color: #0c0c0c; }
    .home .api-integration-banner .text {
      color: #636d77;
      margin-bottom: 0; }
    .home .api-integration-banner .learn-more-text {
      display: flex;
      align-items: center;
      width: fit-content;
      color: #a79779;
      list-style-type: none;
      text-decoration: none;
      margin-top: 15px; }
      .home .api-integration-banner .learn-more-text:hover {
        text-decoration: none; }
    .home .api-integration-banner .banner-image {
      position: absolute;
      right: 50.8px;
      top: 50%;
      width: 350px;
      height: 100%;
      transform: translateY(-50%); }
    .home .api-integration-banner .banner-ellipse {
      position: absolute;
      right: 0;
      top: 0;
      width: fit-content;
      height: 300px;
      z-index: -1; }
  @media (max-width: 1030px) {
    .home .api-integration-banner {
      background-image: none; }
      .home .api-integration-banner .banner-image {
        display: none; }
    .home .banner-ellipse {
      display: none; } }
  .home .home-cards-container {
    display: flex;
    flex-direction: column;
    gap: 24px; }
    @media (max-width: 991.98px) {
      .home .home-cards-container {
        margin-bottom: 24px; } }
    .home .home-cards-container .home-card {
      padding: 24px;
      border-radius: 4px;
      background-color: #fafafa;
      border: 1px solid rgba(139, 149, 158, 0.3); }
      .home .home-cards-container .home-card.earnings-container {
        display: flex;
        flex-direction: column;
        gap: 0; }
      .home .home-cards-container .home-card .progress {
        height: 8px;
        border-radius: 2px;
        background-color: #d9d9d9; }
        .home .home-cards-container .home-card .progress .progress-bar {
          background-color: #29ae11; }
      .home .home-cards-container .home-card .card-head {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        margin-bottom: 16px;
        font-size: 18px; }
      .home .home-cards-container .home-card .text {
        font-size: 14px; }
      .home .home-cards-container .home-card .commissions-or-credits-USD {
        font-size: 24px;
        margin-bottom: 8px; }
      .home .home-cards-container .home-card .credits-eur {
        font-size: 18px;
        margin-bottom: 0;
        color: #636d77; }
      .home .home-cards-container .home-card .goal-achieved-message {
        font-size: 12px;
        color: #636d77;
        margin-top: 16px;
        margin-bottom: 0; }
      .home .home-cards-container .home-card .referral-link-container {
        margin-top: 16px; }
        .home .home-cards-container .home-card .referral-link-container .title {
          color: #636d77;
          font-size: 15px;
          margin-bottom: 10px; }
        .home .home-cards-container .home-card .referral-link-container .link {
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 2px;
          font-size: 14px;
          color: #a79779; }
          .home .home-cards-container .home-card .referral-link-container .link .copy-btn {
            cursor: pointer; }
          .home .home-cards-container .home-card .referral-link-container .link p {
            text-overflow: ellipsis;
            flex-grow: 1;
            overflow: hidden;
            margin-bottom: 0;
            white-space: nowrap;
            max-width: 264px;
            border: 1px solid rgba(139, 149, 158, 0.3);
            padding: 8px 10px;
            background-color: #fff; }
          .home .home-cards-container .home-card .referral-link-container .link .btn {
            color: #a79779;
            font-size: 14px;
            height: fit-content !important; }
            @media (max-width: 991.98px) {
              .home .home-cards-container .home-card .referral-link-container .link .btn {
                display: none; } }
      .home .home-cards-container .home-card .commission-rate {
        color: #417505;
        margin-bottom: 0;
        font-size: 12px; }
      .home .home-cards-container .home-card .info-icon {
        margin-left: 10px;
        padding: 0;
        width: 15px;
        height: 15px; }
  .home .quote-alert-container {
    display: flex;
    flex-direction: column;
    gap: 6px; }
  .home .quote-alert {
    background-color: #1e2b8e0d;
    padding: 16px 24px 16px 24px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px; }

@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateY(-20px); }
  to {
    opacity: 1;
    transform: translateY(0); } }
    .home .quote-alert:nth-child(1) {
      animation-delay: 0.3s; }
    .home .quote-alert:nth-child(2) {
      animation-delay: 0.6s; }
    .home .quote-alert:nth-child(3) {
      animation-delay: 0.9s; }
    .home .quote-alert:nth-child(4) {
      animation-delay: 1.2s; }
    .home .quote-alert:nth-child(5) {
      animation-delay: 1.5s; }
    @media (max-width: 767.98px) {
      .home .quote-alert {
        flex-direction: column;
        margin-bottom: 24px;
        align-items: start; } }
    .home .quote-alert .quote-alert-btns {
      display: flex; }
      @media (max-width: 767.98px) {
        .home .quote-alert .quote-alert-btns {
          width: 100%;
          margin-top: 16px; } }
      .home .quote-alert .quote-alert-btns .view {
        display: flex;
        align-items: center;
        color: #a79779;
        gap: 4px; }
        @media (max-width: 767.98px) {
          .home .quote-alert .quote-alert-btns .view {
            justify-content: flex-end;
            width: 100%; } }
    .home .quote-alert .text {
      margin-bottom: 0; }
    .home .quote-alert button {
      padding: 0;
      border: none;
      background: transparent; }
    .home .quote-alert .view {
      color: #a79779;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px; }
  .home .home-calendar-container {
    background-color: #fafafa;
    height: fit-content;
    border: 1px solid rgba(139, 149, 158, 0.3);
    border-radius: 4px; }
    .home .home-calendar-container .datepicker-section {
      border-bottom: 1px solid rgba(139, 149, 158, 0.3);
      padding: 25px; }
      .home .home-calendar-container .datepicker-section .calendar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; }
      .home .home-calendar-container .datepicker-section .header {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end; }
      .home .home-calendar-container .datepicker-section .days {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 18.5px;
        overflow-x: auto;
        scroll-behavior: smooth; }
        .home .home-calendar-container .datepicker-section .days .selected-underline {
          position: absolute;
          bottom: 2px;
          height: 1px;
          background-color: #a79779;
          transition: all 0.2s ease-in-out; }
        @media (max-width: 767.98px) {
          .home .home-calendar-container .datepicker-section .days {
            gap: 6.67px; } }
      .home .home-calendar-container .datepicker-section .days::-webkit-scrollbar {
        display: none; }
      .home .home-calendar-container .datepicker-section .day-container {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .home .home-calendar-container .datepicker-section .day-name {
        font-size: 14px;
        color: #636d77;
        padding: 4px; }
      .home .home-calendar-container .datepicker-section .day {
        cursor: pointer;
        padding: 4px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .home .home-calendar-container .datepicker-section .day.selected {
          color: #a79779; }
      .home .home-calendar-container .datepicker-section .custom-dropdown {
        display: inline-block;
        display: flex;
        align-items: center;
        position: relative; }
      .home .home-calendar-container .datepicker-section .dropdown-trigger {
        display: flex;
        align-items: center;
        margin-right: 12px; }
      .home .home-calendar-container .datepicker-section .dropdown-menu {
        position: absolute;
        width: 100%;
        top: 26px;
        right: 0;
        overflow-y: auto;
        background-color: #ffffff;
        border-radius: 4px;
        z-index: 10;
        display: block; }
      .home .home-calendar-container .datepicker-section .dropdown-item {
        padding: 10px;
        cursor: pointer; }
      .home .home-calendar-container .datepicker-section .dropdown-item:hover {
        background-color: #f0f0f0; }
      .home .home-calendar-container .datepicker-section .prev-button {
        all: unset;
        margin-right: 6px;
        cursor: pointer; }
      .home .home-calendar-container .datepicker-section .next-button {
        all: unset;
        margin-left: 6px;
        cursor: pointer; }
    .home .home-calendar-container .calendar-bookings {
      padding: 25px;
      display: flex;
      flex-direction: column; }
      .home .home-calendar-container .calendar-bookings .booking-container {
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        font-size: 14px;
        color: #0c0c0c;
        text-decoration: none;
        padding-block: 8px;
        cursor: pointer; }
        .home .home-calendar-container .calendar-bookings .booking-container .booking {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          transition: background-color 0.15s ease-in-out; }
          .home .home-calendar-container .calendar-bookings .booking-container .booking:hover, .home .home-calendar-container .calendar-bookings .booking-container .booking.selected {
            background-color: rgba(167, 151, 121, 0.15); }
          .home .home-calendar-container .calendar-bookings .booking-container .booking .time {
            padding: 12px;
            margin-bottom: 0; }
          .home .home-calendar-container .calendar-bookings .booking-container .booking .booking-details {
            padding: 12px;
            border-left: 1px solid rgba(139, 149, 158, 0.3); }
            .home .home-calendar-container .calendar-bookings .booking-container .booking .booking-details .booking-type {
              color: #636d77;
              text-transform: capitalize; }
            .home .home-calendar-container .calendar-bookings .booking-container .booking .booking-details p:first-child {
              margin-bottom: 4px; }
    .home .home-calendar-container .world-events-container {
      display: flex;
      flex-direction: column;
      padding: 25px; }
      .home .home-calendar-container .world-events-container .world-event-title {
        font-size: 18px;
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        gap: 12px; }
        .home .home-calendar-container .world-events-container .world-event-title .Worlds-events-text {
          font-size: 18px;
          color: black; }
      .home .home-calendar-container .world-events-container .world-event-cards-container {
        display: flex;
        flex-direction: column;
        gap: 6px; }
        .home .home-calendar-container .world-events-container .world-event-cards-container .world-event-card {
          padding: 16px;
          background-color: #ffffff;
          border-radius: 4px;
          border: 1px solid rgba(139, 149, 158, 0.3);
          text-decoration: none;
          color: #000;
          font-weight: 500;
          font-size: 14px; }
          .home .home-calendar-container .world-events-container .world-event-cards-container .world-event-card .event-location {
            font-weight: 400;
            color: #636d77;
            margin-left: 6px; }
  .home .no-bookings-img-wrap {
    margin: 0 auto 16px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #8b959e;
    display: flex;
    justify-content: center;
    align-items: center; }
  .home .no-bookings-text {
    color: #0c0c0c;
    font-size: 14px;
    display: flex;
    padding-block: 12px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(139, 149, 158, 0.3); }
  .home .no-events-text {
    color: #0c0c0c;
    font-size: 20px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .home .banner-close-btn {
    border: 0;
    background: transparent;
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.home-booking-details-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center; }
  .home-booking-details-modal-overlay .home-booking-details-modal {
    display: flex;
    flex-direction: column;
    color: #0c0c0c;
    width: 800px;
    height: 660px;
    background-color: #fff;
    overflow-y: auto; }
    .home-booking-details-modal-overlay .home-booking-details-modal::-webkit-scrollbar {
      display: none;
      /* Hide scrollbar */ }
    .home-booking-details-modal-overlay .home-booking-details-modal p {
      margin-bottom: 0; }
    .home-booking-details-modal-overlay .home-booking-details-modal .header {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
      padding: 40px 40px 16px 40px;
      background-color: white;
      z-index: 1;
      border-bottom: 1px solid transparent;
      transition: border-color 0.5s ease-in-out; }
      .home-booking-details-modal-overlay .home-booking-details-modal .header.show-border {
        border-color: rgba(139, 149, 158, 0.3); }
      .home-booking-details-modal-overlay .home-booking-details-modal .header.hide-border {
        border-color: transparent; }
      .home-booking-details-modal-overlay .home-booking-details-modal .header .number-status {
        display: flex;
        align-items: center;
        gap: 12px; }
    .home-booking-details-modal-overlay .home-booking-details-modal .book-transportation-text {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;
      gap: 4px;
      font-size: 14px;
      color: #a79779;
      cursor: pointer; }
    .home-booking-details-modal-overlay .home-booking-details-modal .home-responsive-view-booking-btn {
      padding: 16px 40px 16px 40px;
      border-top: 1px solid rgba(139, 149, 158, 0.3);
      transition: border-color 0.5s ease-in-out;
      bottom: 0;
      position: sticky;
      line-height: 20px;
      background-color: white; }
      .home-booking-details-modal-overlay .home-booking-details-modal .home-responsive-view-booking-btn.show-border {
        border-color: rgba(139, 149, 158, 0.3); }
      .home-booking-details-modal-overlay .home-booking-details-modal .home-responsive-view-booking-btn.hide-border {
        border-color: transparent; }
      .home-booking-details-modal-overlay .home-booking-details-modal .home-responsive-view-booking-btn button {
        padding: 10px 48px 10px 48px;
        background-color: #a79779;
        border-radius: 2px;
        border: none;
        font-size: 15px;
        float: right;
        color: white; }
    .home-booking-details-modal-overlay .home-booking-details-modal .service-card.home-layout {
      display: block; }
    .home-booking-details-modal-overlay .home-booking-details-modal .service-card .card-body label.expand-title {
      font-size: 12px; }
    @media (max-width: 767.98px) {
      .home-booking-details-modal-overlay .home-booking-details-modal .service-card .card-body {
        margin-bottom: 89px; } }

.home-booking-details-card .smart-suggestions-container,
.home-booking-details-modal .smart-suggestions-container {
  padding: 24px;
  border-radius: 4px;
  background-color: rgba(167, 151, 121, 0.1); }
  .home-booking-details-card .smart-suggestions-container .title,
  .home-booking-details-modal .smart-suggestions-container .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-size: 18px;
    margin-bottom: 16px; }
  .home-booking-details-card .smart-suggestions-container .section-header.expandable,
  .home-booking-details-modal .smart-suggestions-container .section-header.expandable {
    cursor: pointer;
    padding-right: 40px;
    position: relative; }
    .home-booking-details-card .smart-suggestions-container .section-header.expandable:after,
    .home-booking-details-modal .smart-suggestions-container .section-header.expandable:after {
      content: '';
      position: absolute;
      right: 0;
      width: 20px;
      height: 20px;
      background-image: url("/images/icons/caret-down.svg");
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat; }
    .home-booking-details-card .smart-suggestions-container .section-header.expandable.expanded:after,
    .home-booking-details-modal .smart-suggestions-container .section-header.expandable.expanded:after {
      transform: rotate(180deg); }
  .home-booking-details-card .smart-suggestions-container .smart-sugg-icon,
  .home-booking-details-modal .smart-suggestions-container .smart-sugg-icon {
    position: relative;
    bottom: 2px; }
  .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card,
  .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card {
    background-color: #ffffff;
    border-radius: 2px;
    border: 0.5px solid rgba(139, 149, 158, 0.3);
    padding: 16px; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .car-details-container,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .car-details-container {
      display: flex;
      align-items: flex-end;
      gap: 16px; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .car-img-container,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .car-img-container {
      display: flex;
      align-items: end;
      height: 75%; }
      .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .car-img-container .car-img,
      .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .car-img-container .car-img {
        width: 160px; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .card-price-div,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .card-price-div {
      display: flex;
      flex-direction: column;
      align-items: end; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .card-price,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .card-price {
      display: flex;
      align-items: center; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .text-category,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .text-category {
      font-size: 12px;
      text-transform: uppercase;
      color: #8b959e; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .empty,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .empty {
      visibility: hidden; }
    .home-booking-details-card .smart-suggestions-container .smart-sugg-car-card .add-top,
    .home-booking-details-modal .smart-suggestions-container .smart-sugg-car-card .add-top {
      margin-top: 8px; }

.book-transportation-text {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  text-decoration: none;
  list-style-type: none;
  gap: 4px;
  font-size: 14px;
  color: #a79779;
  width: fit-content;
  margin-top: 16px;
  cursor: pointer; }
  .book-transportation-text:hover {
    color: #a79779;
    /* Keeps the color the same on hover */
    text-decoration: none;
    /* Ensures no underline appears on hover */ }

.associated-bookings {
  padding: 24px;
  border-radius: 4px;
  background-color: #fafafa; }
  .associated-bookings p {
    font-size: 20px; }
  .associated-bookings .title {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 43px; }
  .associated-bookings .section-header.expandable {
    cursor: pointer;
    padding-right: 40px;
    position: relative; }
    .associated-bookings .section-header.expandable:after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url("/images/icons/caret-down.svg");
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat; }
    .associated-bookings .section-header.expandable.expanded:after {
      transform: rotate(180deg); }
  .associated-bookings .bookings-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px; }
  .associated-bookings .booking-row {
    display: flex;
    align-items: end;
    padding-bottom: 8px; }
    .associated-bookings .booking-row .booking-cell:nth-child(1) {
      flex: 0.7; }
    .associated-bookings .booking-row .booking-cell:nth-child(2) {
      flex: 1.2; }
    .associated-bookings .booking-row .booking-cell:nth-child(3) {
      flex: 1; }
    .associated-bookings .booking-row .booking-cell:nth-child(4) {
      flex: 1; }
  .associated-bookings .booking-cell {
    flex: 1;
    font-size: 14px;
    color: #0c0c0c; }
    .associated-bookings .booking-cell:last-child {
      text-align: right;
      font-weight: bold; }
  .associated-bookings .booking-number {
    color: #a79779 !important; }
    .associated-bookings .booking-number span {
      margin-right: 10px; }
  .associated-bookings hr {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }

.more-page-card-container {
  display: flex;
  flex-direction: column;
  gap: 16px; }
  .more-page-card-container .more-page-card {
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    font-size: 15px;
    color: #000; }
    .more-page-card-container .more-page-card:not(:last-child) {
      border-bottom: 1px solid rgba(139, 149, 158, 0.3); }
    .more-page-card-container .more-page-card .desc {
      font-size: 14px;
      color: #636d77; }
    .more-page-card-container .more-page-card .icon {
      padding: 10px;
      border: 1px solid rgba(139, 149, 158, 0.3);
      border-radius: 50%;
      width: fit-content;
      height: fit-content; }

.form-wrap {
  max-width: 850px; }
  .form-wrap.reset-password {
    max-width: 806px; }
  .form-wrap .search-vehicle .submit-btn {
    margin-top: 0; }
  .form-wrap .book-vehicle {
    margin-left: 23px;
    margin-right: 3px;
    margin-top: 5px; }
    .form-wrap .book-vehicle .submit-btn {
      margin-top: 0;
      width: 100%;
      border-radius: 20px; }
  .form-wrap .b2c-empty {
    margin-top: 10px; }
    .form-wrap .b2c-empty .submit-btn {
      margin-top: 0;
      width: 150px;
      border-radius: 20px; }
  .form-wrap .submit-block {
    padding-right: 50px;
    margin-bottom: 20px; }
  .form-wrap button.forgot-password {
    width: 100%;
    margin-top: 0; }
  .form-wrap .team-member {
    margin: 0 0 50px;
    position: relative; }
  .form-wrap .team-member.last {
    margin: 0; }

.form-wrap-supplier {
  width: 109%;
  float: left; }
  .form-wrap-supplier .search-vehicle .submit-btn {
    margin-top: 0; }
  .form-wrap-supplier .submit-block {
    padding-right: 50px;
    margin-bottom: 20px; }
  .form-wrap-supplier button.forgot-password {
    width: 100%;
    margin-top: 0; }
  .form-wrap-supplier .form-block {
    margin: 0px; }
  .form-wrap-supplier .chauffeur-card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(139, 149, 158, 0.1);
    padding: 1em 1em;
    margin-top: 1em;
    max-height: 70px; }
    .form-wrap-supplier .chauffeur-card-item .add-pic-container {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #8b959e;
      justify-content: center;
      border-radius: 50%; }
    .form-wrap-supplier .chauffeur-card-item .add-pic-container-uploaded {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid black; }
    .form-wrap-supplier .chauffeur-card-item .info-span {
      flex: 0 0 65%; }
  .form-wrap-supplier .vehicle-card-item {
    display: flex;
    align-items: center;
    background-color: rgba(139, 149, 158, 0.1);
    padding: 1em 1em;
    margin-top: 1em;
    max-height: 70px; }
    .form-wrap-supplier .vehicle-card-item .car-edit-img {
      width: 24%;
      margin-right: 15px;
      align-self: flex-start; }
    .form-wrap-supplier .vehicle-card-item .vehicle-brand {
      display: block;
      font-weight: bolder; }
  .form-wrap-supplier .form-row-vehicle {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(21em, 23.3em)); }
    @media (min-width: 992px) {
      .form-wrap-supplier .form-row-vehicle {
        grid-template-columns: repeat(auto-fill, minmax(21em, 1fr)); } }
    @media (max-width: 768px) {
      .form-wrap-supplier .form-row-vehicle {
        grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr)); } }
  .form-wrap-supplier .form-row-chauffeur {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.625em, 18.65em)); }
    @media (min-width: 992px) {
      .form-wrap-supplier .form-row-chauffeur {
        grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr)); } }
    @media (max-width: 768px) {
      .form-wrap-supplier .form-row-chauffeur {
        grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr)); } }
  .form-wrap-supplier .flex-container-cities {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .form-wrap-supplier .grid-item-cities {
    flex: 0 0 50%;
    /* Two items per row */ }
    @media (max-width: 768px) {
      .form-wrap-supplier .grid-item-cities {
        flex: 0 0 100%;
        /* One item per row on smaller screens */ } }
    .form-wrap-supplier .grid-item-cities .form-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      /* Adjust as needed */ }
      .form-wrap-supplier .grid-item-cities .form-row .col {
        flex: 0 0 30%;
        /* Three items beside each other */
        /* Add margin-right for spacing */ }
  .form-wrap-supplier .grid-hidden-col {
    flex: 0 0 30%;
    /* Adjust the width as needed */
    visibility: hidden; }
  .form-wrap-supplier .flex-container-vehicles {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .form-wrap-supplier .flex-item-vehicles {
    flex: 0 0 calc(50% - 8px); }
    @media (max-width: 992px) {
      .form-wrap-supplier .flex-item-vehicles {
        flex: 0 0 calc(100% - 16px); } }
  .form-wrap-supplier .add-item-button {
    width: 100%;
    /* Button takes full width */
    margin-top: 16px; }
  .form-wrap-supplier .customCol-vehicles {
    flex: 0 0 60%;
    max-width: 60%; }
    @media (max-width: 992px) {
      .form-wrap-supplier .customCol-vehicles {
        flex: 0 0 55%;
        max-width: 55%; } }
  .form-wrap-supplier .supplier-save-button {
    margin-right: 7rem !important; }

.form-block {
  padding: 50px 0 50px 0; }
  .form-block.invite-new-members {
    padding-inline: 0 !important; }
    @media (max-width: 769px) {
      .form-block.invite-new-members {
        padding-inline: 24px !important; } }
  .form-block:not(:last-child) {
    border-bottom: 1px solid rgba(139, 149, 158, 0.25); }
  .form-block.no-border {
    border: none; }
  .form-block .currency-select-col .form-group {
    max-width: 115px; }

.form-block-Modal {
  padding: 20px 0 0px 0 !important; }
  .form-block-Modal:not(:last-child) {
    border-bottom: 0px solid rgba(139, 149, 158, 0.25) !important; }
  .form-block-Modal.no-border {
    border: none; }
  .form-block-Modal .sub-header {
    font-size: 20px;
    margin-bottom: 10px !important; }
  .form-block-Modal .sub-header-text {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 10px !important;
    color: #8b959e; }
  .form-block-Modal .currency-select-col .form-group {
    max-width: 115px; }

.form-block-custom {
  padding: 50px 0 30px 0; }
  .form-block-custom:not(:last-child) {
    border-bottom: 1px solid rgba(139, 149, 158, 0.25); }
  .form-block-custom.no-border {
    border: none; }
  .form-block-custom .currency-select-col .form-group {
    max-width: 115px; }

.form-block-supplier {
  padding: 5px 0 0 5px; }
  .form-block-supplier:not(:last-child) {
    border-bottom: 1px solid rgba(139, 149, 158, 0.25); }
  .form-block-supplier.no-border {
    border: none; }
  .form-block-supplier .currency-select-col .form-group {
    max-width: 115px; }

.form-cities {
  padding: 50px 0 50px 0;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  border-bottom: 1px solid rgba(139, 149, 158, 0.25); }

.form-contact {
  padding: 50px 0 50px 0;
  border-top: 1px solid rgba(139, 149, 158, 0.25); }

.form-service {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25); }

.phone-button {
  display: flex;
  bottom: 3.5em;
  pointer-events: none;
  justify-content: flex-end;
  padding-right: 1rem !important;
  position: relative; }
  .phone-button a {
    position: absolute;
    pointer-events: auto; }

.phone-button-form {
  display: flex;
  bottom: 2.5em;
  pointer-events: none;
  justify-content: flex-end;
  padding-right: 1rem !important;
  position: relative; }
  .phone-button-form a {
    position: absolute;
    pointer-events: auto; }

.form-vehicle {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25); }

.kpi-dispatch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px; }

.kpi-dispatch-end {
  display: flex;
  justify-content: end;
  margin-bottom: 5px; }

.kpi-bookings {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 10px; }

.kpi-revenue {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px; }

.kpi-score {
  border: 1px solid black;
  border-radius: 50%;
  width: 4em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center; }

.kpi-score-admin {
  border: 1px solid black;
  border-radius: 50%;
  padding: 7px;
  margin-left: 18px;
  font-size: 16px;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: middle; }

.new-bookings-car-rentals .form-block .form-row {
  width: 100% !important; }

.max-w-494px {
  max-width: 494px !important; }

.driver-card .react-select__control {
  padding-inline: 10px; }

.driver-card.px-10px {
  padding-inline: 10px !important; }

.driver-card .last-driver-section {
  border-bottom: none; }

.driver-card .border-container-line {
  border-bottom: 1px solid rgba(139, 149, 158, 0.25); }

.driver-card .driver-submit {
  border-bottom: 1px solid rgba(139, 149, 158, 0.25); }
  .driver-card .driver-submit .submit-btn {
    margin-top: 0;
    margin-bottom: 50px; }

.driver-card .remove-file-btn {
  display: block;
  color: #d0021b;
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  justify-content: flex-end; }
  .driver-card .remove-file-btn:hover {
    text-decoration: none;
    color: #d0021b; }
  .driver-card .remove-file-btn img {
    width: 10px;
    height: 10px; }

.book-form .form-block .form-row {
  width: 82%;
  max-width: 505px; }
  .book-form .form-block .form-row .form-benefits {
    width: 100%;
    max-width: 505px; }
  .book-form .form-block .form-row .form-benefits-carRental {
    width: 89%;
    max-width: 505px; }

.book-form .form-block .form-benefits {
  width: 100%;
  max-width: 505px; }

.book-form .remove-card-btn {
  display: block;
  color: #d0021b;
  margin-bottom: 5px; }
  .book-form .remove-card-btn:hover {
    text-decoration: none;
    color: #d0021b; }

.book-form .add-driver-link {
  display: block; }

.is-book {
  margin-right: -10px !important; }

.phone-field-row {
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: nowrap; }
  .phone-field-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px; }
  .phone-field-row .phone-number-input,
  .phone-field-row .phone-number-flag {
    padding: 0; }
  .phone-field-row .dropdown-btn {
    display: flex;
    align-items: center; }
    .phone-field-row .dropdown-btn span {
      width: 100% !important;
      flex-shrink: 1; }
    .phone-field-row .dropdown-btn img.dropdown-svg,
    .phone-field-row .dropdown-btn img.icon-flag {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
  .phone-field-row .phone-input .is-invalid {
    border: 1px solid red !important; }
  .phone-field-row .phone-input {
    width: 100% !important;
    border: 0.5px solid transparent !important;
    border-radius: 2px !important;
    background-color: #fbfbfb !important;
    background: #fbfbfb !important;
    color: #0c0c0c !important;
    font-size: 14px !important;
    font-weight: 400 !important; }
    .phone-field-row .phone-input:hover {
      border: 0.5px solid #0c0c0c !important; }
    .phone-field-row .phone-input:focus {
      border: 0.5px solid #0c0c0c !important; }
    .phone-field-row .phone-input:active {
      color: #0c0c0c !important; }

.phone-field-equal {
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: nowrap; }
  .phone-field-equal > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px; }
  .phone-field-equal .phone-number-input,
  .phone-field-equal .phone-number-flag {
    padding: 0; }
  .phone-field-equal .dropdown-btn {
    display: flex;
    align-items: center; }
    .phone-field-equal .dropdown-btn span {
      width: 100% !important;
      flex-shrink: 1; }
    .phone-field-equal .dropdown-btn img.dropdown-svg,
    .phone-field-equal .dropdown-btn img.icon-flag {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
  .phone-field-equal .phone-input .is-invalid {
    border: 1px solid red !important; }
  .phone-field-equal .phone-input {
    width: 100% !important;
    border: 0.5px solid transparent !important;
    border-radius: 2px !important;
    background-color: #fbfbfb !important;
    background: #fbfbfb !important;
    color: #0c0c0c !important;
    font-size: 14px !important;
    font-weight: 400 !important; }
    .phone-field-equal .phone-input:hover {
      border: 0.5px solid #0c0c0c !important; }
    .phone-field-equal .phone-input:focus {
      box-shadow: none !important;
      border: 0.5px solid #0c0c0c !important; }
    .phone-field-equal .phone-input:active {
      box-shadow: none !important;
      color: #0c0c0c !important; }

.pointer-link {
  cursor: pointer; }

.custom-height {
  height: 62%; }

.benefits-member-message {
  box-sizing: content-box;
  background-color: #ffffff;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  border-radius: 2px;
  border: 1px solid rgba(139, 149, 158, 0.1);
  border-top-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: solid !important;
  border-left-style: solid !important;
  border-top-width: 0.5px !important;
  border-right-width: 0.5px !important;
  border-bottom-width: 0.5px !important;
  border-left-width: 0.5px !important;
  border-image-width: initial !important;
  border-image-source: initial !important;
  border-image-repeat: initial !important;
  border-image-outset: initial !important;
  border-image-slice: initial !important; }
  .benefits-member-message .benefits-header {
    font-size: 14px !important;
    font-weight: 600 !important;
    word-break: break-word; }
  .benefits-member-message .benefits-body {
    font-size: 13px !important; }
  .benefits-member-message .benefits-confirm {
    width: 100% !important;
    margin-bottom: 7% !important; }
  .benefits-member-message .close-icon {
    cursor: pointer; }
    .benefits-member-message .close-icon img {
      width: 10px;
      height: 10px; }

.promoCode-mobile {
  margin-bottom: 0.3rem !important; }

.promoCode-icon-width {
  margin-left: 1px !important; }

.promoCode-x-icon-width {
  margin-right: 0px !important; }

.promoCode-x-icon-desktop {
  margin-bottom: 0.2rem !important; }

.add-global-rate .form-block {
  max-width: 850px; }
  .add-global-rate .form-block.wide {
    max-width: 100%; }

.add-global-rate .rates-block .left-side,
.add-global-rate .rates-block .right-side {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 9px 0px rgba(12, 12, 12, 0.03);
  padding: 30px;
  width: 100%; }

.add-global-rate .rates-block .left-side {
  padding: 30px 20px; }

.add-global-rate .rates-block .locations-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .add-global-rate .rates-block .locations-list .location-item {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #e9eff4;
    padding: 20px;
    padding-top: 10px;
    padding-right: 35px;
    font-size: 14px;
    color: #8b959e;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px; }
    .add-global-rate .rates-block .locations-list .location-item .dropdown {
      position: absolute;
      right: 5px;
      top: calc(50% - 17px); }
      .add-global-rate .rates-block .locations-list .location-item .dropdown .dots-toggle {
        opacity: 0.5; }
    .add-global-rate .rates-block .locations-list .location-item:hover {
      border-color: rgba(139, 149, 158, 0.25);
      color: #0c0c0c; }
      .add-global-rate .rates-block .locations-list .location-item:hover .dropdown .dots-toggle {
        opacity: 1; }
    .add-global-rate .rates-block .locations-list .location-item.disabled {
      background-color: #fbfbfb;
      color: #8b959e; }
      .add-global-rate .rates-block .locations-list .location-item.disabled:hover {
        border-color: rgba(139, 149, 158, 0.25);
        color: #8b959e; }
    @media (min-width: 1200px) {
      .add-global-rate .rates-block .locations-list .location-item {
        width: calc(50% - 10px); } }
    .add-global-rate .rates-block .locations-list .location-item .location-state {
      position: absolute;
      margin-top: 15px;
      font-size: 10px; }
    .add-global-rate .rates-block .locations-list .location-item .location-state-country {
      display: block;
      font-size: 10px;
      margin-top: -3px;
      margin-bottom: -3px; }

.add-global-rate .rates-block .rate-form label {
  display: inline-block;
  min-height: 12px; }

.add-global-rate .rates-block .rate-form .remove-btn {
  margin-top: 36px; }

.add-global-rate .rates-block .rate-form .rate-form-section {
  padding: 20px;
  border: 1px solid rgba(139, 149, 158, 0.3);
  border-radius: 5px; }

.add-global-rate .rates-block .rate-form .rate-form-section-red {
  padding: 20px;
  border: 1px solid rgba(208, 2, 27, 0.3);
  border-radius: 5px; }

.add-global-rate .rates-block .rate-form .rate-form-section-yellow {
  padding: 20px;
  border: 2px solid rgba(245, 166, 35, 0.6);
  border-radius: 5px; }

.add-global-rate .rates-block .rate-form .rate-form-smart-status {
  border: none;
  padding-left: 0px;
  padding-top: 25px;
  padding-bottom: 0px; }

.add-global-rate .rates-block .rate-form .remove-section {
  display: block;
  text-align: right; }
  .add-global-rate .rates-block .rate-form .remove-section .btn-link {
    font-size: 12px;
    padding-right: 0; }

.meet-greet-rate .form-block {
  max-width: 95%; }

.spinner {
  display: inline; }

.edit-link {
  color: #303030; }
  .edit-link:hover {
    text-decoration: none;
    color: #303030; }

.passenger-information-special {
  padding-top: 9px !important;
  padding-bottom: 10px !important; }

.description-exclusive-benefits-desktop {
  background: url("/images/icons/loyalty-icon-2.png");
  background-position: top 1px left 0px;
  background-size: 16px 16px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 28px;
  font-weight: 300;
  margin-bottom: 4px; }

.description-exclusive-benefits-mobile {
  background: url("/images/icons/loyalty-icon-2.png");
  background-position: top 0px left 0px;
  background-size: 16px 16px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 28px;
  font-weight: 300;
  margin-bottom: 4px; }

.description-benefits-desktop {
  background: url("/images/icons/loyalty-icon-2.png");
  background-position: top 0.8px left 0px;
  background-size: 15px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 26px;
  margin-bottom: 4px; }

.description-benefits-mobile {
  background: url("/images/icons/loyalty-icon-2.png");
  background-position: top 1px left 0px;
  background-size: 15px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 30px;
  margin-bottom: 4px;
  margin-left: 3rem; }

.additional-charge-edit-btn {
  display: flex;
  align-items: center; }

.flightTracking-info {
  padding-left: 1.1rem;
  padding-right: 0.1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: none; }
  .flightTracking-info .flightTitle {
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    color: #0c0c0c; }
  .flightTracking-info .flightCityName {
    font-size: 13px !important;
    text-align: left;
    color: #0c0c0c;
    font-weight: 400;
    margin-bottom: 0.3rem; }
  .flightTracking-info .flightSubTitle {
    font-size: 14px !important;
    text-align: left;
    color: #0c0c0c;
    font-weight: 400; }
  .flightTracking-info .flightStatus-delayed {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: #d0021b;
    padding: 0.25rem 0.3rem 0.1rem 0.3rem;
    margin-bottom: 1.3rem;
    text-transform: uppercase; }
  .flightTracking-info .flightStatus-onTime {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: #417505;
    padding: 0.25rem 0.3rem 0.1rem 0.3rem;
    margin-bottom: 1.3rem; }
  .flightTracking-info .flight-iata-departure {
    font-size: 20px !important;
    font-weight: lighter;
    color: #0c0c0c;
    margin-right: 1.75rem; }
  .flightTracking-info .flight-iata-arrival {
    font-size: 20px !important;
    font-weight: lighter;
    color: #0c0c0c;
    margin-left: 1rem; }
  .flightTracking-info .flight-time-green {
    font-size: 18px !important;
    font-weight: lighter !important;
    color: #417505; }
  .flightTracking-info .flight-time-red {
    font-size: 18px !important;
    font-weight: lighter !important;
    color: #d0021b; }
  .flightTracking-info .flight-inner-title {
    display: block;
    line-height: 10px;
    font-size: 9px !important;
    color: #8b959e !important;
    text-transform: uppercase; }
  .flightTracking-info .flight-arrival-section {
    padding-left: 2rem !important; }
  .flightTracking-info .flight-section-border {
    border-right: 1px solid rgba(139, 149, 158, 0.25); }
  .flightTracking-info .flight-align-row {
    padding-left: 0.1rem; }
  .flightTracking-info .flight-gate {
    padding-left: 2rem !important; }
  .flightTracking-info .terminal-gate-num {
    font-size: 16px !important; }
  .flightTracking-info .progressBar {
    width: 100%;
    height: 0.75em;
    border: 0.125em solid #373737;
    background: #323232;
    overflow: hidden; }
  .flightTracking-info .iata-width {
    max-width: 10% !important; }
  .flightTracking-info .myProgress {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    border-radius: 3px;
    background-color: #8b959e; }
  .flightTracking-info .margin-top {
    margin-top: 0.7rem !important; }
  .flightTracking-info .myBar-red {
    position: absolute;
    width: 1%;
    height: 100%;
    border-radius: 2px;
    background-color: #d0021b;
    transition: width 2s; }
  .flightTracking-info .myBar-green {
    position: absolute;
    width: 1%;
    height: 100%;
    border-radius: 2px;
    background-color: #417505;
    transition: width 2s; }
  .flightTracking-info .progress-dot-grey {
    height: 8px;
    width: 8px;
    background-color: #8b959e;
    border-radius: 50%;
    display: inline-block; }
  .flightTracking-info .progress-dot-red {
    height: 8px;
    width: 8px;
    background-color: #d0021b;
    border-radius: 50%;
    display: inline-block; }
  .flightTracking-info .progress-dot-green {
    height: 8px;
    width: 8px;
    background-color: #417505;
    border-radius: 50%;
    display: inline-block; }
  .flightTracking-info .plane-position {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .flightTracking-info .plane-size {
    max-width: 20px !important;
    max-height: 20px !important;
    border-left: 1rem;
    border-color: white;
    border-width: 1rem; }
  .flightTracking-info .invalid-flight {
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    color: #0c0c0c; }
  .flightTracking-info .dot-size {
    font-size: 7px; }
  .flightTracking-info .progress-time {
    font-size: 9px !important;
    color: #8b959e !important; }
  .flightTracking-info .time-bar {
    flex-wrap: wrap;
    padding-bottom: 1.3rem; }

.flightTracking-loader {
  padding-right: 30px;
  background-image: url("/images/planeIcons/planeLoader.svg");
  background-repeat: no-repeat;
  background-size: 15px 20px;
  background-position: center right 8px;
  color: #0c0c0c; }

.flightTracking-delete {
  position: absolute;
  display: block;
  top: 5px;
  right: 0px;
  width: 16px;
  height: 16px;
  background: url("http://cdn.sstatic.net/stackoverflow/img/sprites.png?v=4") 0 -690px;
  cursor: pointer; }

.markAsPaid {
  padding-left: 0.2rem !important;
  font-style: italic !important; }
  .markAsPaid:hover {
    text-decoration: underline; }

.selectButton {
  padding-left: 0.3rem !important;
  font-size: 14px !important;
  color: #0c0c0c; }
  .selectButton:hover {
    text-decoration: underline; }
  .selectButton:focus {
    box-shadow: none; }

.SOA-total-title {
  font-size: 15px;
  font-weight: bold;
  color: #0c0c0c; }

.SOA-total-value {
  font-size: 13px;
  font-weight: 300;
  color: #0c0c0c; }

.SOA-Button {
  height: fit-content;
  background-color: #0c0c0c;
  color: white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-color: #0c0c0c; }

.SOA-border {
  border-top: 1px solid rgba(139, 149, 158, 0.25); }

.SOA-text {
  font-weight: bold; }

.SOA-type {
  font-size: 10px;
  color: #8b959e; }

.meet-and-assist-container {
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb;
  padding: 1.5em 1.5em;
  margin-top: 1em; }
  .meet-and-assist-container .meet-and-assist-outer .outer-title {
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    color: #0c0c0c;
    display: block; }
  .meet-and-assist-container .meet-and-assist-outer .outer-description {
    font-size: 14px;
    text-align: left;
    font-weight: lighter;
    color: #0c0c0c;
    padding-top: 7px;
    width: 85%; }
  .meet-and-assist-container .meet-and-assist-inner {
    background-color: #ffffff;
    border-radius: 2px;
    border: 1.5px solid rgba(139, 149, 158, 0.1);
    border-color: #a79779;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 15px 45px 20px 25px;
    margin-top: 2em; }
    .meet-and-assist-container .meet-and-assist-inner .inner-title {
      font-size: 14px;
      color: #303030;
      display: block;
      padding-top: 3px;
      padding-left: 8px; }
    .meet-and-assist-container .meet-and-assist-inner .price {
      font-size: 15px;
      color: #303030;
      font-weight: normal;
      display: block;
      text-align: right;
      margin-top: 1rem; }
    .meet-and-assist-container .meet-and-assist-inner .inner-description {
      font-size: 11px;
      color: #303030;
      padding-left: 2em;
      display: block; }
    .meet-and-assist-container .meet-and-assist-inner .commission {
      color: #8b959e;
      font-size: 12px;
      display: block;
      text-align: right; }
    .meet-and-assist-container .meet-and-assist-inner .meet-and-assist-features {
      padding-top: 5px; }
      .meet-and-assist-container .meet-and-assist-inner .meet-and-assist-features div {
        background: url("/images/Tick_Icon.png");
        font-size: 14px;
        background-position: top 3px left 0px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 28px;
        margin-bottom: 4px; }
    .meet-and-assist-container .meet-and-assist-inner .meet-and-assist-payment {
      padding-top: 10px; }
    .meet-and-assist-container .meet-and-assist-inner .custom-radio {
      margin-bottom: 10px; }
      .meet-and-assist-container .meet-and-assist-inner .custom-radio .custom-control-label:after {
        background-image: url("/images/icons/radio-golden.svg"); }
      .meet-and-assist-container .meet-and-assist-inner .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
        background-image: url("/images/icons/radio-checked.svg"); }

.flight-tracking-prompt {
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  padding: 1rem 1rem;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  padding-top: 20px;
  border-radius: 2px; }
  .flight-tracking-prompt p {
    font-weight: 900; }
  .flight-tracking-prompt span {
    color: #636d77; }

.planeSpinner {
  padding-right: 30px;
  background-repeat: no-repeat;
  background-size: 15px 20px;
  background-position: center left 28px;
  color: #0c0c0c;
  background-image: url("/images/planeIcons/plane_green.png");
  animation: spin_main 2s infinite linear; }

@-webkit-keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.inlineColClass {
  display: inline-block;
  width: max-content; }

.viewButton:hover {
  text-decoration: underline; }

.notesBorder {
  border-radius: 2px;
  border: 1.5px solid #d0021b;
  padding-top: 0.3rem; }

.notesBorder-black {
  border-radius: 2px;
  border: 1.5px solid #0c0c0c;
  padding-top: 0.3rem; }

.blackBorder {
  border-color: #0c0c0c !important; }

.marginBottom {
  margin-bottom: 0rem !important; }

.headSectionPadding {
  padding-right: 2rem !important; }

.topPaddingConfirm {
  padding-top: 2px !important; }

.withoutBadge {
  margin-top: 32px !important;
  padding: 26px 33px 26px 20px !important;
  font-size: 12px !important; }

.withBadge {
  margin-top: 26px !important;
  padding: 26px 33px 26px 20px !important;
  font-size: 12px !important; }

.blackFont {
  color: #0c0c0c; }

.warning-text {
  color: #f5a623; }

.success-text {
  color: #417505; }

.quote-req-text {
  color: #1e2b8e; }

.dispute-text {
  color: #635bff; }

.refund-text {
  color: #636d77; }

.messageContainer {
  box-sizing: content-box;
  background-color: #ffffff;
  padding-left: 0.4rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 1px;
  border: 1px solid rgba(139, 149, 158, 0.3);
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important; }
  .messageContainer .message {
    background-color: #ffffff;
    background-position: top 0.8px left 0px;
    background-size: 20px 15px;
    font-size: 14px;
    font-weight: 400;
    background-repeat: no-repeat;
    padding-left: 32px;
    border-radius: 4px; }

.service-card {
  margin: 6.5px 0;
  padding: 30px;
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  overflow: hidden;
  width: 700px; }
  .service-card.home-layout {
    padding: 24px;
    overflow: visible;
    margin-bottom: 0;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    @media (max-width: 767.98px) {
      .service-card.home-layout {
        display: block; } }
    .service-card.home-layout::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari and Opera */ }
    .service-card.home-layout .card-body p {
      margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .service-card {
      display: none; } }
  .service-card .custom-tabs-nav {
    background: rgba(139, 149, 158, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px; }
    .service-card .custom-tabs-nav .nav-link {
      background-color: transparent;
      color: rgba(139, 149, 158, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px; }
      .service-card .custom-tabs-nav .nav-link:hover {
        color: rgba(139, 149, 158, 0.7); }
      .service-card .custom-tabs-nav .nav-link.active {
        background-color: #ffffff;
        color: #323c47;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .service-card .custom-tabs-nav.light-tabs {
      border-bottom: 1px solid rgba(139, 149, 158, 0.3);
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0; }
      .service-card .custom-tabs-nav.light-tabs .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none; }
        .service-card .custom-tabs-nav.light-tabs .nav-link.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid #0c0c0c;
          box-shadow: none;
          color: #303030;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600; }
        .service-card .custom-tabs-nav.light-tabs .nav-link:focus {
          box-shadow: none; }
        .service-card .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
          margin-right: 25px; }
  .service-card .card-body {
    padding: 0;
    padding-left: 6px; }
    .service-card .card-body label.expand-title {
      margin-bottom: 10px;
      font-size: 10px; }
    .service-card .card-body label.expand-title-service {
      font-size: 10px; }
    .service-card .card-body p {
      margin-bottom: 5px;
      font-size: 14px; }

.tandc-card {
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  overflow: hidden;
  width: 880px; }

.tandc-text {
  font-size: 15px;
  color: black;
  display: block;
  text-transform: none;
  margin-bottom: 0.3rem;
  padding-left: 24.5px;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.tandc-text-two {
  font-size: 15px;
  color: black;
  display: block;
  text-transform: none;
  margin-bottom: 0.3rem;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.tandc-rental-card {
  margin: 6.5px 0;
  padding: 30px;
  border-radius: 2px;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  overflow: hidden;
  width: 700px; }

.vehicle-card-service .custom-tabs-nav {
  background: rgba(139, 149, 158, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 35px; }
  .vehicle-card-service .custom-tabs-nav .nav-link {
    background-color: transparent;
    color: rgba(139, 149, 158, 0.5);
    font-weight: 500;
    padding: 9px 18px;
    border-radius: 3px; }
    .vehicle-card-service .custom-tabs-nav .nav-link:hover {
      color: rgba(139, 149, 158, 0.7); }
    .vehicle-card-service .custom-tabs-nav .nav-link.active {
      background-color: #ffffff;
      color: #323c47;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .vehicle-card-service .custom-tabs-nav .nav-link:focus {
      box-shadow: none; }
  .vehicle-card-service .custom-tabs-nav.light-tabs {
    border-bottom: 1px solid rgba(139, 149, 158, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    background: transparent;
    font-size: 15px;
    padding: 0; }
    .vehicle-card-service .custom-tabs-nav.light-tabs .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 14px;
      font-weight: 600;
      color: #8b959e;
      opacity: 0.55;
      padding: 7px 7px 0px 7px;
      border: none; }
      .vehicle-card-service .custom-tabs-nav.light-tabs .nav-link.active {
        background: transparent;
        border: none;
        border-bottom: 2px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600; }
      .vehicle-card-service .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
        margin-right: 25px; }
      .vehicle-card-service .custom-tabs-nav.light-tabs .nav-link:focus {
        box-shadow: none; }

.paymentType .title {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #8b959e;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.paymentType .expanded {
  margin-bottom: 0.8rem; }

.paymentType .label {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #0c0c0c;
  font-size: 15px !important;
  font-weight: 400 !important; }

.payment-cancel-btn {
  padding-top: 2rem !important;
  padding-left: 0px !important; }

.refund-btn {
  padding-left: 3.6rem !important;
  padding-right: 3.6rem !important; }

.payment-invoice {
  padding-left: 1.6rem !important;
  margin-top: 0.6rem !important; }

.payment-booking-confirmation {
  padding-left: 1.6rem !important;
  margin-top: 0 !important; }

.payment-receipt {
  padding-left: 1.7rem !important;
  margin-top: 0.8rem !important; }

.payment-newLabel {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #8b959e;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }
  .payment-newLabel .benefits {
    color: #47dc95 !important; }

.payment-newLabel-mg {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #8b959e;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
  position: relative;
  float: right; }
  .payment-newLabel-mg .benefits {
    color: #47dc95 !important; }

.most-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: #417505;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.more-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: blue;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.mid-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: #ffcc00;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.less-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: darkorange;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.least-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: red;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: #0c0c0c;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem; }

.change-request-label label {
  color: #ff6666 !important; }

.change-request-p {
  color: #ff6666 !important;
  font-size: 10px !important; }

.decline-change-request {
  color: #d0021b !important;
  border-color: unset !important; }
  .decline-change-request:hover {
    color: #d0021b !important; }
  .decline-change-request:after {
    background-color: #d0021b !important;
    color: #d0021b !important;
    transition: all ease 0.5s; }

.close-btn-wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end; }
  .close-btn-wrap .close-icon-partner-modal {
    cursor: pointer; }
    .close-btn-wrap .close-icon-partner-modal img {
      width: 10px;
      height: 10px; }

.partner-name {
  font-size: 14px !important;
  color: #0c0c0c !important; }

.mismatch-text {
  color: #d0021b;
  font-size: 12px;
  float: right; }

.partner-status-opened {
  color: #0c0c0c;
  font-size: 14px !important; }

.partner-status-unopened {
  color: #8b959e;
  font-size: 14px !important; }

.close-icon-partners {
  cursor: pointer;
  display: flex;
  justify-content: flex-end; }
  .close-icon-partners img {
    width: 10px;
    height: 10px; }

.banners-message {
  line-height: 12px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b959e;
  display: block; }

.admin-bookings-tab {
  overflow: hidden; }
  .admin-bookings-tab .custom-tabs-nav {
    background: rgba(139, 149, 158, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px; }
    .admin-bookings-tab .custom-tabs-nav .nav-link {
      background-color: transparent;
      color: rgba(139, 149, 158, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px; }
      .admin-bookings-tab .custom-tabs-nav .nav-link:hover {
        color: rgba(139, 149, 158, 0.7); }
      .admin-bookings-tab .custom-tabs-nav .nav-link.active {
        background-color: #ffffff;
        color: #323c47;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .admin-bookings-tab .custom-tabs-nav.light-tabs {
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0; }
      .admin-bookings-tab .custom-tabs-nav.light-tabs .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none; }
        .admin-bookings-tab .custom-tabs-nav.light-tabs .nav-link.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid #0c0c0c;
          box-shadow: none;
          color: #303030;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600; }
        .admin-bookings-tab .custom-tabs-nav.light-tabs .nav-link:focus {
          box-shadow: none; }
        .admin-bookings-tab .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
          margin-right: 25px; }
  .admin-bookings-tab .card-body {
    padding: 0;
    padding-left: 6px; }
    .admin-bookings-tab .card-body label.expand-title {
      margin-bottom: 10px;
      font-size: 10px; }
    .admin-bookings-tab .card-body label.expand-title-service {
      font-size: 10px; }
    .admin-bookings-tab .card-body p {
      margin-bottom: 5px;
      font-size: 14px; }

.partner-modal-tab {
  overflow: hidden; }
  .partner-modal-tab .custom-tabs-nav {
    background: rgba(139, 149, 158, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px; }
    .partner-modal-tab .custom-tabs-nav .nav-link {
      background-color: transparent;
      color: rgba(139, 149, 158, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px; }
      .partner-modal-tab .custom-tabs-nav .nav-link:hover {
        color: rgba(139, 149, 158, 0.7); }
      .partner-modal-tab .custom-tabs-nav .nav-link.active {
        background-color: #ffffff;
        color: #323c47;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .partner-modal-tab .custom-tabs-nav.light-tabs {
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0; }
      .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none; }
        .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid #0c0c0c;
          box-shadow: none;
          color: #303030;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600; }
        .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link:focus {
          box-shadow: none; }
        .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
          margin-right: 25px; }
  .partner-modal-tab .card-body {
    padding: 0;
    padding-left: 6px; }
    .partner-modal-tab .card-body label.expand-title {
      margin-bottom: 10px;
      font-size: 10px; }
    .partner-modal-tab .card-body label.expand-title-service {
      font-size: 10px; }
    .partner-modal-tab .card-body p {
      margin-bottom: 5px;
      font-size: 14px; }

.vehicle-passenger-select-Async {
  background: url("/images/icons/team.svg");
  background-position: top -2.5px left 0px;
  background-size: 15px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 20px;
  margin-right: 5px;
  margin-bottom: 4px; }

.vehicle-luggage-select-Async {
  background: url("/images/icons/portfolio.svg");
  background-position: top -2.5px left 0px;
  background-size: 13px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 20px;
  margin-bottom: 4px; }

.decline-reason-span {
  cursor: pointer;
  font-size: 14px; }

.decline-reason-span:hover {
  color: #a79779; }

.decline-comment-modal-title {
  display: flex;
  justify-content: space-between; }
  .decline-comment-modal-title div {
    padding: 17px;
    font-size: 1.2rem; }

.decline-comment-modal-btn {
  max-height: 61px; }
  .decline-comment-modal-btn img {
    width: 60px;
    padding: 10px; }

.decilne-comment-modal-body {
  height: 170px;
  display: flex;
  justify-content: center; }
  .decilne-comment-modal-body div {
    margin-top: 6%; }

.grey-background-payout {
  background-color: #e6e6e6;
  margin-top: 0em !important; }

.payout-container {
  padding: 1.5rem 3.5625rem 3.75rem 3.5625rem !important; }
  .payout-container .payout-card {
    border-radius: 10px;
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #fbfbfb;
    padding: 1.5em 1.5em;
    font-size: 15px;
    margin-top: 1em; }
    .payout-container .payout-card .payout-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px; }
      .payout-container .payout-card .payout-card-header .select-title {
        font-size: 15px;
        font-weight: 700; }
    .payout-container .payout-card .payout-card-body {
      margin-top: 1.5em; }
      .payout-container .payout-card .payout-card-body div:not(:first-child) {
        margin-top: 0.5em; }

.payout-table-title {
  text-align: left !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  text-transform: uppercase; }

.payout-expandable {
  cursor: pointer;
  padding-right: 40px;
  position: relative; }
  .payout-expandable:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-image: url("/images/icons/caret-down.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 1rem; }
  .payout-expandable.expanded:after {
    transform: rotate(180deg); }

.payout_checkBox {
  margin-bottom: 0.3rem; }

.global-rate-text {
  color: #8b959e;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: bolder;
  font-size: 13px !important;
  font-style: italic !important; }

.caret-gold {
  position: absolute;
  top: calc(50% - 10px);
  width: 49px;
  height: 20px;
  filter: invert(73%) sepia(3%) saturate(2935%) hue-rotate(360deg) brightness(83%) contrast(86%); }

.caret-black {
  position: absolute;
  top: calc(50% - 10px);
  width: 49px;
  height: 20px; }

.flight-header {
  font-size: 14px;
  font-weight: 400;
  display: grid;
  grid-template-columns: 20% 80%;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
  font-weight: bolder;
  color: #0c0c0c;
  border-bottom: 1.5px solid rgba(139, 149, 158, 0.1);
  border-top: 1.5px solid rgba(139, 149, 158, 0.1); }
  .flight-header .flightStatus {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: #417505;
    padding: 0.25rem 0.3rem 0rem 0.3rem;
    height: 15px; }
  .flight-header .flight-delayed {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: #d0021b;
    padding: 0.25rem 0.3rem 0rem 0.3rem;
    height: 15px;
    text-transform: uppercase; }
  .flight-header .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box; }
  .flight-header .flight-header-iata {
    font-size: 12px;
    margin-left: 0.2rem;
    color: #8b959e;
    padding-top: 0.13rem;
    font-weight: bolder; }
  .flight-header.expandable {
    cursor: pointer;
    position: relative; }
    .flight-header.expandable:after {
      content: '';
      position: absolute;
      right: calc(2%);
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url("/images/icons/caret-down.svg");
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat; }
    .flight-header.expandable.expanded:after {
      right: calc(2%);
      transform: rotate(180deg); }

.payment-link-rolzo {
  display: block;
  text-align: center;
  padding-right: 100px;
  padding-top: 40px;
  margin-bottom: 3rem; }
  .payment-link-rolzo img {
    width: 140px; }
  @media (max-width: 768px) {
    .payment-link-rolzo {
      padding-right: 10px;
      margin-bottom: 2rem; } }

.payment-link-body {
  padding: 20px 35px 20px 38px; }
  @media (min-width: 992px) {
    .payment-link-body {
      margin-right: 6.7rem; } }

.payment-link-header {
  display: block;
  text-align: center;
  margin-bottom: 3rem; }

.payment-link-footer {
  font-size: 13px;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.payment-link-prompt {
  background-color: #ffffff;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  padding: 0.5rem 0.5rem;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px; }
  .payment-link-prompt span {
    color: #0c0c0c; }

.payment-link-radio .custom-control-label {
  flex-grow: 1;
  padding-top: 2px;
  cursor: pointer;
  text-transform: none;
  letter-spacing: initial;
  padding-top: 0.5rem; }
  .payment-link-radio .custom-control-label:before, .payment-link-radio .custom-control-label:after {
    top: 1.2px;
    width: 15px;
    height: 15px;
    cursor: pointer; }
  .payment-link-radio .custom-control-label:after {
    background-size: 15px 15px; }

.flight-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  border-bottom: none;
  border-top: none;
  border-radius: 2px; }

.flight-border-bottom {
  border-bottom: 1.5px solid rgba(139, 149, 158, 0.1); }

.flight-border-top-none {
  border-top: none; }

.card-margin {
  margin-bottom: 0.5em; }

.createdBy-text {
  color: #8b959e;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: bolder;
  font-size: 13px !important;
  font-style: italic !important;
  padding-right: 16px;
  float: right;
  margin-top: 0.3rem;
  margin-right: 0.2rem; }

.payment-badge-paid {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #417505;
  padding: 6px 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase; }

.payment-badge-unPaid {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  color: #f5a623;
  padding: 6px 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase; }

.float-btn-wrap {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 48px;
  right: 25px; }
  .float-btn-wrap.notification-btn-wrap {
    bottom: 125px; }
  .float-btn-wrap .unread-indicator {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: red;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    top: 10px;
    right: 12px; }
  .float-btn-wrap .float-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; }
    .float-btn-wrap .float-btn img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    .float-btn-wrap .float-btn.notification-btn {
      background: #a79779;
      border: none; }
      .float-btn-wrap .float-btn.notification-btn img {
        object-fit: contain; }
    .float-btn-wrap .float-btn:hover {
      opacity: 0.9; }

.no-title-main .booking-preview-info .col-booking-detail {
  height: calc(100vh - 130px) !important; }

.no-title-main .booking-preview-info .chat-list-container {
  height: calc(100vh - 290px) !important; }

.no-title-main .booking-preview-info .messages-list {
  height: calc(100vh - 400px) !important; }

.benefits-code {
  padding-right: 2.7rem;
  padding-left: 2.7rem; }

.benefits-code-button {
  padding-right: 1.9rem;
  padding-left: 2.6rem; }

.booking-preview-info .col-booking-detail {
  min-height: 150px;
  height: calc(100vh - 230px);
  overflow: auto;
  padding-bottom: 40px; }

.booking-preview-info .col-booking-detail::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

.booking-preview-info .col-booking-detail::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent; }

.booking-preview-info .col-booking-detail::-webkit-scrollbar-thumb {
  background-color: rgba(120, 128, 134, 0.5) !important;
  border-radius: 10px; }

.booking-preview-info .vehicle-img-small {
  width: 190px; }

.booking-preview-info .booking-preview-info-section {
  padding: 24px;
  background-color: #fafafa;
  font-size: 16px;
  border-radius: 4px; }
  .booking-preview-info .booking-preview-info-section .grey-field-input input,
  .booking-preview-info .booking-preview-info-section .grey-field-input label,
  .booking-preview-info .booking-preview-info-section .grey-field-input .react-select__control,
  .booking-preview-info .booking-preview-info-section .grey-field-input .react-tel-input.form-control,
  .booking-preview-info .booking-preview-info-section .grey-field-input .phone-input.form-control {
    background-color: white !important; }
  .booking-preview-info .booking-preview-info-section .react-tel-input .selected-flag {
    background-color: white !important; }
  .booking-preview-info .booking-preview-info-section .react-select-container .react-select__control {
    background-color: white !important; }
  .booking-preview-info .booking-preview-info-section input.custom-datepicker-control:read-only,
  .booking-preview-info .booking-preview-info-section select.custom-datepicker-control:read-only {
    background-color: white; }
  .booking-preview-info .booking-preview-info-section input.grey-field-input,
  .booking-preview-info .booking-preview-info-section select.grey-field-input {
    background-color: white !important; }
  .booking-preview-info .booking-preview-info-section input.form-control, .booking-preview-info .booking-preview-info-section input.react-phone-number-input__input.react-phone-number-input__phone,
  .booking-preview-info .booking-preview-info-section select.form-control,
  .booking-preview-info .booking-preview-info-section select.react-phone-number-input__input.react-phone-number-input__phone {
    background-color: white !important; }
    .booking-preview-info .booking-preview-info-section input.form-control:read-only, .booking-preview-info .booking-preview-info-section input.form-control:disabled, .booking-preview-info .booking-preview-info-section input.react-phone-number-input__input.react-phone-number-input__phone:read-only, .booking-preview-info .booking-preview-info-section input.react-phone-number-input__input.react-phone-number-input__phone:disabled,
    .booking-preview-info .booking-preview-info-section select.form-control:read-only,
    .booking-preview-info .booking-preview-info-section select.form-control:disabled,
    .booking-preview-info .booking-preview-info-section select.react-phone-number-input__input.react-phone-number-input__phone:read-only,
    .booking-preview-info .booking-preview-info-section select.react-phone-number-input__input.react-phone-number-input__phone:disabled {
      background-color: white; }
  .booking-preview-info .booking-preview-info-section .cancel-booking {
    color: #d0021b; }
    .booking-preview-info .booking-preview-info-section .cancel-booking:hover {
      text-decoration: none;
      color: #d0021b; }
  .booking-preview-info .booking-preview-info-section .mt-20 {
    margin-top: 20px; }
  .booking-preview-info .booking-preview-info-section .chat-list-container {
    height: calc(100vh - 370px);
    width: 100%; }
    .booking-preview-info .booking-preview-info-section .chat-list-container .ScrollbarsCustom .ScrollbarsCustom-Track {
      background-color: #ffffff !important;
      opacity: 0;
      transition: opacity 200ms ease;
      transform: translateY(0px);
      width: 8px !important;
      padding-left: 2px; }
      .booking-preview-info .booking-preview-info-section .chat-list-container .ScrollbarsCustom .ScrollbarsCustom-Track .ScrollbarsCustom-Thumb {
        width: 100%;
        background-color: rgba(var(--sk_primary_foreground, 29, 28, 29), 0.52); }
    .booking-preview-info .booking-preview-info-section .chat-list-container .ScrollbarsCustom:hover .ScrollbarsCustom-Track {
      opacity: 1; }
  .booking-preview-info .booking-preview-info-section:not(:last-child) {
    margin-bottom: 20px; }
  .booking-preview-info .booking-preview-info-section label {
    font-size: 10px; }
  .booking-preview-info .booking-preview-info-section .benefit-Fields div:last-child label {
    font-size: 10px;
    color: #47dc95 !important; }
  .booking-preview-info .booking-preview-info-section .section-header {
    font-size: 20px;
    line-height: 43px;
    font-weight: 400; }
    .booking-preview-info .booking-preview-info-section .section-header.expandable {
      cursor: pointer;
      padding-right: 40px;
      position: relative; }
      .booking-preview-info .booking-preview-info-section .section-header.expandable:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-image: url("/images/icons/caret-down.svg");
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat; }
      .booking-preview-info .booking-preview-info-section .section-header.expandable.expanded:after {
        transform: rotate(180deg); }
    .booking-preview-info .booking-preview-info-section .section-header.booking-header {
      display: flex;
      align-items: center; }
      .booking-preview-info .booking-preview-info-section .section-header.booking-header .service-label {
        line-height: 15px !important;
        margin-left: 25px; }
    .booking-preview-info .booking-preview-info-section .section-header.closing-header {
      display: inline-block; }
  .booking-preview-info .booking-preview-info-section .section-sub-header {
    font-size: 18px;
    line-height: 43px;
    margin-bottom: 5px;
    font-weight: 400; }
    .booking-preview-info .booking-preview-info-section .section-sub-header.expandable {
      cursor: pointer;
      padding-right: 40px;
      position: relative; }
      .booking-preview-info .booking-preview-info-section .section-sub-header.expandable:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-image: url("/images/icons/caret-down.svg");
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat; }
      .booking-preview-info .booking-preview-info-section .section-sub-header.expandable.expanded:after {
        transform: rotate(180deg); }
    .booking-preview-info .booking-preview-info-section .section-sub-header.booking-header {
      display: flex;
      align-items: center; }
      .booking-preview-info .booking-preview-info-section .section-sub-header.booking-header .service-label {
        line-height: 15px !important;
        margin-left: 25px; }
    .booking-preview-info .booking-preview-info-section .section-sub-header.closing-header {
      display: inline-block; }
  .booking-preview-info .booking-preview-info-section .booking-preview-info-section-close {
    float: right;
    margin-top: 8px; }
    .booking-preview-info .booking-preview-info-section .booking-preview-info-section-close img {
      width: 12px; }
  .booking-preview-info .booking-preview-info-section .form-group p {
    font-size: 15px;
    text-transform: capitalize; }
  .booking-preview-info .booking-preview-info-section .form-group pre {
    font-size: 15px; }
  .booking-preview-info .booking-preview-info-section .model-row {
    margin-bottom: 15px; }
  .booking-preview-info .booking-preview-info-section .vehicle-value {
    color: #0c0c0c; }
    .booking-preview-info .booking-preview-info-section .vehicle-value img {
      width: auto;
      height: 11px;
      margin-right: 5px;
      margin-bottom: 3px;
      display: inline-block; }
  .booking-preview-info .booking-preview-info-section .electric-vehicle {
    color: #28ae0f; }
    .booking-preview-info .booking-preview-info-section .electric-vehicle img {
      width: auto;
      height: 10px;
      margin-right: 5px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: baseline; }
  .booking-preview-info .booking-preview-info-section .benefits-body {
    font-size: 15px !important;
    margin-top: 1%;
    padding-left: 1%; }
  .booking-preview-info .booking-preview-info-section .black-Button {
    border-radius: 2px;
    background-color: white !important;
    color: #0c0c0c;
    border: 1px dashed !important; }
  .booking-preview-info .booking-preview-info-section .black-button-width {
    max-width: fit-content; }
  .booking-preview-info .booking-preview-info-section .meet-and-assist-description {
    font-size: 14px;
    color: #303030;
    padding-left: 5px;
    display: block;
    line-height: 27px !important; }
  .booking-preview-info .booking-preview-info-section .meet-and-assist-features {
    padding-left: 5px;
    padding-top: 2.5em;
    padding-bottom: 2em; }
    .booking-preview-info .booking-preview-info-section .meet-and-assist-features div {
      background: url("/images/Tick_Icon.png");
      font-size: 14px;
      background-position: top 3px left 0px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      padding-left: 28px;
      margin-bottom: 4px; }
  .booking-preview-info .booking-preview-info-section .meet-and-assist-confirm {
    line-height: 34px !important;
    margin-bottom: 0px !important; }
  .booking-preview-info .booking-preview-info-section .meet-and-assist-confirm-inner {
    padding-left: 5px !important;
    padding-top: 2em !important;
    padding-bottom: 2em !important; }
  .booking-preview-info .booking-preview-info-section .meet-assist-payment-block {
    font-size: 15px;
    margin-top: 1em;
    display: flex;
    justify-content: space-between; }
  .booking-preview-info .booking-preview-info-section .status-paid {
    font-size: 13px;
    color: #417505; }
  .booking-preview-info .booking-preview-info-section .status-unpaid {
    font-size: 13px;
    color: #8b959e; }

.booking-preview-info .booking-preview-info-section-booking-summary {
  padding: 24px;
  background-color: #fafafa;
  font-size: 15px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .cancel-booking {
    color: #d0021b; }
    .booking-preview-info .booking-preview-info-section-booking-summary .cancel-booking:hover {
      text-decoration: none;
      color: #d0021b; }
  .booking-preview-info .booking-preview-info-section-booking-summary .mt-20 {
    margin-top: 20px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .chat-list-container {
    height: calc(100vh - 370px);
    width: 100%; }
    .booking-preview-info .booking-preview-info-section-booking-summary .chat-list-container .ScrollbarsCustom .ScrollbarsCustom-Track {
      background-color: #ffffff !important;
      opacity: 0;
      transition: opacity 200ms ease;
      transform: translateY(0px);
      width: 8px !important;
      padding-left: 2px; }
      .booking-preview-info .booking-preview-info-section-booking-summary .chat-list-container .ScrollbarsCustom .ScrollbarsCustom-Track .ScrollbarsCustom-Thumb {
        width: 100%;
        background-color: rgba(var(--sk_primary_foreground, 29, 28, 29), 0.52); }
    .booking-preview-info .booking-preview-info-section-booking-summary .chat-list-container .ScrollbarsCustom:hover .ScrollbarsCustom-Track {
      opacity: 1; }
  .booking-preview-info .booking-preview-info-section-booking-summary:not(:last-child) {
    margin-bottom: 20px; }
  .booking-preview-info .booking-preview-info-section-booking-summary label {
    font-size: 10px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .benefit-Fields div:last-child label {
    font-size: 10px;
    color: #47dc95 !important; }
  .booking-preview-info .booking-preview-info-section-booking-summary .section-header {
    font-size: 20px;
    line-height: 43px;
    font-weight: 400; }
    .booking-preview-info .booking-preview-info-section-booking-summary .section-header.expandable {
      cursor: pointer;
      padding-right: 40px;
      position: relative; }
      .booking-preview-info .booking-preview-info-section-booking-summary .section-header.expandable:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-image: url("/images/icons/caret-down.svg");
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat; }
      .booking-preview-info .booking-preview-info-section-booking-summary .section-header.expandable.expanded:after {
        transform: rotate(180deg); }
    .booking-preview-info .booking-preview-info-section-booking-summary .section-header.booking-header {
      display: flex;
      align-items: center; }
      .booking-preview-info .booking-preview-info-section-booking-summary .section-header.booking-header .service-label {
        line-height: 15px !important;
        margin-left: 25px; }
    .booking-preview-info .booking-preview-info-section-booking-summary .section-header.closing-header {
      display: inline-block; }
  .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header {
    font-size: 18px;
    line-height: 43px;
    margin-bottom: 5px;
    font-weight: 400; }
    .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header.expandable {
      cursor: pointer;
      padding-right: 40px;
      position: relative; }
      .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header.expandable:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-image: url("/images/icons/caret-down.svg");
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat; }
      .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header.expandable.expanded:after {
        transform: rotate(180deg); }
    .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header.booking-header {
      display: flex;
      align-items: center; }
      .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header.booking-header .service-label {
        line-height: 15px !important;
        margin-left: 25px; }
    .booking-preview-info .booking-preview-info-section-booking-summary .section-sub-header.closing-header {
      display: inline-block; }
  .booking-preview-info .booking-preview-info-section-booking-summary .booking-preview-info-section-close {
    float: right;
    margin-top: 8px; }
    .booking-preview-info .booking-preview-info-section-booking-summary .booking-preview-info-section-close img {
      width: 12px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .form-group p {
    font-size: 15px;
    text-transform: capitalize; }
  .booking-preview-info .booking-preview-info-section-booking-summary .form-group pre {
    font-size: 15px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .model-row {
    margin-bottom: 15px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .vehicle-value {
    color: #0c0c0c; }
    .booking-preview-info .booking-preview-info-section-booking-summary .vehicle-value img {
      width: auto;
      height: 11px;
      margin-right: 5px;
      margin-bottom: 3px;
      display: inline-block; }
  .booking-preview-info .booking-preview-info-section-booking-summary .electric-vehicle {
    color: #28ae0f; }
    .booking-preview-info .booking-preview-info-section-booking-summary .electric-vehicle img {
      width: auto;
      height: 10px;
      margin-right: 5px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: baseline; }
  .booking-preview-info .booking-preview-info-section-booking-summary .benefits-body {
    font-size: 15px !important;
    margin-top: 1%;
    padding-left: 1%; }
  .booking-preview-info .booking-preview-info-section-booking-summary .black-Button {
    border-radius: 2px;
    background-color: white !important;
    color: #0c0c0c;
    border: 1px dashed !important; }
  .booking-preview-info .booking-preview-info-section-booking-summary .black-button-width {
    max-width: fit-content; }
  .booking-preview-info .booking-preview-info-section-booking-summary .meet-and-assist-description {
    font-size: 14px;
    color: #303030;
    padding-left: 5px;
    display: block;
    line-height: 27px !important; }
  .booking-preview-info .booking-preview-info-section-booking-summary .meet-and-assist-features {
    padding-left: 5px;
    padding-top: 2.5em;
    padding-bottom: 2em; }
    .booking-preview-info .booking-preview-info-section-booking-summary .meet-and-assist-features div {
      background: url("/images/Tick_Icon.png");
      font-size: 14px;
      background-position: top 3px left 0px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      padding-left: 28px;
      margin-bottom: 4px; }
  .booking-preview-info .booking-preview-info-section-booking-summary .meet-and-assist-confirm {
    line-height: 34px !important;
    margin-bottom: 0px !important; }
  .booking-preview-info .booking-preview-info-section-booking-summary .meet-and-assist-confirm-inner {
    padding-left: 5px !important;
    padding-top: 2em !important;
    padding-bottom: 2em !important; }
  .booking-preview-info .booking-preview-info-section-booking-summary .meet-assist-payment-block {
    font-size: 15px;
    margin-top: 1em;
    display: flex;
    justify-content: space-between; }
  .booking-preview-info .booking-preview-info-section-booking-summary .status-paid {
    font-size: 13px;
    color: #417505; }
  .booking-preview-info .booking-preview-info-section-booking-summary .status-unpaid {
    font-size: 13px;
    color: #8b959e; }

.booking-preview-info .booking-summary-section {
  padding-left: 6px;
  padding-right: 6px; }

.booking-preview-info .driver-actions--container {
  margin-top: 20px; }
  .booking-preview-info .driver-actions--container .nav-tabs {
    background: rgba(139, 149, 158, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 25px; }
    .booking-preview-info .driver-actions--container .nav-tabs .nav-link {
      color: #8b959e;
      border: none;
      border-radius: 3px; }
      .booking-preview-info .driver-actions--container .nav-tabs .nav-link:hover {
        color: #303030; }
      .booking-preview-info .driver-actions--container .nav-tabs .nav-link.active {
        background-color: #fff;
        font-weight: bold;
        color: #0c0c0c;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
      .booking-preview-info .driver-actions--container .nav-tabs .nav-link.disabled {
        pointer-events: inherit; }
    .booking-preview-info .driver-actions--container .nav-tabs .redTab {
      color: red !important; }
    .booking-preview-info .driver-actions--container .nav-tabs .reload-button {
      display: flex;
      align-items: flex-end;
      font-size: 10px; }
      .booking-preview-info .driver-actions--container .nav-tabs .reload-button img {
        width: 20px;
        height: 20px;
        margin-right: 3px; }
        .booking-preview-info .driver-actions--container .nav-tabs .reload-button img.reloading {
          -webkit-animation: spin 2s linear infinite;
          -moz-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite; }
  .booking-preview-info .driver-actions--container .booking-operation-container {
    padding: 0px 10px; }
    .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item {
      padding: 10px 0px 10px 20px;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status {
        flex-basis: 50%; }
        .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status:before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: #a79779;
          position: absolute;
          left: 0px;
          top: 15px; }
        .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status--label {
          font-size: 14px;
          color: #0c0c0c;
          margin-bottom: 0; }
          .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status--label:last-of-type {
            margin-bottom: 1rem; }
        .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status--detail {
          font-size: 12px;
          color: #8b959e;
          margin-top: -12px; }
          .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status--detail .sms-status .received {
            position: relative; }
            .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--status--detail .sms-status .received:before {
              display: block;
              background-image: url(/images/icons/check_2.png);
              background-size: cover;
              width: 12px;
              height: 9px;
              content: '';
              right: -26px;
              top: -1px;
              position: absolute; }
      .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--time {
        flex-basis: 35%;
        text-align: right;
        padding-right: 10px; }
        .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--time--diff {
          font-size: 12px;
          color: #323c47;
          font-weight: bold; }
        .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--time--detail {
          font-size: 12px;
          color: #8b959e;
          margin-top: -12px; }
      .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--location {
        flex-basis: 30%;
        padding: 0 5px; }
        .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--location .location--container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          width: 100%; }
          .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--location .location--container .map--container {
            position: relative;
            height: 100%; }
          .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--location .location--container .view-location {
            text-align: right;
            cursor: pointer; }
            .booking-preview-info .driver-actions--container .booking-operation-container .booking-operation-item--location .location--container .view-location img {
              width: 20px; }

.booking-preview-info .booking-main-section {
  margin-left: 10px;
  margin-top: 2.2em; }
  .booking-preview-info .booking-main-section .events-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    height: 20px;
    min-height: 43px; }
    .booking-preview-info .booking-main-section .events-header .section-header {
      margin: 0; }

.booking-preview-info .payment-link-main-section {
  margin-left: 10px;
  margin-top: 1.5em; }
  @media (max-width: 768px) {
    .booking-preview-info .payment-link-main-section {
      margin-left: 0px;
      margin-right: 0.625em;
      margin-top: 0em; } }

.booking-preview-info .booking-event-section {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  min-height: 150px;
  position: relative;
  width: calc(100% - 20px); }
  .booking-preview-info .booking-event-section .booking-form-wrap {
    max-width: 900px; }
  .booking-preview-info .booking-event-section .events-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    height: 20px;
    min-height: 43px; }
    .booking-preview-info .booking-event-section .events-header .section-header {
      margin: 0; }
    .booking-preview-info .booking-event-section .events-header .section-sub-header {
      margin: 0; }
    .booking-preview-info .booking-event-section .events-header .cancel-booking {
      color: #d0021b; }
      .booking-preview-info .booking-event-section .events-header .cancel-booking:hover {
        text-decoration: none;
        color: #d0021b; }
  .booking-preview-info .booking-event-section .events-div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 15px;
    background-color: #fafafa;
    padding: 24px;
    border-radius: 4px; }
    .booking-preview-info .booking-event-section .events-div .events-near-me-card {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      background-color: #fafafa;
      border-radius: 2px;
      font-size: 15px;
      color: #0c0c0c;
      margin-top: 10px;
      margin-bottom: 5px; }
      @media (max-width: 991.98px) {
        .booking-preview-info .booking-event-section .events-div .events-near-me-card {
          gap: 5px;
          align-items: flex-start; } }
      .booking-preview-info .booking-event-section .events-div .events-near-me-card .text {
        flex: 1; }
      .booking-preview-info .booking-event-section .events-div .events-near-me-card .duration {
        font-size: 14px;
        color: #636d77; }
    .booking-preview-info .booking-event-section .events-div .sep {
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 3px;
      background-color: #8b959e;
      vertical-align: middle;
      margin-left: 4px;
      margin-bottom: 2px;
      margin-right: 1px; }
    .booking-preview-info .booking-event-section .events-div .event-details {
      display: flex;
      gap: 1.5rem;
      margin-top: 4px; }
      .booking-preview-info .booking-event-section .events-div .event-details span {
        font-size: 14px;
        color: #636d77; }
      .booking-preview-info .booking-event-section .events-div .event-details .temp {
        width: 20px;
        height: 20px;
        right: 2px;
        position: relative;
        bottom: 3px; }
      .booking-preview-info .booking-event-section .events-div .event-details .traffic-status {
        display: flex;
        align-items: baseline; }
      .booking-preview-info .booking-event-section .events-div .event-details .traffic {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 5px; }
      .booking-preview-info .booking-event-section .events-div .event-details .heavy-traffic {
        background-color: #d0021b; }
      .booking-preview-info .booking-event-section .events-div .event-details .moderate-traffic {
        background-color: #f5a623; }
      .booking-preview-info .booking-event-section .events-div .event-details .normal-traffic {
        background-color: #417505; }
  .booking-preview-info .booking-event-section .messages-list {
    height: calc(100vh - 440px); }
    .booking-preview-info .booking-event-section .messages-list .message-header {
      line-height: 36px; }
    .booking-preview-info .booking-event-section .messages-list .vehicle-card {
      width: unset;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); }
  .booking-preview-info .booking-event-section .message-block-mobile {
    padding-right: 1% !important; }
  .booking-preview-info .booking-event-section .message-block {
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
    border-top: 1px solid rgba(12, 12, 12, 0.05);
    padding-right: 15%; }
    .booking-preview-info .booking-event-section .message-block:hover {
      text-decoration: none; }
    .booking-preview-info .booking-event-section .message-block .message-header {
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 32vw;
      max-width: 100%;
      flex-direction: row; }
      .booking-preview-info .booking-event-section .message-block .message-header .event-type-indicator {
        width: 40px;
        display: flex;
        justify-content: center; }
      .booking-preview-info .booking-event-section .message-block .message-header p {
        padding: 0;
        margin: 0; }
        .booking-preview-info .booking-event-section .message-block .message-header p.date-row {
          padding-left: 10px;
          font-size: 12px;
          color: #8b959e; }
      .booking-preview-info .booking-event-section .message-block .message-header pre {
        padding: 0;
        margin: 0; }
        .booking-preview-info .booking-event-section .message-block .message-header pre.date-row {
          padding-left: 10px;
          font-size: 12px;
          color: #8b959e; }
    .booking-preview-info .booking-event-section .message-block .message-content {
      padding-left: 50px; }
      .booking-preview-info .booking-event-section .message-block .message-content p {
        padding: 0;
        margin: 0;
        font-size: 15px; }
        .booking-preview-info .booking-event-section .message-block .message-content p.text-content {
          font-size: 15px;
          color: #0c0c0c;
          word-break: break-word; }
          .booking-preview-info .booking-event-section .message-block .message-content p.text-content img {
            width: 14px;
            height: auto;
            margin: 0 6px 0 0;
            margin-bottom: 5px; }
        .booking-preview-info .booking-event-section .message-block .message-content p.date-row {
          font-size: 8px;
          color: #8b959e; }
        .booking-preview-info .booking-event-section .message-block .message-content p:hover {
          text-decoration: none; }
      .booking-preview-info .booking-event-section .message-block .message-content .download-link {
        color: #a79779;
        font-size: 10px; }
        .booking-preview-info .booking-event-section .message-block .message-content .download-link .pdf-icon {
          width: 11px;
          height: 11px;
          margin-right: 7px; }
        .booking-preview-info .booking-event-section .message-block .message-content .download-link:hover {
          text-decoration: none; }
    .booking-preview-info .booking-event-section .message-block .user-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid rgba(12, 12, 12, 0.1);
      overflow: hidden; }
      .booking-preview-info .booking-event-section .message-block .user-avatar.user-online {
        border: 1px solid #417505; }
      .booking-preview-info .booking-event-section .message-block .user-avatar img {
        display: block;
        width: 34px;
        height: auto;
        object-fit: cover;
        object-position: center center; }
    .booking-preview-info .booking-event-section .message-block .client-indicator {
      background: rgba(139, 149, 158, 0.3);
      height: 10px;
      width: 10px;
      border-radius: 10px; }
    .booking-preview-info .booking-event-section .message-block .vehicle-wrap {
      order: 2;
      color: rgba(33, 55, 71, 0.6);
      font-size: 8px; }
  .booking-preview-info .booking-event-section .input-message-block {
    bottom: 10px;
    width: 100%;
    background: white; }
    .booking-preview-info .booking-event-section .input-message-block .input-message-area {
      display: flex;
      align-items: flex-start;
      width: auto;
      max-width: none;
      padding: 0; }
      .booking-preview-info .booking-event-section .input-message-block .input-message-area .logo {
        width: 45px;
        min-width: 45px;
        display: flex;
        justify-content: center;
        margin-right: 15px; }
      .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap {
        width: calc(100% - 100px);
        margin: 0;
        padding-top: 2px;
        position: relative;
        flex-grow: 1; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .form-group {
          margin-bottom: 0px; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .chat-paperclip {
          position: absolute;
          background: transparent;
          left: 5px; }
          .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .chat-paperclip .custom-file-label {
            background: transparent; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .toolbar-buttons {
          position: absolute;
          left: 5px;
          top: 7px;
          display: flex;
          align-items: center; }
          .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .toolbar-buttons .toolbar-btn.car-btn {
            width: 24px;
            height: 24px;
            margin-right: 22px;
            z-index: 1; }
          .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .toolbar-buttons .toolbar-btn img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap textarea {
          padding-left: 40px !important;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          border-color: rgba(12, 12, 12, 0.1);
          font-size: 15px !important; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap textarea::placeholder {
          color: #000000;
          opacity: 0.4; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap textarea:-ms-input-placeholder {
          color: #000000;
          opacity: 0.4; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap textarea::-ms-input-placeholder {
          color: #000000;
          opacity: 0.4; }
        .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .placeholder-icon-wrap {
          position: absolute;
          z-index: 1;
          top: 11px;
          background: transparent; }
          .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap .placeholder-icon-wrap .placeholder-icon {
            width: 16px;
            height: 16px;
            object-fit: contain; }
  .booking-preview-info .booking-event-section .preview-files-block {
    margin-top: 20px; }
    .booking-preview-info .booking-event-section .preview-files-block .file-preview {
      justify-content: flex-start; }
    .booking-preview-info .booking-event-section .preview-files-block .image-preview-label {
      max-width: unset;
      width: 100%;
      overflow: hidden;
      margin: 0 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px; }
    .booking-preview-info .booking-event-section .preview-files-block .remove-file-btn {
      display: block;
      color: #a79779; }
      .booking-preview-info .booking-event-section .preview-files-block .remove-file-btn:hover {
        text-decoration: none;
        color: #a79779; }
      .booking-preview-info .booking-event-section .preview-files-block .remove-file-btn img {
        width: 10px;
        height: 10px; }
  .booking-preview-info .booking-event-section .send-btn-wrap {
    overflow: hidden;
    padding: 0; }
    .booking-preview-info .booking-event-section .send-btn-wrap a {
      padding: 6px 6px; }
      .booking-preview-info .booking-event-section .send-btn-wrap a img {
        width: 26px;
        height: 20px; }
  .booking-preview-info .booking-event-section .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid rgba(12, 12, 12, 0.1);
    overflow: hidden; }
  .booking-preview-info .booking-event-section .user-logo img {
    width: 100%;
    height: 100%; }
  .booking-preview-info .booking-event-section .default-logo {
    height: 34px;
    width: 34px;
    min-width: 34px;
    border-radius: 50%;
    background: rgba(139, 149, 158, 0.25); }

.vip-section {
  display: flex;
  justify-content: space-between; }
  .vip-section img {
    width: 16px;
    height: 14px; }

.vip-passenger-img {
  width: 17px;
  height: 15px;
  margin-left: 0.5rem;
  margin-bottom: 4px; }

.rolzo-first-star-icon {
  width: 14.5px;
  height: 15px;
  margin-left: 0.5rem;
  margin-bottom: 4px; }

.follow-up-img {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  margin-bottom: 4px; }

.booking-total {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .booking-total .total-title {
    font-size: 20px; }
  .booking-total .total-value {
    font-size: 25px; }
  .booking-total .total-value-confirm-booking {
    font-size: 20px !important; }
  .booking-total .total-value-confirm-booking-mobile {
    font-size: 18px !important;
    font-weight: 500 !important; }
  .booking-total .total-title-confirm-booking-mobile {
    font-size: 18px !important;
    font-weight: 500 !important; }
  .booking-total .total-title-new-mobile {
    font-size: 16px !important;
    color: #303030; }
  .booking-total .total-value-new-mobile {
    font-size: 16px !important;
    color: #0c0c0c; }
  .booking-total .total-title-new {
    font-size: 18px !important;
    color: #303030; }
  .booking-total .total-value-new {
    font-size: 18px; }

.discount-value {
  text-align: end;
  font-size: 12px;
  color: #8b959e; }

.exclusive-benefits-font {
  font-size: 1rem !important;
  color: #303030; }

.total-wrap {
  flex-direction: column; }
  .total-wrap .total-title {
    font-size: 15px;
    color: #8b959e; }
  .total-wrap .dispatch-title {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    color: #8b959e;
    margin-bottom: 0.3rem;
    line-height: 12px;
    margin-top: 5px; }
  .total-wrap .dispatch-text {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 1;
    background-color: rgba(139, 149, 158, 0.15);
    padding: 12px; }
    .total-wrap .dispatch-text a {
      font-size: 14px !important; }
    .total-wrap .dispatch-text a:hover {
      text-decoration: none; }
  .total-wrap .dispatch-text-mismatch {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 1;
    background-color: rgba(139, 149, 158, 0.15);
    padding: 12px;
    border: 1px solid #d0021b; }
  .total-wrap .mismatch-text {
    color: #d0021b;
    font-size: 12px;
    float: right; }
  .total-wrap .dispatch-textarea {
    font-size: 14px;
    margin-bottom: 1;
    background-color: rgba(139, 149, 158, 0.15);
    padding: 13px; }
  .total-wrap .total-link {
    color: #96866a;
    font-size: 10px; }
  .total-wrap .total-title-benefit {
    font-size: 11px;
    color: #8b959e; }

.total-wrap-half {
  flex-direction: column;
  width: 50%; }
  .total-wrap-half .total-title {
    font-size: 15px;
    color: #8b959e; }
  .total-wrap-half .total-title-benefit {
    font-size: 11px;
    color: #8b959e; }

.payment-section {
  display: flex;
  justify-content: space-between; }

.success-block {
  min-width: 163px; }
  .success-block .confirmation-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 15px; }
  .success-block .download-link {
    color: #a79779;
    font-size: 14px; }
    .success-block .download-link .pdf-icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-bottom: 7.5px; }
    .success-block .download-link:hover {
      text-decoration: none; }

.success-payment-block {
  min-width: 163px;
  justify-content: end;
  display: grid; }
  .success-payment-block .success-payment-msg {
    color: green;
    font-size: 14px;
    text-align: right; }
  .success-payment-block .danger-payment-msg {
    color: red;
    font-size: 14px;
    text-align: right; }
  .success-payment-block .black-payment-msg {
    color: #0c0c0c;
    font-size: 14px;
    text-align: right; }
  .success-payment-block .download-link {
    color: #a79779;
    font-size: 14px; }
    .success-payment-block .download-link .pdf-icon {
      width: 14px;
      height: 14px;
      margin-right: 7px;
      margin-bottom: 7.5px; }
    .success-payment-block .download-link:hover {
      text-decoration: none; }
  .success-payment-block .invoice-link {
    padding-right: 5.7rem; }

.authorized-driver-files .download-link {
  color: #a79779;
  font-size: 14px; }
  .authorized-driver-files .download-link .pdf-icon {
    width: 14px;
    height: 14px;
    margin-right: 7px; }
  .authorized-driver-files .download-link:hover {
    text-decoration: none; }

.success-charges-payment {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

::-webkit-scrollbar-thumb {
  background-color: rgba(120, 128, 134, 0.5) !important;
  height: 10%;
  opacity: 0.5; }

.charge-form .is-invalid {
  padding-right: 5px; }

.driver-card-pb-0 {
  padding: 0px !important;
  margin-top: 0px !important; }
  .driver-card-pb-0 .sub-header {
    margin-bottom: 0px !important; }

.driver-card {
  padding: 20px;
  margin-top: 50px; }

.booking-form-wrap {
  max-width: 700px;
  margin-top: 10px; }
  .booking-form-wrap .distance-duration {
    margin-top: 1.2rem;
    font-size: 14px;
    color: #8b959e; }
  .booking-form-wrap .distance-duration-new {
    margin-top: 15px;
    font-size: 14px;
    color: #8b959e; }
  .booking-form-wrap .cancelable {
    color: #d0021b;
    text-transform: initial;
    margin-top: -9px;
    font-size: 12px; }
  .booking-form-wrap .white-field-input input.custom-datepicker-control {
    background-color: #ffffff;
    border: 0.5px solid rgba(140, 150, 159, 0.3); }
    .booking-form-wrap .white-field-input input.custom-datepicker-control:hover, .booking-form-wrap .white-field-input input.custom-datepicker-control:active {
      border: 0.5px solid #8c969f !important; }
  .booking-form-wrap .white-field-input .react-select-container .react-select__control {
    background-color: white;
    border: 0.5px solid rgba(140, 150, 159, 0.3); }
    .booking-form-wrap .white-field-input .react-select-container .react-select__control:hover, .booking-form-wrap .white-field-input .react-select-container .react-select__control:active {
      border: 0.5px solid #8c969f !important; }
  .booking-form-wrap .custom-file-label {
    z-index: unset !important; }
  .booking-form-wrap .new-booking-wrap {
    background: #fafafa;
    padding: 2em;
    padding-right: 2.1em;
    margin-right: 2em;
    border-radius: 2px; }
  .booking-form-wrap .b2c-booking-wrap {
    background: #fafafa;
    padding: 2em;
    padding-right: 2.1em;
    border-radius: 2px; }
  .booking-form-wrap .results-wrap {
    margin-right: 2em;
    padding-bottom: 10rem;
    margin-top: 1rem; }
  .booking-form-wrap .reduce-field-width {
    width: 90% !important; }
  .booking-form-wrap .new-booking-wrap {
    padding: 30px 30px 40px 47px; }
    .booking-form-wrap .new-booking-wrap .new-booking-title {
      margin-left: 0; }
    .booking-form-wrap .new-booking-wrap .itinerary-container {
      position: relative; }
    .booking-form-wrap .new-booking-wrap .itinerary-indicator {
      position: absolute;
      left: -19px; }
    .booking-form-wrap .new-booking-wrap .remove-button-stops-container,
    .booking-form-wrap .new-booking-wrap .remove-button-stops.absolute {
      position: absolute;
      right: -23px;
      top: 50%;
      transform: translateY(-50%);
      left: unset; }
    .booking-form-wrap .new-booking-wrap .form-row {
      margin-inline: 0; }
  .booking-form-wrap .row-without-stops-start {
    width: 100%;
    padding-right: 23px; }
  .booking-form-wrap .row-without-stops {
    width: 100%; }
  .booking-form-wrap .row-with-stops {
    width: 100%; }
  .booking-form-wrap .line-break-daily {
    margin-top: 2.1rem;
    margin-bottom: 3rem; }
  .booking-form-wrap .new-booking-container {
    margin-bottom: 1.5em; }
    .booking-form-wrap .new-booking-container .new-booking-label {
      padding: 0.25rem 1.25rem;
      background-color: rgba(139, 149, 158, 0.1);
      border-radius: 2px;
      font-size: 14px;
      font-weight: 800;
      color: black;
      width: fit-content;
      margin-bottom: 2rem; }
    .booking-form-wrap .new-booking-container .new-booking-title {
      font-size: 24px;
      margin-left: 22px;
      margin-bottom: 0 !important; }
    .booking-form-wrap .new-booking-container .b2c-booking-title {
      font-size: 20px;
      margin-left: 22px;
      margin-bottom: 0 !important; }
    .booking-form-wrap .new-booking-container .selected {
      border-bottom: 1px solid black;
      padding-bottom: 4px; }
    .booking-form-wrap .new-booking-container .not-selected {
      color: rgba(139, 149, 158, 0.5);
      cursor: pointer; }
  .booking-form-wrap .itinerary-container .itinerary-title {
    font-size: 20px; }
  .booking-form-wrap .itinerary-container .itinerary-location-container {
    display: flex;
    column-gap: 1em; }
    .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator {
      margin-top: 2.5em;
      display: flex;
      flex-direction: column; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .circle-empty {
        height: 8px;
        width: 8px;
        border: 1.5px solid #8b959e;
        border-radius: 50%;
        display: inline-block;
        z-index: 0;
        background-color: white; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .circle-filled-pu {
        height: 8px;
        width: 8px;
        background-color: #8c969f;
        border-radius: 50%;
        display: inline-block; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .circle-filled {
        height: 8px;
        width: 8px;
        background-color: #0c0c0c;
        border-radius: 50%;
        display: inline-block;
        z-index: 0; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .dotted-container {
        position: relative;
        width: 8px;
        height: 75px;
        overflow: hidden; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .dotted-container-stop-last {
        position: relative;
        width: 8px;
        height: 193px;
        overflow: hidden; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .dotted-container-stop {
        position: relative;
        width: 8px;
        height: 158px;
        overflow: hidden; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .dotted-container-last {
        position: relative;
        width: 8px;
        height: 110px;
        overflow: hidden; }
      .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-indicator .dotted-line {
        transform: rotate(90deg);
        position: absolute;
        top: -9999px;
        left: -9999px;
        right: -9999px;
        bottom: -9999px;
        margin: auto; }
    .booking-form-wrap .itinerary-container .itinerary-location-container .itinerary-fields {
      width: 100%; }

.remove-button-preference {
  position: relative;
  top: 3.5px;
  left: 3px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
  cursor: pointer; }

.remove-button-stops {
  position: relative;
  top: 30.5px;
  left: 3px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
  cursor: pointer; }

.remove-button-stops-details {
  position: relative;
  top: 30.5px;
  right: 5px;
  height: 23px;
  width: 23px;
  border-radius: 18px;
  cursor: pointer; }

.override-max-width {
  max-width: none !important; }

#search-container {
  overflow-x: none; }

#search-container-rental {
  width: calc(100vw - 101px - 55.7%);
  padding-left: 2em; }

#search-container::-webkit-scrollbar {
  width: 10px; }

#search-container::-webkit-scrollbar-track {
  border: 1px solid rgba(139, 149, 158, 0.3);
  border-top: none;
  border-bottom: none; }

#search-container::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: rgba(139, 149, 158, 0.3) !important;
  border-radius: 5px; }

.bottom-stepper {
  display: flex;
  position: sticky;
  bottom: 0;
  border-top: 1px solid rgba(139, 149, 158, 0.3);
  padding: 0.75rem 2rem;
  margin-left: -4rem;
  background-color: #ffffff;
  z-index: 1; }
  .bottom-stepper .stepper-btn {
    width: 200px;
    padding: 0 !important; }

.bottom-stepper-cont {
  display: flex;
  position: sticky;
  bottom: 0;
  margin-left: -4rem;
  width: calc(100vw - 101px);
  border-top: 1px solid rgba(139, 149, 158, 0.3);
  background-color: #ffffff; }

.bottom-stepper-div {
  display: flex;
  padding: 0.75rem 2rem;
  width: calc(100vw - 101px - 49%); }
  .bottom-stepper-div .stepper-btn {
    width: 200px;
    padding: 0 !important; }

.booking-form-wrap-payment {
  max-width: none;
  margin-top: 10px; }
  .booking-form-wrap-payment .distance-duration {
    margin-top: 1.2rem;
    font-size: 14px;
    color: #8b959e; }
  .booking-form-wrap-payment .distance-duration-new {
    margin-top: 5px;
    font-size: 14px;
    color: #8b959e; }
  .booking-form-wrap-payment .cancelable {
    color: #d0021b;
    text-transform: initial;
    margin-top: -9px;
    font-size: 12px; }
  .booking-form-wrap-payment .custom-file-label {
    z-index: unset !important; }

.stop-map-marker {
  width: 10px;
  height: 10px;
  background-color: #8c969f;
  border-radius: 5px;
  color: white; }

.booking-empty-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 45px;
  border-radius: 2px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1); }
  .booking-empty-search h5 {
    margin-bottom: 14px;
    font-size: 20px;
    color: #303030;
    font-weight: normal; }
  .booking-empty-search .empty-description {
    font-size: 15px;
    font-weight: 400;
    color: #8b959e;
    white-space: pre-wrap; }
  .booking-empty-search .chat-btn,
  .booking-empty-search .float-btn {
    position: static;
    flex-shrink: 0;
    margin-left: 10px;
    width: 70px;
    height: 70px; }

.flight-preview {
  display: flex;
  justify-content: space-between; }
  .flight-preview .flight-preview-form {
    width: 50%; }

.invoicing-mode {
  float: right;
  margin-top: -25px;
  padding: 5px;
  border-radius: 3px;
  color: gray;
  margin-right: 34px;
  font-size: 12px; }

.cancel-booking-modal .modal-header {
  border-bottom: none !important;
  padding-bottom: 0px; }
  .cancel-booking-modal .modal-header .modal-title {
    width: 100%; }
    .cancel-booking-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .cancel-booking-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .cancel-booking-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .cancel-booking-modal .modal-header .modal-title .btn-link {
      margin-right: 2%;
      margin-top: 2%; }
      .cancel-booking-modal .modal-header .modal-title .btn-link:hover, .cancel-booking-modal .modal-header .modal-title .btn-link:focus {
        outline: none; }
      .cancel-booking-modal .modal-header .modal-title .btn-link img {
        width: 100%;
        height: 100%;
        outline: none; }

.cancel-booking-modal .title {
  display: flex;
  font-size: 20px;
  color: #0c0c0c;
  text-align: center;
  font-weight: 400;
  margin-top: 2%;
  padding-bottom: 2%;
  justify-content: center; }

.cancel-booking-modal .modal-title-delete {
  display: flex;
  font-size: 20px;
  color: #0c0c0c;
  text-align: center;
  font-weight: 400;
  margin-top: 2%;
  padding-bottom: 1%;
  justify-content: center; }

.cancel-booking-modal .billing-title {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #8b959e;
  text-transform: uppercase;
  margin-left: 0.9rem !important;
  margin-top: 1.5rem !important; }

.cancel-booking-modal .billing-type {
  font-weight: 400;
  font-size: 14px !important;
  color: #0c0c0c;
  margin-left: 0.9rem !important;
  margin-top: 0.5rem !important; }

.cancel-booking-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 80px !important; }
  .cancel-booking-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.cancel-booking-modal .modal-description {
  font-size: 14px;
  color: #636d77;
  text-align: center; }

.cancel-booking-modal .modal-description-delete {
  font-size: 14px;
  color: #636d77;
  text-align: center;
  width: 57%;
  margin: 0 auto; }

.cancel-booking-modal .SharedBooking-modal-description {
  font-size: 15px;
  color: #8b959e;
  text-align: center; }

.cancel-booking-modal .refund-modal-description {
  font-size: 20px;
  color: #0c0c0c;
  text-align: center;
  padding-right: 11rem;
  padding-left: 11rem;
  line-height: 40px; }

.cancel-booking-modal .payout-modal-description {
  font-size: 14px;
  color: #8b959e;
  text-align: left; }

.cancel-booking-modal .payout-modal-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 10rem; }

.share-booking-modal .modal-header {
  border-bottom: none !important;
  padding: 2rem;
  padding-bottom: 0px; }
  .share-booking-modal .modal-header .modal-title {
    width: 100%;
    margin-left: 0.5rem !important; }
    .share-booking-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .share-booking-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .share-booking-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .share-booking-modal .modal-header .modal-title .btn-link {
      float: right;
      height: fit-content; }
      .share-booking-modal .modal-header .modal-title .btn-link img {
        width: 100% !important;
        height: 100% !important; }
    .share-booking-modal .modal-header .modal-title .btn-link-mobile {
      float: right;
      margin-right: 20px;
      margin-top: 2%; }
      .share-booking-modal .modal-header .modal-title .btn-link-mobile img {
        width: 14px; }

.share-booking-modal .modal-body {
  padding: 0.5rem 2rem 2.5rem 2rem; }
  .share-booking-modal .modal-body .sub-header {
    margin-left: 0.5rem !important;
    font-size: 18px; }

.share-booking-modal .title {
  font-size: 20px;
  color: #0c0c0c;
  display: inline-block;
  font-weight: 400; }

.share-booking-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 100px !important; }
  .share-booking-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.share-booking-modal .modal-description {
  font-size: 15px;
  color: #636d77; }

.dispatch-booking-modal div {
  max-width: 900px !important; }

.dispatch-booking-modal .modal-header {
  border-bottom: none !important;
  padding-bottom: 0px; }
  .dispatch-booking-modal .modal-header .modal-title {
    width: 100%;
    margin-left: 0.5rem !important; }
    .dispatch-booking-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .dispatch-booking-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .dispatch-booking-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .dispatch-booking-modal .modal-header .modal-title .btn-link {
      float: right;
      margin-right: 35px;
      margin-top: 2%; }
      .dispatch-booking-modal .modal-header .modal-title .btn-link img {
        width: 14px; }
    .dispatch-booking-modal .modal-header .modal-title .btn-link-mobile {
      float: right;
      margin-right: 20px;
      margin-top: 2%; }
      .dispatch-booking-modal .modal-header .modal-title .btn-link-mobile img {
        width: 14px; }

.dispatch-booking-modal .modal-body .sub-header {
  margin-left: 0.5rem !important; }

.dispatch-booking-modal .title {
  font-size: 20px;
  color: #0c0c0c;
  display: inline-block;
  font-weight: 400;
  margin-top: 2%; }

.dispatch-booking-modal .dispatch-title {
  font-size: 18px;
  color: #0c0c0c;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%; }

.dispatch-booking-modal .dispatch-subtitle {
  font-size: 14px;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%;
  color: #8b959e;
  position: absolute; }

.dispatch-booking-modal .dispatch-form-title {
  font-size: 16px;
  padding-left: 24px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%;
  position: absolute; }

.dispatch-booking-modal .add-partner-submit {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 9px; }

.dispatch-booking-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 100px !important; }
  .dispatch-booking-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.dispatch-booking-modal .modal-description {
  font-size: 15px;
  color: #636d77; }

.outsourced-rate-modal div {
  max-width: 736px !important;
  max-height: 312px !important; }

.outsourced-rate-modal .modal-content {
  padding: 2rem; }

.outsourced-rate-modal .modal-header {
  border-bottom: none !important;
  padding: 0px; }
  .outsourced-rate-modal .modal-header .modal-title {
    width: 100%;
    margin-left: 0.5rem !important; }
    .outsourced-rate-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .outsourced-rate-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .outsourced-rate-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .outsourced-rate-modal .modal-header .modal-title .btn-link {
      float: right;
      margin-right: 35px;
      margin-top: 2%; }
      .outsourced-rate-modal .modal-header .modal-title .btn-link img {
        width: 14px; }
    .outsourced-rate-modal .modal-header .modal-title .btn-link-mobile {
      float: right;
      margin-right: 20px;
      margin-top: 2%; }
      .outsourced-rate-modal .modal-header .modal-title .btn-link-mobile img {
        width: 14px; }

.outsourced-rate-modal .form-group {
  width: 213.67px !important; }

.outsourced-rate-modal .modal-body {
  padding: 0px; }
  .outsourced-rate-modal .modal-body .sub-header {
    margin-left: 0.5rem !important; }

.outsourced-rate-modal .title {
  font-size: 20px;
  color: #0c0c0c;
  display: inline-block;
  font-weight: 400;
  margin-top: 2%; }

.outsourced-rate-modal .dispatch-title {
  font-size: 20px;
  color: #0c0c0c;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%; }

.outsourced-rate-modal .dispatch-subtitle {
  font-size: 14px;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%;
  color: #8b959e;
  position: absolute; }

.outsourced-rate-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 100px !important; }
  .outsourced-rate-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.outsourced-rate-modal .modal-description {
  font-size: 15px;
  color: #636d77; }

.manual-partner-modal div {
  max-width: 540px !important;
  max-height: 350px !important; }

.manual-partner-modal .modal-content {
  padding: 2rem; }

.manual-partner-modal .modal-description {
  font-size: 15px;
  color: #636d77; }

.manual-partner-modal .modal-header {
  border-bottom: none !important;
  padding: 0px; }
  .manual-partner-modal .modal-header .modal-title {
    width: 100%;
    margin-left: 0.5rem !important; }
    .manual-partner-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .manual-partner-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .manual-partner-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .manual-partner-modal .modal-header .modal-title .btn-link {
      float: right;
      margin-right: 35px;
      margin-top: 2%; }
      .manual-partner-modal .modal-header .modal-title .btn-link img {
        width: 14px; }
    .manual-partner-modal .modal-header .modal-title .btn-link-mobile {
      float: right;
      margin-right: 20px;
      margin-top: 2%; }
      .manual-partner-modal .modal-header .modal-title .btn-link-mobile img {
        width: 14px; }

.manual-partner-modal .modal-body {
  padding: 0px; }
  .manual-partner-modal .modal-body .sub-header {
    margin-left: 0.5rem !important; }

.manual-partner-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 100px !important; }
  .manual-partner-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.manual-partner-modal .title {
  font-size: 18px;
  color: #0c0c0c;
  display: inline-block;
  font-weight: 400;
  margin-top: 2%; }

.manual-partner-modal .dispatch-title {
  font-size: 20px;
  color: #0c0c0c;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%;
  margin-bottom: 2rem;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0.75%; }

.manual-partner-modal .dispatch-subtitle {
  font-size: 14px;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%;
  color: #8b959e;
  position: absolute; }

.manual-partner-modal .form-check-box .row:not(:last-child) {
  margin-bottom: 1rem; }

.manual-partner-modal .form-check-box .row:last-child {
  margin-bottom: 0.5rem; }

.manual-partner-modal .custom-checkbox .custom-control-label {
  font-weight: normal;
  font-size: 14px !important;
  color: #0c0c0c;
  padding-top: 0.15rem; }
  .manual-partner-modal .custom-checkbox .custom-control-label .status-paid {
    margin-left: 0.3rem;
    font-size: 12px !important;
    color: #417505;
    padding: 0px; }
  .manual-partner-modal .custom-checkbox .custom-control-label .status-unpaid {
    margin-left: 0.3rem;
    font-size: 12px !important;
    color: #8b959e;
    padding: 0px; }

.manual-partner-modal .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  border: none; }

.manual-partner-modal .custom-control-input:checked ~ .custom-control-label:after {
  border: 1px solid #0c0c0c; }

.charges-partner-modal div {
  max-width: 735px !important; }

.charges-partner-modal .modal-header {
  border-bottom: none !important;
  padding-bottom: 0px; }
  .charges-partner-modal .modal-header .modal-title {
    width: 100%;
    margin-left: 0.5rem !important; }
    .charges-partner-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }
    .charges-partner-modal .modal-header .modal-title .check-btn-wrap {
      width: 100%; }
      .charges-partner-modal .modal-header .modal-title .check-btn-wrap img {
        width: 60px; }
    .charges-partner-modal .modal-header .modal-title .btn-link {
      float: right;
      margin-right: 35px;
      margin-top: 2%; }
      .charges-partner-modal .modal-header .modal-title .btn-link img {
        width: 14px; }
    .charges-partner-modal .modal-header .modal-title .btn-link-mobile {
      float: right;
      margin-right: 20px;
      margin-top: 2%; }
      .charges-partner-modal .modal-header .modal-title .btn-link-mobile img {
        width: 14px; }

.charges-partner-modal .modal-body .sub-header {
  margin-left: 0.5rem !important; }

.charges-partner-modal .title {
  font-size: 20px;
  color: #0c0c0c;
  display: inline-block;
  font-weight: 400;
  margin-top: 2%; }

.charges-partner-modal .modal-description {
  font-size: 15px;
  color: #636d77; }

.charges-partner-modal .dispatch-title {
  font-size: 18px;
  color: #0c0c0c;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%; }

.charges-partner-modal .dispatch-subtitle {
  font-size: 14px;
  padding-left: 39px;
  display: inline-block;
  font-weight: 400;
  padding-top: 2.4%;
  color: #8b959e;
  position: absolute; }

.charges-partner-modal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
  padding-bottom: 100px !important; }
  .charges-partner-modal .modal-footer .select-btn {
    margin-top: 0px;
    font-size: 14px;
    min-width: 175px;
    height: 40px; }

.add-max-width-search {
  max-width: calc((100vw - 101px) - 70%); }

.add-max-width-map {
  max-width: calc((100vw - 101px) - 63%); }

.shareModalList {
  height: 45vh;
  overflow-y: scroll; }

.vehicleModalList {
  height: 50vh;
  overflow-y: scroll; }

.dispatch-search-field {
  padding-left: 5%; }

.shareDispatchList {
  height: 42.9vh;
  overflow-y: scroll; }

.shareDispatchList-partner {
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  height: 42.9vh;
  overflow-y: scroll; }

.sub-header-dispatch {
  font-size: 16.5px;
  font-weight: bold;
  margin-bottom: 50px; }

.check-box-dispatch {
  margin-right: 7%; }

.dispatch-info :first-child {
  font-weight: bolder;
  color: #0c0c0c; }

.dispatch-info p {
  margin-top: 0;
  margin-bottom: 5px;
  color: #8b959e; }

.dispatch-grey-row {
  background-color: rgba(139, 149, 158, 0.2) !important; }

.share-profile-info {
  margin-left: 60px; }
  .share-profile-info p {
    margin-top: 0;
    margin-bottom: 5px; }

.share-profile-info-mobile p {
  margin-top: 0;
  margin-bottom: 5px; }

.share-profile-image {
  width: 45px;
  height: 45px;
  float: left; }

.shareUserImage {
  float: left; }

.share-mobile-field {
  font-size: 14px !important; }

.share-button {
  float: right; }
  .share-button button {
    margin-right: 5px; }
  .share-button .select-btn {
    height: 35px;
    min-width: 100px; }
  .share-button img {
    width: 20px; }
  .share-button .share-mobile-cross-img {
    margin-right: 8px !important; }
    .share-button .share-mobile-cross-img img {
      width: 14px !important; }
  .share-button .share-desktop-cross-img img {
    width: 14px !important; }
  .share-button .share {
    margin-right: 25px; }
  .share-button .shared:hover,
  .share-button .shared:active {
    color: #0c0c0c !important; }

.terms-button {
  display: flex;
  justify-content: flex-end;
  padding: 15px 40px; }
  .terms-button .btn {
    height: 40px;
    width: 150px; }

.badge {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 10px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.badge.badge-outlined.badge-size11 {
  font-size: 11px !important; }

.badge.badge-outlined {
  background-color: transparent; }

.badge.badge-outlined-none {
  background-color: transparent;
  border-color: transparent !important;
  font-size: 12px !important; }

.badge.badge-outlined.badge-default {
  border-color: #b0bec5;
  color: #b0bec5; }

.badge.badge-outlined.badge-primary {
  border-color: #2196f3;
  color: #2196f3; }

.badge.badge-outlined.badge-secondary {
  border-color: #323a45;
  color: #323a45; }

.badge.badge-outlined.badge-global-card {
  border-color: #b0bec5;
  color: #323a45; }

.badge.badge-outlined.badge-success,
.badge.badge-Confirmed,
.badge.badge-accepted,
.badge.badge-outlined.badge-confirmed {
  border-color: #417505;
  color: #417505;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(65, 117, 5, 0.1); }

.badge.badge-outlined.badge-success,
.badge.badge-completed {
  border-color: #0c0c0c;
  color: #0c0c0c;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(12, 12, 12, 0.1); }

.osm-map {
  width: -webkit-fill-available;
  width: -moz-available;
  height: calc(100vh - 59px);
  position: absolute;
  z-index: 4;
  overflow: hidden; }

.map-label-container {
  position: fixed;
  z-index: 100;
  margin-left: 2em;
  margin-top: 2em; }

.map-label {
  padding: 3px 15px;
  background-color: white;
  color: #8b959e;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 800;
  width: fit-content;
  position: sticky;
  z-index: 100;
  margin-bottom: 2px; }

.map-label-selected {
  color: black !important;
  border: 1px solid black !important; }

.leaflet-control-zoom {
  border: 1px solid #9e9e9e !important;
  border-radius: 2px !important; }

.leaflet-touch .leaflet-bar a {
  width: 18px !important;
  height: 18px !important;
  line-height: 14px !important;
  font-size: 16px !important; }

.badge.badge-outlined.badge-quote_in_progress,
.badge.badge-outlined.badge-is_request,
.badge.badge-outlined.badge-is_request,
.badge.badge-Request,
.badge.badge-outlined.badge-in_review,
.badge.badge-dispatched,
.badge.badge-outlined.badge-Pending {
  border-color: #f5a623;
  color: #f5a623;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(245, 166, 35, 0.1); }

.badge.badge-outlined.badge-change_request {
  border-color: #ff6666;
  color: #ff6666;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(255, 102, 102, 0.1); }

.badge.badge-outlined.badge-quote_in_progress,
.badge.badge-outlined.badge-changes_sent {
  border-color: #1e2b8e;
  color: #1e2b8e;
  font-weight: 700;
  background-color: rgba(30, 43, 142, 0.1);
  font-size: 11px; }

.badge.badge-outlined.badge-is_request {
  border-color: #fabd05;
  color: #fabd05;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(250, 189, 5, 0.1); }

.badge.badge-outlined.badge-info {
  border-color: #29b6f6;
  color: #29b6f6; }

.badge.badge-unavailable {
  border-color: #8b959e;
  color: #8b959e;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(139, 149, 158, 0.1); }

.badge.badge-new-booking {
  border-color: #f5a623;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #f5a623;
  color: white; }

.badge.badge-outsourced-dispatch {
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white; }

.badge.badge-accepted-dispatch {
  border-color: #417505;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #417505;
  color: white; }

.badge.badge-cancelled-dispatch {
  border-color: #d0021b;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #d0021b;
  color: white; }

.badge.badge-expired-dispatch {
  border-color: #d0021b;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #d0021b;
  color: white; }

.badge.badge-change-request-dispatch {
  border-color: #ff6666;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #ff6666;
  color: white; }

.badge.badge-completed-dispatch {
  border-color: #000000;
  padding: 6px 6px !important;
  font-size: 11px;
  font-weight: 900;
  background-color: #000000;
  color: white; }

.badge.badge-outlined.badge-danger,
.badge.badge-outlined.badge-Cancelled,
.badge.badge-cancellation_requested_by_client,
.badge.badge-cancellation_accepted_by_agent,
.badge.badge-transaction_declined,
.badge.badge-Declined,
.badge.badge-not_dispatched,
.badge.badge-cancelled_by_agent,
.badge.badge-outlined.badge-quote_expired {
  border-color: #d0021b;
  color: #d0021b;
  font-size: 11px;
  font-weight: 700;
  background-color: rgba(208, 2, 27, 0.1); }

.badge.badge-outlined.badge-important {
  color: #d0021b;
  background-color: rgba(208, 2, 27, 0.1);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-shadow: 0.5px 0 #d0021b; }

.user-avatar {
  text-align: center; }
  .user-avatar .rolzo-logo {
    width: 20px !important;
    height: auto !important;
    display: inline !important;
    margin-top: 6px;
    vertical-align: initial; }

.custom-datepicker-calendar .react-datepicker__day--disabled.react-datepicker__day--selected, .custom-datepicker-calendar .react-datepicker__day--disabled.react-datepicker__day--today {
  color: #ccc !important; }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.sharedList {
  color: #0c0c0c !important; }
  .sharedList span {
    margin-right: 10px; }
  .sharedList img {
    width: 15px;
    height: 15px;
    margin-top: -5px; }

.electic-icon {
  width: 18px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: baseline; }

.unPaidBlock {
  background: url("/images/icons/refundIcons/icon-unpaid.png");
  background-position: top 0.8px left 0px;
  background-size: 15px 15px;
  font-weight: 700;
  background-repeat: no-repeat;
  padding-left: 25px;
  margin-bottom: 4px;
  color: #d0021b;
  font-size: 14px; }

.paidBlock {
  background: url("/images/icons/refundIcons/icon-succeeded.png");
  background-position: top 0.8px left 0px;
  background-size: 16px 13px;
  font-weight: 700;
  background-repeat: no-repeat;
  padding-left: 26px;
  margin-bottom: 4px;
  color: #417505;
  font-size: 14px; }

.refundedBlock {
  background: url("/images/icons/refundIcons/icon-refunded.png");
  background-position: top 0.8px left 3px;
  background-size: 17px 13px;
  font-weight: 700;
  background-repeat: no-repeat;
  padding-left: 29px;
  margin-bottom: 4px;
  color: #0c0c0c;
  font-size: 14px; }

.dueDateMessage {
  color: #8b959e;
  font-size: 14px;
  margin-left: 1.6rem; }

.change-request-delete {
  position: absolute;
  right: -33px;
  top: 34px; }
  .change-request-delete img {
    height: 12px;
    cursor: pointer; }

.pos-rel {
  position: relative; }

.add-mr {
  margin-right: 20px; }

.textArea-border {
  background-color: #ffffff !important;
  border-radius: 2px !important;
  border: 1px solid rgba(139, 149, 158, 0.1) !important;
  border-color: #a79779 !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }

.textArea-shadow {
  background-color: #ffffff !important;
  border-radius: none !important;
  border: none !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1) !important;
  min-height: 90px !important; }

.dispatchState-cancelled,
.dispatchState-declined,
.dispatchState-No {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-quote_requested {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-dispatched {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #f5a623;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-accepted {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-completed {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #0c0c0c;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-updated {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #f5a623;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-completed {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #0c0c0c;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.dispatchState-incomplete {
  font-size: 11px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #8b959e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.chauffeur-yes {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.chauffeur-no {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  color: white;
  padding: 0.4rem 0.45rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.chauffeur-received {
  font-size: 11px !important;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #f5a623;
  color: white;
  padding: 0.4rem 0.45rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.alerts-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #d0021b;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fbfbfb;
  color: #d0021b;
  padding-top: 0.1rem; }

.dispatch-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #0c0c0c;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fbfbfb;
  color: #0c0c0c;
  padding-top: 0.1rem; }

.timer-red {
  white-space: nowrap;
  color: #d0021b; }

.timer-black {
  white-space: nowrap;
  color: #0c0c0c; }

.filters-booking-modal {
  border: 1px solid rgba(139, 149, 158, 0.3) !important; }
  .filters-booking-modal .modal-header {
    border-bottom: none !important;
    padding-bottom: 0px; }
    .filters-booking-modal .modal-header .modal-title {
      width: 100%; }
      .filters-booking-modal .modal-header .modal-title .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end; }
      .filters-booking-modal .modal-header .modal-title .check-btn-wrap {
        width: 100%; }
        .filters-booking-modal .modal-header .modal-title .check-btn-wrap img {
          width: 60px; }
      .filters-booking-modal .modal-header .modal-title .btn-link {
        margin-right: 5%;
        margin-top: 3%; }
        .filters-booking-modal .modal-header .modal-title .btn-link img {
          width: 60px; }
  .filters-booking-modal .title {
    display: flex;
    font-size: 20px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400;
    margin-top: 2%;
    padding-bottom: 2%;
    justify-content: center; }
  .filters-booking-modal .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    background-color: #fafafa;
    padding-bottom: 65px !important; }
    .filters-booking-modal .modal-footer .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px; }

.badge.badge-refunded-admin,
.badge.badge-not_dispatched-admin,
.badge.badge-declined-admin,
.badge.badge-cancelled_by_agent-admin,
.badge.badge-cancellation_requested_by_client-admin,
.badge.badge-cancellation_accepted_by_agent-admin,
.badge.badge-outlined.badge-quote_expired-admin,
.badge.badge-transaction_declined-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  border-color: #d0021b;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-is_request-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #fabd05;
  border-color: #fabd05;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-change_request-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #ff6666;
  border-color: #ff6666;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-change_request-approval-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-partially_paid-admin,
.badge.badge-outlined.badge-refunded-admin,
.badge.badge-outlined.badge-quote_requested-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-not_paid-admin,
.badge.badge-dispatched-admin,
.badge.badge-outlined.badge-in_review-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #f5a623;
  border-color: #f5a623;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-paid-admin,
.badge.badge-outlined.badge-confirmed-admin,
.badge.badge-outlined.badge-accepted-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  border-color: #417505;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-on_the_way-admin,
.badge.badge-outlined.badge-on_location-admin,
.badge.badge-outlined.badge-passenger_onboard-admin,
.badge.badge-outlined.badge-ten_mins_away-admin,
.badge.badge-outlined.badge-delivered-admin,
.badge.badge-outlined.badge-accepted-admin,
.badge.badge-outlined.badge-passenger_dropped_off-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  border-color: #417505;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-not_on_the_way-admin,
.badge.badge-outlined.badge-not_on_location-admin,
.badge.badge-outlined.badge-not_onboard-admin,
.badge.badge-outlined.badge-not_delivered-admin,
.badge.badge-outlined.badge-not_dropped_off-admin,
.badge.badge-outlined.badge-not_accepted-admin,
.badge.badge-outlined.badge-not_assigned-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  border-color: #d0021b;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-received-admin,
.badge.badge-outlined.badge-changed-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #f5a623;
  border-color: #f5a623;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-assigned-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-cancelled-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  border-color: #d0021b;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-completed-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #0c0c0c;
  border-color: #0c0c0c;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-incomplete-admin,
.badge.badge-unavailable-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #8b959e;
  border-color: #8b959e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-quote_in_progress-admin,
.badge.badge-outlined.badge-changes_sent-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.filter-btn {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  position: relative;
  min-width: auto;
  max-width: fit-content !important;
  height: 30px !important;
  padding: 0.3rem;
  border: none;
  border-radius: 2px;
  background-color: #fbfbfb;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2) !important; }
  .filter-btn .btnTitle {
    font-size: 13px !important;
    display: inline-block;
    color: #0c0c0c !important;
    font-weight: 950 !important;
    text-shadow: 0.3px 0 #0c0c0c;
    margin-right: 0.2rem !important;
    padding-top: 0.2rem !important; }
  .filter-btn .btnValue {
    font-size: 13px !important;
    display: inline-block;
    color: rgba(12, 12, 12, 0.7) !important; }

.filter-close-btn {
  border-left: 0.5px solid rgba(139, 149, 158, 0.2); }

.clear-filter-btn {
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 13px !important;
  display: block;
  color: #d0021b;
  text-shadow: 0.6px 0 #d0021b;
  padding-bottom: 0.2rem; }
  .clear-filter-btn:hover {
    text-decoration: none;
    color: #d0021b; }

.filter-btn-padding {
  padding-left: 1.9rem !important; }

.badge.badge-outlined.badge-new-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-active-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  border-color: #417505;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-unverified-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #4a332d;
  border-color: #4a332d;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-gold-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d6af36;
  border-color: #d6af36;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-silver-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #8b959e;
  border-color: #8b959e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-inactive-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  border-color: #d0021b;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-blacklisted-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #0c0c0c;
  border-color: #0c0c0c;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-backup-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #800080;
  border-color: #800080;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-declined-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #ffffff;
  border-color: #0c0c0c;
  color: #0c0c0c;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-exclusive-partner {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #a79779;
  border-color: #a79779;
  color: #ffffff;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.admin-changes-logs .message-block-mobile {
  padding-right: 1% !important; }

.admin-changes-logs .message-block {
  max-width: 100%;
  margin-bottom: 15px;
  margin-left: 0;
  border-bottom: 1px solid rgba(12, 12, 12, 0.05);
  padding-right: 8%; }
  .admin-changes-logs .message-block:hover {
    text-decoration: none; }
  .admin-changes-logs .message-block:first-child {
    border-top: none; }
  .admin-changes-logs .message-block:last-child {
    padding-bottom: 10px; }
  .admin-changes-logs .message-block .message-header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 32vw;
    max-width: 100%;
    flex-direction: row; }
    .admin-changes-logs .message-block .message-header .event-type-indicator {
      width: 40px;
      display: flex;
      justify-content: center; }
    .admin-changes-logs .message-block .message-header p {
      padding: 0;
      margin: 0; }
      .admin-changes-logs .message-block .message-header p.date-row {
        padding-left: 10px;
        font-size: 12px;
        color: #8b959e; }
    .admin-changes-logs .message-block .message-header pre {
      padding: 0;
      margin: 0; }
      .admin-changes-logs .message-block .message-header pre.date-row {
        padding-left: 10px;
        font-size: 12px;
        color: #8b959e; }
  .admin-changes-logs .message-block .message-content {
    padding-left: 50px; }
    .admin-changes-logs .message-block .message-content p {
      padding: 0;
      margin: 0;
      font-size: 15px; }
      .admin-changes-logs .message-block .message-content p.text-content {
        font-size: 15px;
        color: #0c0c0c;
        word-break: break-word; }
        .admin-changes-logs .message-block .message-content p.text-content img {
          width: 14px;
          height: auto;
          margin: 0 6px 0 0;
          margin-bottom: 5px; }
      .admin-changes-logs .message-block .message-content p.date-row {
        font-size: 8px;
        color: #8b959e; }
      .admin-changes-logs .message-block .message-content p:hover {
        text-decoration: none; }
    .admin-changes-logs .message-block .message-content .download-link {
      color: #a79779;
      font-size: 10px; }
      .admin-changes-logs .message-block .message-content .download-link .pdf-icon {
        width: 11px;
        height: 11px;
        margin-right: 7px; }
      .admin-changes-logs .message-block .message-content .download-link:hover {
        text-decoration: none; }
  .admin-changes-logs .message-block .client-indicator {
    background: rgba(139, 149, 158, 0.3);
    height: 10px;
    width: 10px;
    border-radius: 10px; }

.admin-changes-logs .client-indicator {
  background: rgba(139, 149, 158, 0.3);
  height: 10px;
  width: 10px;
  border-radius: 10px; }

.admin-changes-logs .message-header {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 32vw;
  max-width: 100%;
  flex-direction: row;
  line-height: 36px; }
  .admin-changes-logs .message-header .event-type-indicator {
    width: 40px;
    display: flex;
    justify-content: center; }
  .admin-changes-logs .message-header p {
    padding: 0;
    margin: 0; }
    .admin-changes-logs .message-header p.date-row {
      padding-left: 10px;
      font-size: 12px;
      color: #8b959e; }
  .admin-changes-logs .message-header pre {
    padding: 0;
    margin: 0; }
    .admin-changes-logs .message-header pre.date-row {
      padding-left: 10px;
      font-size: 12px;
      color: #8b959e; }

.admin-changes-logs .message-content {
  padding-left: 50px; }
  .admin-changes-logs .message-content p {
    padding: 0;
    margin: 0;
    font-size: 15px; }
    .admin-changes-logs .message-content p.text-content {
      font-size: 15px;
      color: #0c0c0c;
      word-break: break-word; }
      .admin-changes-logs .message-content p.text-content img {
        width: 14px;
        height: auto;
        margin: 0 6px 0 0;
        margin-bottom: 5px; }
    .admin-changes-logs .message-content p.date-row {
      font-size: 8px;
      color: #8b959e; }
    .admin-changes-logs .message-content p:hover {
      text-decoration: none; }
  .admin-changes-logs .message-content .download-link {
    color: #a79779;
    font-size: 10px; }
    .admin-changes-logs .message-content .download-link .pdf-icon {
      width: 11px;
      height: 11px;
      margin-right: 7px; }
    .admin-changes-logs .message-content .download-link:hover {
      text-decoration: none; }

.admin-changes-logs .close-icon {
  display: flex;
  justify-content: flex-start;
  cursor: pointer; }
  .admin-changes-logs .close-icon img {
    width: 10px;
    height: 10px; }

.bookingChanges-header {
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 100 !important;
  margin: 1.5em 0.3em 2.5em 0.3em !important; }

.acceptedChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #417505;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #417505;
  color: #ffffff;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem; }

.declinedChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #d0021b;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #d0021b;
  color: #ffffff;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem; }

.not_openedChanges-number,
.openedChanges-number,
.unopenedChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #fabd05;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fabd05;
  color: #ffffff;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem; }

.newChanges-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #a79779;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #a79779;
  color: #ffffff;
  padding-top: 0.1rem;
  margin-left: 0.8rem;
  margin-bottom: 0.1rem; }

.acceptedDispatch-number-tab,
.acceptedChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #417505;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #417505;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 0.3rem; }

.accepted-partner-number-tab {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #417505;
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background: #417505;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 1.3rem;
  font-weight: 900; }

.notes-partner-number {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #a79779;
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background: #a79779;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 1.3rem;
  font-weight: 900; }

.declinedDispatch-number-tab,
.declinedChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #d0021b;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d0021b;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 0.3rem; }

.dispatched-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #f5a623;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f5a623;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 0.3rem; }

.completedDispatched-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #0c0c0c;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0c0c0c;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 0.3rem; }

.not_openedChanges-number-tab,
.openedChanges-number-tab,
.unopenedChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #fabd05;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fabd05;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 0.3rem; }

.received-partner-number-tab {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #fabd05;
  width: 24.5px;
  height: 24.5px;
  border-radius: 50%;
  background: #fabd05;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 1.3rem;
  font-weight: 900; }

.newChanges-number-tab {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #a79779;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #a79779;
  color: #ffffff;
  padding-top: 0.2rem;
  margin-left: 0.3rem; }

.decline-offer-booking-modal .modal-content {
  padding: 40px;
  padding-bottom: 48px; }

.decline-offer-booking-modal .modal-header {
  border-bottom: none !important;
  padding: 0; }
  .decline-offer-booking-modal .modal-header .modal-title {
    width: 100%;
    display: flex;
    padding-bottom: 0px; }
    .decline-offer-booking-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }

.decline-offer-booking-modal .title {
  display: flex;
  font-size: 20px;
  color: #0c0c0c;
  text-align: center;
  font-weight: 400;
  margin-bottom: 36px;
  width: 67%; }

.decline-offer-booking-modal .modal-body {
  padding: 0;
  padding-bottom: 36px; }
  .decline-offer-booking-modal .modal-body .modal-description {
    font-size: 15px; }
  .decline-offer-booking-modal .modal-body .modal-options {
    margin-top: 5px; }

.decline-offer-booking-modal .modal-footer {
  border-top: none !important;
  justify-content: end !important;
  padding: 0; }

.client-commission-modal .modal-content {
  width: 90%; }

.client-commission-modal .modal-header {
  border-bottom: none !important;
  padding: 2rem 1rem; }
  .client-commission-modal .modal-header .modal-title {
    width: 100%;
    display: flex;
    padding-bottom: 0px; }
    .client-commission-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }

.client-commission-modal .modal-body {
  margin-bottom: 5em;
  padding: 0;
  padding-left: 13em;
  padding-right: 13em; }
  .client-commission-modal .modal-body .price-text {
    text-align: center;
    font-size: 25px; }
  .client-commission-modal .modal-body .commission-text {
    text-align: center;
    font-size: 15px;
    color: #417505; }
  .client-commission-modal .modal-body .description-text {
    text-align: center;
    font-size: 15px;
    margin-top: 1.5em;
    color: #636d77; }

.bank-deatil-modal .modal-content {
  width: 548px; }

.fade.modal-backdrop.whats-new-backdrop.show {
  opacity: 0.6; }

.whats-new-modal .modal-size {
  width: 920px;
  max-width: 920px; }

.whats-new-modal .modal-content {
  height: 100%;
  border-radius: 0.3rem;
  border: none !important;
  overflow-y: auto; }

.whats-new-modal .modal-header {
  border-bottom: none !important;
  padding-top: 40px;
  padding-bottom: 0px; }
  .whats-new-modal .modal-header .modal-title {
    width: 100%;
    display: flex;
    padding-bottom: 0px; }
    .whats-new-modal .modal-header .modal-title .close-btn-wrap img {
      width: 12px;
      position: absolute;
      right: 40px;
      top: 40px; }

.whats-new-modal .modal-body {
  padding: 0; }

.whats-new-modal .modal-panel {
  display: flex;
  height: 460px;
  max-height: 460px; }
  .whats-new-modal .modal-panel .modal-div {
    max-width: calc(100vw - 50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1; }
    .whats-new-modal .modal-panel .modal-div .image {
      height: 100%; }

.whats-new-modal .modal-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1; }
  .whats-new-modal .modal-container .whatsnew {
    color: #a79779;
    font-size: 15px;
    margin-bottom: 12px;
    align-self: start;
    margin-left: 40px;
    margin-top: 45px; }
  .whats-new-modal .modal-container .title {
    font-size: 24px;
    margin-bottom: 12px;
    align-self: start;
    margin-left: 40px;
    margin-right: 40px; }
  .whats-new-modal .modal-container .description {
    font-size: 15px;
    color: #636d77;
    margin-bottom: 25px;
    word-wrap: break-word;
    align-self: start;
    margin-left: 40px;
    white-space: pre-line;
    margin-right: 45px; }
  .whats-new-modal .modal-container .cta {
    display: flex;
    align-self: flex-end;
    margin-bottom: 70px;
    margin-right: 40px; }
    .whats-new-modal .modal-container .cta a {
      padding: 0 2.3rem;
      font-size: 15px;
      padding-top: 8px; }

.com-popup-modal .modal-size {
  width: 45rem;
  max-width: 45rem; }

.com-popup-modal .modal-header {
  border-bottom: none !important;
  padding: 2rem 1rem; }
  .com-popup-modal .modal-header .modal-title {
    width: 100%;
    display: flex;
    padding-bottom: 0px; }
    .com-popup-modal .modal-header .modal-title .close-btn-wrap img {
      width: 12px;
      position: absolute;
      right: 40px;
      top: 35px; }

.com-popup-modal .modal-body {
  margin-bottom: 3rem;
  padding: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }
  .com-popup-modal .modal-body .title {
    font-size: 23px;
    margin-bottom: 10px;
    text-align: center; }
  .com-popup-modal .modal-body .description {
    font-size: 14px;
    color: #636d77;
    width: 95%;
    margin-bottom: 1.5rem;
    word-wrap: break-word; }

.terms-modal .modal-size {
  width: 920px;
  max-width: 920px; }

.terms-modal .modal-content {
  height: 100%;
  border-radius: 0.3rem;
  border: none !important; }

.terms-modal .modal-header {
  border-bottom: none !important;
  padding-top: 40px;
  padding-bottom: 0px; }
  .terms-modal .modal-header .modal-title {
    width: 100%;
    display: flex;
    padding-bottom: 0px; }
    .terms-modal .modal-header .modal-title .close-btn-wrap img {
      width: 12px;
      position: absolute;
      right: 40px;
      top: 40px; }

.terms-modal .modal-body {
  padding: 0; }

.terms-modal .modal-panel {
  display: flex; }
  .terms-modal .modal-panel .modal-div {
    max-width: calc(100vw - 50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1; }
    .terms-modal .modal-panel .modal-div .image {
      height: 100%; }

.terms-modal .modal-container {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1; }
  .terms-modal .modal-container .title {
    font-size: 24px;
    margin-bottom: 12px;
    align-self: start;
    padding-left: 40px;
    padding-right: 40px; }
  .terms-modal .modal-container .description {
    font-size: 15px;
    color: #636d77;
    margin-bottom: 10px;
    word-wrap: break-word;
    align-self: start;
    white-space: pre-line;
    padding-left: 40px;
    padding-right: 40px; }
  .terms-modal .modal-container .terms {
    padding-top: 20px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 40px;
    padding-right: 20px; }
  .terms-modal .modal-container .terms::-webkit-scrollbar {
    width: 8px; }
  .terms-modal .modal-container .terms::-webkit-scrollbar-thumb {
    background-color: rgba(139, 149, 158, 0.5);
    border-radius: 10px;
    height: 2px;
    width: 6px; }
  .terms-modal .modal-container .terms::-webkit-scrollbar-track {
    background-color: #fafafa;
    margin-top: 20px;
    margin-bottom: 30px; }
  .terms-modal .modal-container .text-normal {
    font-size: 14px; }
  .terms-modal .modal-container .text-bold {
    font-size: 14px;
    font-weight: 800 !important;
    text-shadow: 0 0 #0c0c0c; }
  .terms-modal .modal-container .cta {
    display: flex;
    align-self: flex-end;
    margin-bottom: 70px;
    margin-right: 40px; }
    .terms-modal .modal-container .cta a {
      padding: 0 2.3rem;
      font-size: 15px;
      padding-top: 8px; }

.terms-modal-mobile .modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; }

.terms-modal-mobile .content-container-terms {
  background-color: white;
  width: 100%;
  height: 100%; }

.terms-modal-mobile .header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  align-items: center; }

.terms-modal-mobile .close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.terms-modal-mobile .close {
  height: 12px;
  width: 12px; }

.terms-modal-mobile .popup-content {
  width: 100%;
  height: 100%;
  padding: 16px;
  padding-top: 250px; }

.terms-modal-mobile .popup-title {
  font-size: 30px;
  margin-bottom: 5px; }

.terms-modal-mobile .popup-text {
  font-size: 15px;
  text-align: left;
  width: 100%;
  margin-top: 20px; }

.terms-modal-mobile .bottom-container {
  position: absolute;
  bottom: 0;
  justify-content: center;
  width: 100%;
  align-items: center;
  border-top: 1.5px solid rgba(139, 149, 158, 0.25);
  padding-top: 20px;
  background-color: #ffffff; }

.terms-modal-mobile .text-normal {
  font-size: 14px; }

.terms-modal-mobile .text-normal {
  font-size: 14px; }

.terms-modal-mobile .text-bold {
  font-size: 14px;
  font-weight: 800 !important;
  text-shadow: 0 0 #0c0c0c; }

.terms-modal-mobile .terms {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  padding-bottom: 150px; }

.modal-content {
  border: 1px solid rgba(139, 149, 158, 0.3) !important; }

.upload-photo-modal .modal-content {
  width: 70%; }

.upload-photo-modal .modal-header {
  border-bottom: none !important;
  padding: 2rem 1rem; }
  .upload-photo-modal .modal-header .modal-title {
    width: 100%;
    display: flex;
    padding-bottom: 0px; }
    .upload-photo-modal .modal-header .modal-title .close-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end; }

.upload-photo-modal .modal-body {
  margin-bottom: 5em;
  padding: 0;
  padding-left: 2em;
  padding-right: 2em; }
  .upload-photo-modal .modal-body button {
    float: right; }

.chargeCheckBox label {
  color: #8b959e !important;
  padding-top: 0.2rem !important;
  line-height: 12px;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: pre; }

.important-booking-img {
  width: 0.875rem;
  height: 0.906rem;
  margin-left: 0.5rem;
  margin-bottom: 4px; }

.important-booking-img-chat {
  width: 0.875rem;
  height: 0.906rem;
  margin-left: 0.5rem; }

.important-booking-badge {
  width: 1.313rem;
  height: 1.313rem;
  margin-top: 0.1rem;
  margin-left: 10px;
  margin-bottom: 4px; }

.tragectory-img {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-bottom: 4px; }

.unsort-icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  cursor: pointer; }

.sort-icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  margin-bottom: 4px;
  cursor: pointer; }
  .sort-icon.ascending {
    transform: rotate(-90deg); }
  .sort-icon.descending {
    transform: rotate(90deg); }

.badge.badge-outlined.badge-paid-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  border-color: #417505;
  color: white;
  padding: 0.3rem 1rem 0.3rem 1rem;
  text-transform: uppercase;
  text-align: center; }

.badge.badge-outlined.badge-inactive-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b !important;
  border-color: #d0021b !important;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-new-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  border-color: #417505;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-semi_active-user,
.badge.badge-outlined.badge-early_bird-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #fabd05;
  border-color: #fabd05;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-first_time-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #ff9500;
  border-color: #ff9500;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-inapt-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #ffffff;
  border-color: #0c0c0c;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-active-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #1e2b8e;
  border-color: #1e2b8e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-lapsed-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #800080;
  border-color: #800080;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-dormant-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #0c0c0c;
  border-color: #0c0c0c;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-churned-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #8b959e;
  border-color: #8b959e;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-invited-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #4a332d;
  border-color: #4a332d;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-rolzo-first {
  font-size: 12px;
  border-radius: 2px;
  font-weight: 700 !important;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.badge.badge-outlined.badge-fraud-user {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #ff6666;
  border-color: #ff6666;
  color: white;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase; }

.rolzo-first-label-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: 700 !important;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
  margin-bottom: 1rem;
  width: 6.8rem;
  border: solid 1px;
  display: flex; }

.rolzo-first-label-prefence {
  font-size: 12px;
  border-radius: 2px;
  font-weight: 700 !important;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.1);
  border-color: black;
  color: black;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  padding-top: 0.4rem !important;
  text-transform: uppercase;
  margin-bottom: 1rem;
  width: 5.8rem;
  border: solid 1px;
  display: flex; }

.rolzo-first-label-client:hover {
  cursor: pointer; }

.rolzo-first-redirect {
  color: #a79779;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 1rem;
  text-transform: none; }

.rolzo-first-redirect:hover {
  cursor: pointer; }

.rolzo-first-label-mobile {
  font-size: 12px;
  border-radius: 50%;
  font-weight: 700 !important;
  white-space: nowrap;
  display: flex;
  border: 1px solid rgba(139, 149, 158, 0.25);
  color: black;
  padding: 0.54rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  height: 2.813rem;
  width: 2.813rem; }

.driver-section-driver-files-name {
  font-size: 14px !important; }

.warning-img {
  width: 1.3rem;
  height: 1.3rem;
  /* margin-bottom: 4px; */
  margin-right: 0.8rem; }

.modal-header-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }

.modal-body-2 {
  padding: 2rem 5rem 4rem 5rem; }

.modal-fields {
  background-color: rgba(139, 149, 158, 0.05); }

.modal-message {
  font-size: 15px !important;
  color: #0c0c0c;
  padding: 3rem 0rem 3rem 0rem; }

.travel-doc-col {
  padding-right: 5px !important;
  padding-left: 5px !important; }

.travel-doc-row {
  margin-left: 0.01rem !important;
  max-width: none !important;
  width: 100% !important; }

.travel-doc-remove {
  cursor: pointer;
  position: relative;
  top: 30.5px;
  height: 23px;
  width: 23px;
  border-radius: 18px; }

@media only screen and (max-width: 768px) {
  .travel-doc-row .travel-doc-remove {
    margin-left: 8px;
    flex-shrink: 0; } }

.dispatch-modal-button {
  display: flex;
  justify-content: end;
  width: 100%;
  border-top: 1px solid rgba(139, 149, 158, 0.25);
  padding-top: 1rem; }

.two-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-height: 1.2em;
  /* Adjust based on your font size and desired line height */
  max-height: 2.4em;
  /* Should be 2 times the line-height to accommodate two lines */ }

.partnerBadge {
  color: #ffffff;
  background: #636d77;
  border-radius: 10px;
  max-width: 16px;
  min-width: 16px;
  text-align: center;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 10px;
  font-weight: bold;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding-top: 0.1rem; }

.partnerBadgeBooking {
  color: #ffffff;
  background: #636d77;
  border-radius: 10px;
  max-width: 21px;
  min-width: 21px;
  text-align: center;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  padding-top: 2px;
  justify-content: center; }

.partner-badge-list {
  color: #ffffff;
  background: #636d77;
  border-radius: 10px;
  font-weight: bold;
  font-size: 9px;
  font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 3px 3px 2px 3px;
  margin-left: 6px;
  text-align: center; }

.border-grey-30 {
  border-color: rgba(139, 149, 158, 0.3); }

.left-0 {
  left: 0; }

.z-index-1 {
  z-index: 1; }

.flight-banner-alert {
  display: flex;
  align-items: center;
  border: 1px solid #ffa500;
  border-radius: 8px;
  padding: 16px;
  background-color: #fafafa;
  color: #333;
  font-size: 14px;
  line-height: 1.5; }

.flight-banner-icon {
  height: 20px;
  width: 20px;
  margin-left: -6px;
  margin-right: 10px; }

.flight-banner-icon-mobile {
  height: 20px;
  width: 20px;
  margin-left: -6px;
  margin-right: 10px;
  margin-bottom: 7rem; }

@media (max-width: 425px) {
  .flight-banner-icon-mobile {
    margin-bottom: 8.2rem; } }

.flight-banner-message {
  flex: 1; }

.reinstate-booking-div {
  cursor: pointer;
  display: inline-block;
  margin-left: 1rem; }
  .reinstate-booking-div img {
    height: 1rem;
    width: 1rem; }
  .reinstate-booking-div span {
    color: #a79779;
    font-size: 14px;
    margin-left: 0.25rem; }

.mt-fourHalf {
  margin-top: 2rem;
  white-space: pre; }

.mt-fourHalf-right {
  right: 35px; }

.maxWidth100 {
  max-width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

h4.sub-header {
  font-weight: lighter; }

h4.sub-header-rates {
  font-weight: lighter; }

a.invite-new-user {
  font-size: 15px; }

.profile-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; }

.profile-link {
  display: flex;
  align-items: center;
  width: 450px;
  max-width: 100%;
  height: 100px;
  border: 1px solid rgba(139, 149, 158, 0.25);
  margin-left: 0;
  padding-left: 30px;
  border-radius: 2px; }
  .profile-link:hover {
    text-decoration: none;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1); }
  .profile-link .profile-info {
    padding-left: 20px; }
    .profile-link .profile-info p {
      padding: 0;
      margin: 0; }
      .profile-link .profile-info p.header {
        font-size: 14px;
        color: #0c0c0c; }
      .profile-link .profile-info p.description {
        font-size: 12px;
        color: #8b959e; }
      .profile-link .profile-info p:hover {
        text-decoration: none; }
  .profile-link .notifications {
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 50%; }
    .profile-link .notifications img {
      width: 45px;
      height: 45px;
      padding: 10px;
      border: none !important; }
  .profile-link .billing {
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 50%; }
    .profile-link .billing img {
      width: 45px;
      height: 45px;
      padding: 10px;
      border: none !important;
      translate: 2px; }
  .profile-link .preference {
    border: 1px solid rgba(139, 149, 158, 0.25);
    border-radius: 50%; }
    .profile-link .preference img {
      width: 45px;
      height: 45px;
      padding: 10px;
      border: none !important; }
  .profile-link img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border: 1px solid rgba(139, 149, 158, 0.25); }

.submit-btn-change-pass {
  height: 40px !important; }

.NotificationSettings .BookingUpdates span {
  font-size: 20px !important;
  color: black; }

.NotificationSettings .icons .email-icon {
  width: 17px !important;
  vertical-align: baseline;
  margin-right: 5px; }

.NotificationSettings .icons img {
  width: 15px;
  vertical-align: baseline;
  margin-right: 5px; }

.NotificationSettings .icons span {
  color: #8b959e; }

.NotificationSettings .checkBox-Center {
  margin: 0% 40%; }

.NotificationSettings thead th {
  border-bottom: none !important; }

.NotificationSettings td {
  background-color: transparent; }

.NotificationSettings tbody tr td {
  border: none !important; }

.NotificationSettings th {
  border: none !important; }
  .NotificationSettings th td {
    border-radius: none !important; }

.NotificationSettings .custom-control-label:after {
  background-color: #fbfbfb;
  border: 1px solid #8b959e; }

.NotificationSettings .custom-control-input:checked ~ .custom-control-label:after {
  border-color: black;
  background-size: 9px 11px; }

.earnings-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  gap: 15px;
  padding-right: 0.938rem; }
  .earnings-container .grid-item {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb;
    border-color: transparent;
    padding: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .earnings-container .top-left {
    grid-column: 1 / span 2;
    margin-bottom: 1rem; }
  .earnings-container .top-right {
    grid-column: 3 / span 2;
    margin-bottom: 1rem;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    /* creates two rows */
    gap: 10px;
    /* space between the grid items */ }
    .earnings-container .top-right .pending-expected {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .earnings-container .top-right .pending-expected-body {
      font-size: 20px;
      display: flex;
      justify-content: flex-end;
      padding: 0px; }
      .earnings-container .top-right .pending-expected-body .amount {
        font-weight: normal;
        margin-right: 0.3em; }
      .earnings-container .top-right .pending-expected-body .currency {
        font-weight: normal; }
  .earnings-container .bottom-left,
  .earnings-container .bottom-center-left,
  .earnings-container .bottom-center-right,
  .earnings-container .bottom-right {
    grid-column: auto; }
  .earnings-container .card-header {
    font-size: 14px;
    margin-bottom: 0.5rem;
    padding: 0px;
    color: #8b959e;
    background-color: transparent;
    border-bottom: none;
    margin-bottom: 2rem; }
  .earnings-container .card-body {
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    padding: 0px; }
    .earnings-container .card-body .amount {
      font-weight: normal;
      margin-right: 0.3em; }
    .earnings-container .card-body .currency {
      font-weight: normal; }
  .earnings-container .card-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .earnings-container .increase {
    color: #417505;
    font-size: 0.8rem; }
  .earnings-container .decrease {
    color: #d0021b;
    font-size: 0.8rem; }

.account-payout-card {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(139, 149, 158, 0.25);
  max-height: 12.938rem;
  max-width: 22.813rem;
  padding: 1.5rem;
  border-radius: 2px; }
  .account-payout-card img {
    height: 1.6rem;
    width: 1.6rem;
    margin-bottom: 1rem; }

.account-tabs .custom-tabs-nav {
  background: rgba(139, 149, 158, 0.15);
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 13px;
  margin-bottom: 35px; }
  .account-tabs .custom-tabs-nav .nav-link {
    background-color: transparent;
    color: rgba(139, 149, 158, 0.5);
    font-weight: 500;
    padding: 9px 18px;
    border-radius: 3px; }
    .account-tabs .custom-tabs-nav .nav-link:hover {
      color: rgba(139, 149, 158, 0.7); }
    .account-tabs .custom-tabs-nav .nav-link.active {
      background-color: #ffffff;
      color: #323c47;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
  .account-tabs .custom-tabs-nav.light-tabs {
    border-bottom: 1px solid rgba(139, 149, 158, 0.3);
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0; }
    .account-tabs .custom-tabs-nav.light-tabs .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: #8b959e;
      opacity: 0.55;
      padding: 7px 7px 14px 7px;
      border: none; }
      .account-tabs .custom-tabs-nav.light-tabs .nav-link.active {
        background: transparent;
        border: none;
        border-bottom: 3px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600; }
      .account-tabs .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
        margin-right: 25px; }
  .account-tabs .custom-tabs-nav:focus {
    outline: none; }

.preview-card {
  height: 17rem;
  width: 23.7rem;
  margin-left: 1.2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(139, 149, 158, 0.3);
  padding: 1.6rem;
  border-radius: 2px;
  margin-top: 1rem;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.preview-card .top-image-container {
  width: 80px;
  height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem; }

.preview-card .bottom-image-container {
  width: 80px;
  height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center; }

.preview-card .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: black;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase; }

.preview-card .top-image {
  max-height: 100%;
  /* Ensure the image fits within the reserved space */ }

.preview-card .bottom-image {
  max-height: 100%;
  /* Ensure the image fits within the reserved space */
  align-self: center; }

.my-profile-mobile {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem; }
  .my-profile-mobile .layout-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    width: 80px;
    min-width: 80px;
    background: #8b959e;
    opacity: 0.5;
    border-radius: 50%;
    margin-right: 0px !important;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 33px;
    margin-bottom: 1.5rem; }
  .my-profile-mobile .header {
    font-size: 16px;
    padding-bottom: 0.4rem;
    display: flex; }

.my-profile-section {
  margin-top: 4rem !important; }

.host-agency-bank {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1rem 7rem 1rem 1rem;
  background-color: rgba(139, 149, 158, 0.05);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 100%; }

.host-agency-bank-title {
  font-size: 12px;
  margin-bottom: 0.5rem; }

.form-field-with-remove {
  display: flex;
  align-items: center; }
  .form-field-with-remove .col-md-4 {
    flex: 0 0 37.8%;
    max-width: 37.8%;
    display: flex;
    align-items: center; }
  .form-field-with-remove .form-group {
    width: inherit;
    margin-right: 0.5rem; }

.toolTip-settings {
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  margin-left: 1em;
  margin-top: 0.5em;
  padding: 0.2rem; }
  .toolTip-settings .toolTip-light-grey {
    display: inline-block;
    text-align: left;
    font-size: 13px;
    color: #0c0c0c;
    text-align: left; }

.radio-prefrence-margin {
  margin-top: 0.7rem;
  margin-bottom: 0px; }

.register-cool-responsive {
  max-width: 730px; }

.client-chat-layout {
  background: #ffffff; }

.client-chat-layout main {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.client-chat-container {
  height: 100%;
  display: grid;
  grid-template-columns: 420px 1fr;
  grid-gap: 10px;
  align-items: stretch;
  padding: 0 20px 0;
  align-self: center;
  border-top: none;
  width: 100%; }
  .client-chat-container .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden; }
    .client-chat-container .user-avatar.user-online {
      border: 1px solid #417505; }
    .client-chat-container .user-avatar img {
      display: block;
      width: 34px;
      height: 34px;
      object-fit: cover;
      object-position: center center; }
      .client-chat-container .user-avatar img.rolzo-avatar {
        width: 34px;
        height: 34px; }
  .client-chat-container .client-chat-room {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(100vw - 250px);
    margin: 0 auto;
    border-left: none;
    height: 100%; }
    .client-chat-container .client-chat-room .spinner_wrapper {
      z-index: 1; }
    .client-chat-container .client-chat-room .room-header {
      position: absolute;
      height: 108px;
      width: calc(100vw - 490px);
      top: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 70px;
      border-left: 1px solid rgba(12, 12, 12, 0.05);
      font-size: 14px;
      font-weight: 500;
      color: #a79779; }
      .client-chat-container .client-chat-room .room-header a:hover {
        text-decoration: none; }
      .client-chat-container .client-chat-room .room-header .user-avatar {
        margin-right: 10px; }
    .client-chat-container .client-chat-room .client-messages-list {
      height: 100%;
      padding: 20px 20px 0 20px;
      max-width: 1000px;
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column-reverse; }
      .client-chat-container .client-chat-room .client-messages-list .ScrollbarsCustom-Track {
        background-color: #ffffff !important;
        opacity: 0;
        transition: opacity 200ms ease;
        transform: translateY(0px);
        width: 8px !important;
        padding-left: 2px; }
        .client-chat-container .client-chat-room .client-messages-list .ScrollbarsCustom-Track .ScrollbarsCustom-Thumb {
          width: 100%;
          background-color: rgba(120, 128, 134, 0.5) !important; }
      .client-chat-container .client-chat-room .client-messages-list:hover .ScrollbarsCustom-Track {
        opacity: 1; }
      .client-chat-container .client-chat-room .client-messages-list .ScrollbarsCustom-Content {
        padding-right: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 25px !important; }
      .client-chat-container .client-chat-room .client-messages-list .date-line {
        width: 100%;
        border-top: 1px solid rgba(12, 12, 12, 0.05);
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding-top: 5px; }
        .client-chat-container .client-chat-room .client-messages-list .date-line div {
          font-size: 12px;
          color: #8b959e;
          background: #ffffff;
          margin-top: -13px;
          width: 80px;
          text-align: center;
          white-space: nowrap; }
      .client-chat-container .client-chat-room .client-messages-list .message {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;
        padding-left: 5px; }
        .client-chat-container .client-chat-room .client-messages-list .message .user-avatar {
          order: 1;
          margin-right: 10px;
          flex-shrink: 0;
          border: 1px solid rgba(12, 12, 12, 0.1);
          object-fit: cover;
          object-position: center center; }
        .client-chat-container .client-chat-room .client-messages-list .message .text-wrap {
          order: 2;
          max-width: 65%;
          background-color: #ffffff;
          border-radius: 2px;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
          padding: 12px; }
          .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .text {
            margin-bottom: 4px;
            display: inline-block;
            width: 100%;
            word-break: break-word;
            font-size: 15px; }
          .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .message-info {
            font-size: 12px;
            color: rgba(139, 149, 158, 0.5);
            text-align: right;
            display: inline; }
          .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .message-info-booked {
            font-size: 12px;
            color: rgba(139, 149, 158, 0.5);
            float: right; }
          .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .download-link {
            color: #a79779;
            font-size: 10px;
            display: block;
            text-align: start; }
            .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .download-link .pdf-icon {
              width: 11px;
              height: 11px;
              margin-right: 7px; }
            .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .download-link:hover {
              text-decoration: none; }
        .client-chat-container .client-chat-room .client-messages-list .message.is-current .text-wrap {
          background-color: rgba(139, 149, 158, 0.1);
          box-shadow: none; }
        .client-chat-container .client-chat-room .client-messages-list .message.is-typing {
          align-items: center;
          visibility: visible; }
          .client-chat-container .client-chat-room .client-messages-list .message.is-typing .user-avatar {
            order: 0; }
        .client-chat-container .client-chat-room .client-messages-list .message.is-current {
          justify-content: flex-end; }
          .client-chat-container .client-chat-room .client-messages-list .message.is-current .user-avatar {
            order: 2;
            margin-right: 0;
            margin-left: 15px; }
          .client-chat-container .client-chat-room .client-messages-list .message.is-current .text-wrap {
            order: 1; }
        .client-chat-container .client-chat-room .client-messages-list .message .typer-name {
          margin-top: 3%;
          margin-bottom: -13%;
          color: #8b959e; }
        .client-chat-container .client-chat-room .client-messages-list .message .typer-name-desktop {
          margin-bottom: -10%;
          color: #8b959e; }
        .client-chat-container .client-chat-room .client-messages-list .message.hidden {
          visibility: hidden; }
        .client-chat-container .client-chat-room .client-messages-list .message.displaynone {
          opacity: 0; }
    .client-chat-container .client-chat-room .client-message-input-block {
      position: fixed;
      height: inherit;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-top: 5px;
      border-top: 1px solid rgba(139, 149, 158, 0.3); }
      .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container {
        max-width: 380px;
        position: fixed;
        bottom: 0; }
        .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container .empty-collect-files {
          visibility: hidden;
          padding: 0 !important;
          margin: 0 !important; }
        .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container .collected-files {
          display: block;
          background: white;
          width: 380px;
          border-top: 1px solid rgba(139, 149, 158, 0.3);
          margin-left: 0;
          padding: 10px 10px 5px 10px; }
          .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container .collected-files .file-preview {
            justify-content: normal;
            padding: 3px 0; }
            .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container .collected-files .file-preview .image-preview-label {
              max-width: unset;
              width: 100%; }
    .client-chat-container .client-chat-room .message-input-toolbar {
      display: flex; }
      .client-chat-container .client-chat-room .message-input-toolbar .form-group {
        margin: 0; }
      .client-chat-container .client-chat-room .message-input-toolbar .custom-file-label {
        height: 40px;
        left: 26px; }
        .client-chat-container .client-chat-room .message-input-toolbar .custom-file-label .placeholder-icon {
          height: auto;
          width: 14px;
          margin-top: 2px; }
      .client-chat-container .client-chat-room .message-input-toolbar .message-input-wrap {
        position: relative;
        flex-grow: 1;
        height: 100%;
        width: calc(100% - 45px);
        max-width: 660px;
        margin-left: 4px; }
        .client-chat-container .client-chat-room .message-input-toolbar .message-input-wrap .custom-file,
        .client-chat-container .client-chat-room .message-input-toolbar .message-input-wrap .custom-file-label {
          background-color: transparent; }
        .client-chat-container .client-chat-room .message-input-toolbar .message-input-wrap .toolbar-buttons {
          position: absolute;
          left: 8px;
          top: 10px;
          display: flex;
          align-items: center; }
          .client-chat-container .client-chat-room .message-input-toolbar .message-input-wrap .toolbar-buttons .toolbar-btn.car-btn {
            width: 24px;
            height: 24px;
            margin-right: 22px;
            z-index: 1; }
          .client-chat-container .client-chat-room .message-input-toolbar .message-input-wrap .toolbar-buttons .toolbar-btn img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
      .client-chat-container .client-chat-room .message-input-toolbar.client-toolbar {
        bottom: 110px;
        width: 100%;
        max-width: 1000px; }
        .client-chat-container .client-chat-room .message-input-toolbar.client-toolbar .message-input-wrap textarea {
          padding: 9px 0 0 46px !important;
          font-size: 15px !important; }
        .client-chat-container .client-chat-room .message-input-toolbar.client-toolbar .message-input-wrap textarea::placeholder {
          color: #000000;
          opacity: 0.4; }
        .client-chat-container .client-chat-room .message-input-toolbar.client-toolbar .message-input-wrap textarea:-ms-input-placeholder {
          color: #000000;
          opacity: 0.4; }
        .client-chat-container .client-chat-room .message-input-toolbar.client-toolbar .message-input-wrap textarea::-ms-input-placeholder {
          color: #000000;
          opacity: 0.4; }
        .client-chat-container .client-chat-room .message-input-toolbar.client-toolbar .custom-file-label {
          left: 1px; }
      .client-chat-container .client-chat-room .message-input-toolbar .send-avatar-wrap {
        display: none;
        position: relative;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px; }
        .client-chat-container .client-chat-room .message-input-toolbar .send-avatar-wrap .send-avatar {
          top: 0;
          position: absolute;
          width: 36px;
          height: 36px;
          flex-shrink: 0;
          margin: auto;
          border: 1px solid rgba(12, 12, 12, 0.1);
          border-radius: 40px;
          object-position: center center;
          object-fit: cover; }
          .client-chat-container .client-chat-room .message-input-toolbar .send-avatar-wrap .send-avatar img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            margin-left: 7px; }
      .client-chat-container .client-chat-room .message-input-toolbar .send-btn-wrap {
        position: relative;
        height: 100%; }
        .client-chat-container .client-chat-room .message-input-toolbar .send-btn-wrap .send-btn {
          top: 0;
          position: absolute;
          width: 30px;
          height: 40px;
          margin: 0;
          margin-left: 20px;
          flex-shrink: 0;
          padding-top: 9px; }
          .client-chat-container .client-chat-room .message-input-toolbar .send-btn-wrap .send-btn:hover {
            opacity: 0.8; }
          .client-chat-container .client-chat-room .message-input-toolbar .send-btn-wrap .send-btn:focus {
            box-shadow: none !important; }
          .client-chat-container .client-chat-room .message-input-toolbar .send-btn-wrap .send-btn img {
            width: 24px;
            height: 20px;
            object-fit: contain; }

.collected-files {
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow-x: auto;
  align-items: center;
  justify-content: flex-start; }
  .collected-files .form-group {
    margin: 0; }

.file-preview {
  flex: 0 0 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 3px 0; }
  .file-preview .image-preview-label {
    width: 100%;
    overflow: hidden;
    margin: 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px; }
  .file-preview .removable-image img {
    max-width: 16px !important;
    max-height: 16px !important; }
  .file-preview .remove-file-btn {
    display: block;
    color: #a79779; }
    .file-preview .remove-file-btn:hover {
      text-decoration: none;
      color: #a79779; }
    .file-preview .remove-file-btn img {
      width: 10px;
      height: 10px; }

.vehicle-checkbox .custom-control-label:after {
  border-radius: 2px;
  background-color: #fbfbfb;
  border: 1px solid #8b959e;
  background-size: 8px 11px;
  background-position: center center;
  height: 20px;
  width: 20px; }

.coverage-checkbox {
  z-index: 0; }
  .coverage-checkbox .custom-control-label:after {
    border-radius: 2px;
    background-color: #fbfbfb;
    border: 1px solid #8b959e;
    background-size: 5px 8px;
    background-position: center center;
    height: 15px;
    width: 15px;
    z-index: 0; }
  .coverage-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    border-color: black;
    background-size: 9px 11px; }

.is-editable-checkbox {
  float: right;
  margin-top: 5px; }

.is-editable-checkbox-admin {
  float: right;
  margin-top: 30px; }

.vehicle-wrap {
  order: 2;
  color: rgba(33, 55, 71, 0.6);
  font-size: 12px; }

.support-chat {
  width: 100%; }
  .support-chat .client-chat-container {
    padding: 0px !important;
    border-top: unset;
    display: unset;
    grid-template-columns: unset;
    grid-gap: unset;
    align-items: unset;
    padding: unset; }
  .support-chat .client-messages-list {
    padding: 0px !important;
    height: calc(100vh - 113px) !important; }
  .support-chat .client-chat-room {
    width: 100% !important; }
  .support-chat .client-message-input-block {
    position: unset !important;
    height: 52px !important; }
  .support-chat .chat-vehicle-card {
    max-width: 360px !important; }
  .support-chat .send-avatar-wrap {
    width: 45px !important;
    height: 47px !important; }
  .support-chat .send-btn-wrap {
    width: 40px !important;
    height: 45px !important; }
  .support-chat .send-btn {
    margin-left: 6px !important; }
  .support-chat .ScrollbarsCustom-Scroller::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .support-chat .ScrollbarsCustom-Scroller::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent; }
  .support-chat .ScrollbarsCustom-Scroller::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px; }
  .support-chat .ScrollbarsCustom-Content::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .support-chat .ScrollbarsCustom-Content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent; }
  .support-chat .ScrollbarsCustom-Content::-webkit-scrollbar-thumb {
    background-color: rgba(120, 128, 134, 0.5) !important;
    border-radius: 10px; }

.message-info-bottom .message-info {
  font-size: 12px;
  color: rgba(139, 149, 158, 0.5);
  text-align: right;
  display: inline; }

.message-info-bottom .message-info-booked {
  font-size: 12px;
  color: #0c0c0c;
  text-align: right; }
  .message-info-bottom .message-info-booked img {
    width: 10px;
    vertical-align: text-top; }

.message-info-bottom-chat .message-info {
  font-size: 12px;
  color: rgba(139, 149, 158, 0.5);
  text-align: right;
  display: inline; }

.message-info-bottom-chat .message-info-booked {
  font-size: 12px;
  color: #0c0c0c;
  text-align: right; }
  .message-info-bottom-chat .message-info-booked img {
    width: 10px;
    vertical-align: text-top; }

.client-messages-list .message-info-bottom {
  max-width: 300px; }

.client-messages-list .message-info-bottom-chat {
  max-width: 360px; }

.client-chat {
  max-width: 360px; }

.select-vehicle-chat {
  height: 40px;
  min-width: 360px !important;
  font-size: 14px;
  margin-bottom: 5px !important; }

.care-info-column .quote-right-top-section {
  padding-top: 32px; }

.app-content .sidebar-container .nav-link span {
  padding-top: 8px;
  padding-bottom: 5px; }

.my-booking-table .data-table tr:hover td:first-child,
.all-bookings .data-table tr:hover td:first-child {
  text-decoration: underline;
  cursor: pointer; }

.booking-preview-info .booking-event-section {
  display: flex;
  flex-direction: column;
  height: 100%; }

.booking-preview-info .booking-preview-info-section .chat-list-container {
  flex-grow: 1;
  height: 100% !important; }

.booking-preview-info .booking-preview-info-section .chat-list-booking {
  flex-grow: 1;
  height: calc(100vh - 17rem) !important; }

.booking-preview-info .booking-event-section .input-message-block .input-message-area {
  flex-wrap: nowrap !important; }
  .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap {
    max-width: 100%; }

.client-chat-container .client-chat-room {
  height: calc(100vh - 62px);
  display: flex;
  flex-direction: column; }
  .client-chat-container .client-chat-room .client-messages-list {
    width: 100%;
    flex-grow: 1;
    height: 0% !important; }
  .client-chat-container .client-chat-room .client-message-input-block {
    height: auto !important; }
    .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container {
      position: unset; }
  .client-chat-container .client-chat-room .textarea-chat-control {
    border: 1px solid rgba(12, 12, 12, 0.1); }
    .client-chat-container .client-chat-room .textarea-chat-control div {
      border: none !important; }

.client-chat-room-open {
  height: calc(100vh - 125px) !important; }

.client-chat-room-close {
  height: calc(100vh - 220px) !important; }

@media (max-width: 768px) {
  .chat-box-focus .sidebar-bottom-container {
    display: none; }
  .chat-box-focus .client-chat-container .client-chat-room .client-message-input-block {
    bottom: 0 !important; } }

.chat-padding__input {
  left: 6px !important; }

.chat-padding__control {
  width: 102%; }

.page-wrap .nested-layout.admin-chat-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; }
  .page-wrap .nested-layout.admin-chat-layout header {
    margin: 0;
    background-color: #fafafa; }
  .page-wrap .nested-layout.admin-chat-layout main {
    padding: 0;
    height: calc(100% - 55px);
    background-color: #ffffff !important; }

.admin-chat-container {
  height: 100%;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  display: grid;
  grid-template-columns: 420px 1fr;
  grid-gap: 10px;
  align-items: stretch;
  border-right: 1px solid rgba(12, 12, 12, 0.05); }
  @media (max-width: 767.98px) {
    .admin-chat-container {
      border-right: none; } }
  .admin-chat-container .user-avatar {
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    width: fit-content; }
    .admin-chat-container .user-avatar.user-online {
      border: 1px solid #417505; }
    .admin-chat-container .user-avatar img {
      display: block;
      width: 34px;
      height: 34px;
      object-fit: cover;
      object-position: center center; }
      .admin-chat-container .user-avatar img.rolzo-avatar {
        width: 34px;
        height: 34px; }
  .admin-chat-container .admin-chat-room {
    flex-direction: column;
    overflow: hidden;
    width: calc(100vw - 490px); }
    .admin-chat-container .admin-chat-room .room-header {
      position: absolute;
      height: 53px;
      width: calc(100vw - 43%);
      top: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 70px;
      border-left: 1px solid rgba(12, 12, 12, 0.05);
      font-size: 12px;
      font-weight: 500;
      color: #8b959e; }
      .admin-chat-container .admin-chat-room .room-header a:hover {
        text-decoration: none; }
      .admin-chat-container .admin-chat-room .room-header .user-avatar {
        margin-right: 10px; }
      .admin-chat-container .admin-chat-room .room-header .userLabel-plain {
        color: #0c0c0c;
        font-weight: bold; }
      .admin-chat-container .admin-chat-room .room-header .userLabel-grey {
        color: #8b959e;
        text-align: center;
        font-weight: bold; }
      .admin-chat-container .admin-chat-room .room-header .userLabel-border-right {
        color: #0c0c0c;
        padding: 0px 10px 0px 0px;
        border-right: 1px solid #8b959e;
        margin-right: 10px;
        font-weight: bold; }
    .admin-chat-container .admin-chat-room .room-content {
      border-left: 1px solid rgba(12, 12, 12, 0.05);
      height: 100%; }
      .admin-chat-container .admin-chat-room .room-content .admin-messages-list {
        overflow-y: auto;
        max-height: calc(100vh - 22%); }
      .admin-chat-container .admin-chat-room .room-content .message-input-wrap textarea {
        padding-left: 65px !important;
        padding-top: 11px !important;
        font-size: 15px !important; }
      .admin-chat-container .admin-chat-room .room-content .message-input-wrap textarea::placeholder {
        color: #000000;
        opacity: 0.4; }
      .admin-chat-container .admin-chat-room .room-content .message-input-wrap textarea:-ms-input-placeholder {
        color: #000000;
        opacity: 0.4; }
      .admin-chat-container .admin-chat-room .room-content .message-input-wrap textarea::-ms-input-placeholder {
        color: #000000;
        opacity: 0.4; }
    .admin-chat-container .admin-chat-room .admin-messages-list {
      height: 100%;
      padding: 20px 65px 0 10px; }
      .admin-chat-container .admin-chat-room .admin-messages-list .date-line {
        width: 100%;
        border-top: 1px solid rgba(12, 12, 12, 0.05);
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding-top: 5px; }
        .admin-chat-container .admin-chat-room .admin-messages-list .date-line div {
          font-size: 12px;
          color: #8b959e;
          background: #ffffff;
          margin-top: -13px;
          width: 80px;
          text-align: center;
          white-space: nowrap; }
      .admin-chat-container .admin-chat-room .admin-messages-list #options .hides {
        display: none; }
      .admin-chat-container .admin-chat-room .admin-messages-list #options:hover .hides {
        display: block;
        float: right;
        margin-top: -25px;
        padding: 5px;
        background-color: gainsboro; }
      .admin-chat-container .admin-chat-room .admin-messages-list .msg-editing .cancel-btn {
        width: auto;
        position: relative;
        margin: 10px 10px;
        border: 1px solid #8b959e;
        height: 40px;
        display: inline-block;
        line-height: 20px;
        color: #8b959e !important;
        font-weight: 500;
        z-index: 50; }
      .admin-chat-container .admin-chat-room .admin-messages-list .msg-editing .save-btn {
        padding: 0px 1rem !important;
        position: relative;
        z-index: 50; }
      .admin-chat-container .admin-chat-room .admin-messages-list .message {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;
        padding-left: 5px; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message .user-avatar {
          order: 1;
          margin-right: 10px;
          flex-shrink: 0;
          border: 1px solid rgba(12, 12, 12, 0.1);
          object-fit: cover;
          object-position: center center; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap {
          order: 2;
          max-width: 56%;
          background-color: #ffffff;
          border-radius: 2px;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
          padding: 12px; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap .text {
            margin-bottom: 4px;
            display: inline-block;
            width: 100%;
            word-break: break-word;
            font-size: 15px; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap .message-info {
            font-size: 12px;
            color: rgba(139, 149, 158, 0.5);
            text-align: right;
            display: inline; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap .message-info-booked {
            font-size: 12px;
            color: rgba(139, 149, 158, 0.5);
            float: right; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap .download-link {
            color: #a79779;
            font-size: 10px;
            display: block; }
            .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap .download-link .pdf-icon {
              width: 11px;
              height: 11px;
              margin-right: 7px; }
            .admin-chat-container .admin-chat-room .admin-messages-list .message .text-wrap .download-link:hover {
              text-decoration: none; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message.is-current .text-wrap {
          background-color: rgba(139, 149, 158, 0.1);
          box-shadow: none; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message.is-typing {
          align-items: center;
          visibility: visible; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message.is-typing .user-avatar {
            order: 0; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message.is-current {
          justify-content: flex-end; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message.is-current .user-avatar {
            order: 2;
            margin-right: 0;
            margin-left: 15px; }
          .admin-chat-container .admin-chat-room .admin-messages-list .message.is-current .text-wrap {
            order: 1; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message.hidden {
          visibility: hidden; }
        .admin-chat-container .admin-chat-room .admin-messages-list .message.displaynone {
          opacity: 0; }
    .admin-chat-container .admin-chat-room .admin-message-input-block {
      position: fixed;
      bottom: 0;
      padding: 5px 15px 15px 11px;
      border-top: 1px solid rgba(139, 149, 158, 0.3);
      width: calc(100vw - 40%);
      background: white; }
      .admin-chat-container .admin-chat-room .admin-message-input-block .chat-footer-container {
        max-width: 660px; }
    .admin-chat-container .admin-chat-room .message-input-toolbar {
      width: calc(100% - 80px);
      display: flex; }
      .admin-chat-container .admin-chat-room .message-input-toolbar .form-group {
        margin: 0; }
      .admin-chat-container .admin-chat-room .message-input-toolbar .custom-file-label {
        height: 40px;
        left: 26px; }
        .admin-chat-container .admin-chat-room .message-input-toolbar .custom-file-label .placeholder-icon {
          height: auto;
          width: 14px;
          margin-top: 2px; }
      .admin-chat-container .admin-chat-room .message-input-toolbar .message-input-wrap {
        position: relative;
        flex-grow: 1;
        height: 100%;
        margin-left: 4px;
        width: 100%; }
        .admin-chat-container .admin-chat-room .message-input-toolbar .message-input-wrap .custom-file,
        .admin-chat-container .admin-chat-room .message-input-toolbar .message-input-wrap .custom-file-label {
          background-color: transparent; }
        .admin-chat-container .admin-chat-room .message-input-toolbar .message-input-wrap .toolbar-buttons {
          position: absolute;
          left: 8px;
          top: 10px;
          display: flex;
          align-items: center; }
          .admin-chat-container .admin-chat-room .message-input-toolbar .message-input-wrap .toolbar-buttons .toolbar-btn.car-btn {
            width: 24px;
            height: 24px;
            margin-right: 22px;
            z-index: 1; }
          .admin-chat-container .admin-chat-room .message-input-toolbar .message-input-wrap .toolbar-buttons .toolbar-btn img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
      .admin-chat-container .admin-chat-room .message-input-toolbar .send-avatar-wrap {
        display: none;
        position: relative;
        height: 100%;
        margin-top: 8px;
        margin-left: 8px; }
        .admin-chat-container .admin-chat-room .message-input-toolbar .send-avatar-wrap .send-avatar {
          top: 0;
          position: absolute;
          width: 36px;
          height: 36px;
          flex-shrink: 0;
          margin: auto;
          border: 1px solid rgba(12, 12, 12, 0.1);
          border-radius: 40px;
          object-position: center center;
          object-fit: cover; }
          .admin-chat-container .admin-chat-room .message-input-toolbar .send-avatar-wrap .send-avatar img {
            width: 20px;
            height: 20px;
            margin-top: 6px;
            margin-left: 7px; }
      .admin-chat-container .admin-chat-room .message-input-toolbar .send-btn-wrap {
        position: relative;
        height: 100%; }
        .admin-chat-container .admin-chat-room .message-input-toolbar .send-btn-wrap .send-btn {
          top: 0;
          position: absolute;
          width: 30px;
          height: 40px;
          margin: 0;
          margin-left: 20px;
          flex-shrink: 0;
          padding-top: 9px; }
          .admin-chat-container .admin-chat-room .message-input-toolbar .send-btn-wrap .send-btn:hover {
            opacity: 0.8; }
          .admin-chat-container .admin-chat-room .message-input-toolbar .send-btn-wrap .send-btn:focus {
            box-shadow: none !important; }
          .admin-chat-container .admin-chat-room .message-input-toolbar .send-btn-wrap .send-btn img {
            width: 24px;
            height: 20px;
            object-fit: contain; }
  .admin-chat-container .chats-list-wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative; }
    .admin-chat-container .chats-list-wrap .spinner_wrapper {
      top: 130px;
      z-index: 1; }
    .admin-chat-container .chats-list-wrap .list-panel {
      height: 120px;
      flex-shrink: 0;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .admin-chat-container .chats-list-wrap .list-panel .search-field-wrap {
        flex-grow: 1; }
        .admin-chat-container .chats-list-wrap .list-panel .search-field-wrap .form-group {
          margin-bottom: 0; }
          .admin-chat-container .chats-list-wrap .list-panel .search-field-wrap .form-group .form-control {
            height: 40px;
            box-shadow: none;
            border: 1px solid #8b959e;
            font-size: 14px;
            padding: 0 16px;
            padding-left: 54px;
            background-image: url("/images/icons/chat/search.png");
            background-position: center left 16px;
            background-size: 24px 24px;
            background-repeat: no-repeat; }
      .admin-chat-container .chats-list-wrap .list-panel .chat-sort-menu {
        padding: 10px 0 20px; }
        .admin-chat-container .chats-list-wrap .list-panel .chat-sort-menu .dropdown-item {
          padding: 10px 20px; }
          .admin-chat-container .chats-list-wrap .list-panel .chat-sort-menu .dropdown-item:not(:last-child) {
            border-bottom: 0; }
    .admin-chat-container .chats-list-wrap .chats-list {
      height: 100%;
      overflow-y: auto;
      padding-right: 10px;
      padding-left: 5px; }
      .admin-chat-container .chats-list-wrap .chats-list .chat {
        background-color: #fbfbfb;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #0c0c0c;
        font-size: 14px;
        border-right: 2px solid #fbfbfb;
        text-decoration: none;
        padding: 10px 20px;
        margin-right: 15px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat:not(:last-child) {
          margin-bottom: 9px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat.chat-unread {
          border: 2px solid #d0021b;
          margin-right: 15px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat.add-border {
          border: 2px solid #417505;
          margin-right: 15px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat:hover {
          opacity: 0.7; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-avatar {
          margin-right: 1em; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .add-border {
          border: 2px solid #417505 !important; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .add-border-danger {
          border: 2px solid #d0021b; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .booking-badge {
          display: inline-block;
          margin-bottom: 5px;
          border: 1px solid #8b959e;
          border-radius: 2px;
          padding: 3px 10px 3px;
          color: #8b959e;
          font-size: 12px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-info-detailed {
          display: flex;
          align-items: end; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-name-chat {
          color: #000;
          font-size: 14px;
          font-weight: bold;
          white-space: initial;
          padding-top: 5px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .company-time-chat {
          color: #0c0c0c;
          font-size: 12px;
          font-weight: 500;
          white-space: initial;
          padding-top: 0 !important; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .company-name-chat {
          color: #8b959e;
          font-size: 12px;
          font-weight: 500;
          white-space: initial; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-status-idle {
          width: 12px;
          height: 12px;
          background-color: #f5a623;
          border-radius: 50%;
          margin: 0px 5px 5px 5px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-status-active {
          width: 12px;
          height: 12px;
          background-color: #5fd837;
          border-radius: 50%;
          margin: 0px 5px 5px 5px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-status-inactive {
          width: 12px;
          height: 12px;
          background-color: #8b959e;
          border-radius: 50%;
          margin: 0px 5px 5px 5px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .user-info .user-last-seen {
          color: #8b959e;
          font-style: italic;
          font-size: 10px;
          padding-bottom: 2px; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .top-first {
          top: 1.1em; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .top-second {
          top: -0.5em; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .top-third {
          top: -1.5em; }
        .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info {
          display: flex;
          align-items: end;
          flex-direction: column;
          justify-content: flex-end;
          flex-shrink: 0;
          font-size: 12px;
          text-align: right;
          margin-left: 10px;
          position: relative; }
          .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .user-status-idle {
            width: 12px;
            height: 12px;
            background-color: #f5a623;
            border-radius: 50%;
            margin: 0px 5px 5px 5px;
            position: relative;
            top: -12px;
            right: -6px; }
          .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .user-status-active {
            width: 12px;
            height: 12px;
            background-color: #5fd837;
            border-radius: 50%;
            margin: 0px 5px 5px 5px;
            position: relative;
            top: -12px;
            right: -6px; }
          .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .user-status-inactive {
            width: 12px;
            height: 12px;
            background-color: #8b959e;
            border-radius: 50%;
            margin: 0px 5px 5px 5px;
            position: relative;
            top: -12px;
            right: -6px; }
          .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .unread-title {
            color: #d0021b;
            display: flex; }
            .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .unread-title span {
              font-weight: bolder;
              font-size: 13px;
              text-transform: uppercase;
              margin-left: 3px; }
            .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .unread-title .unread-counter {
              display: inline-block;
              width: 24px;
              height: 24px;
              border: 1px solid #d0021b;
              border-radius: 20px;
              line-height: 24px;
              margin-left: 10px;
              text-align: center;
              font-weight: 600;
              font-size: 13px; }
          .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .message-date {
            color: #8b959e; }
          .admin-chat-container .chats-list-wrap .chats-list .chat .last-message-info .user-avatar {
            margin-left: 14px; }

.modal-chat-body {
  padding: 30px; }

.flag-btn {
  height: 30px; }

.chat-search {
  font-size: 14px !important; }

.message-info-bottom .message-info {
  font-size: 12px;
  color: rgba(139, 149, 158, 0.5);
  text-align: right;
  display: inline; }

.message-info-bottom .message-info-booked {
  font-size: 12px;
  color: #0c0c0c;
  float: right;
  text-align: right; }
  .message-info-bottom .message-info-booked img {
    width: 10px;
    vertical-align: text-top; }

.message-info-bottom .message-info-booked-event {
  font-size: 12px;
  color: #0c0c0c;
  text-align: right; }
  .message-info-bottom .message-info-booked-event img {
    width: 10px;
    vertical-align: text-top; }

.message-info-bottom .message-info-booked-event-chat {
  font-size: 12px;
  color: #0c0c0c;
  text-align: right;
  display: inline-block;
  float: right;
  margin-top: -5px; }
  .message-info-bottom .message-info-booked-event-chat img {
    width: 10px;
    vertical-align: text-top; }

.message-info-bottom-chat .message-info {
  font-size: 12px;
  color: rgba(139, 149, 158, 0.5);
  text-align: right;
  display: inline; }

.message-info-bottom-chat .message-info-booked {
  font-size: 12px;
  color: #0c0c0c;
  float: right;
  text-align: right; }
  .message-info-bottom-chat .message-info-booked img {
    width: 10px;
    vertical-align: text-top; }

.message-info-bottom-chat .message-info-booked-event {
  font-size: 12px;
  color: #0c0c0c;
  text-align: right; }
  .message-info-bottom-chat .message-info-booked-event img {
    width: 10px;
    vertical-align: text-top; }

.message-info-bottom-chat .message-info-booked-event-chat {
  font-size: 12px;
  color: #0c0c0c;
  text-align: right;
  display: inline-block;
  float: right;
  margin-top: -5px; }
  .message-info-bottom-chat .message-info-booked-event-chat img {
    width: 10px;
    vertical-align: text-top; }

.notifications-loyout {
  flex-direction: column;
  background: #ffffff; }
  .notifications-loyout header {
    background-color: #ffffff !important; }
  .notifications-loyout main {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 0px !important;
    align-self: center; }
    .notifications-loyout main .remove-btn {
      color: #d0021b; }
      .notifications-loyout main .remove-btn:hover {
        text-decoration: none; }
    .notifications-loyout main .data-table {
      min-width: 100% !important; }

.support-panel-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear; }

.support-panel-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear; }

.support-panel-wrapper {
  position: fixed;
  right: 0px;
  width: 380px;
  height: 100vh;
  z-index: 10;
  background: white;
  padding: 9px 0 0 0;
  border-left: 1px solid rgba(139, 149, 158, 0.3);
  box-shadow: 2px 2px 10px 0px #aaaaaa; }

.support-panel-wrapper thead {
  display: none; }

.support-panel-titlebar {
  display: flex;
  background: white;
  padding: 10px;
  justify-content: space-between;
  height: 51px;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3); }

.support-panel-title {
  font-weight: 600;
  font-size: 16px; }

.support-panel-img {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-right: 20px; }

.support-panel-table {
  height: calc(100vh - 50px);
  overflow: hidden hidden;
  padding-right: 15px; }
  .support-panel-table .data-table tr td {
    font-size: 15px; }
  .support-panel-table .notification-moment {
    margin-top: 6px;
    color: #8b959e;
    font-size: 13px; }

.support-panel-table:hover {
  overflow: hidden scroll;
  padding-right: 10px; }

.support-panel-table::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

.support-panel-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent; }

.support-panel-table::-webkit-scrollbar-thumb {
  background-color: rgba(120, 128, 134, 0.5) !important;
  border-radius: 10px; }

.invoice-download-link {
  color: #a79779;
  font-size: 10px; }
  .invoice-download-link:hover {
    text-decoration: none; }

.invoice-type {
  font-size: 8px;
  color: #8b959e; }

.custom-control.custom-radio label {
  cursor: default !important;
  font-size: 14px; }

.custom-control.custom-radio:hover {
  box-shadow: none; }

.small-screen-sign-up-header {
  padding: 12px 15px;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3); }
  .small-screen-sign-up-header h6 {
    font-weight: 500; }

@media (max-width: 1120px) and (min-width: 770px) {
  .new-booking-row .booking-col:last-child {
    margin-left: 0px; } }

.table-responsive {
  min-height: 200px; }

@media (min-width: 992px) {
  body > .navbar-collapse.collapse {
    display: none !important; } }

/*          Changes for small display      */
@media (max-width: 768px) {
  .tandc-card {
    margin: 6.5px 0;
    padding: 30px;
    border-radius: 2px;
    border: 1.5px solid rgba(139, 149, 158, 0.1);
    overflow: hidden;
    width: 500px; }
  .tandc-rental-card {
    margin: 6.5px 0;
    padding: 30px;
    border-radius: 2px;
    border: 1.5px solid rgba(139, 149, 158, 0.1);
    overflow: hidden;
    width: 485px; }
  .client-chat {
    max-width: 100%; }
  .client-messages-list .vehicle-card .vehicle-img-wrap .vehicle-img {
    height: 102px;
    object-fit: contain; }
  .client-messages-list .message-info-bottom {
    max-width: 100%; }
  .client-messages-list .message-info-bottom-chat {
    max-width: 100%; }
  .page-wrap {
    width: 100%; }
    .page-wrap .scrollable-page-content {
      height: 100vh !important;
      overflow: auto; }
      .page-wrap .scrollable-page-content .custom-tabs-nav.light-tabs .nav-link {
        font-size: 16px;
        font-weight: 600; }
    .page-wrap .contain-responsive-button.scrollable-page-content {
      padding-bottom: 75px; }
    .page-wrap .nested-layout.reset-password-layout h4 {
      white-space: unset !important;
      line-height: 32px;
      font-size: 16px;
      font-weight: 400;
      padding: 4px; }
    .page-wrap .nested-layout.reset-password-layout .navbar {
      height: fit-content; }
    .page-wrap .nested-layout.reset-password-layout header {
      font-size: 16px; }
    .page-wrap .nested-layout.reset-password-layout .layout-btn.back-btn {
      left: 0;
      top: -25px !important; }
    .page-wrap .nested-layout.reset-password-layout .layout-btn.light-close-btn {
      right: 20px;
      width: 30px;
      top: -10px; }
    .page-wrap .nested-layout.reset-password-layout .page-title {
      height: fit-content;
      font-size: 16px !important;
      font-weight: 500 !important;
      padding-top: 13px !important;
      width: 100vw;
      text-align: center;
      padding-left: 0;
      padding-right: 20px; }
      .page-wrap .nested-layout.reset-password-layout .page-title.reset-passowrd-page-title {
        padding-top: 0 !important; }
    .page-wrap main {
      padding: 35px 15px !important;
      padding-bottom: 0 !important; }
      .page-wrap main .search-form {
        display: none; }
      .page-wrap main .sub-header {
        font-size: 22px;
        font-weight: 400; }
      .page-wrap main .profile-link {
        margin-bottom: 10px; }
      .page-wrap main .profile-link .profile-info p.header {
        font-size: 16px;
        font-weight: 600; }
      .page-wrap main .my-bookings-table {
        margin-top: -20px; }
        .page-wrap main .my-bookings-table .title-cat {
          font-size: 16px;
          font-weight: 600; } }
      @media (max-width: 768px) and (max-width: 992px) {
        .page-wrap main .my-bookings-table {
          font-size: 15px; } }

@media (max-width: 768px) {
        .page-wrap main .my-bookings-table .sub-cat {
          font-size: 15px !important;
          font-weight: 400 !important; }
        .page-wrap main .my-bookings-table .badge {
          font-size: 14px !important;
          font-weight: 400 !important; }
        .page-wrap main .my-bookings-table .table-responsive .data-table tr td:first-child {
          padding-right: 0 !important; }
        .page-wrap main .my-bookings-table .table-responsive .data-table tr td:last-child {
          padding-left: 0 !important; }
      .page-wrap main .team-bookings-table {
        max-width: 1000px !important; }
      .page-wrap main .small-invoices-table {
        margin-top: -20px; }
        .page-wrap main .small-invoices-table .title-cat {
          font-size: 15px;
          font-weight: 600; } }
      @media (max-width: 768px) and (max-width: 992px) {
        .page-wrap main .small-invoices-table {
          font-size: 15px; } }

@media (max-width: 768px) {
        .page-wrap main .small-invoices-table .sub-cat {
          font-size: 15px !important;
          font-weight: 400 !important; }
      .page-wrap main .data-table thead {
        display: none; }
      .page-wrap main .data-table td p.title-cat {
        font-size: 16px; } }
    @media (max-width: 768px) and (max-width: 992px) {
      .page-wrap main .data-table td p.title-cat {
        font-size: 15px; } }

@media (max-width: 768px) {
      .page-wrap main .data-table td p.sub-cat {
        font-size: 15px;
        color: #8b959e; }
      .page-wrap main .data-table td.w-1 {
        width: 10px; }
      .page-wrap main .data-table td .btn-anchor {
        vertical-align: bottom; }
      .page-wrap main .data-table td .badge {
        font-size: 15px; }
      .page-wrap main .data-table td .users-displayname,
      .page-wrap main .data-table td .users-email {
        font-size: 16px;
        font-weight: 400; }
      .page-wrap main .data-table td .users-role {
        font-size: 16px;
        font-weight: 400; }
      .page-wrap main .data-table td .dropdown-item {
        font-size: 16px !important;
        font-weight: 400; }
      .page-wrap main .float-btn-wrap {
        width: 42px;
        height: 42px;
        bottom: 12px;
        right: 24px; }
        .page-wrap main .float-btn-wrap .chat-btn {
          width: 42px;
          height: 42px; }
        .page-wrap main .float-btn-wrap .unread-indicator {
          top: 5px;
          right: 2px; }
      .page-wrap main .booking-form-wrap .booking-empty-search {
        margin-top: 48px; }
      .page-wrap main .booking-form-wrap .booking-step-text {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 30px;
        margin-top: 20px; }
      .page-wrap main .booking-form-wrap .search-fixedheader-wrapper {
        display: flex;
        padding-top: 4px;
        margin-left: -15px;
        margin-bottom: 35px;
        height: 120px;
        width: 100vw; }
      .page-wrap main .booking-form-wrap .search-fixedheader-back {
        position: absolute;
        left: 0;
        top: 51px; }
        .page-wrap main .booking-form-wrap .search-fixedheader-back img {
          width: 40px;
          height: 40px;
          cursor: pointer; }
      .page-wrap main .booking-form-wrap .search-fixedheader-text {
        position: absolute;
        left: -40px;
        top: 100px;
        font-size: 22px;
        font-weight: 400;
        padding-left: 55px; }
      .page-wrap main .booking-form-wrap .sub-header {
        font-size: 22px;
        font-weight: 400; }
      .page-wrap main .booking-preview-info .booking-preview-info-section {
        padding: 24px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .section-header {
          font-size: 22px;
          font-weight: 400; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .section-sub-header {
          font-size: 20px;
          font-weight: 400; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .cancel-booking {
          color: #d0021b; }
          .page-wrap main .booking-preview-info .booking-preview-info-section .cancel-booking:hover {
            text-decoration: none;
            color: #d0021b; }
        .page-wrap main .booking-preview-info .booking-preview-info-section label {
          font-size: 12px;
          font-weight: 500; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .coverage-checkbox label {
          font-size: 16px !important; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .form-group p {
          font-size: 16px;
          font-weight: 400; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .badge {
          font-size: 14px;
          font-weight: 400;
          padding-top: 6px !important; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .inline-edit-btn {
          font-size: 16px;
          font-weight: 600; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .payment-select-label .displayName {
          display: inline-table;
          line-height: 1.5; }
          .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .payment-select-label .displayName .fontItalic {
            font-style: italic;
            font-size: 12px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .payment-select-label span {
          display: block;
          margin-left: 10px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .payment-link-label .displayName {
          display: inline-table;
          line-height: 1.5;
          margin-top: 0.125rem; }
          .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .payment-link-label .displayName .fontItalic {
            font-style: italic;
            font-size: 12px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .payment-link-label span {
          display: block;
          margin-left: 10px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .montly-billing-label .displayName {
          display: inline-table;
          line-height: 1.5;
          margin-top: 0.125rem; }
          .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .montly-billing-label .displayName .fontItalic {
            font-style: italic;
            font-size: 12px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .montly-billing-label span {
          display: block;
          margin-left: 10px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .booking-total.justify-content-end {
          justify-content: normal !important; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .booking-total.justify-content-center {
          justify-content: center; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .success-payment-block .success-payment-msg {
          text-align: left; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .payment-section .success-payment-block .danger-payment-msg {
          text-align: left; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .chat-list-container .ScrollbarsCustom .ScrollbarsCustom-Track {
          height: calc(100vh - 230px) !important; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .benefits-body {
          font-size: 15px;
          padding-top: 0.5%;
          padding-left: 1%; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .benefits-body-exclusive {
          font-size: 16px !important;
          color: #303030;
          padding-top: 0% !important; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .meet-and-assist-features {
          padding-left: 5px;
          padding-top: 2.5em;
          padding-bottom: 2em; }
          .page-wrap main .booking-preview-info .booking-preview-info-section .meet-and-assist-features div {
            background: url("/images/Tick_Icon.png");
            font-size: 16px;
            background-position: top 3px left 0px;
            background-size: 16px 16px;
            background-repeat: no-repeat;
            padding-left: 28px;
            margin-bottom: 4px; }
        .page-wrap main .booking-preview-info .booking-preview-info-section .meet-and-assist-description {
          font-size: 16px;
          color: #303030;
          padding-left: 5px;
          display: block;
          line-height: 27px !important; }
      .page-wrap main .booking-preview-info .booking-event-section .message-block .message-header p.date-row {
        font-size: 12px;
        font-weight: 500; }
      .page-wrap main .booking-preview-info .booking-event-section .message-block .message-content p {
        font-size: 16px;
        font-weight: 400; }
      .page-wrap main .booking-preview-info .booking-event-section .input-message-block .input-message-area .input-message-wrap textarea {
        box-shadow: unset;
        font-size: 16px !important;
        font-weight: 400 !important; }
      .page-wrap main .booking-preview-info .booking-event-section .events-div {
        margin-left: 10px;
        padding: 8px; }
    .page-wrap .success-msg-modal img {
      width: 40px;
      height: 40px;
      margin-bottom: 15px; }
    .page-wrap .success-msg-modal .success-msg-header {
      font-size: 22px;
      font-weight: 400; }
    .page-wrap .success-msg-modal .success-msg-description {
      font-size: 16px;
      font-weight: 400; }
    .page-wrap .success-msg-modal .btn {
      font-size: 16px !important;
      font-weight: 600; }
    .page-wrap .success-msg-verification img {
      width: 40px;
      height: 40px;
      margin-bottom: 15px; }
    .page-wrap .success-msg-verification .success-msg-header {
      font-size: 22px;
      font-weight: 400; }
    .page-wrap .success-msg-verification .success-msg-description {
      font-size: 16px;
      font-weight: 400; }
    .page-wrap .success-msg-verification .btn {
      font-size: 16px !important;
      font-weight: 600; }
    .page-wrap .success-msg-quote {
      color: #ffffff;
      z-index: 10;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      top: 0;
      left: 0;
      margin-top: 15%;
      background: #ffffff; }
      .page-wrap .success-msg-quote img {
        display: block;
        width: 150px;
        height: 110px;
        object-fit: cover;
        margin: 0 auto 15px auto;
        margin-bottom: 0; }
      .page-wrap .success-msg-quote .success-msg-header {
        font-size: 20px;
        color: #0c0c0c;
        text-align: center;
        font-weight: 400; }
      .page-wrap .success-msg-quote .btn {
        text-align: center;
        font-weight: 500;
        font-size: 15px; }
      .page-wrap .success-msg-quote .content-wrap {
        display: flex;
        max-width: 500px;
        flex-direction: column;
        justify-content: center; }
  .page-with-bottom-menu .scrollable-page-content {
    height: calc(100vh - 80px) !important; }
  body {
    position: relative; }
  .page-wrap {
    left: 0; }
    .page-wrap .navbar-without-stepper {
      height: 60px; }
    .page-wrap .client-chat-layout.show-header-line .navbar .navbar-brand header,
    .page-wrap .notifications-loyout.show-header-line .navbar .navbar-brand header {
      margin-top: 90px; }
    .page-wrap .navbar {
      border-bottom: 0; }
      .page-wrap .navbar.sticky-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff; }
      .page-wrap .navbar.show-border {
        border-bottom: 1px solid #8b959e4d; }
      .page-wrap .navbar .navbar-brand header {
        margin-top: 130px;
        padding: 0 16px;
        background-color: #ffffff !important; }
        .page-wrap .navbar .navbar-brand header .light-close-btn {
          right: 32px;
          top: calc(50% - 32px); }
          .page-wrap .navbar .navbar-brand header .light-close-btn img {
            width: 36px !important; }
        .page-wrap .navbar .navbar-brand header .back-btn {
          left: 6%; }
          .page-wrap .navbar .navbar-brand header .back-btn img {
            width: 40px; }
        .page-wrap .navbar .navbar-brand header .step-back-btn {
          left: 0px;
          top: -77px; }
        .page-wrap .navbar .navbar-brand header .company-sm-logo {
          margin-right: 20px;
          font-size: 1rem;
          margin-top: 8px;
          margin-left: 5%; }
        .page-wrap .navbar .navbar-brand header .company-logo {
          margin-left: 20px;
          margin-right: 20px; }
          .page-wrap .navbar .navbar-brand header .company-logo img {
            width: 45px; }
        .page-wrap .navbar .navbar-brand header .stepper .indicator .info {
          font-size: 12px; }
        .page-wrap .navbar .navbar-brand header .edit-client-page-title {
          height: 2rem;
          font-size: 1.75rem !important;
          font-weight: 400;
          margin-bottom: 0.75rem; }
        .page-wrap .navbar .navbar-brand header .page-title {
          height: 70px;
          font-size: 28px;
          font-weight: 400;
          display: table-cell;
          vertical-align: top; }
          .page-wrap .navbar .navbar-brand header .page-title.stepper-label {
            font-size: 22px; }
          .page-wrap .navbar .navbar-brand header .page-title p {
            margin-bottom: 0; }
          .page-wrap .navbar .navbar-brand header .page-title .sub-link {
            font-size: 15px;
            font-weight: 600;
            color: #a79779;
            display: block;
            padding-top: 5px; }
        .page-wrap .navbar .navbar-brand header .page-title-admin {
          height: 70px;
          font-size: 28px;
          font-weight: 400;
          padding-top: 10px;
          display: table-cell;
          vertical-align: top; }
          .page-wrap .navbar .navbar-brand header .page-title-admin.stepper-label {
            font-size: 22px; }
          .page-wrap .navbar .navbar-brand header .page-title-admin p {
            margin-bottom: 0; }
          .page-wrap .navbar .navbar-brand header .page-title-admin .sub-link {
            font-size: 15px;
            font-weight: 600;
            color: #a79779;
            display: block;
            padding-top: 5px; }
        .page-wrap .navbar .navbar-brand header .page-title-booking {
          height: 70px;
          font-size: 28px;
          font-weight: 400;
          padding-top: 30px;
          display: table-cell;
          vertical-align: top; }
          .page-wrap .navbar .navbar-brand header .page-title-booking.stepper-label {
            font-size: 22px; }
          .page-wrap .navbar .navbar-brand header .page-title-booking p {
            margin-bottom: 0; }
          .page-wrap .navbar .navbar-brand header .page-title-booking .sub-link {
            font-size: 15px;
            font-weight: 600;
            color: #a79779;
            display: block;
            padding-top: 5px; }
        .page-wrap .navbar .navbar-brand header .stepper {
          width: 90%;
          margin-left: 0; }
          .page-wrap .navbar .navbar-brand header .stepper .page-title {
            height: 36px; }
        .page-wrap .navbar .navbar-brand header .badge {
          position: absolute;
          top: 16px;
          right: 40px; }
      .page-wrap .navbar .navbar-brand .settings-logout-img-wrapper {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: -45px; }
        .page-wrap .navbar .navbar-brand .settings-logout-img-wrapper img {
          width: 25px;
          height: 25px; }
    .page-wrap .navbar-partner {
      border-bottom: 0;
      height: 80px !important; }
      .page-wrap .navbar-partner .navbar-brand header {
        margin-top: 130px;
        padding: 0 0 0 15px;
        background-color: #ffffff; }
        .page-wrap .navbar-partner .navbar-brand header .light-close-btn {
          right: 32px;
          top: calc(50% - 32px); }
          .page-wrap .navbar-partner .navbar-brand header .light-close-btn img {
            width: 36px !important; }
        .page-wrap .navbar-partner .navbar-brand header .back-btn {
          left: 6%; }
          .page-wrap .navbar-partner .navbar-brand header .back-btn img {
            width: 40px !important; }
        .page-wrap .navbar-partner .navbar-brand header .step-back-btn {
          left: 0px;
          top: -77px; }
        .page-wrap .navbar-partner .navbar-brand header .company-sm-logo {
          margin-right: 20px;
          font-size: 1rem;
          margin-top: 8px;
          margin-left: 5%; }
        .page-wrap .navbar-partner .navbar-brand header .company-logo {
          margin-left: 20px;
          margin-right: 20px; }
          .page-wrap .navbar-partner .navbar-brand header .company-logo img {
            width: 45px; }
        .page-wrap .navbar-partner .navbar-brand header .stepper .indicator .info {
          font-size: 12px; }
        .page-wrap .navbar-partner .navbar-brand header .page-title {
          height: 70px;
          font-size: 28px;
          font-weight: 400;
          padding-top: 2px;
          display: table-cell;
          vertical-align: top; }
          .page-wrap .navbar-partner .navbar-brand header .page-title.stepper-label {
            font-size: 22px; }
          .page-wrap .navbar-partner .navbar-brand header .page-title p {
            margin-bottom: 0; }
          .page-wrap .navbar-partner .navbar-brand header .page-title .sub-link {
            font-size: 15px;
            font-weight: 600;
            color: #a79779;
            display: block;
            padding-top: 5px; }
        .page-wrap .navbar-partner .navbar-brand header .page-title-admin {
          height: 70px;
          font-size: 28px;
          font-weight: 400;
          padding-top: 10px;
          display: table-cell;
          vertical-align: top; }
          .page-wrap .navbar-partner .navbar-brand header .page-title-admin.stepper-label {
            font-size: 22px; }
          .page-wrap .navbar-partner .navbar-brand header .page-title-admin p {
            margin-bottom: 0; }
          .page-wrap .navbar-partner .navbar-brand header .page-title-admin .sub-link {
            font-size: 15px;
            font-weight: 600;
            color: #a79779;
            display: block;
            padding-top: 5px; }
        .page-wrap .navbar-partner .navbar-brand header .page-title-booking {
          height: 70px;
          font-size: 28px;
          font-weight: 400;
          padding-top: 30px;
          display: table-cell;
          vertical-align: top; }
          .page-wrap .navbar-partner .navbar-brand header .page-title-booking.stepper-label {
            font-size: 22px; }
          .page-wrap .navbar-partner .navbar-brand header .page-title-booking p {
            margin-bottom: 0; }
          .page-wrap .navbar-partner .navbar-brand header .page-title-booking .sub-link {
            font-size: 15px;
            font-weight: 600;
            color: #a79779;
            display: block;
            padding-top: 5px; }
        .page-wrap .navbar-partner .navbar-brand header .stepper {
          width: 90%;
          margin-left: 0; }
          .page-wrap .navbar-partner .navbar-brand header .stepper .page-title {
            height: 36px; }
        .page-wrap .navbar-partner .navbar-brand header .badge {
          position: absolute;
          top: 16px;
          right: 40px; }
      .page-wrap .navbar-partner .navbar-brand .settings-logout-img-wrapper {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: -45px; }
        .page-wrap .navbar-partner .navbar-brand .settings-logout-img-wrapper img {
          width: 25px;
          height: 25px; }
    .page-wrap .sub-page-with-title .navbar-brand header .page-title {
      font-size: 22px;
      font-weight: 400; }
    .page-wrap .sub-page-with-title .sub-header {
      font-size: 22px;
      font-weight: 400; }
    .page-wrap .sub-page-with-title .company-logo-field .text-brown {
      font-size: 16px; }
    .page-wrap .profile-change-password-link {
      position: absolute;
      top: -37px;
      right: 0px;
      font-size: 15px;
      color: #a79779;
      text-decoration: underline; }
    .page-wrap .chatnotification-page-title {
      font-size: 16px !important;
      font-weight: 500 !important;
      text-align: center;
      width: 100vw;
      padding-block: 7px !important;
      height: fit-content !important; }
    .page-wrap .account-page-title {
      padding-inline: 16px !important;
      padding-left: 0 !important; }
    .page-wrap .chatnotification-page-title .back-btn {
      width: fit-content !important;
      height: fit-content !important; }
      .page-wrap .chatnotification-page-title .back-btn img.back-btn-img {
        width: fit-content !important; }
    .page-wrap .invoices-page-title {
      flex-direction: column !important;
      align-items: start !important;
      border-bottom: 0 !important;
      height: fit-content !important;
      padding-top: 15px !important; }
      .page-wrap .invoices-page-title .back-btn {
        width: fit-content !important;
        height: fit-content !important; }
        .page-wrap .invoices-page-title .back-btn img.back-btn-img {
          width: fit-content !important; }
      .page-wrap .invoices-page-title span {
        margin-top: 20px; }
    .page-wrap .show-header-line .navbar {
      height: 120px;
      margin-bottom: 35px;
      border-bottom: 1px solid rgba(139, 149, 158, 0.3); }
      .page-wrap .show-header-line .navbar .navbar-brand header {
        background-color: transparent !important; }
        .page-wrap .show-header-line .navbar .navbar-brand header .step-back-btn {
          left: 0px;
          top: -25px; }
      .page-wrap .show-header-line .navbar .mobile-header-title {
        position: absolute;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        left: 0;
        width: 100vw;
        pointer-events: none;
        padding-top: 11px; }
      .page-wrap .show-header-line .navbar .mobile-header-title-activity {
        position: absolute;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        left: 0;
        width: 100vw;
        pointer-events: none;
        padding-top: 11px; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-link {
        position: absolute;
        text-decoration: none;
        top: -1px;
        right: 0px; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-link-indecator img {
        margin-left: calc(50% - 10px); }
      .page-wrap .show-header-line .navbar .mobile-header-sub-link-indecator .supportbar-unread-indicator {
        top: -30px;
        right: -15px; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-linktext-with-indecator {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        color: #8b959e;
        margin-top: -15px; }
      .page-wrap .show-header-line .navbar .mobile-hader-img-wrapper {
        text-align: center;
        font-size: 20px; }
        .page-wrap .show-header-line .navbar .mobile-hader-img-wrapper img {
          width: 20px;
          height: 20px; }
      .page-wrap .show-header-line .navbar .mobile-hader-img-wrapper-share {
        text-align: center;
        font-size: 20px; }
        .page-wrap .show-header-line .navbar .mobile-hader-img-wrapper-share img {
          width: 25px; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-linktext {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        color: #8b959e; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-link-textonly {
        font-size: 12px;
        color: #d0021b;
        padding-top: 16px; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-link-textonly-golden {
        color: #a79779 !important;
        font-size: 14px !important; }
      .page-wrap .show-header-line .navbar .mobile-header-sub-link-textonly-black {
        color: #0c0c0c !important;
        font-size: 14px !important; }
      .page-wrap .show-header-line .navbar .sub-link {
        line-height: 40px; }
    .page-wrap .booking-detail-layout .navbar .navbar-brand header {
      margin-top: 94px; }
    .page-wrap .change-password-layout .navbar .navbar-brand header {
      margin-top: 94px; }
    .page-wrap .profile-layout .navbar {
      height: 150px; }
    .page-wrap .profile-layout .navbar .navbar-brand header {
      margin-top: 125px; }
    .page-wrap .new-booking-layout .step-back-btn {
      top: -10px !important; }
    .page-wrap .booking-detail-layout {
      overflow: auto !important; }
      .page-wrap .booking-detail-layout main {
        height: calc(100vh - 180px);
        margin-top: -20px;
        padding-top: 10px !important; }
      .page-wrap .booking-detail-layout .booking-preview-info {
        height: 100%; }
        .page-wrap .booking-detail-layout .booking-preview-info .booking-preview-row {
          height: 100%; }
        .page-wrap .booking-detail-layout .booking-preview-info .col-booking-detail {
          height: auto; }
        .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section {
          width: 100%;
          height: 100%;
          margin-left: 0;
          padding: 0;
          height: calc(100% - 1px); }
          .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .chat-list-container {
            max-height: 100%; }
            .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .chat-list-container .messages-list {
              height: 0%;
              padding-bottom: 20%; }
              .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .chat-list-container .messages-list .vehicle-card {
                width: auto !important; }
            .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .chat-list-container .message-block {
              width: 100%; }
              .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .chat-list-container .message-block .message-header {
                width: 100%; }
          .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .input-message-block {
            position: fixed;
            width: calc(100% - 30px);
            bottom: 10px;
            background: #fff; }
            .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .input-message-block .align-items-start {
              width: 100vw;
              border-top: 1px solid rgba(139, 149, 158, 0.3);
              margin-left: -30px;
              padding-left: 10px; }
            .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .input-message-block .empty-items-files {
              display: none !important;
              visibility: hidden; }
            .page-wrap .booking-detail-layout .booking-preview-info .booking-event-section .input-message-block .input-message-area {
              width: auto;
              max-width: none; }
    .page-wrap .no-title {
      display: block !important; }
      .page-wrap .no-title .no-title-header .no-title-back-btn-wrap {
        height: auto !important; }
        .page-wrap .no-title .no-title-header .no-title-back-btn-wrap a {
          position: absolute; }
    .page-wrap .form-block {
      padding-right: 0; }
      .page-wrap .form-block .form-row {
        width: auto;
        max-width: none;
        margin: 0; }
    .page-wrap .notifications-loyout main {
      padding: 10px 20px !important;
      margin-top: -20px; }
      .page-wrap .notifications-loyout main .btn-anchor {
        font-size: 16px;
        font-weight: 600; }
    .page-wrap .notifications-loyout .table-responsive {
      height: calc(100vh - 220px);
      overflow-x: hidden !important;
      overflow-y: scroll !important; }
    .page-wrap .notifications-loyout .sub-cat {
      margin-top: 5px;
      font-size: 14px !important;
      font-weight: 500; }
    .page-wrap .notifications-loyout .table-responsive::-webkit-scrollbar {
      width: 3px;
      height: 3px; }
    .page-wrap .notifications-loyout .table-responsive::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent; }
    .page-wrap .notifications-loyout .table-responsive::-webkit-scrollbar-thumb {
      background-color: rgba(120, 128, 134, 0.5) !important;
      border-radius: 10px; }
    .page-wrap .notifications-loyout .data-table td .btn-anchor {
      vertical-align: top !important; }
    .page-wrap .notifications-loyout .title-cat {
      line-height: 1.5; }
      .page-wrap .notifications-loyout .title-cat span {
        vertical-align: bottom; }
    .page-wrap .client-chat-layout main {
      padding: 0 20px !important;
      margin-top: -20px; }
      .page-wrap .client-chat-layout main .client-chat-container {
        display: block;
        padding: 0; }
        .page-wrap .client-chat-layout main .client-chat-container .client-chat-room {
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          height: 100%; }
          .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-messages-list {
            height: calc(100vh - 260px);
            padding: 0;
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column-reverse; }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-messages-list .message .text-wrap .text {
              font-size: 16px;
              font-weight: 500; }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-messages-list .ScrollbarsCustom-Track {
              width: 5px !important; }
              .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-messages-list .ScrollbarsCustom-Track .ScrollbarsCustom-Thumb {
                background-color: rgba(120, 128, 134, 0.25) !important; }
          .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block {
            position: fixed;
            height: inherit;
            bottom: 0;
            padding-bottom: 10px;
            left: 0;
            width: 100vw;
            border-top: unset;
            background: #fff; }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .message-input-toolbar {
              width: calc(100% - 60px);
              padding-left: 10px;
              display: flex; }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .textarea-chat-control {
              max-height: 110px;
              overflow: auto; }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .message-input-wrap {
              position: relative;
              flex-grow: 1;
              width: calc(100% - 55px); }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .chat-footer-container {
              position: unset !important;
              max-width: unset !important; }
            .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .collected-files {
              margin: 0 5px 5px 0;
              display: flex;
              flex-direction: column;
              align-items: stretch;
              overflow-y: auto;
              width: 100vw;
              padding-left: 20px; }
              .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .collected-files .file-preview {
                flex: 1 1 100%;
                justify-content: flex-start;
                display: flex;
                align-items: stretch;
                margin-right: 20px; }
                .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .collected-files .file-preview .image-preview-label {
                  max-width: 100%; }
                .page-wrap .client-chat-layout main .client-chat-container .client-chat-room .client-message-input-block .collected-files .file-preview .remove-file-btn {
                  margin-left: auto; }
    .page-wrap .company-logo-wrap {
      min-height: 100px; }
      .page-wrap .company-logo-wrap .company-logo-field {
        width: 200px; }
        .page-wrap .company-logo-wrap .company-logo-field .company-logo {
          width: 80px;
          height: 80px;
          float: left; }
        .page-wrap .company-logo-wrap .company-logo-field .text-brown {
          margin-top: 28px; }
  .partner-btn-desktop-tertiary {
    height: 50px !important;
    font-size: 16px !important; }
  .outsource-btn-partner {
    height: 35px !important;
    font-size: 16px !important; }
  .admin-chat-container .admin-chat-room .message-input-toolbar .send-btn-wrap .send-btn {
    margin-left: 10px; }
  .navbar .navbar-collapse.collapse,
  .navbar .navbar-collapse.collapse.in,
  .navbar .navbar-collapse.collapsing {
    display: none !important; }
  .badge.badge-outlined.badge-important {
    color: #d0021b;
    background-color: rgba(208, 2, 27, 0.1);
    border-radius: 10px;
    font-size: 14.5px !important;
    font-weight: bold;
    text-shadow: none !important;
    letter-spacing: normal !important; }
  .mismatch-text {
    color: #d0021b;
    font-size: 15px;
    float: none; }
  .sidebar-container {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: 0;
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    border-top: none;
    text-align: left;
    padding: 0;
    -webkit-transition: width 0.28s;
    transition: width 0.28s;
    -webkit-transform: translate3d(260px, 0, 0);
    -moz-transform: translate3d(260px, 0, 0);
    -o-transform: translate3d(260px, 0, 0);
    -ms-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    .sidebar-container > ul {
      position: relative;
      z-index: 4;
      height: calc(100vh - 61px);
      width: 100%; }
    .sidebar-container .sidebar-nav {
      margin-top: 40%; }
  .partner-accepted-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: calc(100vh - 2rem);
    justify-content: center; }
    .partner-accepted-modal p {
      margin-top: 1rem; }
  .badge.badge-new-booking {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .badge.badge-accepted-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .badge.badge-completed-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .badge.badge-cancelled-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .badge.badge-expired-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .badge.badge-change-request-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .badge.badge-completed-dispatch {
    margin: 0 !important;
    margin-bottom: 5px !important;
    padding: 7px 5px 5px 6px !important;
    font-size: 14px; }
  .benefits-member-message {
    background-color: #ffffff;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 87.5%;
    border-radius: 2px;
    border: 1px solid rgba(139, 149, 158, 0.1);
    border-top-style: solid !important;
    border-right-style: solid !important;
    border-bottom-style: solid !important;
    border-left-style: solid !important;
    border-top-width: 0.5px !important;
    border-right-width: 0.5px !important;
    border-bottom-width: 0.5px !important;
    border-left-width: 0.5px !important;
    border-image-width: initial !important;
    border-image-source: initial !important;
    border-image-repeat: initial !important;
    border-image-outset: initial !important;
    border-image-slice: initial !important; }
    .benefits-member-message .benefits-header {
      font-size: 14px !important;
      font-weight: 600 !important;
      word-wrap: break-word; }
    .benefits-member-message .benefits-body {
      font-size: 12px !important; }
  .flightTracking-info {
    padding-left: 1.1rem;
    padding-right: 0.1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border: none; }
    .flightTracking-info .flightTitle {
      font-size: 18px;
      text-align: left;
      font-weight: lighter;
      color: #0c0c0c; }
    .flightTracking-info .flightSubTitle {
      font-size: 14px !important;
      text-align: left;
      color: #303030; }
    .flightTracking-info .flightStatus-delayed {
      font-size: 11px !important;
      font-weight: 600 !important;
      display: block;
      box-sizing: content-box;
      border-radius: 2px;
      max-width: fit-content;
      color: white;
      background-color: #d0021b;
      padding: 0.3rem 0.3rem 0.1rem 0.3rem;
      margin-bottom: 1.3rem;
      margin-top: 0.3rem !important;
      text-transform: uppercase; }
    .flightTracking-info .flightStatus-onTime {
      font-size: 11px !important;
      font-weight: 600 !important;
      display: block;
      box-sizing: content-box;
      border-radius: 2px;
      max-width: fit-content;
      color: white;
      background-color: #417505;
      padding: 0.25rem 0.3rem 0.1rem 0.3rem;
      margin-bottom: 1.3rem;
      margin-top: 0.3rem !important; }
    .flightTracking-info .flight-iata-departure {
      max-width: 10%;
      font-size: 20px !important;
      font-weight: lighter;
      color: #0c0c0c;
      margin-right: 1.75rem !important;
      margin-top: 0.2rem !important; }
    .flightTracking-info .flight-iata-arrival {
      max-width: 10%;
      font-size: 20px !important;
      font-weight: lighter;
      color: #0c0c0c;
      margin-left: 1rem !important;
      margin-top: 0.2rem !important; }
    .flightTracking-info .flight-time-green {
      font-size: 18px !important;
      font-weight: lighter !important;
      color: #417505;
      padding-top: 0.3rem; }
    .flightTracking-info .flight-time-red {
      font-size: 18px !important;
      font-weight: lighter !important;
      color: #d0021b;
      padding-top: 0.3rem; }
    .flightTracking-info .flight-inner-title {
      line-height: 12px;
      font-size: 10px !important;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #8b959e !important;
      text-transform: uppercase;
      display: block;
      margin-top: 0.3rem; }
    .flightTracking-info .flight-arrival-section {
      padding-left: 2rem !important; }
    .flightTracking-info .flight-section-border {
      border-right: 1px solid rgba(139, 149, 158, 0.25); }
    .flightTracking-info .flight-align-row {
      padding-left: 0.1rem; }
    .flightTracking-info .flight-gate {
      padding-left: 2rem !important; }
    .flightTracking-info .terminal-gate-title {
      line-height: 12px;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #8b959e;
      text-transform: uppercase;
      display: block; }
    .flightTracking-info .terminal-gate-num {
      font-size: 18px !important;
      padding-top: 0.3rem; }
    .flightTracking-info .scheduled-time {
      font-size: 12px !important;
      text-align: left;
      color: #303030; }
    .flightTracking-info .progressBar {
      width: 100%;
      height: 0.75em;
      border: 0.125em solid #373737;
      background: #323232;
      overflow: hidden; }
    .flightTracking-info .iata-width {
      max-width: 10% !important; }
    .flightTracking-info .myProgress {
      position: relative;
      width: 100%;
      height: 1px;
      background-color: #ddd;
      border-radius: 3px;
      background-color: #8b959e; }
    .flightTracking-info .myBar-red {
      position: absolute;
      width: 1%;
      height: 100%;
      border-radius: 2px;
      background-color: #d0021b;
      transition: width 2s; }
    .flightTracking-info .myBar-green {
      position: absolute;
      width: 1%;
      height: 100%;
      border-radius: 2px;
      background-color: #417505;
      transition: width 2s; }
    .flightTracking-info .progress-dot-grey {
      height: 8px;
      width: 8px;
      background-color: #8b959e;
      border-radius: 50%;
      display: inline-block; }
    .flightTracking-info .progress-dot-red {
      height: 8px;
      width: 8px;
      background-color: #d0021b;
      border-radius: 50%;
      display: inline-block; }
    .flightTracking-info .progress-dot-green {
      height: 8px;
      width: 8px;
      background-color: #417505;
      border-radius: 50%;
      display: inline-block; }
    .flightTracking-info .plane-position {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
    .flightTracking-info .plane-size {
      max-width: 20px !important;
      max-height: 20px !important;
      border-left: 1rem;
      border-color: white;
      border-width: 1rem; }
    .flightTracking-info .invalid-flight {
      font-size: 18px;
      text-align: left;
      font-weight: lighter;
      color: #0c0c0c; }
    .flightTracking-info .dot-size {
      font-size: 7px; }
    .flightTracking-info .progress-time {
      font-size: 12px !important;
      color: #8b959e !important; }
    .flightTracking-info .time-bar {
      flex-wrap: wrap;
      padding-bottom: 1rem; }
  .partner-header-home {
    margin: 0 8.5% !important;
    margin-top: 40px !important;
    justify-content: space-between;
    width: 100vw;
    padding: 0 !important; }
  .switch-client-banner {
    display: block !important;
    background-color: rgba(167, 151, 121, 0.05);
    border: 1px solid #a79779;
    border-radius: 2px;
    margin: 5.1%;
    padding: 20px;
    align-items: center; }
    .switch-client-banner .close-div {
      display: flex;
      justify-content: end; }
    .switch-client-banner p {
      margin-bottom: 1rem;
      color: #636d77; }
    .switch-client-banner .close-icon {
      width: 10px;
      height: 10px; }
  .meet-and-assist-container {
    border-radius: 2px;
    border: 1.5px solid rgba(139, 149, 158, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 1.5em 1.5em;
    margin-top: 1em; }
    .meet-and-assist-container .meet-and-assist-inner {
      border-radius: 2px;
      border: 1.5px solid rgba(139, 149, 158, 0.1);
      border-color: #a79779;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      padding: 11px 20px;
      margin-top: 1em; }
      .meet-and-assist-container .meet-and-assist-inner .inner-title {
        font-size: 14px;
        color: #303030;
        width: 80%;
        padding: 0;
        float: left; }
      .meet-and-assist-container .meet-and-assist-inner .price {
        font-size: 14px;
        color: #303030;
        font-weight: normal;
        display: block;
        text-align: right; }
      .meet-and-assist-container .meet-and-assist-inner .inner-description {
        font-size: 11px;
        color: #303030;
        display: block;
        width: 116px; }
      .meet-and-assist-container .meet-and-assist-inner .meet-and-assist-payment {
        padding-top: 20px; }
      .meet-and-assist-container .meet-and-assist-inner .custom-radio {
        margin-bottom: 10px; }
        .meet-and-assist-container .meet-and-assist-inner .custom-radio .custom-control-label:after {
          background-image: url("/images/icons/radio-golden.svg"); }
        .meet-and-assist-container .meet-and-assist-inner .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
          background-image: url("/images/icons/radio-checked.svg"); }
  .partner-tabs {
    display: flex;
    align-items: baseline;
    justify-content: right;
    margin: 5%;
    margin-top: 0; }
  .partner-tab {
    font-size: 12px;
    padding: 5px;
    width: 125px;
    text-align: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .info-icon-multi-stop {
    left: 7.1em !important; }
  .tab-active {
    background-color: black;
    color: white;
    border: 1px solid black; }
  .tab-inactive {
    background-color: white;
    color: #8b959e;
    border: 1px solid rgba(139, 149, 158, 0.5); }
  .nav-open .sidebar-container {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); }
  .nav-open .navbar .container {
    left: -260px; }
  .nav-open .page-wrap {
    left: 0;
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform: translate3d(-260px, 0, 0);
    -o-transform: translate3d(-260px, 0, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0); }
  .navbar-toggler .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }
  .navbar-toggler {
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px; }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent; }
  .bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .bar2 {
    opacity: 1; }
  .bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar1 {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar2 {
    opacity: 0; }
  .toggled .bar3 {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg); }
    45% {
      top: 6px;
      transform: rotate(145deg); }
    75% {
      transform: rotate(130deg); }
    100% {
      transform: rotate(135deg); } }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg); }
    75% {
      -webkit-transform: rotate(130deg); }
    100% {
      -webkit-transform: rotate(135deg); } }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg); }
    75% {
      -moz-transform: rotate(130deg); }
    100% {
      -moz-transform: rotate(135deg); } }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg); }
    45% {
      transform: rotate(-10deg); }
    75% {
      transform: rotate(5deg); }
    100% {
      top: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg); }
    45% {
      -webkit-transform: rotate(-10deg); }
    75% {
      -webkit-transform: rotate(5deg); }
    100% {
      top: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg); }
    45% {
      -moz-transform: rotate(-10deg); }
    75% {
      -moz-transform: rotate(5deg); }
    100% {
      top: 0px;
      -moz-transform: rotate(0); } }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg); }
    45% {
      bottom: 6px;
      transform: rotate(-145deg); }
    75% {
      transform: rotate(-130deg); }
    100% {
      transform: rotate(-135deg); } }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg); }
    75% {
      -webkit-transform: rotate(-130deg); }
    100% {
      -webkit-transform: rotate(-135deg); } }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg); }
    75% {
      -moz-transform: rotate(-130deg); }
    100% {
      -moz-transform: rotate(-135deg); } }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg); }
    45% {
      transform: rotate(10deg); }
    75% {
      transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg); }
    45% {
      -webkit-transform: rotate(10deg); }
    75% {
      -webkit-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg); }
    45% {
      -moz-transform: rotate(10deg); }
    75% {
      -moz-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0); } }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 250px;
    content: '';
    z-index: 9999;
    overflow-x: hidden; }
  .form-control + .form-control-feedback {
    margin-top: -8px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    background-color: transparent !important; }
  .navbar-collapse.collapse {
    height: 100% !important; }
  .navbar-collapse.collapse.in {
    display: block; }
  .navbar-expand {
    justify-content: space-between; }
  .collapse,
  .navbar-toggler {
    display: block !important;
    border: none;
    float: right;
    padding-right: 11%; }
    .collapse:focus,
    .navbar-toggler:focus {
      outline: none; }
  .navbar-header {
    float: none; }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .app-content .page-wrap header .add-btn {
    width: 45px;
    height: 45px;
    top: 0; }
  .admin-booking-activity {
    position: absolute;
    top: 9em;
    left: calc(100vw - 4em);
    text-align: center; }
    .admin-booking-activity .admin-unread-indicator {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: red;
      color: red;
      font-size: 6px;
      font-weight: 600;
      text-align: center;
      top: -23px;
      right: -25px; }
  .cancel-button-confirmation {
    margin-left: 0px !important;
    margin-right: 4px !important;
    margin-bottom: 6px !important; }
  .follow-button-confirmation {
    margin-left: 4px !important;
    margin-right: 4px !important;
    margin-bottom: 6px !important; }
  .delete-button-confirmation {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 6px !important; }
  .admin-chat-container {
    padding: 0;
    overflow-x: hidden; }
    .admin-chat-container .admin-chat-room {
      flex-direction: column;
      overflow: hidden;
      width: calc(100% - 40px); }
      .admin-chat-container .admin-chat-room .room-content {
        margin-top: 20%; }
      .admin-chat-container .admin-chat-room .admin-message-input-block {
        width: calc(115vw - 20px);
        left: 0;
        padding: 0px 15px 0 3px;
        border-top: unset;
        bottom: 0px;
        position: fixed; }
        .admin-chat-container .admin-chat-room .admin-message-input-block .message-input-wrap {
          position: relative;
          flex-grow: 1;
          width: calc(100% - 55px); }
          .admin-chat-container .admin-chat-room .admin-message-input-block .message-input-wrap div div {
            max-height: 120px; }
            .admin-chat-container .admin-chat-room .admin-message-input-block .message-input-wrap div div textarea {
              overflow-y: auto !important; }
      .admin-chat-container .admin-chat-room .message-input-toolbar {
        padding-bottom: 10px; }
      .admin-chat-container .admin-chat-room .room-header {
        width: calc(100vw - 10px);
        display: block;
        padding: 0;
        position: fixed;
        z-index: 1;
        background-color: #fff;
        border-bottom: 1px solid rgba(12, 12, 12, 0.1); }
        .admin-chat-container .admin-chat-room .room-header .align-items-center {
          justify-content: space-between;
          width: calc(100vw - 10px);
          height: 93%; }
        .admin-chat-container .admin-chat-room .room-header .userLabel-plain {
          font-size: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px; }
        .admin-chat-container .admin-chat-room .room-header .userLabel-border-right {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          font-size: 16px;
          text-align: center;
          margin-right: 0;
          margin-top: 1em; }
        .admin-chat-container .admin-chat-room .room-header .text-brown {
          font-size: 13px;
          display: flex;
          margin-right: 0;
          text-align: center; }
        .admin-chat-container .admin-chat-room .room-header .room-title {
          display: flex;
          align-items: center; }
      .admin-chat-container .admin-chat-room .admin-messages-list {
        height: auto;
        max-height: calc(100vh - 13rem) !important;
        margin-top: 5rem;
        overflow-x: hidden;
        padding: 20px 10px 80px 0; }
    .admin-chat-container .chats-list-wrap .chats-list {
      width: calc(100% - 1.4em) !important; }
    .admin-chat-container .chats-list-wrap .spinner_wrapper {
      left: 45%; }
    .admin-chat-container .chats-list-wrap .list-panel {
      width: 90%; }
      .admin-chat-container .chats-list-wrap .list-panel .search-field-wrap .form-group .form-control {
        margin-left: 4%; }
  .chatScrollDown {
    font-size: 12px;
    font-weight: 700;
    margin-left: 25%;
    height: 30px !important;
    border: 1px solid #d0021b;
    z-index: 99999;
    position: relative;
    top: 0px;
    color: #d0021b;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: #ffffff; }
    .chatScrollDown:hover {
      color: #d0021b !important; }
  .chatScrollDownFiles {
    font-size: 12px;
    font-weight: 700;
    margin-left: 25%;
    margin-top: 5px;
    height: 30px !important;
    border: 1px solid #d0021b;
    z-index: 99999;
    position: relative;
    top: 0px;
    color: #d0021b;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: #ffffff; }
    .chatScrollDownFiles:hover {
      color: #d0021b !important; }
  .apiBadgeBookingMobile {
    color: #ffffff;
    background: #0c0c0c;
    border-radius: 10px;
    max-width: 40px;
    min-width: 40px;
    text-align: center;
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    font-weight: bold;
    height: 16px;
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    padding-top: 2px;
    justify-content: center; } }

.apply-button-mobile {
  display: flex;
  justify-content: flex-end; }

@media (max-width: 768px) {
  .notification-btn {
    display: none !important; }
  .table-responsive {
    min-height: 155px;
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; }
  .auth-page .image-container {
    width: 0%; }
  .auth-page .image-container-new {
    width: 0%; }
  .auth-page .scrollable-page-content {
    height: 100vh !important;
    width: 100%; }
  .auth-page .auth-container {
    width: 100%; }
    .auth-page .auth-container .auth-wrap {
      min-width: 160px;
      max-width: unset;
      padding: 0 30px;
      margin-top: 150px;
      height: 100vh;
      display: table-cell;
      vertical-align: middle;
      width: 100vw; }
      .auth-page .auth-container .auth-wrap .rolzo-login-footer {
        height: 70px;
        overflow: visible; }
      .auth-page .auth-container .auth-wrap .rolzo-header:first-child {
        margin-top: 30px;
        text-align: left !important; }
      .auth-page .auth-container .auth-wrap .rolzo-header .height150 {
        opacity: 0;
        display: none; }
      .auth-page .auth-container .auth-wrap .rolzo-header h3 {
        font-size: 28px;
        font-weight: 400; }
      .auth-page .auth-container .auth-wrap .rolzo-header .text-dark {
        font-size: 16px;
        font-weight: 400; }
      .auth-page .auth-container .auth-wrap .rolzo-header .btn.btn-anchor {
        font-size: 16px;
        font-weight: 600;
        vertical-align: text-bottom; }
      .auth-page .auth-container .auth-wrap .btn.login-btn {
        padding-left: 0;
        padding-right: 0; }
  .auth-page .auth-container-100 .client-register-continue {
    width: 100%;
    max-width: 100%;
    margin-left: 0; }
  .auth-page .auth-container-100 .signup-back-btn {
    position: absolute;
    left: 20px;
    top: 20px; }
    .auth-page .auth-container-100 .signup-back-btn img {
      width: 40px; }
  .auth-page .auth-container-100 .form-block:first-child {
    padding-top: 10px; }
  .auth-page .auth-container-100 .form-block {
    margin: 0 20px; }
  .auth-page .page-title {
    background-color: #ffffff !important; }
    .auth-page .page-title h1 {
      font-size: 1.2rem !important;
      padding: 70px 50px 0px 20px !important;
      margin-bottom: 0px; }
  .hide-bottom-button {
    display: none; }
  .continue-right {
    float: right; }
  .light-tabs-content .booking-col {
    max-width: 100%; }
  .booking-col {
    max-width: 100%; }
    .booking-col:not(:first-child) {
      margin-left: 0; }
    .booking-col .booking-card {
      padding: 15px 0 20px 10px;
      border: none;
      border: 1px solid rgba(139, 149, 158, 0.25);
      border-radius: 2px;
      width: 100%; }
      .booking-col .booking-card .card-block {
        width: 95%; }
      .booking-col .booking-card .card-body {
        padding: 0 10px 0px; }
        .booking-col .booking-card .card-body .card-logo {
          display: block;
          width: 23px;
          margin-right: 12px; }
          .booking-col .booking-card .card-body .card-logo.booking_car {
            width: 32px; }
          .booking-col .booking-card .card-body .card-logo.booking_hourly {
            width: 22px; }
        .booking-col .booking-card .card-body .booking-title {
          margin-bottom: 10px !important;
          font-size: 16px;
          font-weight: 600; }
        .booking-col .booking-card .card-body .description {
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 400; }
      .booking-col .booking-card .booking-img-wrap {
        display: none !important; }
      .booking-col .booking-card:hover {
        box-shadow: none; }
    .booking-col .mobile-arrow {
      display: none; }
  .client-info,
  .business_logo {
    display: none; }
  .keyboard-open .sidebar-bottom-container {
    display: none; }
  .keyboard-open .client-chat-layout .client-messages-list {
    height: calc(100vh - 180px) !important; }
  .keyboard-open .client-chat-layout .client-message-input-block {
    bottom: 5px !important;
    padding-bottom: 5px !important; }
  .keyboard-open .book-continue-btn {
    display: none; }
  .remove-button-stops {
    height: 25px !important;
    width: 25px !important;
    left: 1px !important; }
  .sidebar-bottom-container-partner {
    height: 65px !important;
    display: flex;
    justify-content: space-around !important; }
  .sidebar-bottom-container {
    z-index: 100;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 80px;
    border-top: 1px solid rgba(139, 149, 158, 0.3);
    background: white;
    width: 100%; }
    .sidebar-bottom-container .bottom-nav-link {
      text-align: center;
      color: #8b959e;
      font-size: 10px;
      font-weight: 600;
      padding: 12px 8px 16px 8px !important;
      cursor: pointer; }
      .sidebar-bottom-container .bottom-nav-link img {
        margin: auto !important; }
      .sidebar-bottom-container .bottom-nav-link .selected-nav {
        color: black; }
    .sidebar-bottom-container .bottom-nav-img-wrapper {
      height: 25px;
      margin-top: 2px;
      width: 30px;
      margin-left: auto;
      margin-right: auto; }
      .sidebar-bottom-container .bottom-nav-img-wrapper .bottom-nav-link-img {
        height: 20px; }
    .sidebar-bottom-container .bottom-menu-unread-indicator {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: red;
      color: red;
      font-size: 6px;
      font-weight: 600;
      text-align: center;
      top: -23px;
      left: 16px; }
  .sidebar-bottom-container-admin {
    z-index: 100;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 70px;
    border-top: 1px solid rgba(139, 149, 158, 0.3);
    background: white;
    width: 100%; }
    .sidebar-bottom-container-admin .bottom-nav-link {
      text-align: center;
      color: #8b959e;
      font-size: 10px;
      font-weight: 600;
      padding: 12px 8px 16px 8px !important;
      cursor: pointer; }
      .sidebar-bottom-container-admin .bottom-nav-link img {
        margin: auto !important; }
      .sidebar-bottom-container-admin .bottom-nav-link .selected-nav {
        color: black; }
    .sidebar-bottom-container-admin .bottom-nav-img-wrapper {
      height: 25px;
      margin-top: 2px;
      width: 30px;
      margin-left: auto;
      margin-right: auto; }
      .sidebar-bottom-container-admin .bottom-nav-img-wrapper .bottom-nav-link-img {
        height: 20px; }
    .sidebar-bottom-container-admin .bottom-menu-unread-indicator {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: red;
      color: red;
      font-size: 6px;
      font-weight: 600;
      text-align: center;
      top: -23px;
      left: 16px; }
  .custom-datepicker-calendar .react-datepicker__day-name,
  .custom-datepicker-calendar .react-datepicker__day,
  .custom-datepicker-calendar .react-datepicker__time-name {
    font-size: 16px !important; }
  .custom-datepicker-calendar .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
    font-size: 14px; }
  .react-datepicker__time-list-item {
    font-size: 16px !important; }
  .driver-booking-container {
    margin: 5%; }
    .driver-booking-container .driver-service-card {
      border-radius: 10px;
      border: 1.5px solid rgba(139, 149, 158, 0.2);
      background-color: #fbfbfb;
      padding: 1.5em 1.5em;
      font-size: 16px;
      margin-top: 1em; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container {
        margin-bottom: 50px;
        margin-top: 0; }
        .driver-booking-container .driver-service-card .driver-service-card-body-container p {
          font-size: 16px !important; }
        .driver-booking-container .driver-service-card .driver-service-card-body-container .step {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          column-gap: 20px;
          padding-left: 0;
          padding-right: 0; }
        .driver-booking-container .driver-service-card .driver-service-card-body-container .driver-service-card-item-updates {
          display: grid;
          grid-template-columns: 10% 73%;
          grid-auto-flow: column;
          border: 1px solid rgba(139, 149, 158, 0.3);
          padding: 1.5em 1em;
          background: none;
          border-radius: 5px; }
        .driver-booking-container .driver-service-card .driver-service-card-body-container .updates-complete {
          grid-template-columns: 10% 80% !important;
          border: 1px solid rgba(139, 149, 158, 0.5); }
    .driver-booking-container .tabs-container .custom-tabs-nav-driver {
      background: rgba(139, 149, 158, 0.15);
      padding: 7px 15px;
      border-radius: 4px;
      font-size: 13px; }
      .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs {
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        border-radius: 0;
        width: 100%;
        max-width: 1000px;
        background: transparent;
        font-size: 15px;
        padding: 0; }
        .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link {
          background: transparent;
          border-radius: 0;
          font-size: 16px !important;
          font-weight: 600;
          color: #8b959e;
          opacity: 0.55;
          padding: 7px 7px 0px 7px;
          border: none; }
          .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link.active {
            background: transparent;
            border: none;
            border-bottom: 2px solid #0c0c0c;
            box-shadow: none;
            color: #303030;
            opacity: 1;
            margin-bottom: -2px;
            font-weight: 600; }
          .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link:focus {
            box-shadow: none; }
          .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link:not(:last-child) {
            margin-right: 10px !important; }
    .driver-booking-container .newOffer-message {
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 12px;
      font-size: 16px;
      font-weight: 500;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem;
      white-space: pre; }
    .driver-booking-container .card-message {
      line-height: 12px;
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      font-weight: 400;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem; }
    .driver-booking-container .offer-date {
      white-space: pre;
      font-size: 16px;
      font-weight: 700; }
    .driver-booking-container .partner-action-title {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 0.4em; }
    .driver-booking-container .partner-action-message {
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 12px;
      font-size: 16px;
      font-weight: 500;
      color: #8b959e;
      display: block;
      margin-bottom: 0.5rem;
      white-space: pre; }
    .driver-booking-container .partner-action-counter {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #d0021b; }
    .driver-booking-container .counter {
      font-size: 22px;
      font-weight: 400; }
    .driver-booking-container .price-currency {
      font-size: 16px;
      white-space: pre; }
    .driver-booking-container .partner-bookingType {
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 12px;
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin-bottom: 0.3rem; }
  .grey-theme-background-driver {
    background-color: rgba(139, 149, 158, 0.1) !important;
    height: calc(100vh - 60px) !important; }
  .grey-theme-background-partner {
    background-color: rgba(139, 149, 158, 0.1) !important; }
  .partner-btn-desktop-decline {
    max-width: 95px;
    min-width: 95px;
    height: 42px !important;
    font-size: 14px !important;
    font-weight: 600;
    border: 1px solid #a79779;
    color: #a79779 !important;
    background-color: rgba(139, 149, 158, 0); }
    .partner-btn-desktop-decline:active, .partner-btn-desktop-decline:focus, .partner-btn-desktop-decline:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: rgba(165, 151, 124, 0.15) !important;
      border: rgba(165, 151, 124, 0.15) !important;
      color: #a79779 !important; } }

@media (max-width: 768px) {
  .spinner_wrapper {
    top: 102px;
    z-index: 1; }
  .modal-dialog {
    width: calc(100% - 16px); }
  .submit-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100%; }
  .book-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100%; }
  .quote-book-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 100%; }
  .submit-btn,
  .back-btn,
  .cancel-btn {
    margin-top: 15px !important;
    margin-bottom: 22px !important; }
  .light-tabs-partner {
    border-bottom: 0 !important;
    border-radius: 0;
    width: 100%;
    max-width: 1000px;
    background: transparent;
    font-size: 16px;
    padding: 0; }
    .light-tabs-partner .nav-link {
      background: transparent;
      border-radius: 0;
      font-size: 16px;
      font-weight: 600;
      color: #8b959e;
      opacity: 0.55;
      padding: 7px 0px 14px 0px !important; }
      .light-tabs-partner .nav-link.active {
        background: transparent;
        border-bottom: 3px solid #0c0c0c;
        box-shadow: none;
        color: #303030;
        opacity: 1;
        margin-bottom: -2px;
        font-weight: 600; }
      .light-tabs-partner .nav-link:not(:last-child) {
        margin-right: 10px !important; }
  .cancel-booking-modal {
    border: 1px solid rgba(139, 149, 158, 0.3) !important; }
    .cancel-booking-modal .modal-header {
      border-bottom: none !important;
      padding-bottom: 0px; }
      .cancel-booking-modal .modal-header .modal-title {
        width: 100%; }
        .cancel-booking-modal .modal-header .modal-title .btn-link {
          margin-right: 5%;
          margin-top: 3%; }
          .cancel-booking-modal .modal-header .modal-title .btn-link img {
            width: 20px !important;
            height: 20px !important; }
        .cancel-booking-modal .modal-header .modal-title .btn-link-draft-mobile {
          margin-right: 1%;
          margin-top: 3%;
          margin-bottom: 7%; }
          .cancel-booking-modal .modal-header .modal-title .btn-link-draft-mobile img {
            width: 12px !important; }
    .cancel-booking-modal .modal-body {
      padding-bottom: 0 !important; }
      .cancel-booking-modal .modal-body .modal-description {
        font-size: 15px;
        color: #000000;
        text-align: center; }
      .cancel-booking-modal .modal-body .modal-description-delete {
        font-size: 14px;
        color: #636d77;
        width: 88%;
        margin: 0 auto 0.8rem auto; }
    .cancel-booking-modal .modal-title-delete {
      display: flex;
      font-size: 20px;
      color: #0c0c0c;
      text-align: center;
      font-weight: 400;
      margin-top: 2%;
      padding-bottom: 2%;
      justify-content: center;
      margin: 0 auto 0.8rem auto;
      width: 90%; }
    .cancel-booking-modal .modal-content {
      width: 80% !important;
      margin: auto !important; }
    .cancel-booking-modal .modal-footer {
      border-top: none !important;
      justify-content: center !important;
      padding-bottom: 50px !important;
      flex-direction: column-reverse; }
      .cancel-booking-modal .modal-footer .select-btn {
        margin-top: 0px;
        font-size: 14px;
        min-width: 175px;
        height: 40px;
        font-weight: 900 !important; }
      .cancel-booking-modal .modal-footer .no-btn {
        margin-top: 10px;
        margin-left: 7px; }
  #logo-tooltip {
    display: none;
    top: 10px !important;
    left: 220px !important; }
  input.form-control {
    font-size: 16px; }
  .location-autocomplete-result,
  .autocomplete-result {
    font-size: 16px !important; }
  .vehicle-card.chat-vehicle-card {
    padding: 15px 20px !important; }
  .vehicle-card.chat-vehicle-card:hover {
    cursor: pointer;
    border: 1px solid #000; }
  .vehicle-card {
    display: block;
    padding: 20px 30px !important;
    max-width: unset !important;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important; }
    .vehicle-card.active {
      box-shadow: none; }
    .vehicle-card .card-body {
      padding: 5px 0 !important; }
      .vehicle-card .card-body .sub-title {
        font-size: 12px !important;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 0 !important; }
    .vehicle-card .vehicle-img-value {
      border-bottom: 1px solid rgba(139, 149, 158, 0.3); }
    .vehicle-card .vehicle-value {
      font-size: 12px !important;
      font-weight: 500; }
      .vehicle-card .vehicle-value img {
        height: 15px !important; }
    .vehicle-card .electric-vehicle-mobile {
      position: absolute;
      bottom: 110px !important;
      left: calc(100% - 110px) !important; }
    .vehicle-card .electric-vehicle {
      font-size: 12px !important;
      font-weight: 500; }
      .vehicle-card .electric-vehicle img {
        height: 15px !important; }
    .vehicle-card .vehicle-title {
      font-size: 16px;
      font-weight: 600;
      color: #303030;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    .vehicle-card .card-title {
      margin-bottom: 0;
      line-height: 1.5 !important; }
    .vehicle-card .card-action {
      padding: 0 !important; }
      .vehicle-card .card-action .lead {
        min-width: 85px;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        line-height: 1.5 !important; }
    .vehicle-card .vehicle-img-wrap {
      width: calc(100vw - 220px) !important;
      min-width: 100px;
      margin-left: 0 !important;
      max-height: 150px; }
      .vehicle-card .vehicle-img-wrap img {
        max-height: 150px;
        object-fit: scale-down !important;
        object-position: left; }
  .vehicle-card .col.card-action .lead {
    font-size: 16px;
    font-weight: 600; }
  .total-title-new {
    font-size: 16px !important;
    color: #303030; }
  .vehicle-card.active {
    border: 1.5px solid #000; }
  .vehicle-card-noclick:hover {
    border-radius: unset !important;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important;
    border: 1px solid rgba(139, 149, 158, 0.1) !important; }
  .vehicle-card.car-rent .card-action {
    min-width: 50px; }
  .input-group {
    background-color: #fbfbfb !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }
    .input-group .input-group-prepend {
      border-right: transparent !important; }
      .input-group .input-group-prepend .input-group-text {
        border: 0.5px solid transparent;
        background-color: #fbfbfb !important;
        border-radius: 2px;
        border-right: transparent !important; }
    .input-group input.form-control {
      box-shadow: none !important;
      border-left: transparent !important;
      padding-left: 0px !important; }
      .input-group input.form-control:focus, .input-group input.form-control:hover {
        border-radius: 0px !important;
        outline: none !important;
        border-color: transparent !important;
        border-left: transparent !important;
        background-color: #fbfbfb; }
    .input-group .form-control {
      border-left: transparent !important; }
      .input-group .form-control:focus, .input-group .form-control:hover {
        border-radius: 0px !important;
        outline: none !important;
        border-color: transparent !important;
        border-left: transparent !important;
        background-color: #fbfbfb !important; }
    .input-group:focus, .input-group:hover {
      border: 0.5px solid transparent !important;
      background-color: #fbfbfb !important;
      border-radius: 1px;
      height: 52px;
      border-color: #0c0c0c !important; }
  .round-input-group {
    box-shadow: none !important;
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    border-radius: 10px !important;
    height: 60px !important; }
    .round-input-group:hover, .round-input-group:focus, .round-input-group:focus-within, .round-input-group:active {
      border: 1px solid #0c0c0c !important;
      border-radius: 10px !important;
      border-color: #0c0c0c !important;
      height: 60px !important; }
    .round-input-group input.form-control {
      margin-top: 9px;
      border-radius: 10px !important; }
  .round-date-group {
    border-radius: 10px; }
    .round-date-group input.form-control {
      box-shadow: none !important;
      border: 1.5px solid rgba(139, 149, 158, 0.2);
      border-radius: 10px !important;
      height: 60px !important; }
      .round-date-group input.form-control:hover, .round-date-group input.form-control:focus, .round-date-group input.form-control:active {
        border-radius: 10px !important;
        border: 1px solid #0c0c0c;
        border-color: #0c0c0c !important; }
  .round-select-group {
    width: 100%; }
    .round-select-group .react-select-container .react-select__control {
      box-shadow: none !important;
      border: 1.5px solid rgba(139, 149, 158, 0.2);
      border-radius: 10px !important;
      height: 60px !important;
      font-size: 16px; }
      .round-select-group .react-select-container .react-select__control:hover, .round-select-group .react-select-container .react-select__control:focus, .round-select-group .react-select-container .react-select__control:active, .round-select-group .react-select-container .react-select__control:focus-within {
        border-radius: 10px !important;
        border: 1px solid #0c0c0c;
        border-color: #0c0c0c !important; }
      .round-select-group .react-select-container .react-select__control .react-select__dropdown-indicator {
        padding: 0 10px; }
        .round-select-group .react-select-container .react-select__control .react-select__dropdown-indicator img {
          width: 23px;
          height: 23px; }
    .round-select-group .react-select-container .react-select__menu {
      font-size: 16px !important; }
  .client-select-group {
    width: 100%; }
    .client-select-group .react-select-container {
      font-size: 16px; }
  .child-seat-sub-header {
    margin-bottom: 0px !important;
    width: auto !important;
    max-width: none !important; }
  .react-select-container {
    font-size: 16px !important; }
  .decline-offer {
    height: 100%;
    width: 100%;
    margin: 0; }
  .decline-offer-booking-modal .modal-header {
    border-bottom: none !important; }
    .decline-offer-booking-modal .modal-header .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px; }
      .decline-offer-booking-modal .modal-header .modal-title .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end; }
  .decline-offer-booking-modal .modal-content {
    height: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important; }
  .decline-offer-booking-modal .title {
    display: block;
    font-size: 20px;
    margin-bottom: 1em;
    margin-top: 0;
    color: #0c0c0c;
    font-weight: 400;
    text-align: left; }
  .decline-offer-booking-modal .modal-body {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 4rem;
    padding-left: 0; }
    .decline-offer-booking-modal .modal-body .modal-description {
      font-size: 15px; }
    .decline-offer-booking-modal .modal-body .modal-options {
      padding-top: 1em; }
  .decline-offer-booking-modal .modal-footer {
    border-top: none !important;
    justify-content: end !important;
    padding-bottom: 50px !important;
    margin: 0 !important; }
  .decline-offer-booking-modal .modal-footer > * {
    margin: 0 !important; }
  .client-commission-modal .modal-content {
    width: 100% !important; }
  .client-commission-modal .modal-header {
    border-bottom: none !important;
    padding: 2rem 1rem; }
    .client-commission-modal .modal-header .modal-title {
      width: 100%;
      display: flex;
      padding-bottom: 0px; }
      .client-commission-modal .modal-header .modal-title .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end; }
  .client-commission-modal .modal-body {
    margin-bottom: 4em;
    padding: 0;
    padding-left: 3em;
    padding-right: 3em; }
    .client-commission-modal .modal-body .price-text {
      text-align: center;
      font-size: 25px; }
    .client-commission-modal .modal-body .commission-text {
      text-align: center;
      font-size: 15px;
      color: #417505; }
    .client-commission-modal .modal-body .description-text {
      text-align: center;
      font-size: 15px;
      margin-top: 1.5em;
      color: #636d77; }
  .whats-new-modal {
    display: flex !important;
    justify-content: center; }
    .whats-new-modal .modal-size {
      width: 350px;
      max-width: 350px; }
    .whats-new-modal .modal-content {
      height: fit-content;
      overflow-y: initial;
      border-radius: 0.3rem; }
    .whats-new-modal .modal-header {
      border-bottom: none !important;
      padding-right: 0;
      padding-top: 20px;
      padding-bottom: 30px; }
      .whats-new-modal .modal-header .modal-title {
        width: 100%;
        display: flex;
        padding-bottom: 0px; }
        .whats-new-modal .modal-header .modal-title .close-btn-wrap img {
          width: 12px;
          position: static; }
    .whats-new-modal .modal-body {
      padding: 0 !important;
      display: block; }
      .whats-new-modal .modal-body .whatsnew {
        color: #a79779;
        font-size: 15px;
        margin-bottom: 10px;
        align-self: start;
        margin-left: 0;
        margin-top: 0; }
      .whats-new-modal .modal-body .title {
        font-size: 22px;
        margin-bottom: 10px;
        padding-right: unset;
        margin-left: 0;
        margin-right: 0; }
      .whats-new-modal .modal-body .description {
        font-size: 15px;
        color: #636d77;
        margin-bottom: 5px;
        word-wrap: break-word;
        align-self: start;
        margin-left: 0;
        margin-right: 0;
        width: auto; }
      .whats-new-modal .modal-body .cta {
        display: block;
        align-self: auto;
        margin-bottom: 0;
        margin-right: 0; }
        .whats-new-modal .modal-body .cta a {
          padding-top: 9px;
          height: 40px;
          font-size: 16px !important;
          margin-bottom: 45px !important; }
    .whats-new-modal .modal-panel {
      flex-direction: column;
      height: 654px;
      max-height: 654px; }
      .whats-new-modal .modal-panel .modal-div {
        max-width: none;
        flex: 1;
        overflow-y: auto;
        border-radius: 0.3rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        max-width: 100%; }
        .whats-new-modal .modal-panel .modal-div .image {
          height: 100%; }
      .whats-new-modal .modal-panel .modal-container {
        height: max-content;
        padding-left: 25px;
        padding-right: 25px;
        flex: none;
        overflow-y: auto;
        max-height: 70vh; }
    .whats-new-modal .modal-panel-update {
      flex-direction: column; }
      .whats-new-modal .modal-panel-update .modal-div {
        max-width: none;
        flex: 1;
        overflow-y: auto;
        border-radius: 0.3rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        max-width: 100%; }
        .whats-new-modal .modal-panel-update .modal-div .image {
          height: 100%; }
      .whats-new-modal .modal-panel-update .modal-container {
        height: max-content;
        padding-left: 25px;
        padding-right: 25px;
        flex: none;
        overflow-y: auto;
        max-height: 70vh; }
    .whats-new-modal .update-img {
      width: 55%;
      height: 80%;
      margin-top: 20px;
      align-self: center; }
    .whats-new-modal .modal-div::-webkit-scrollbar,
    .whats-new-modal .modal-container::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .partner-filters {
    border: 1.5px solid rgba(139, 149, 158, 0.2) !important;
    border-radius: 10px !important;
    height: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center; }
    .partner-filters img {
      width: 24px;
      height: 12px; }
  .client-filters {
    display: flex;
    justify-content: center;
    align-items: center; }
    .client-filters img {
      width: 24px;
      height: 12px; }
  .partner-filter-container {
    margin-top: 8em; }
  .app-search-field .partner-search-bar {
    height: 50px !important;
    background-color: #ffffff !important; }
  .app-search-field .input-group {
    background-color: #fbfbfb !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important; }
    .app-search-field .input-group .input-group-prepend {
      border-right: transparent !important; }
      .app-search-field .input-group .input-group-prepend .input-group-text {
        border: 0.5px solid transparent;
        background-color: #ffffff !important;
        border-radius: 2px;
        border-right: transparent !important; }
    .app-search-field .input-group input.form-control {
      box-shadow: none !important;
      border-left: transparent !important;
      padding-left: 0px !important; }
      .app-search-field .input-group input.form-control:focus, .app-search-field .input-group input.form-control:hover {
        border-radius: 0px !important;
        outline: none !important;
        border-color: transparent !important;
        border-left: transparent !important;
        background-color: #ffffff; }
    .app-search-field .input-group .form-control {
      border-left: transparent !important; }
      .app-search-field .input-group .form-control:focus, .app-search-field .input-group .form-control:hover {
        border-radius: 0px !important;
        outline: none !important;
        border-color: transparent !important;
        background-color: #ffffff; }
    .app-search-field .input-group:focus, .app-search-field .input-group:hover {
      border: 0.5px solid transparent;
      background-color: #fbfbfb !important;
      border-radius: 1px;
      border-color: #0c0c0c !important; }
  .form-row .credit-card-img {
    margin-top: 4px; }
  .info-icon {
    width: 13px !important;
    margin-left: 6px;
    padding-bottom: 4px !important;
    padding-top: 0.5px !important;
    vertical-align: middle !important; }
  .info-icon-rentals {
    width: 15px !important;
    margin-left: 6px;
    padding-bottom: 4px !important;
    padding-top: 2px !important;
    vertical-align: middle !important; }
  .info-icon-vip {
    width: 12px !important;
    padding-bottom: 3px !important;
    margin-left: 4px !important; }
  .info-icon-total {
    width: 13px !important;
    padding-bottom: 4px !important; }
  .door-to-door-div {
    padding: 2rem 1.5rem; }
    .door-to-door-div .door-to-door-cards {
      flex-direction: column; }
    .door-to-door-div .door-to-door-card {
      padding: 1rem 2rem;
      width: 340px; }
      .door-to-door-div .door-to-door-card img {
        width: 22px !important;
        height: 22px !important;
        margin: 0 !important; }
      .door-to-door-div .door-to-door-card span {
        margin-top: 10px;
        font-size: 15px; }
      .door-to-door-div .door-to-door-card span:last-of-type {
        margin-top: 10px;
        font-size: 15px;
        width: 80%; }
      .door-to-door-div .door-to-door-card .book-text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-decoration: none;
        gap: 4px;
        font-size: 14px;
        color: #a79779;
        cursor: pointer;
        margin-top: 5px; }
        .door-to-door-div .door-to-door-card .book-text img {
          width: 15px !important;
          height: 15px !important; }
  .home-booking-details-modal p {
    font-size: 20px; }
  .home-booking-details-modal .section-header.expandable:after {
    bottom: 35px;
    top: unset !important;
    width: 20px !important;
    height: 20px !important; }
  .home-booking-details-modal .smart-sugg-car-card {
    padding: 12px !important; }
  .booking-details-mobile-btn {
    padding: 10px 48px 10px 48px;
    background-color: #a79779;
    border-radius: 2px;
    border: none;
    font-size: 15px;
    color: white;
    width: 100%; }
  .associated-bookings {
    padding: 24px; }
    .associated-bookings p {
      font-size: 22px; }
    .associated-bookings .section-header.expandable:after {
      top: calc(50% - 15px) !important;
      width: 30px !important;
      height: 30px !important; }
    .associated-bookings .bookings-grid {
      margin-top: 25px; }
    .associated-bookings .booking-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px; }
      .associated-bookings .booking-row .booking-cell {
        font-size: 15px !important; }
        .associated-bookings .booking-row .booking-cell div {
          margin-bottom: 2px !important; }
    .associated-bookings hr {
      margin-top: 0 !important;
      margin-bottom: 5px !important; }
    .associated-bookings .badge {
      font-size: 14px !important;
      font-weight: 400 !important; } }

@media (max-width: 576px) {
  .vehicle-card .col {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .vehicle-card .card-action .hint-text {
    margin-right: 15px; }
  .vehicle-card .card-action .vehicle-selected {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 14px; }
  .booking-preview-info .care-info-column {
    margin-top: 20px; }
  .quotes-rent-from .vehicle-card,
  .new-booking-shared-from .vehicle-card,
  .quotes-shared-from .vehicle-card,
  .car-rent-form .vehicle-card {
    margin-top: 40px; }
  .last-driver-section .custom-file-label .file-name {
    margin-top: 4px; } }

@media (max-height: 576px) {
  .admin-chat-container {
    padding: 0;
    overflow-x: hidden; }
    .admin-chat-container .admin-chat-room {
      flex-direction: column;
      overflow: hidden;
      width: calc(100% - 40px); }
      .admin-chat-container .admin-chat-room .room-content {
        margin-top: 20%; }
      .admin-chat-container .admin-chat-room .admin-message-input-block {
        width: calc(115vw - 20px);
        left: 0;
        padding: 0px 15px 0 3px;
        border-top: unset;
        bottom: 0px;
        position: fixed; }
        .admin-chat-container .admin-chat-room .admin-message-input-block .message-input-wrap {
          position: relative;
          flex-grow: 1;
          width: calc(100% - 55px); }
          .admin-chat-container .admin-chat-room .admin-message-input-block .message-input-wrap div div {
            max-height: 120px; }
            .admin-chat-container .admin-chat-room .admin-message-input-block .message-input-wrap div div textarea {
              overflow-y: auto !important; }
      .admin-chat-container .admin-chat-room .message-input-toolbar {
        padding-bottom: 10px; }
      .admin-chat-container .admin-chat-room .room-header {
        width: calc(100vw - 10px);
        display: block;
        padding: 0;
        position: fixed;
        z-index: 1;
        background-color: #fff;
        border-bottom: 1px solid rgba(12, 12, 12, 0.1); }
        .admin-chat-container .admin-chat-room .room-header .align-items-center {
          justify-content: space-between;
          width: calc(100vw - 10px);
          height: 93%; }
        .admin-chat-container .admin-chat-room .room-header .userLabel-plain {
          font-size: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px; }
        .admin-chat-container .admin-chat-room .room-header .userLabel-border-right {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          font-size: 16px;
          text-align: center;
          margin-right: 0;
          margin-top: 1em; }
        .admin-chat-container .admin-chat-room .room-header .text-brown {
          font-size: 13px;
          display: flex;
          margin-right: 0;
          text-align: center; }
        .admin-chat-container .admin-chat-room .room-header .room-title {
          display: flex;
          align-items: center; }
      .admin-chat-container .admin-chat-room .admin-messages-list {
        height: auto;
        max-height: calc(100vh - 13rem) !important;
        margin-top: 5rem;
        overflow-x: hidden;
        padding: 20px 10px 80px 0; }
    .admin-chat-container .chats-list-wrap .chats-list {
      width: calc(100% - 5em) !important; }
    .admin-chat-container .chats-list-wrap .spinner_wrapper {
      left: 45%; }
    .admin-chat-container .chats-list-wrap .list-panel {
      width: 90%; }
      .admin-chat-container .chats-list-wrap .list-panel .search-field-wrap .form-group .form-control {
        margin-left: 4%; } }

.vehicle-card-desktop.chat-vehicle-card {
  padding: 15px 20px !important; }

.chat-vehicle-card:hover {
  cursor: pointer;
  border: 1px solid #000; }

.vehicle-card-desktop:hover {
  cursor: pointer !important;
  border-radius: 2px;
  border: 1px solid rgba(139, 149, 158, 0.25);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1) !important; }

.vehicle-card-noclick:hover {
  cursor: default !important;
  border-radius: unset !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important;
  border: 1px solid rgba(139, 149, 158, 0.1); }

.vehicle-card-desktop {
  display: block;
  padding: 20px 30px !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.04) !important; }
  .vehicle-card-desktop .card-body {
    padding: 5px 0 !important; }
    .vehicle-card-desktop .card-body .sub-title {
      font-size: 12px !important;
      font-weight: 500;
      margin-bottom: 0 !important; }
  .vehicle-card-desktop .vehicle-img-value {
    border-bottom: 1px solid rgba(139, 149, 158, 0.3); }
  .vehicle-card-desktop .vehicle-value {
    font-size: 12px !important;
    font-weight: 500; }
    .vehicle-card-desktop .vehicle-value img {
      height: 15px !important; }
  .vehicle-card-desktop .electric-vehicle-mobile {
    position: absolute;
    bottom: 110px !important;
    left: calc(100% - 100px) !important; }
  .vehicle-card-desktop .electric-vehicle {
    font-size: 12px !important;
    font-weight: 500; }
    .vehicle-card-desktop .electric-vehicle img {
      height: 15px !important; }
  .vehicle-card-desktop .vehicle-title {
    font-size: 15px;
    font-weight: 400;
    color: #303030;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .vehicle-card-desktop .card-title {
    margin-bottom: 0;
    line-height: 1.5 !important; }
  .vehicle-card-desktop .card-action {
    padding: 0 !important; }
    .vehicle-card-desktop .card-action .lead {
      min-width: 85px;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      line-height: 1.5 !important; }
  .vehicle-card-desktop .vehicle-img-wrap {
    min-width: 100px;
    margin-left: 0 !important;
    max-height: 150px; }
    .vehicle-card-desktop .vehicle-img-wrap img {
      max-height: 150px;
      object-fit: scale-down !important;
      object-position: left; }

.vehicle-card-desktop .col.card-action .lead {
  font-size: 15px;
  font-weight: 400; }

.vehicle-card-desktop.active {
  border: 1.5px solid #000; }

.vehicle-card-desktop.car-rent .card-action {
  min-width: 50px; }

@media (min-width: 438px) {
  .benefits-div {
    max-width: 35%;
    padding-bottom: 7%;
    padding-top: 0%;
    display: flex;
    justify-content: start;
    align-items: center; }
  .benefits-member-message {
    background-color: #ffffff;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 89.5%;
    border-radius: 2px;
    border: 1px solid rgba(139, 149, 158, 0.1);
    border-top-style: solid !important;
    border-right-style: solid !important;
    border-bottom-style: solid !important;
    border-left-style: solid !important;
    border-top-width: 0.5px !important;
    border-right-width: 0.5px !important;
    border-bottom-width: 0.5px !important;
    border-left-width: 0.5px !important;
    border-image-width: initial !important;
    border-image-source: initial !important;
    border-image-repeat: initial !important;
    border-image-outset: initial !important;
    border-image-slice: initial !important; }
    .benefits-member-message .benefits-header {
      font-size: 14px !important;
      font-weight: 600 !important;
      word-wrap: break-word;
      white-space: nowrap; }
    .benefits-member-message .benefits-body {
      font-size: 12px !important; } }

@media (max-width: 380px) {
  .driver-booking-container {
    margin: 5%; }
    .driver-booking-container .driver-service-card {
      border-radius: 10px;
      border: 1.5px solid rgba(139, 149, 158, 0.2);
      background-color: #fbfbfb;
      padding: 1.5em 1.5em;
      font-size: 16px;
      margin-top: 1em; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container p {
        font-size: 16px !important; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .step {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 20px;
        padding-left: 0;
        padding-right: 0; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .driver-service-card-item-updates {
        display: grid;
        grid-template-columns: 10% 73%;
        grid-auto-flow: column;
        border: 1px solid rgba(139, 149, 158, 0.3);
        padding: 1.5em 1em;
        background: none;
        border-radius: 5px; }
      .driver-booking-container .driver-service-card .driver-service-card-body-container .updates-complete {
        grid-template-columns: 10% 80% !important;
        border: 1px solid rgba(139, 149, 158, 0.5); }
    .driver-booking-container .tabs-container .custom-tabs-nav-driver {
      background: rgba(139, 149, 158, 0.15);
      padding: 7px 15px;
      border-radius: 4px;
      font-size: 13px; }
      .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs {
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        border-radius: 0;
        width: 100%;
        max-width: 1000px;
        background: transparent;
        font-size: 15px;
        padding: 0; }
        .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link {
          background: transparent;
          border-radius: 0;
          font-size: 16px !important;
          font-weight: 600;
          color: #8b959e;
          opacity: 0.55;
          padding: 7px 7px 0px 7px;
          border: none; }
          .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link.active {
            background: transparent;
            border: none;
            border-bottom: 2px solid #0c0c0c;
            box-shadow: none;
            color: #303030;
            opacity: 1;
            margin-bottom: -2px;
            font-weight: 600; }
          .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link:focus {
            box-shadow: none; }
          .driver-booking-container .tabs-container .custom-tabs-nav-driver.light-tabs .nav-link:not(:last-child) {
            margin-right: 10px !important; }
    .driver-booking-container .newOffer-message {
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 12px;
      font-size: 13px;
      font-weight: 500;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem;
      white-space: pre; }
    .driver-booking-container .card-message {
      line-height: 12px;
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 13px;
      font-weight: 400;
      color: #8b959e;
      display: block;
      margin-bottom: 0.3rem; }
    .driver-booking-container .offer-date {
      white-space: pre;
      font-size: 13px;
      font-weight: 700; }
    .driver-booking-container .counter {
      font-size: 15px;
      font-weight: 400; }
    .driver-booking-container .price-currency {
      font-size: 13px;
      white-space: pre; }
    .driver-booking-container .partner-bookingType {
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 12px;
      font-size: 13px;
      font-weight: 700;
      display: block;
      margin-bottom: 0.3rem; } }

.no-background-colour {
  background-color: inherit !important; }

@media (max-width: 768px) {
  .partner-btn-desktop-red {
    max-width: 100% !important;
    min-width: 100% !important;
    font-weight: 500 !important;
    height: 42px !important;
    font-size: 14px !important;
    border: 1px solid #d0021b !important;
    background-color: transparent !important;
    color: #d0021b !important; }
    .partner-btn-desktop-red:hover {
      font-weight: 600;
      box-shadow: none !important;
      background-color: transparent !important;
      border: 1px solid #d0021b !important;
      color: #d0021b !important;
      cursor: pointer; }
  .osm-trip-duration-container-b2c {
    margin-left: -60px !important; }
  .additional-earning-modal {
    display: flex;
    flex-direction: column-reverse; }
  .partner-modal-tab {
    overflow: hidden; }
    .partner-modal-tab .custom-tabs-nav {
      background: rgba(139, 149, 158, 0.15);
      padding: 7px 15px;
      border-radius: 4px;
      font-size: 13px;
      margin-bottom: 14px; }
      .partner-modal-tab .custom-tabs-nav .nav-link {
        background-color: transparent;
        color: rgba(139, 149, 158, 0.5);
        font-weight: 500;
        padding: 9px 18px;
        border-radius: 3px; }
        .partner-modal-tab .custom-tabs-nav .nav-link:hover {
          color: rgba(139, 149, 158, 0.7); }
        .partner-modal-tab .custom-tabs-nav .nav-link.active {
          background-color: #ffffff;
          color: #323c47;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .partner-modal-tab .custom-tabs-nav.light-tabs {
        border-bottom: none;
        border-radius: 0;
        width: 100%;
        max-width: 1000px;
        background: transparent;
        font-size: 15px;
        padding: 0; }
        .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link {
          background: transparent;
          border-radius: 0;
          font-size: 14px;
          font-weight: 600;
          color: #8b959e;
          opacity: 0.55;
          padding: 7px 7px 0px 7px;
          border: none; }
          .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link.active {
            background: transparent;
            border: none;
            border-bottom: 2px solid #0c0c0c;
            box-shadow: none;
            color: #303030;
            opacity: 1;
            margin-bottom: -2px;
            font-weight: 600; }
          .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link:focus {
            box-shadow: none; }
          .partner-modal-tab .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
            margin-right: 25px; }
    .partner-modal-tab .card-body {
      padding: 0;
      padding-left: 6px; }
      .partner-modal-tab .card-body label.expand-title {
        margin-bottom: 10px;
        font-size: 10px; }
      .partner-modal-tab .card-body label.expand-title-service {
        font-size: 10px; }
      .partner-modal-tab .card-body p {
        margin-bottom: 5px;
        font-size: 14px; }
  .earnings-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 15px;
    padding-right: 0.938rem;
    padding-bottom: 1rem; }
    .earnings-container .grid-item {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: #fbfbfb;
      border-color: transparent;
      padding: 20px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }
    .earnings-container .bottom-left,
    .earnings-container .bottom-center-left,
    .earnings-container .bottom-center-right,
    .earnings-container .bottom-right {
      grid-column: auto; }
    .earnings-container .card-header {
      font-size: 14px;
      margin-bottom: 0.5rem;
      padding: 0px;
      color: #8b959e;
      background-color: transparent;
      border-bottom: none;
      margin-bottom: 2rem; }
    .earnings-container .card-body {
      font-size: 18px;
      display: flex;
      justify-content: flex-start;
      padding: 0px; }
      .earnings-container .card-body .amount {
        font-weight: normal;
        margin-right: 0.3em; }
      .earnings-container .card-body .currency {
        font-weight: normal; }
    .earnings-container .card-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
    .earnings-container .increase {
      color: #417505;
      font-size: 0.8rem; }
    .earnings-container .decrease {
      color: #d0021b;
      font-size: 0.8rem; }
  .client-payout-card {
    border-radius: 3px !important;
    border: 1px solid rgba(139, 149, 158, 0.2);
    font-size: 15px;
    margin-top: 1em;
    width: 107%;
    margin-top: 0em !important; }
  .partner-payout-details {
    display: block;
    padding: 0.8rem 1rem 0rem 1rem; }
  .caret-black {
    position: absolute;
    top: 0px !important;
    width: 49px;
    height: 20px; }
  .caret-gold {
    position: absolute;
    top: 0px !important;
    width: 49px;
    height: 20px;
    filter: invert(73%) sepia(3%) saturate(2935%) hue-rotate(360deg) brightness(83%) contrast(86%); }
  .b2c-booking-title:first-of-type {
    margin-left: 0 !important; }
  .book-vehicle {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important; } }

.detailsRow {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 3%;
  padding-top: 5%; }
  .detailsRow img {
    width: 18px;
    display: inline-block;
    padding-top: 2px;
    vertical-align: top; }
  .detailsRow .add-margin {
    margin-left: 31px; }
  .detailsRow p {
    font-size: 16px;
    display: inline-block;
    margin-left: 15px;
    width: 85%; }

.detailsServiceRow {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 3%;
  padding-top: 5%; }
  .detailsServiceRow p {
    font-size: 16px;
    display: inline-block;
    width: 85%; }

.details-a {
  margin-top: 4%;
  margin-bottom: 5%; }
  .details-a img {
    width: 40px; }

.details-h {
  font-size: 0.8rem;
  color: #8b959e;
  margin-bottom: 4%; }

.details-s {
  font-size: 0.8rem;
  color: #8b959e; }

.detailsText {
  font-size: 16px; }

.border-bottom {
  border-bottom-color: #8b959e;
  border-bottom-style: solid;
  border-radius: 0px;
  border-width: 0.1px; }

.details-container {
  padding-right: 0px;
  padding-left: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 60px); }
  .details-container .fixedheader-wrapper {
    display: flex;
    margin-left: -15px; }
    .details-container .fixedheader-wrapper .fixedheader-back {
      margin-top: 51px; }
      .details-container .fixedheader-wrapper .fixedheader-back img {
        width: 40px; }

.detailsCol {
  padding-left: 0px;
  padding-top: 4px; }

@media (max-width: 1205px) and (max-width: 1400px) {
  .vehicle-price-width {
    max-width: fit-content;
    white-space: nowrap; } }

.vehicle-title-limit {
  /* Above 1540px and up to 1570px */ }
  @media (min-width: 1520px) and (max-width: 1540px) {
    .vehicle-title-limit {
      width: 12rem;
      text-overflow: ellipsis;
      overflow: hidden; } }
  @media (min-width: 1541px) and (max-width: 1570px) {
    .vehicle-title-limit {
      width: 13rem;
      text-overflow: ellipsis;
      overflow: hidden; } }
  @media (min-width: 1205px) and (max-width: 1500px) {
    .vehicle-title-limit {
      width: 15.5rem !important;
      text-overflow: ellipsis;
      overflow: hidden; } }
  @media (max-width: 1205px) {
    .vehicle-title-limit {
      width: 12rem !important;
      text-overflow: ellipsis;
      overflow: hidden; } }

.row-top-bottom-border {
  border-bottom-style: groove;
  border-bottom-width: thin;
  border-top-style: groove;
  border-top-width: thin;
  padding-top: 13px; }

.col-right-left-padding {
  padding-right: 30px;
  padding-left: 30px; }

.col-no-padding {
  padding-right: 0px;
  padding-left: 0px; }

.col-no-margin {
  margin-right: 0px;
  margin-left: 0px; }

.row-border-bottom {
  border-bottom-style: groove;
  border-bottom-width: thin; }

#bg-nested-dropdown-ll {
  height: 50px;
  border-radius: 2px;
  border: 0.5px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb;
  padding: 0;
  color: #0c0c0c; }
  #bg-nested-dropdown-ll ::after {
    margin-left: 100px; }

.dashboard-container {
  padding: 8%; }

.dashboard-bg {
  background-color: #e6e6e6; }

.d-box {
  background-color: white;
  padding: 4%;
  border: #e6e6e6;
  border-style: solid;
  border-bottom-width: 18px; }

.dropdown-col .dropdown-menu {
  min-width: 37rem !important;
  transform: translate3d(-66%, 50px, 0px) !important; }

.top-bottom-margin {
  margin-top: 8%;
  margin-bottom: 8%; }

.currency-heading {
  font-size: 18px;
  margin-bottom: 0.5rem; }

.currency-discription {
  font-size: 14px; }

.currency-DropDown {
  margin-left: 5%; }

@media (max-width: 768px) {
  .currency-heading {
    font-size: 16px;
    font-weight: 400; }
  .currency-discription {
    font-size: 14px; }
  .currency-DropDown {
    margin-left: 12%; } }

.chargeTab {
  font-size: 15px !important;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #303030;
  opacity: 1;
  margin-bottom: -2px;
  padding-bottom: 0px;
  font-weight: 600; }
  .chargeTab:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #303030;
    opacity: 1;
    margin-bottom: -2px;
    padding-bottom: 0px;
    font-weight: 600; }
  .chargeTab:focus {
    box-shadow: none; }

.activeChargeTab {
  font-size: 15px !important;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #303030;
  opacity: 1;
  margin-bottom: -2px;
  padding-bottom: 0px;
  font-weight: 600;
  border-bottom: 1.5px solid #0c0c0c; }
  .activeChargeTab:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #303030;
    opacity: 1;
    margin-bottom: -2px;
    padding-bottom: 0px;
    font-weight: 600;
    border-bottom: 1.5px solid #0c0c0c; }
  .activeChargeTab:focus {
    box-shadow: none; }

.chargeTargetFields {
  padding-left: 0.9375rem;
  padding-bottom: 1rem; }

.chargeCheckBox {
  padding-left: 1.9rem !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-left: 6.5rem; }

.chargeCheckBox label {
  color: #8b959e !important;
  padding-top: 0.2rem !important;
  line-height: 12px;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: pre; }

.buying-amount .form-label {
  white-space: nowrap; }

.buying-total-discount {
  padding-inline: 15px; }

.buying-total-commission {
  padding-right: 0.5rem;
  padding-left: 1.2rem; }

.partnerFields {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-inline: 0; }

.partnerFields-commission {
  width: 100%;
  padding-left: 0.9375rem; }

.chargesBackGround {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  background-color: #ffffff !important;
  margin-bottom: 1rem !important;
  padding-right: 2.1875rem !important;
  padding-left: 0.9375rem !important; }

.driver-service-additionlCharges {
  background-color: rgba(139, 149, 158, 0.1);
  padding: 1em 1em;
  font-size: 15px; }
  .driver-service-additionlCharges span.title {
    color: #8b959e;
    max-width: 75%; }

.add-location .form-block {
  max-width: 850px; }
  .add-location .form-block.wide {
    max-width: 100%; }

.add-location .right-side {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%; }

.add-location .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .add-location .header-container .section {
    flex: 1; }

.add-location .header-expandable {
  cursor: pointer;
  padding-right: 40px;
  position: relative; }
  .add-location .header-expandable:before {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    width: 4.391rem;
    height: 20px;
    background-image: url("/images/icons/caret-down.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }
  .add-location .header-expandable.expanded:before {
    transform: rotate(180deg); }

.add-location .remove-section {
  display: block;
  text-align: right;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  font-weight: bold; }
  .add-location .remove-section .btn-link {
    font-size: 12px;
    padding-right: 0; }

.removable-CMS-image {
  max-width: 120%;
  height: auto; }

@media (max-width: 768px) {
  .settings-page {
    padding: 20px 15px 25px 0px; }
    .settings-page .account-header {
      display: flex !important;
      justify-content: center;
      align-items: center; }
      .settings-page .account-header .back-btn {
        left: 0% !important;
        top: 0% !important;
        margin-top: 0.6px !important;
        margin-bottom: 0px !important; }
  .useless-class .settings-row {
    border-bottom: 1px solid rgba(139, 149, 158, 0.2);
    margin-left: 0.2rem;
    margin-right: 0.4rem; }
  .useless-class .NoBorderBottom {
    border-bottom: none !important; }
  .useless-class .settings-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
    margin-bottom: 0.8rem !important;
    margin-top: 0.8rem !important;
    color: #0c0c0c !important;
    padding-left: 0% !important; }
    .useless-class .settings-option img {
      max-height: 16px; } }

@media (max-width: 768px) {
  .NotificationSettings .BookingUpdates span {
    font-size: 15px !important;
    color: black; }
  .NotificationSettings .icons .email-icon {
    width: 12px !important;
    vertical-align: baseline;
    margin-right: 5px; }
  .NotificationSettings .icons img {
    width: 11px;
    vertical-align: baseline;
    margin-right: 5px; }
  .NotificationSettings .icons span {
    color: #8b959e;
    font-size: 10px; }
  .NotificationSettings .checkBox-Center {
    margin: 0% 20%; }
  .NotificationSettings .maxWidth {
    width: 55% !important; }
  .NotificationSettings .allignCheckBoxMobile {
    padding-top: 1.5rem; }
  .NotificationSettings thead {
    display: table-caption !important; }
    .NotificationSettings thead th {
      border-bottom: none !important;
      width: 100%; }
  .NotificationSettings tbody tr td {
    border: none !important; }
  .NotificationSettings th {
    border: none !important; }
    .NotificationSettings th td {
      border: none !important; }
  .NotificationSettings .custom-control-label:after {
    border-radius: 2px;
    background-color: #fbfbfb;
    border: 1px solid #8b959e; }
  .NotificationSettings .custom-control-input:checked ~ .custom-control-label:after {
    border-color: black;
    background-size: 9px 11px; }
  .no-top {
    padding-top: 0px !important; }
  .preview-card {
    height: 14rem;
    width: 19.7rem;
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(139, 149, 158, 0.3);
    padding: 1.6rem;
    border-radius: 2px;
    margin-top: 1rem;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding-bottom: 0px !important; }
  .preview-card .top-image-container {
    width: 80px;
    height: 80px;
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem; }
  .preview-card .bottom-image-container {
    width: 80px;
    height: 80px;
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem; }
  .preview-card .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: black;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase; }
  .preview-card .top-image {
    max-height: 100%;
    /* Ensure the image fits within the reserved space */ }
  .preview-card .bottom-image {
    max-height: 100%;
    /* Ensure the image fits within the reserved space */
    align-self: center; }
  .payment-currency-settings .react-currencySelect-container {
    width: 100%;
    font-size: 14px;
    background: transparent;
    z-index: 0 !important; }
    .payment-currency-settings .react-currencySelect-container.is-invalid .react-currencySelect__control {
      border: 1px solid #d0021b;
      background: transparent; }
      .payment-currency-settings .react-currencySelect-container.is-invalid .react-currencySelect__control:hover, .payment-currency-settings .react-currencySelect-container.is-invalid .react-currencySelect__control:focus {
        border-color: #d0021b; }
    .payment-currency-settings .react-currencySelect-container .react-currencySelect__control {
      height: 50px;
      border-radius: 2px;
      border: 0.5px solid transparent;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: red;
      padding: 0;
      background: transparent; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control--is-focused, .payment-currency-settings .react-currencySelect-container .react-currencySelect__control--menu-is-open, .payment-currency-settings .react-currencySelect-container .react-currencySelect__control:hover {
        border-color: #0c0c0c;
        background-color: transparent; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control--is-disabled {
        background-color: #fafafa; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__indicator-separator {
        display: none; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator {
        padding: 0 5px; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator img {
          width: 17px;
          height: 17px; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator-creatable {
        padding: 0 10px !important; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__dropdown-indicator-creatable img {
          width: 10px !important;
          height: 10px !important; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__single-value {
        color: #0c0c0c; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .select-multiple-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container {
        z-index: -1; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi {
          flex-wrap: nowrap; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn {
            display: flex;
            align-items: center;
            overflow: hidden;
            cursor: pointer; }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn:before {
              content: '';
              display: inline-block;
              width: 15px;
              min-width: 15px;
              height: 15px;
              background-image: url("/images/icons/radio.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 15px; }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn.selected-all:before {
              background-image: url("/images/icons/radio-checked.svg"); }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__value-container--is-multi .select-all-btn .btn-title {
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__control .react-currencySelect__placeholder {
        color: rgba(12, 12, 12, 0.3);
        z-index: 1; }
    .payment-currency-settings .react-currencySelect-container .react-currencySelect__control--menu-is-open .react-currencySelect__value-container {
      z-index: 0; }
    .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu {
      margin-top: 7px;
      border: 1px solid rgba(12, 12, 12, 0.1);
      box-shadow: none;
      border-radius: 0;
      z-index: -2; }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select__option {
        padding: 10px;
        color: #0c0c0c;
        cursor: pointer;
        border: none; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select__option:hover, .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select__option--is-focused {
          background-color: rgba(12, 12, 12, 0.1); }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select__option--is-selected {
          color: #ffffff;
          background-color: #0c0c0c; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select__option--is-selected:hover {
            background-color: rgba(12, 12, 12, 0.1); }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-currencySelect__option {
        background-color: #ffffff; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-currencySelect__option:hover, .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-currencySelect__option--is-focused {
          background-color: rgba(12, 12, 12, 0.1); }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option {
        padding: 10px;
        color: #0c0c0c;
        cursor: pointer;
        border: none; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option:hover, .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option--is-focused {
          background-color: rgba(12, 12, 12, 0.1); }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option--is-selected {
          color: #ffffff;
          background-color: #0c0c0c; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu .react-select-filters__option--is-selected:hover {
            background-color: rgba(12, 12, 12, 0.1); }
      .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list {
        padding: 0;
        margin-left: -1px; }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option {
          display: flex;
          align-items: center; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url("/images/icons/radio.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected {
            color: #0c0c0c;
            background-color: transparent; }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected:hover, .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected--is-focused {
              background-color: rgba(167, 151, 121, 0.3); }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select__option--is-selected:before {
              background-image: url("/images/icons/radio-checked.svg"); }
        .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option {
          display: flex;
          align-items: center; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option:before {
            content: '';
            display: inline-block;
            width: 15px;
            min-width: 15px;
            height: 15px;
            background-image: url("/images/icons/radio.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 15px; }
          .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected {
            color: #0c0c0c;
            background-color: transparent; }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected:hover, .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected--is-focused {
              background-color: rgba(167, 151, 121, 0.3); }
            .payment-currency-settings .react-currencySelect-container .react-currencySelect__menu-list--is-multi .react-select-filters__option--is-selected:before {
              background-image: url("/images/icons/radio-checked.svg"); } }

.event-supportbar-btn-wrap {
  margin: 20px 0px 15px 15px;
  cursor: pointer; }

.event-main-padding {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
  width: 100%;
  height: 100%; }

.event-settings-container {
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: calc(100% - 10vw); }
  .event-settings-container .event-card {
    border-radius: 10px;
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #ffffff;
    padding: 1.5em 1.5em;
    font-size: 15px; }
    .event-settings-container .event-card .event-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px; }
  .event-settings-container .event-card-title {
    margin-top: 2em !important;
    border-radius: 10px 10px 0px 0px !important; }
  .event-settings-container .event-card-body {
    margin-top: 0em;
    border-top: none;
    border-radius: 0px 0px 10px 10px !important; }
  .event-settings-container .event-card-footer {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em; }
  .event-settings-container .event-edit-wrap {
    padding: 1.5em 1.5em; }
    .event-settings-container .event-edit-wrap .event-edit-fields {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .event-settings-container .event-edit-wrap .event-edit-fields input {
        box-shadow: none !important;
        border: 1px solid rgba(139, 149, 158, 0.1) !important;
        font-size: 15px !important; }
        .event-settings-container .event-edit-wrap .event-edit-fields input:hover, .event-settings-container .event-edit-wrap .event-edit-fields input:focus, .event-settings-container .event-edit-wrap .event-edit-fields input:active {
          border: 0.5px solid #000000 !important; }
      .event-settings-container .event-edit-wrap .event-edit-fields .event-checkbox-background {
        padding: 1rem 0.5rem 0.5rem 0.8rem;
        background-color: #fbfbfb; }
      .event-settings-container .event-edit-wrap .event-edit-fields .event-service-label {
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #8b959e;
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.5rem; }
      .event-settings-container .event-edit-wrap .event-edit-fields .custom-control-label {
        max-width: fit-content;
        flex-grow: 1;
        padding-top: 0.2rem;
        padding-left: 0.3rem;
        cursor: pointer;
        text-transform: none;
        letter-spacing: initial;
        font-weight: 400;
        font-size: 14px !important;
        color: #0c0c0c;
        position: relative;
        margin-bottom: 0;
        vertical-align: top; }

.event-card-container {
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: calc(100% - 5vw); }
  .event-card-container .event-card {
    border-radius: 10px;
    border: 1.5px solid rgba(139, 149, 158, 0.2);
    background-color: #ffffff;
    padding: 1.5em 1.5em;
    font-size: 15px; }
    .event-card-container .event-card .event-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px; }
  .event-card-container .event-card-title {
    margin-top: 2em !important;
    border-radius: 10px 10px 0px 0px !important; }
  .event-card-container .event-card-body {
    margin-top: 0em;
    border-top: none;
    border-radius: 0px 0px 10px 10px !important; }
  .event-card-container .event-card-footer {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em; }
  .event-card-container .event-edit-wrap {
    padding: 1.5em 1.5em; }
    .event-card-container .event-edit-wrap .event-edit-fields {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .event-card-container .event-edit-wrap .event-edit-fields input {
        box-shadow: none !important;
        border: 1px solid rgba(139, 149, 158, 0.1) !important;
        font-size: 15px !important; }
        .event-card-container .event-edit-wrap .event-edit-fields input:hover, .event-card-container .event-edit-wrap .event-edit-fields input:focus, .event-card-container .event-edit-wrap .event-edit-fields input:active {
          border: 0.5px solid #000000 !important; }
      .event-card-container .event-edit-wrap .event-edit-fields .event-checkbox-background {
        padding: 1rem 0.5rem 0.5rem 0.8rem;
        background-color: #fbfbfb; }
      .event-card-container .event-edit-wrap .event-edit-fields .event-service-label {
        line-height: 12px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #8b959e;
        text-transform: uppercase;
        display: block;
        margin-bottom: 0.5rem; }
      .event-card-container .event-edit-wrap .event-edit-fields .custom-control-label {
        max-width: fit-content;
        flex-grow: 1;
        padding-top: 0.2rem;
        padding-left: 0.3rem;
        cursor: pointer;
        text-transform: none;
        letter-spacing: initial;
        font-weight: 400;
        font-size: 14px !important;
        color: #0c0c0c;
        position: relative;
        margin-bottom: 0;
        vertical-align: top; }
      .event-card-container .event-edit-wrap .event-edit-fields .value-fields {
        padding: 1rem 0.5rem 0.5rem 0.8rem;
        background-color: #fbfbfb;
        margin-right: 1rem; }
    .event-card-container .event-edit-wrap .event-notes {
      display: block;
      min-height: 100px;
      border: 0.5px solid transparent;
      background-color: #fbfbfb;
      border-radius: 2px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      padding: 0.375rem 0.75rem;
      font-size: 14px;
      font-weight: 400; }
  .event-card-container .no-data-wrap {
    min-height: 35vh !important; }

.event-newForm-container .event-checkbox-background {
  padding: 1rem 0.5rem 0.5rem 0.8rem;
  background-color: #fbfbfb;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }

.event-newForm-container .event-edit-wrap {
  padding: 1.5em 1.5em; }
  .event-newForm-container .event-edit-wrap .event-edit-fields {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    .event-newForm-container .event-edit-wrap .event-edit-fields input {
      box-shadow: none !important;
      border: 1px solid rgba(139, 149, 158, 0.1) !important;
      font-size: 15px !important; }
      .event-newForm-container .event-edit-wrap .event-edit-fields input:hover, .event-newForm-container .event-edit-wrap .event-edit-fields input:focus, .event-newForm-container .event-edit-wrap .event-edit-fields input:active {
        border: 0.5px solid #000000 !important; }
    .event-newForm-container .event-edit-wrap .event-edit-fields .event-service-label {
      line-height: 12px;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #8b959e;
      text-transform: uppercase;
      display: block;
      margin-bottom: 0.5rem; }
    .event-newForm-container .event-edit-wrap .event-edit-fields .custom-control-label {
      max-width: fit-content;
      flex-grow: 1;
      padding-top: 0.2rem;
      padding-left: 0.3rem;
      cursor: pointer;
      text-transform: none;
      letter-spacing: initial;
      font-weight: 400;
      font-size: 14px !important;
      color: #0c0c0c;
      position: relative;
      margin-bottom: 0;
      vertical-align: top; }

.event-newForm-container .new-event-form {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .event-newForm-container .new-event-form .event-service-label {
    line-height: 12px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #8b959e;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.5rem; }
  .event-newForm-container .new-event-form .custom-control-label {
    max-width: fit-content;
    flex-grow: 1;
    padding-top: 0.2rem;
    padding-left: 0.3rem;
    cursor: pointer;
    text-transform: none;
    letter-spacing: initial;
    font-weight: 400;
    font-size: 14px !important;
    color: #0c0c0c;
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }

.event-newForm-container .event-form-footer {
  display: flex;
  justify-content: end;
  column-gap: 10px;
  margin-bottom: 1em;
  margin: 1em 1em 1em;
  padding-right: 1.5em; }

@media (min-width: 768px) {
  .app-content .event-supportbar-gap {
    display: flex;
    width: auto !important; }
  .app-content .supportbar-wrap .custom-tabs-nav {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    background: rgba(139, 149, 158, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 0px !important;
    margin-top: 0.45em;
    white-space: nowrap;
    margin-bottom: 0em !important;
    border: none !important; }
    .app-content .supportbar-wrap .custom-tabs-nav .nav-link {
      background-color: transparent;
      color: rgba(139, 149, 158, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;
      padding: 7px 7px 14px 7px !important;
      height: fit-content !important;
      margin-top: 0.5em !important;
      z-index: 10; }
      .app-content .supportbar-wrap .custom-tabs-nav .nav-link:hover {
        color: rgba(139, 149, 158, 0.7); }
      .app-content .supportbar-wrap .custom-tabs-nav .nav-link.active {
        background-color: #ffffff;
        color: #323c47;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .app-content .supportbar-wrap .custom-tabs-nav.light-tabs {
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0; }
      .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none; }
        .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid #0c0c0c;
          box-shadow: none;
          color: #303030;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600; }
        .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link:focus {
          box-shadow: none; }
        .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
          margin-right: 25px; } }
      @media (min-width: 768px) and (max-width: 870px) {
        .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
          margin-right: 15px; } }
      @media (min-width: 768px) and (max-width: 810px) {
        .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
          margin-right: 10px; } }
      @media (min-width: 768px) and (max-width: 780px) {
        .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link {
          font-size: 13px; }
          .app-content .supportbar-wrap .custom-tabs-nav.light-tabs .nav-link:not(:last-child) {
            margin-right: 5px !important; } }

@media (min-width: 768px) and (max-width: 992px) {
  .app-content .supportbar-wrap .custom-tabs-nav {
    flex-wrap: nowrap;
    display: flex;
    white-space: nowrap;
    width: 100% !important;
    justify-content: end;
    margin-bottom: 0; }
    .app-content .supportbar-wrap .custom-tabs-nav .nav-link {
      font-size: 13px; }
      .app-content .supportbar-wrap .custom-tabs-nav .nav-link:not(:last-child) {
        font-size: 13px;
        margin-right: 5px; } }

.event-edit-button {
  background-color: rgba(165, 151, 124, 0.15) !important;
  border: none;
  color: #a79779 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 0px 30px;
  height: 2.2rem; }
  .event-edit-button:active, .event-edit-button:focus, .event-edit-button:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.4) !important; }

.carousel-content {
  display: flex;
  justify-content: space-between; }
  .carousel-content .text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-basis: 60%;
    padding: 20px; }

.image-content {
  flex-basis: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.image-placeholder {
  width: 100%;
  padding-top: 56.25%;
  background-color: #ddd;
  border: 1px solid #ccc; }

.carousel-indicators-container {
  text-align: center;
  flex-basis: 60%; }

.my-carousel-container {
  display: flex;
  width: 100%;
  border: 1.5px solid rgba(139, 149, 158, 0.2);
  border-radius: 6px;
  background-color: #ffffff;
  margin-bottom: 1rem; }

.carousel-indicators-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center; }

.carousel-indicator-dot {
  width: 9px;
  height: 9px;
  margin: 0 5px;
  background-color: #ebebeb;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block; }

.carousel-indicator-dot.active {
  background-color: #a79779; }

.carousel-control-prev,
.carousel-control-next {
  width: 0%; }

.custom-arrow {
  background-image: none;
  color: #a79779;
  font-weight: bold;
  height: 20px; }

.next-arrow {
  height: 18px;
  width: 18px;
  transform: rotate(180deg);
  cursor: pointer;
  margin-left: 0.4rem; }

.prev-arrow {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-right: 0.4rem; }

.learn_more_img {
  width: 15px;
  height: 15px;
  margin-top: 0.5em;
  margin-left: 0.5em; }

.carousel {
  width: 100%; }
  .carousel .slide {
    width: 100%; }

.hide-dataGrid-toggle .InovuaReactDataGrid__column-header__menu-tool {
  display: none; }

.supportbar-b2c {
  height: 60px;
  width: 100%;
  background: white;
  display: flex;
  z-index: 9;
  padding-left: 6.25rem;
  padding-top: 1.2rem; }
  .supportbar-b2c .supportbar-img {
    width: 8rem; }

.b2c-page {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 100vh; }
  @media (min-width: 360px) {
    .b2c-page {
      max-width: unset; } }
  @media (min-width: 480px) {
    .b2c-page {
      max-width: unset; } }
  @media (min-width: 768px) {
    .b2c-page {
      max-width: 720px; } }
  @media (min-width: 991px) {
    .b2c-page {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .b2c-page {
      max-width: 1110px; } }
  @media (min-width: 1200px) {
    .b2c-page {
      max-width: 1140px; } }
  @media (min-width: 1440px) {
    .b2c-page {
      max-width: 1140px; } }
  .b2c-page .b2c-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem; }
    .b2c-page .b2c-top-bar .rolzo-logo {
      width: 130px;
      height: 16.5px;
      cursor: pointer; }
    .b2c-page .b2c-top-bar .currency-select {
      display: flex;
      align-items: baseline;
      justify-content: end;
      cursor: pointer; }
    .b2c-page .b2c-top-bar .currency-logo {
      width: 14px;
      height: 10.6px; }
    .b2c-page .b2c-top-bar .currency-label {
      margin-left: 5px;
      font-size: 16px; }
    .b2c-page .b2c-top-bar .currency-arrow {
      padding-left: 2px;
      top: 3px;
      position: relative; }
    .b2c-page .b2c-top-bar .show {
      transform: rotate(180deg);
      padding-right: 2px;
      padding-left: 0; }
    .b2c-page .b2c-top-bar .currency-dropdown {
      width: 66px;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      background-color: #fefefe;
      box-sizing: border-box;
      cursor: pointer;
      float: left;
      font-size: 14px;
      font-weight: 400;
      outline: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
      -webkit-tap-highlight-color: transparent;
      z-index: 1; }
    .b2c-page .b2c-top-bar .currency-item {
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding-bottom: 2px;
      width: 100%; }
    .b2c-page .b2c-top-bar .first {
      padding-top: 4px; }
    .b2c-page .b2c-top-bar .currency-item:hover,
    .b2c-page .b2c-top-bar .currency-item.focus,
    .b2c-page .b2c-top-bar .currency-item.selected.focus {
      background-color: #f6f6f6; }
  .b2c-page .b2c-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem; }
  .b2c-page .map-view {
    margin-top: 30px;
    margin-bottom: 30px;
    height: 450px;
    width: 100%; }
  .b2c-page .left-container {
    flex: 0 0 40%; }
  .b2c-page .right-container {
    flex: 0 0 57%; }
    .b2c-page .right-container .vehicle-card {
      flex-wrap: nowrap;
      max-width: 635px;
      padding: 0px 30px 15px 25px;
      margin: 6.5px 0;
      padding-right: 30px;
      flex-direction: row;
      border-radius: 2px;
      box-shadow: none;
      overflow: hidden;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
      .b2c-page .right-container .vehicle-card .active {
        box-shadow: none;
        border: 1px solid #0c0c0c; }
    .b2c-page .right-container .vehicle-img-wrap {
      max-width: 210px;
      height: 100%;
      padding: 25px 0 52px 0px;
      margin: auto;
      width: 230px;
      flex-shrink: 0;
      display: flex;
      justify-content: center; }
    .b2c-page .right-container .vehicle-detail-card .card-body {
      padding: 1.25rem !important; }
    .b2c-page .right-container .card-body {
      padding-left: 6px;
      flex: 1 1 auto;
      min-height: 1px;
      width: 100%; }
    .b2c-page .right-container .card-action {
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      padding: 52px 0 36px; }
    .b2c-page .right-container .vehicle-price-width {
      max-width: fit-content;
      white-space: nowrap; }
    .b2c-page .right-container .vehicle-title-limit {
      /* Above 1540px and up to 1570px */ }
      @media (min-width: 1520px) and (max-width: 1540px) {
        .b2c-page .right-container .vehicle-title-limit {
          width: 12rem;
          text-overflow: ellipsis;
          overflow: hidden; } }
      @media (min-width: 1541px) and (max-width: 1570px) {
        .b2c-page .right-container .vehicle-title-limit {
          width: 13rem;
          text-overflow: ellipsis;
          overflow: hidden; } }
      @media (min-width: 1205px) and (max-width: 1500px) {
        .b2c-page .right-container .vehicle-title-limit {
          width: 12rem !important;
          text-overflow: ellipsis;
          overflow: hidden; } }
      @media (max-width: 1205px) {
        .b2c-page .right-container .vehicle-title-limit {
          width: 12rem !important;
          text-overflow: ellipsis;
          overflow: hidden; } }
    .b2c-page .right-container .passenger-name-div {
      max-width: none; }
    .b2c-page .right-container .capacity-div {
      max-width: none; }
    .b2c-page .right-container .phone-number-div {
      max-width: none; }
    .b2c-page .right-container .react-tel-input .form-control {
      font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .b2c-page .right-container .service-card {
      margin: 6.5px 0;
      padding: 30px;
      border-radius: 2px;
      border: 1.5px solid rgba(139, 149, 158, 0.1);
      overflow: hidden;
      width: auto; }
      .b2c-page .right-container .service-card .card-body {
        padding: 0 !important;
        padding-left: 6px !important; }
    .b2c-page .right-container .sub-header {
      margin-bottom: 24px !important; }
      .b2c-page .right-container .sub-header.no-margin {
        margin-bottom: 0px !important; }
    .b2c-page .right-container .sub-header-email {
      font-size: 20px;
      margin-bottom: 0.75rem !important; }
    .b2c-page .right-container .form-block {
      padding: 2.25rem 0 2.25rem 0; }
    .b2c-page .right-container .form-row {
      width: 100%;
      max-width: none; }
      .b2c-page .right-container .form-row .form-benefits {
        width: 100%;
        max-width: none; }
      .b2c-page .right-container .form-row .form-benefits-carRental {
        width: 89%;
        max-width: none; }
    .b2c-page .right-container .passenger-information-special {
      padding: 9px 0px 0px 0px !important; }
    .b2c-page .right-container .messageContainer .message {
      background-color: #ffffff;
      background-position: top 0.8px left 0px;
      background-size: 20px 15px;
      font-size: 14px;
      font-weight: 400;
      background-repeat: no-repeat;
      padding-left: 18px;
      border-radius: 4px; }
  .b2c-page .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 4rem;
    margin: 1rem 0 1rem 0; }
  .b2c-page .step {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    transition: 0.5s;
    border-radius: 4px;
    user-select: none; }
  .b2c-page .step:first-child {
    padding-left: 0; }
  .b2c-page .step:last-child {
    padding-right: 0; }
  .b2c-page .step .label {
    color: rgba(139, 149, 158, 0.5); }
  .b2c-page .step.active .label {
    color: #0c0c0c; }
  .b2c-page .step.completed .label {
    color: #0c0c0c; }
  .b2c-page .step.completed .label:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px; }
  .b2c-page .step.active .label:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px; }
  .b2c-page .step .icon {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 12px;
    border: 1px solid rgba(139, 149, 158, 0.5);
    color: rgba(139, 149, 158, 0.5);
    margin-right: 0.5rem; }
  .b2c-page .step.active .icon {
    border: 1px solid #0c0c0c;
    color: #0c0c0c; }
  .b2c-page .step.completed .icon {
    border: 1px solid #0c0c0c;
    background-color: #0c0c0c;
    color: white; }
  .b2c-page .step-divider {
    flex: 1;
    border-top-style: solid;
    border-color: #bdbdbd;
    border-top-width: 1px; }
  .b2c-page .step-divider.completed {
    border-color: #0c0c0c; }
  .b2c-page .remove-button-stops-b2c {
    position: absolute;
    top: 30.5px;
    right: -22px;
    height: 23px;
    width: 23px;
    border-radius: 18px;
    cursor: pointer; }

.success-msg-modal-b2c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 20px;
  background: transparent;
  overflow-y: auto; }
  .success-msg-modal-b2c img {
    display: block;
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 0 auto 35px auto; }
  .success-msg-modal-b2c .success-msg-header {
    font-size: 24px;
    color: #0c0c0c;
    text-align: center;
    font-weight: 400; }
  .success-msg-modal-b2c .success-msg-description {
    font-size: 15px;
    color: rgba(99, 109, 119, 0.7);
    text-align: center;
    white-space: pre-wrap;
    line-height: 26px; }
  .success-msg-modal-b2c .btn {
    text-align: center;
    font-weight: 500;
    font-size: 15px; }
  .success-msg-modal-b2c .content-wrap {
    display: flex;
    max-width: 531px;
    flex-direction: column;
    justify-content: center; }
  .success-msg-modal-b2c .content-div {
    display: flex;
    justify-content: center; }

@media (max-width: 768px) {
  .success-msg-modal-b2c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 100%;
    text-align: center;
    padding: 20px;
    background: transparent;
    overflow-y: auto; }
    .success-msg-modal-b2c img {
      display: block;
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin: 0 auto 35px auto; }
    .success-msg-modal-b2c .success-msg-header {
      font-size: 24px;
      color: #0c0c0c;
      text-align: center;
      font-weight: 400; }
    .success-msg-modal-b2c .success-msg-description {
      font-size: 15px;
      color: rgba(99, 109, 119, 0.7);
      text-align: center;
      white-space: pre-wrap;
      line-height: 26px; }
    .success-msg-modal-b2c .btn {
      text-align: center;
      font-weight: 500;
      font-size: 15px; }
    .success-msg-modal-b2c .content-wrap {
      display: flex;
      max-width: 531px;
      flex-direction: column;
      justify-content: center; }
    .success-msg-modal-b2c .content-div {
      display: flex;
      justify-content: center; } }

.continue-round {
  border-radius: 20px; }

.max-width-fit-content {
  max-width: fit-content; }

.b2c-empty-search {
  padding: 55px 45px;
  border-radius: 2px;
  margin-top: 6.5px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1); }
  .b2c-empty-search h5 {
    margin-bottom: 0.75rem;
    font-size: 20px;
    color: #303030;
    font-weight: normal; }
  .b2c-empty-search .b2c-empty-description {
    font-size: 15px;
    font-weight: 400;
    color: #8b959e;
    white-space: pre-wrap; }
  .b2c-empty-search .b2c-empty-info {
    font-size: 16px;
    margin-top: 1.2rem;
    color: #303030; }

.b2c-form-container {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important; }
  .b2c-form-container.passenger-info {
    padding-top: 1.625rem !important; }
  .b2c-form-container .form-block .form-row {
    width: 82%;
    max-width: none; }
    .b2c-form-container .form-block .form-row .form-benefits {
      width: 100%;
      max-width: none; }
  .b2c-form-container .form-block .form-benefits {
    width: 100%;
    max-width: none; }
  .b2c-form-container .container {
    max-width: none; }
  .b2c-form-container .sub-header {
    margin-bottom: 24px; }

@media (max-width: 768px) {
  .b2c-container {
    display: block !important; }
  .b2c-page .steps {
    margin: 1rem 1rem 4rem 1rem !important; }
  .b2c-page .step {
    flex-direction: column;
    width: calc(100vw - 100%);
    text-align: center;
    position: relative;
    width: calc(100vw - 93%); }
  .b2c-page .step-divider {
    margin: 0 -18px; }
  .b2c-page .step:first-child {
    padding-left: 0.5rem !important; }
  .b2c-page .step:last-child {
    padding-right: 0.5rem !important; }
  .b2c-page .step .label {
    position: absolute;
    top: 50px;
    left: 0;
    text-align: center;
    font-size: 12px; }
  .b2c-page .step .icon {
    margin-right: 0 !important; }
  .child-seat {
    display: flex;
    justify-content: space-between; }
  .left-container .booking-preview-info .booking-preview-info-section {
    padding: 20px 24px 20px 24px !important; }
  .right-container .vehicle-img-wrap {
    padding: 5px 10px 0 !important; }
  .right-container .card-action {
    padding: 0 !important; }
  .right-container .react-tel-input .form-control {
    font-family: "AvenirNext", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .right-container .booking-preview-info .booking-preview-info-section {
    padding: 20px 24px 20px 24px !important; }
  .right-container .form-row {
    width: 100% !important; }
  .b2c-empty-search {
    padding: 24px !important; }
    .b2c-empty-search .submit-btn {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 100% !important; } }

.margin-dot-green {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #417505;
  margin-right: 5px;
  margin-bottom: 0.1rem; }

.margin-dot-blue {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3140b0;
  margin-right: 5px;
  margin-bottom: 0.1rem; }

.margin-dot-yellow {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f8ce4c;
  margin-right: 5px;
  margin-bottom: 0.1rem; }

.margin-dot-orange {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffbf56;
  margin-right: 5px;
  margin-bottom: 0.1rem; }

.margin-dot-red {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d0021b;
  margin-right: 5px;
  margin-bottom: 0.1rem; }

.export-button {
  padding-right: 19px; }
  .export-button .download-link {
    color: #a79779;
    font-size: 14px;
    font-weight: 600; }
    .export-button .download-link .pdf-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px; }
    .export-button .download-link:focus, .export-button .download-link:hover {
      text-decoration: none;
      outline: none;
      box-shadow: none; }

.download-button {
  margin-top: 4px;
  margin-left: 1.5rem; }
  .download-button .download-link {
    color: #a79779;
    font-size: 14px;
    font-weight: 600; }
    .download-button .download-link .pdf-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px; }
    .download-button .download-link:focus, .download-button .download-link:hover {
      text-decoration: none;
      outline: none;
      box-shadow: none; }

.badge.badge-outlined.badge-payment_due-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #f5a623;
  border-color: #f5a623;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-billable-admin,
.badge.badge-non_billable-admin,
.badge.badge-declined-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #d0021b;
  border-color: #d0021b;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-paid-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #417505;
  border-color: #417505;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-refunded-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #636d77;
  border-color: #636d77;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-disputed-admin {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: #635bff;
  border-color: #635bff;
  color: white;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase; }

.badge.badge-outlined.badge-payment_due-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba(245, 166, 35, 0.1);
  border-color: #f5a623;
  color: #f5a623;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important; }

.badge.badge-billable-client,
.badge.badge-non_billable-client,
.badge.badge-transaction_declined-client,
.badge.badge-declined-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba(208, 2, 27, 0.1);
  border-color: #d0021b;
  color: #d0021b;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important; }

.badge.badge-outlined.badge-paid-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba(65, 117, 5, 0.1);
  border-color: #417505;
  color: #417505;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important; }

.badge.badge-outlined.badge-refunded-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba(99, 109, 119, 0.1);
  border-color: #636d77;
  color: #636d77;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important; }

.badge.badge-outlined.badge-disputed-client {
  font-size: 12px;
  border-radius: 2px;
  font-weight: bolder !important;
  white-space: nowrap;
  background-color: rgba(99, 91, 255, 0.1);
  border-color: #635bff;
  color: #635bff;
  padding: 0.4rem 0.5rem 0.3rem 0.5rem;
  text-transform: uppercase;
  margin: 0px !important; }

.payment-block-height {
  height: 44px !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.2); }

.payment-list {
  display: flex;
  flex-direction: column; }

.payment-item {
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid rgba(139, 149, 158, 0.2); }

.payment-amount-badge {
  display: flex;
  align-items: center;
  white-space: nowrap; }

.payment-amount {
  font-weight: bold; }

.payment-badge {
  display: flex;
  justify-content: flex-end;
  min-width: 110px; }

.payment-service-badge {
  display: flex;
  flex-direction: column; }

.total {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: bold; }

@media (max-width: 768px) {
  /* Adjust to match Bootstrap's md breakpoint */
  .payment-badge {
    display: flex;
    justify-content: flex-start;
    min-width: 110px;
    margin-top: 12px; }
  .payment-amount-badge {
    display: flex;
    align-items: flex-start;
    white-space: nowrap; } }

.header-charges {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-bottom: 50px; }

.sub-header-charges {
  font-size: 20px;
  font-weight: lighter;
  width: 100%;
  margin-bottom: 0px; }

.drop-down-charges {
  float: right;
  padding-right: 10px; }
