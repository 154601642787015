body {
  font-family: $font-family-base;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

pre {
  font-family: $font-family-base;
}

::-webkit-full-page-media,
_:future,
:root body,
input {
  caret-color: #000;
  letter-spacing: -1px;
}

::-webkit-full-page-media,
_:future,
:root input.form-control {
  caret-color: #000;
  letter-spacing: -1px;
}
:root input.form-control:disabled {
  background-color: rgba($color: $dark-grey, $alpha: 0.1) !important;
}

::-webkit-full-page-media,
_:future,
:root input.form-control:hover,
input.form-control:focus {
  border: none !important;
  outline-color: #0c0c0c !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}

::-webkit-full-page-media,
_:future,
:root .vehicle-card .card-body .sub-title {
  letter-spacing: -0.2px !important;
}

::-webkit-full-page-media,
_:future,
:root label {
  letter-spacing: 0px !important;
}

::-webkit-full-page-media,
_:future,
:root .btn-anchor {
  letter-spacing: -1px !important;
}

::-webkit-full-page-media,
_:future,
:root .btn-brown {
  letter-spacing: -1px !important;
}

:root .btn-black {
  background-color: $dark-grey;
}

:root .btn-green {
  letter-spacing: -1px !important;
  color: #212529;
  background-color: $success;
  border-color: $success;
}

* {
  box-sizing: border-box;
}

.active-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: $red;

  &.active {
    background-color: $green;
  }
}

.monitoring-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  background-color: $red;

  &.high {
    background-color: $danger;
  }

  &.medium {
    background-color: $warning;
  }

  &.low {
    background-color: $success;
  }
}

.horizontal-images-list {
  height: 100px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.add-link {
  .add-link-icon {
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
}
.add-link {
  .add-link-icon-svg {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}

.change-link {
  font-size: 12px;
}

.profile-default-logo {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($dark-grey, 0.25);
  //border: 1px solid rgba($black, 0.25);
  border-radius: 50%;
  color: $dark-grey;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 3px 0 3px;
}

.profile-default-logo-top {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($dark-grey, 0.25);
  border-radius: 50%;
  color: $dark-grey;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 3px 0 3px;
}
.form-group {
  position: relative;
}
.company-logo-top {
  border-radius: 50%;
  height: 45px !important;
  width: 45px !important;
  border: 1px solid rgba($dark-grey, 0.25);
}

.shared-users-default {
  display: inline-flex;
  position: absolute;
  right: 45px;
  top: 20px;
}

.shared-users-default-logo {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($black, 1);
  border-radius: 50%;
  color: $black;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  // padding: 2px 3px 0 8px;
  padding: 4px 3px 0 3px;
}

.chat-users-default-logo {
  height: 34px;
  width: 34px;
  min-width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($black, 1);
  border-radius: 50%;
  color: $black;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  // padding: 2px 3px 0 8px;
  padding: 4px 3px 0 3px;
}

.default-logo {
  height: 34px;
  width: 34px;
  min-width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($dark-grey, 0.25);
  //border: 1px solid rgba($black, 0.25);
  border-radius: 50%;
  color: $dark-grey;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 3px 0 3px;
}

.credit-card-img {
  height: 30px;
  width: auto;
}

.form-row {
  .credit-card-img {
    margin-top: 28px;
  }
}

.radius-eligibility {
  margin-bottom: 40px;
}

.create-rate-card {
  margin-bottom: 20px;
  cursor: pointer;
}

.sub-header {
  font-size: 20px;
  font-weight: lighter;
  margin-bottom: 50px;
}

.sub-header-quote {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 18px;
  font-weight: lighter;
}

.sub-header-rates {
  font-size: 20px;
  font-weight: lighter;
  margin-bottom: 10px;
}

.small-header {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: $dark-grey;
}

hr {
  width: 100%;
  border-color: rgba($dark-grey, 0.25);
}

.tooltip {
  font-size: 12px;
}

.height150 {
  height: 50px;
  max-height: 50px;
  overflow: hidden;
}

.device-icons {
  width: 10px;
  margin-top: -5px;
  margin-right: 10px;
}

.modal-padding {
  padding-top: 30px;
}

.hidden {
  display: none;
}

.booking-notes {
  white-space: pre-wrap;
  word-break: break-all;
}

.test-modal {
  .modal-header {
    border-bottom: none !important;

    .modal-title {
      width: 100%;

      .close-btn-wrap {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .check-btn-wrap {
        width: 100%;

        img {
          width: 60px;
        }
      }

      .btn-link {
        //width: 60px;
        //height: 60px;
        margin-right: 5%;
        margin-top: 3%;

        img {
          width: 60px;
        }
      }
    }

    padding-bottom: 0px;
  }

  .title {
    font-size: 20px;
    color: $black;
    text-align: center;
    font-weight: 400;
    margin-top: 2%;
    padding-bottom: 2%;
  }

  .modal-footer {
    border-top: none !important;
    justify-content: center !important;
    padding-bottom: 50px !important;

    .select-btn {
      margin-top: 0px;
      font-size: 14px;
      min-width: 175px;
      height: 40px;
    }
  }

  .modal-description {
    font-size: 14px;
    color: $dark-grey-2;
    text-align: center;
  }
  .modal-description-draft {
    font-size: 14px;
    color: $dark-grey-2;
    text-align: center;
    margin: 0px;
  }

  .SharedBooking-modal-description {
    font-size: 15px;
    color: $dark-grey;
    text-align: center;
  }
}

.filter-flex-23-9 {
  flex: 0 0 23.9% !important;
}
.vehicle-options-drop {
  width: 149px !important;
}
////////////@Clear flight tracker

span.slideInner span {
  position: absolute;
  display: block;
  top: 30px;
  right: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  img {
    width: 10px;
    height: 10px;
  }
}

span.slideInner input {
  padding-right: 16px;
  box-sizing: border-box;
}

.stop-remove {
  font-size: 11.5px !important;
  padding-bottom: 3.5px !important;
  line-height: 1px !important;
  padding-left: 3px !important;
}

span.slideInnerDropOff span {
  position: absolute;
  display: block;
  top: 30px;
  right: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  img {
    width: 10px;
    height: 10px;
  }
}

span.slideInnerDropOff input {
  padding-right: 16px;
  box-sizing: border-box;
}

.minWith6rem {
  min-width: 6.3rem;
}

.minWith7rem {
  min-width: 7rem;
}

.minWith8rem {
  min-width: 8rem;
}

.minWith10rem {
  min-width: 10rem;
}

.minWith13rem {
  min-width: 13rem;
}

.minWith14rem {
  min-width: 14rem;
}

.minWith15rem {
  min-width: 15rem;
}

.displayBlock {
  display: block;
}

.toUpperCase {
  text-transform: uppercase;
}

.payment-field {
  border: none !important;
  outline-color: #0c0c0c !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}

.payment-field-right {
  width: 49%;
}

.payment-field-accepted {
  background-color: rgba($dark-grey, 0.1) !important;
  border: none !important;
}

.payment-field-mismatch {
  border: none !important;
  outline-color: $danger !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}

.payment-field-dispatch {
  border: none !important;
  outline-color: #0c0c0c !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}
.company-name-dispatch {
  font-weight: bold;
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
  white-space: initial;
  padding-top: 0 !important;
  text-align: left;
  padding: 10px;
  padding-bottom: 25px;
  padding-left: 0;

  .invisible {
    visibility: hidden;
  }
}

.payment-field-hover {
  border: none !important;
}
.marginBottom-4 {
  margin-bottom: 40px;
}
.warning-banner {
  background-color: rgba(#000, 0.05);
  border: 1px solid rgba(#000, 1);
  border-radius: 2px;
  height: auto;
  display: flex;

  .imageContainer {
    float: left;
    align-self: center;
    margin-left: 15px;
    img {
      width: 17px;
    }
  }

  .warningContainer {
    font-size: 14px;
    margin: 15px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }
}

.warning-banner-red {
  background-color: rgba($danger, 0.1);
  border: 1px solid rgba($danger, 1);
  border-radius: 2px;
  height: auto;
  display: flex;

  .imageContainer {
    float: left;
    align-self: center;
    margin-left: 15px;
    img {
      width: 17px;
    }
  }

  .warningContainer {
    font-size: 14px;
    margin: 15px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }

  .warningContainer-dispute {
    font-size: 14px;
    margin: 15px 10px 15px;
    padding-top: 0.2rem;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      // font-weight: 800 !important;
      text-shadow: 0.3px 0 $black;
    }
  }
}

.warning-banner-dark {
  background-color: rgba(#000, 0.05);
  border: 1px solid rgba(#000, 1);
  border-radius: 2px;
  height: auto;
  display: flex;

  .imageContainer {
    float: left;
    align-self: center;
    margin-left: 15px;
    img {
      width: 17px;
    }
  }

  .warningContainer {
    font-size: 14px;
    margin: 15px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 500;
    }
  }
}

.goldenBorder {
  border: 1px solid $brown !important;
}

.quote-continue-btn {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.error-msg {
  color: red;
  font-size: 14px;
  margin: 0;
}
// .error-msg-client {
//   color: $danger;
//   font-size: 12px;
//   margin: 0;
//   text-align: right;
// }
.error-msg-client {
  color: $danger;
  font-size: 12px;
  margin: 0;
  margin-top: 1rem;
  width: 85%;
}
.error-msg-partner {
  color: red;
  font-size: 12px;
  text-align: end;
  margin-bottom: 1%;
  margin-right: 6%;
}
.masqurade-banner {
  height: 40px;
  bottom: 0%;
  width: 10%;
  background-color: #8b959e;
  opacity: 1;
  position: fixed;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, 5%);
  margin: 0 auto;
  .inner-masqurader {
    display: flex;
    justify-content: center;
    span {
      color: $white;
      font-size: 14px;
      margin-right: 15px;
      padding-top: 1px;
    }
    .download-link {
      color: $white;
      font-size: 14px;
      text-decoration-line: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.useless-class {
  .paymentsRow {
    border-bottom: 1px solid rgba($dark-grey, 0.2);
    margin-left: 0.2rem;
    margin-right: 0.4rem;
  }
  .NoBorderBottom {
    border-bottom: none !important;
  }
  .paymentsLinks {
    display: block;
    font-size: 16px;
    margin-bottom: 0.8rem !important;
    margin-top: 0.8rem !important;
    color: $black !important;
    padding-left: 0% !important;
  }
}

.driverBottomMenu {
  border: 0px;
  margin: 0px;
  border-left: 1px solid rgba($dark-grey, 0.2);
  height: 200px;
}
.tableWidth {
  max-width: 8rem;
  white-space: pre;
}
.tableWidthQuotes {
  max-width: 6rem;
}
.smallWidthQuotes {
  max-width: 4rem;
}
.smallTableWidth {
  max-width: 7rem;
}

.clock-img {
  width: 19px !important;
}
.location-img {
  width: 17px !important;
  margin-left: 2px;
  height: 24px;
  margin-top: 3px;
}
.partner-duration {
  margin-left: 13px !important;
}
.partner-arrival {
  margin-left: 17px !important;
}
.pickup-block {
  display: flex;
  margin-top: 1rem;

  img {
    width: 11px !important;
    height: 11px;
    margin-left: 4px;
    margin-top: 5px;
  }
}

.pdf-board {
  color: $brown;
  margin-left: 1.5rem !important;
  font-weight: 900;
  text-shadow: 0.25px 0 $brown;
}

.driver-booking-container {
  margin: 5%;

  .driver-booking-card {
    margin-top: 1.5rem;
  }

  .driver-booking-header {
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 1em 1em;
    margin-top: 1em;
    padding-bottom: 0.5em;
    border-bottom: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .driver-title {
      text-align: center;
      text-transform: uppercase;
    }
    .driver-title-partner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .driver-border-bottom {
    border-bottom: 1.5px solid rgba($dark-grey, 0.2) !important;
  }

  .card-top-indicator {
    top: 1.3em;
    height: 6px;
    position: relative;
    background-color: #417505;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .driver-booking-body {
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 1em 1em;
    font-size: 16px;
    border-bottom: none;

    .passenger-name {
      font-size: 22px;
    }

    .date {
      margin-top: 1.5rem;

      span {
        margin-left: 6px;
      }
    }

    .date-daily {
      margin-top: 1.5rem;
      display: flex;

      span {
        margin-left: 10px;
      }
    }

    .vehicle {
      margin-top: 1rem;

      span {
        margin-left: 10px;
      }
    }
    .vehicle-drop {
      margin-top: 1rem;
      display: flex;

      span {
        margin-left: 14px;

        display: block;
      }
    }
    img {
      width: 22px;
      margin-bottom: 5px;
    }

    .passenger-details {
      margin-top: 0.5rem;

      span {
        margin-left: 5px;
      }
    }

    .to-from-chauffeur {
      margin-top: 1rem;
      display: flex;

      span {
        margin-left: 15px;
        display: block;
      }
      img {
        width: 16px;
        height: 20px;
        margin-left: 2px;
        margin-top: 2px;
      }
    }
    .stop-partner {
      margin-top: 1rem;
      display: flex;

      span {
        margin-left: 15px;
        display: block;
      }
      img {
        width: 14px;
        height: 14px;
        margin-top: 12px;
        margin-left: 3px;
      }
    }

    .flight {
      margin-top: 1rem;
      span {
        margin-left: 12px;
      }
      img {
        width: 21px;
        transform: rotate(90deg);
        margin-bottom: 2px !important;
      }
    }
    .airport {
      margin-top: 1rem;
      span {
        margin-left: 12px;
      }
      img {
        width: 21px;
        margin-bottom: 2px !important;
      }
    }
    .train {
      margin-top: 1rem;
      span {
        margin-left: 12px;
      }
      img {
        width: 18px;
        height: 23px;
        margin-left: 1.5px;
        margin-bottom: 0px !important;
      }
    }
    .nameboard {
      margin-top: 1rem;
      span {
        margin-left: 11px;
        color: $brown;
        font-weight: 900;
        text-shadow: 0.25px 0 $brown;
      }
      img {
        padding-top: 2px;
        width: 23px;
        height: 17px;
      }
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    align-items: center;
    margin-right: 0.8rem;
    width: 2rem;
    padding-top: 2.5%;
    padding-left: 5%;
    height: 65px;
    max-height: 65px;
  }

  .driver-booking-cta {
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 0.5em 0.5em;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    img {
      width: 10%;
      margin: 0.5em;
    }
  }

  .driver-booking-price {
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 0.5em 0.5em;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .driver-booking-price-card {
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 0.5em 0.5em;
    font-size: 16px;
    font-weight: 900;
    display: flex;
    justify-content: center;
  }

  .partner-location-container {
    display: flex;
    column-gap: 1em;

    .partner-indicator {
      display: flex;
      flex-direction: column;
      margin-left: 7px;

      .circle-empty {
        height: 8px;
        width: 8px;
        border: 1.5px solid #8b959e;
        border-radius: 50%;
        display: inline-block;
        z-index: 0;
        background-color: white;
      }
      .circle-filled-pu {
        height: 8px;
        width: 8px;
        background-color: #8c969f;
        border-radius: 50%;
        display: inline-block;
        z-index: 0;
      }
      .circle-filled {
        height: 8px;
        width: 8px;
        background-color: $black;
        border-radius: 50%;
        display: inline-block;
        z-index: 0;
      }
      .dotted-container {
        position: relative;
        width: 8px;
        height: 56px;
        overflow: hidden;
      }
      .dotted-container-stop {
        position: relative;
        width: 8px;
        height: 85px;
        overflow: hidden;
        top: 6px;
        z-index: -1;
      }
      .dotted-line {
        transform: rotate(90deg);
        position: absolute;
        top: -9999px;
        left: -9999px;
        right: -9999px;
        bottom: -9999px;
        margin: auto;
      }
    }

    .partner-fields {
      width: 100%;
      margin-left: 3px;

      p:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .partner-countdown {
    span:nth-child(odd) {
      font-size: 18px;
      font-weight: 400;
      padding-right: 1px;
    }
    span:nth-child(even) {
      font-size: 10px;
      font-weight: 500;
      padding-right: 10px;
    }
    span:last-child {
      padding-right: 0;
    }
  }

  .tabs-container {
    .custom-tabs-nav-driver {
      background: rgba($dark-grey, 0.15);
      padding: 7px 15px;
      border-radius: 4px;
      font-size: 13px;

      .nav-link {
        background-color: transparent;
        color: rgba($dark-grey, 0.5);
        font-weight: 500;
        padding: 9px 18px;
        border-radius: 3px;
        opacity: inherit !important;

        &:hover {
          color: rgba($dark-grey, 0.7);
        }

        &.active {
          background-color: $white;
          color: $dark-3;
          box-shadow: $default-shadow;
        }
      }

      &.light-tabs {
        border-bottom: 1px solid rgba($dark-grey, 0.3);
        border-radius: 0;
        width: 100%;
        max-width: 1000px;
        background: transparent;
        font-size: 15px;
        padding: 0;
        .nav-link {
          background: transparent;
          border-radius: 0;
          font-size: 15px;
          font-weight: 700;
          color: #8b959e;
          opacity: 0.55;
          padding: 7px 7px 0px 7px;
          border: none;

          &.active {
            background: transparent;
            border: none;
            border-bottom: 2px solid $black;
            box-shadow: none;
            color: $dark-2;
            opacity: 1;
            margin-bottom: -2px;
            font-weight: 700;
            font-size: 15px;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(:last-child) {
            margin-right: 24px;
          }
        }
      }
    }

    .card-body {
      padding: 0;
      padding-left: 6px;
      label.expand-title {
        margin-bottom: 10px;
        font-size: 10px;
      }
      label.expand-title-service {
        font-size: 10px;
      }
      p {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }

  .driver-info-layout {
    display: flex;
    justify-content: space-between;
    // margin-top: 2rem;
    width: calc(100% - 10rem);

    .driver-info-column {
      width: 49%;
    }
  }

  .driver-info-layout-mobile {
    margin-top: 2rem;
  }

  .driver-info-layout-banner {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: calc(100% - 10rem);

    .driver-info-column {
      width: 49%;
    }
  }

  .driver-service-card {
    border-radius: 10px;
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 1.5em 1.5em;
    font-size: 15px;
    margin-top: 1em;

    .driver-service-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px;

      .select-title {
        font-size: 15px;
        font-weight: 700;
      }
    }
    .driver-service-card-header-app {
      display: flex;
      flex-direction: column;
      font-weight: bold;

      img {
        width: 8%;
        margin-bottom: 1rem;
      }
    }

    .name-board {
      display: flex;
      justify-content: end;
      margin-top: 5%;
    }

    .driver-docs-pending {
      margin-bottom: 0.5em;
      margin-top: 2em !important;
      text-align: end;
      font-weight: 700;

      span {
        color: $warning;
      }
    }

    .partner-app-para {
      margin-top: 0.5rem;
    }

    .caution {
      color: $red;

      .caution-symbol {
        border: 1px solid $red;
        padding: 2px 5px 0px 5px;
        border-radius: 50%;
        font-size: small;
        margin-right: 1em;
      }
    }

    .driver-service-card-body {
      margin-top: 1.5em;

      div:not(:first-child) {
        margin-top: 0.5em;
      }
    }

    .driver-service-card-body-container {
      margin-bottom: 50px;
      margin-top: 20px;

      .step {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 20px;
      }

      .v-stepper {
        position: relative;
      }

      .update-circle-filled {
        position: absolute;
        height: 8px;
        width: 8px;
        background-color: $black;
        border-radius: 50%;
        display: inline-block;
      }

      .update-circle {
        position: absolute;
        height: 8px;
        width: 8px;
        border: 2px solid black;
        border-radius: 50%;
        display: inline-block;
      }

      .vertical-filled {
        border: 0;
        border-left: 1px solid $black;
        height: 100%;
        margin-top: 14px;
        margin-bottom: 0;
        position: absolute;
        margin-left: 3px;
      }

      .vertical-dashed {
        border: 0;
        border-left: 1px dashed $black;
        height: 100%;
        margin-top: 14px;
        margin-bottom: 0;
        position: absolute;
        margin-left: 3px;
      }

      .driver-service-card-container {
        width: 100%;
      }

      .driver-service-card-item-updates {
        display: grid;
        grid-template-columns: 10% 70%;
        grid-auto-flow: column;
        background-color: rgba($dark-grey, 0.1);
        padding: 1em 1em;
        border-radius: 2px;
      }
    }

    .important-info {
      padding-top: 1.5em;
      font-size: 16px;

      div {
        background: url('/images/icons/icon-checklist-black.png');
        background-position: top 4px left 4px;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        padding-left: 28px;
        margin-bottom: 4px;
      }
    }

    .driver-service-card-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba($dark-grey, 0.1);
      padding: 1em 1em;
      font-size: 15px;

      .from-to-content {
        flex: 0 1 100%;
        text-align: right;
      }

      .from-to-title {
        flex: 1 0 50%;
      }

      span:nth-child(odd) {
        color: $dark-grey;
      }

      span.daily-items {
        color: $black !important;
      }
    }

    .driver-service-card-item-seats {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: rgba($dark-grey, 0.1);
      padding: 0.5em 1em 1em 1em;
      font-size: 15px;

      img {
        width: 20px;
      }
    }

    .driver-service-card-item-left {
      display: flex;
      align-items: flex-start;
    }

    .driver-service-card-item-left img {
      width: 22px;
      margin-top: 5px;
    }

    .driver-service-card-item-seat {
      display: flex;
      align-items: flex-start;
      margin-top: 15px;
    }

    .driver-service-card-item-seat img {
      width: 22px;
    }

    .driver-service-card-item-with-desc-mob {
      margin-left: 10px;

      span {
        display: block;
        font-size: 14px;
        color: #636d77;
      }

      span:first-child {
        font-size: 15px;
        margin-bottom: 5px;
        color: black;
      }
    }

    .driver-service-card-item-with-desc {
      margin-left: 10px;

      span {
        display: block;
        font-size: 12px;
        color: #303030;
      }

      span:first-child {
        color: $dark-grey;
        font-size: 15px;
        margin-bottom: 5px;
      }
    }

    .driver-service-card-item-quantity {
      margin-top: 7px;
    }

    .status-update-timestamp {
      float: right;
      clear: both;
      padding-top: 3px;
      font-size: 12px;
      color: $dark-grey;
    }

    .driver-service-card-item-updates-complete {
      display: grid;
      grid-template-columns: 10% 62%;
      grid-auto-flow: column;
      background-color: rgba($dark-grey, 0.1);
      padding: 1em 1em;
      border-radius: 2px;
    }

    .updates-complete {
      grid-template-columns: 10% 80% !important;
    }

    .indicator-black {
      border-top: 3px solid $black !important;
    }
    .indicator-red {
      border-top: 3px solid $red !important;
    }
  }

  .partner-accept-container {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .partner-dispatch-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 1em;
  }
  .partner-terms {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 2rem;

    a {
      color: $brown;
    }
  }
  .partner-next-container-form {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin: 1em 1em 1em;
  }
  .partner-next-container {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .partner-next-container-assign-multi {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em;
    width: calc(100vw - 60%);
  }
  .partner-next-container-assign {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    margin-bottom: 1em;
    margin-top: 2em;
    width: calc(100vw - 80%);
  }
  .card-message {
    line-height: 12px;
    font-family: $font-family-base;
    font-size: 13px;
    font-weight: 400;
    color: #8b959e;
    display: block;
    margin-bottom: 0.3rem;
  }
  .newOffer-message {
    font-family: $font-family-base;
    line-height: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #8b959e;
    display: block;
    margin-bottom: 0.3rem;
  }
  .counter {
    font-size: 18px;
    font-weight: 400;
  }
  .offer-date {
    font-size: 14px;
    font-weight: 700;
  }
  .partner-action-title {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 0.4em;
  }
  .partner-action-counter {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: $red;
  }
  .partner-action-message {
    font-family: $font-family-base;
    line-height: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #8b959e;
    display: block;
    margin-bottom: 0.3rem;
  }
}

.partner-container {
  background-color: black;
  font-size: 16px;
  height: fit-content;
  color: white;
  border-radius: 15px;
  padding: 6px 15px 5px 15px;
  margin-right: 15px;
}

.partner-card {
  width: calc(100vw - 80%);
  padding: 1.5em 1.5em;
  font-size: 15px;
  border: none;
  input.custom-datepicker-control {
    background-color: transparent !important;
    background-image: url('/images/icons/calendar.png');
    background-size: 17px 17px;
    background-position: center right 13px !important;
    background-repeat: no-repeat;
    padding-right: 30px;
    color: rgba($black, 0.3);
    &.active {
      background-image: url('/images/icons/calendar_active.png');
      color: $black;
    }
  }
  .custom-datepicker-calendar {
    background-color: transparent !important;
  }
  .react-datepicker__header {
    background-color: transparent !important;
  }
}

.driver-booking-cta-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba($dark-grey, 0.25);
  background-color: #ffffff;
  padding: 1em 1.5em 1em 1.5em;
}

.booking-filters-cta-footer {
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba($dark-grey, 0.25);
  background-color: #ffffff;
  padding: 1em 1em 1em 1.5em;
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vh - 42rem);
  margin-bottom: 5rem;

  span {
    font-size: 16px;
    color: $dark-brown;
    display: flex;
    align-self: center;
    text-shadow: $dark-brown 0.25px 0px;
    font-weight: 900;
  }
}

.partner-filters-cta-footer {
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba($dark-grey, 0.25);
  background-color: #ffffff;
  padding: 1em 1em 1em 1.5em;
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vh - 34rem);
  margin-bottom: 4rem;

  span {
    font-size: 16px;
    color: $dark-brown;
    display: flex;
    align-self: center;
    text-shadow: $dark-brown 0.25px 0px;
    font-weight: 900;
  }
}

.driver-booking-cta-footer-acc {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba($dark-grey, 0.25);
  background-color: #ffffff;
  padding: 0em 1.5em 0em 1.5em;
}

.driver-booking-cta-footer-no-border {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  padding: 1em 1.5em;
}

.driver-booking-cta-footer-assign {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2px;
  border: none;
  border-top: 1px solid rgba($dark-grey, 0.25);
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.driver-booking-container-header {
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.2);
  background-color: #ffffff;
  padding: 1em 1.5em;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bolder;
}

.driver-booking-container-header-booking {
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.2);
  background-color: #ffffff;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bolder;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  img {
    width: 6%;
    height: 6%;
    display: flex;
  }
}

.osm-maps-marker-img {
  height: 26px;
  width: 26px;
}

.osm-maps-marker-stops-img {
  height: 22px;
  width: 22px;
}

.osm-maps-marker-span {
  position: absolute;
  top: 50%;
  left: 40%;
  color: white;
}

.osm-maps-marker-stops-span {
  position: absolute;
  top: 3px;
  left: 8px;
  font-size: 11px;
  color: white;
}

.osm-trip-duration-container {
  border: 1px solid black;
  background-color: white;
  border-radius: 2px;
  padding: 3px;
  width: 240px !important;
  height: auto !important;
  margin-left: 30px !important;
  margin-top: 30px !important;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 22px !important;
    height: 30px;
    margin-left: 5px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 36px);
    margin-left: 7px;
  }
}

.osm-trip-duration-container-b2c {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1000;
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  padding: 3px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 20px !important;
    height: 25px;
    margin-left: 5px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-right: 4px;
  }
}

.osm-popup {
  .leaflet-popup-content-wrapper {
    border: 1px solid black;
    background-color: white;
    border-radius: 2px;
    width: 240px !important;
    height: auto !important;
    margin-left: 15px !important;
    box-shadow: none;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .leaflet-popup-tip {
    border: 1px solid black;
  }
}

.driver-booking-container-header-booking-support {
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.2);
  background-color: #ffffff;
  padding: 1em 1.5em;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bolder;

  img {
    width: 6%;
    height: 6%;
    display: flex;
  }
}

.text-no-border {
  border-bottom: none !important;
}

.driver-booking-details-container {
  padding: 1em 1.5em;
  font-size: 20px;
}

.driver-back-icon {
  top: 4px;
  right: 5px;
  position: relative;
}
.driver-back-icon-assign {
  position: absolute;
  top: 19px;
  width: 22px;
  left: 23px;
}
.driver-back-icon-account {
  position: absolute;
  top: 19px;
  width: 22px;
  left: 30px;
}

.alert-countdown {
  span:nth-child(odd) {
    font-size: 14px;
    font-weight: 400;
    padding-right: 1px;
  }
  span:nth-child(even) {
    font-size: 12px;
    font-weight: 500;
    padding-right: 5px;
  }
  span:last-child {
    padding-right: 0;
  }
}

.driver-add-card {
  display: flex;
  border-radius: 5px;
  border: 1px solid rgba($dark-grey, 0.5);
  background-color: $white;
  padding: 1em 1em;
  justify-content: space-between;
  align-items: center;

  .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid rgba($dark-grey, 1);
    justify-content: center;
    border-radius: 50%;
  }

  .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
  }

  .car-img {
    width: 70%;
  }
  .car-img-chauffeur {
    width: 55%;
  }

  .car-edit-img {
    width: 25%;
    margin-right: 10px;
    align-self: flex-start;
    padding-top: 2px;
  }

  .profile-img {
    width: 40px;
    height: 40px;
  }

  .add-img {
    width: 28px;
    padding-right: 5px;
  }
  .add-img-car {
    width: 23px;
  }

  .edit-img {
    img {
      width: 23px;
    }
  }

  .add-span {
    flex: 0 0 65%;
    font-size: 16px;
    padding-top: 5px;
    color: $dark-brown;
    font-weight: 700;
  }

  .info-span {
    flex: 0 0 65%;
    font-size: 16px;
  }
}
.dispatch-list-partner {
  display: flex;
  border-radius: 5px;
  border: 1px solid rgba($dark-grey, 0.5);
  background-color: $white;
  padding: 1em 1em;
  justify-content: space-between;
  align-items: center;

  .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid rgba($dark-grey, 1);
    justify-content: center;
    border-radius: 50%;
  }

  .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
  }

  .car-img {
    width: 70%;
  }
  .car-img-chauffeur {
    width: 55%;
  }

  .car-edit-img {
    width: 25%;
    margin-right: 10px;
    align-self: flex-start;
    padding-top: 2px;
  }

  .profile-img {
    width: 40px;
    height: 40px;
  }

  .add-img {
    width: 28px;
    padding-right: 5px;
  }
  .add-img-car {
    width: 23px;
  }

  .edit-img {
    img {
      width: 23px;
    }
  }

  .add-span {
    flex: 0 0 65%;
    font-size: 16px;
    padding-top: 5px;
    color: $dark-brown;
    font-weight: 700;
  }

  .info-span {
    flex: 0 0 65%;
    font-size: 16px;
  }
}

.add-border {
  border: 1.5px solid rgba($black, 1);
}

.driver-edit-container {
  h1 {
    font-size: 25px;
    margin-left: 2rem;
    margin-top: 2em;
  }

  .image-container {
    margin-top: 3em;

    .chauffeur-pic {
      width: 110px;
      height: 110px;
    }
    .chauffeur-pic-not-uploaded {
      width: 110px;
      height: 110px;
      padding: 20%;
    }

    .chauffeur-pic-container {
      width: fit-content;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid black;
    }

    .chauffeur-pic-container-border {
      width: fit-content;
      border: 1px solid rgba($dark-grey, 1);
      border-radius: 50%;
      overflow: hidden;
    }

    label {
      font-size: 16px;
      padding-top: 1.5em;
      color: $dark-brown;
      align-self: center;
      text-transform: none;
      font-weight: bolder;
    }
  }

  .driver-edit-wrap {
    min-width: 160px;
    max-width: unset;
    margin-top: 20px;
    width: 100vw;

    .driver-fields {
      padding: 1em 2em;
      margin-bottom: 6em;

      input {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.2) !important;
        background-color: $white !important;
        font-size: 15px !important;

        &:hover,
        &:focus,
        &:active {
          border: 0.5px solid #000000 !important;
        }
      }
      input#firstName {
        text-transform: capitalize !important;
      }
      input#lastName {
        text-transform: capitalize !important;
      }
      input#plateNumber {
        text-transform: uppercase !important;
      }
      input#make {
        border: none !important;
      }
      input#model {
        border: none !important;
      }
      .selected-flag {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.1) !important;
        background-color: #ffffff !important;
      }
      .react-select-container {
        font-size: 15px !important;
      }
    }
  }

  .car-container {
    display: flex;
    justify-content: center;

    img {
      width: 60%;
      padding-top: 25px;
    }
  }
}

.driver-edit-container-desktop {
  .image-container-desktop {
    margin: 2em;
    display: flex;
    column-gap: 1em;
    margin-left: 1em;

    .chauffeur-pic {
      width: 70px;
      height: 70px;
      padding: 26%;
    }

    .chauffeur-uploaded-pic {
      width: 70px;
      height: 70px;
    }

    .chauffeur-pic-container {
      width: fit-content;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid black;
    }

    .chauffeur-pic-container-border {
      width: fit-content;
      border: 1px solid rgba($dark-grey, 0.5);
      border-radius: 50%;
      overflow: hidden;
    }

    label {
      color: $dark-brown;
      align-self: center;
      text-transform: none;
      font-size: 14px;
      margin: 0;
      letter-spacing: 0;
    }
  }

  .driver-edit-wrap {
    padding: 1em;

    .driver-fields {
      input {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.1) !important;
        font-size: 15px !important;

        &:hover,
        &:focus,
        &:active {
          border: 0.5px solid #000000 !important;
        }
      }
      input#firstName {
        text-transform: capitalize !important;
      }
      input#lastName {
        text-transform: capitalize !important;
      }
      input#plateNumber {
        text-transform: uppercase !important;
      }
      input#make {
        border: none !important;
      }
      input#make {
        border: none !important;
      }
      input#model {
        border: none !important;
      }
      .selected-flag {
        box-shadow: none !important;
        border: 1px solid rgba($dark-grey, 0.1) !important;
        background-color: #ffffff !important;
      }
    }
  }

  .car-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 100px;
    max-height: 120px;
    position: relative;
    justify-content: flex-start;

    img {
      width: 40%;
      display: block;
      height: auto;
      position: absolute;
      bottom: 0;
    }
  }
}

.driver-booking-assign-body {
  padding: 1.5em;
  height: calc(100vh - 9.5em);
  overflow: scroll;

  h1 {
    font-size: 25px;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

.client-logo-container {
  display: flex;
  column-gap: 3em;
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 50%;
  font-size: 8px;
  text-align: center;
  background-color: $brown;
  border: none;
  position: absolute;
  right: 0;
  margin: 2.5em;

  img {
    width: 20px;
    height: 20px;
  }
}

.partner-banner {
  background-color: rgba($danger, 0.1);
  border: 1px solid rgba($danger, 1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: center;

  .imageContainer {
    float: left;
    position: relative;
    align-self: center;
    margin-left: 10px;
    img {
      width: 17px;
    }
  }

  .partnerContainer {
    font-size: 16px;
    margin: 10px;
    color: #0c0c0c;
    display: flex;
    text-align: center;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }

  .partnerContainerComplete {
    font-size: 16px;
    margin: 15px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }

    .partnerContainerHead {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }

    .text-red {
      color: #d0021b;
      margin-bottom: 0;
      align-self: center;
    }
  }
}

.partner-banner-grey {
  background-color: rgba($dark-grey, 0.1);
  border: 1px solid rgba($dark-grey, 1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  .driven-by {
    display: flex;
    gap: 1em;
  }

  .imageContainer {
    // margin-left: 25px;
    margin-right: 10px;
    align-self: center;
    border: 1px solid black;
    border-radius: 60%;

    img {
      width: 32px;
      padding: 5px;
    }
  }

  .partnerContainer {
    font-size: 16px;
    // margin: 10px;
    color: #0c0c0c;

    p {
      margin-bottom: 0;
      color: black;
    }
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }
}

.partner-banner-grey-assign {
  background-color: rgba($color: $dark-brown, $alpha: 0.05);
  border: 1px solid #a79779;
  border-radius: 10px;
  height: auto;
  display: flex;

  padding: 16px;
  .imageContainer {
    // margin-left: 20px;
    margin-right: 10px;
    align-self: center;
    border: 1px solid $dark-brown;
    border-radius: 60%;

    img {
      width: 33px;
      height: 34px;
      padding: 6.5px;
    }
  }

  .partnerContainer {
    font-size: 16px;
    // margin: 10px;
    color: #0c0c0c;
    display: flex;

    p {
      margin-bottom: 0;
      color: black;
    }
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }
}
.partner-banner-mobile-grey {
  background-color: rgba($color: $dark-grey, $alpha: 0.1);
  border: 1px solid #000000;
  border-radius: 10px;
  height: auto;
  display: flex;
  padding: 16px;

  .imageContainer {
    // margin-left: 20px;
    margin-right: 5px;
    align-self: center;
    border: 1px solid $dark-brown;
    border-radius: 60%;

    img {
      width: 33px;
      height: 34px;
      padding: 6.5px;
    }
  }

  .partnerContainer {
    font-size: 16px;
    // margin: 10px;
    color: #0c0c0c;
    display: flex;

    p {
      margin-bottom: 0;
      color: black;
    }
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }
}

.partner-banner-mobile-warning {
  background-color: rgba($color: $warning, $alpha: 0.05);
  border: 1px solid $warning;
  border-radius: 10px;
  height: auto;
  display: flex;
  padding: 16px;

  .imageContainer {
    // margin-left: 20px;
    margin-right: 5px;
    align-self: center;
    border: 1px solid $dark-brown;
    border-radius: 60%;

    img {
      width: 33px;
      height: 34px;
      padding: 6.5px;
    }
  }

  .partnerContainer {
    font-size: 16px;
    // margin: 10px;
    color: #0c0c0c;
    display: flex;

    p {
      margin-bottom: 0;
      color: black;
    }
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }
  }
}

.partner-checkbox-mobile {
  margin-top: 1.3rem;
  /* padding: 0px; */
  padding-left: 1.2rem;
}

.partner-banner-desktop {
  width: calc(100% - 10rem);
  background-color: rgba($danger, 0.1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid $danger;
  padding: 16px;

  .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .imageContainer {
    float: left;
    align-self: center;
    // margin-left: 25px;
    // margin-right: 5px;
    margin: 0px;
    img {
      width: 17px;
    }
  }

  .warning-imageContainer {
    float: left;
    align-self: flex-start;
    // margin-left: 25px;
    // margin-right: 5px;
    margin: 0px;
    img {
      width: 17px;
    }
  }

  .partnerContainer {
    font-size: 14px;
    // margin: 20px;
    margin: 0px 0px 0px 24px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      display: inline-block;
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
      color: $danger;
      font-weight: bold;
    }
  }
}

.payout-banner {
  width: calc(100%);
  background-color: rgba($danger, 0.1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid $danger;

  .imageContainer {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 5px;
    img {
      width: 17px;
    }
  }

  .partnerContainer {
    font-size: 14px;
    margin: 20px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      display: inline-block;
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
      color: $danger;
      font-weight: bold;
    }
  }
}
.partner-banner-desktop-grey {
  width: calc(100% - 10rem);
  background-color: rgba($color: $dark-grey, $alpha: 0.1);
  border: 1px solid #000000;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;

  .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .imageContainer {
    float: left;
    align-self: center;
    // margin-left: 25px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 60%;
    img {
      width: 36px;
      padding: 6px;
    }
  }

  .imageContainerGold {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid $dark-brown;
    border-radius: 60%;
    img {
      height: 28px;
      width: 27px;
      padding: 6px;
      margin: 2px;
    }
  }

  .partnerContainer {
    font-size: 14px;
    // margin: 20px;
    // margin: 0px 0px 0px 20px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}

.partner-banner-desktop-gold {
  width: calc(100% - 10rem);
  background-color: rgba($color: $dark-brown, $alpha: 0.05);
  border: 1px solid #a79779;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .imageContainer {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 60%;
    img {
      width: 36px;
      padding: 6px;
    }
  }

  .imageContainerGold {
    float: left;
    align-self: center;
    // margin-left: 25px;
    // margin-right: 5px;
    margin-right: 1em;
    border: 1px solid $dark-brown;
    border-radius: 60%;
    img {
      // height: 37px;
      // width: 36px;
      height: 1.75rem;
      width: 1.75rem;
      padding: 6px;
      margin: 2px;
    }
  }

  .partnerContainer {
    font-size: 14px;
    // margin: 30px;
    color: #0c0c0c;
    display: flex;
    align-items: center;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}
.partner-banner-desktop-warning {
  width: calc(100% - 10rem);
  background-color: rgba($color: $warning, $alpha: 0.05);
  border: 1px solid $warning;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  .select-chauffeur {
    align-self: center;
    margin-right: 2em;
    width: 170px;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .imageContainer {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 60%;
    img {
      width: 36px;
      padding: 6px;
    }
  }

  .imageContainerGold {
    float: left;
    align-self: center;
    margin-left: 25px;
    margin-right: 10px;
    border: 1px solid $dark-brown;
    border-radius: 60%;
    img {
      height: 37px;
      width: 36px;
      padding: 6px;
      margin: 2px;
    }
  }

  .partnerContainer {
    font-size: 14px;
    // margin: 15px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}

.partner-header {
  padding: 0px 40px 0px 60px !important;
  display: flex;
  margin-top: 0;
}

.partner-header-home {
  padding: 50px 10% 0px 60px !important;
  margin: 0px 15px 0px 15px !important;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  max-width: none !important;
}

.partner-btn-desktop {
  max-width: 90px;
  min-width: 90px;
  height: 42px !important;
  font-size: 14px !important;
  border: 1px solid $brown;
  background-color: $brown;
  font-weight: 600;
  &:active,
  &:focus {
    box-shadow: none !important;
  }
}
.partner-btn-desktop-red {
  // min-width: 140px !important;
  min-width: 12.5em !important;
  font-weight: 500 !important;
  max-width: 90px;
  min-width: 90px;
  height: 42px !important;
  font-size: 14px !important;
  border: 1px solid $red !important;
  background-color: transparent !important;
  color: $red !important;
  &:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 1px solid $red !important;
    color: $red !important;
    cursor: pointer;
  }
}

.partner-sentDate {
  font-size: 14px;
}

.partner-btn-desktop-decline {
  max-width: 95px;
  min-width: 95px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba($dark-grey, 0);
  &:active,
  &:focus,
  &:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important;
  }
}
.partner-btn-decline-red {
  max-width: 95px;
  min-width: 95px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #d0021b;
  color: #d0021b !important;
  background-color: rgba($dark-grey, 0);
  &:active,
  &:focus,
  &:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: rgba($danger, 0.1) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #d0021b !important;
  }
}
.partner-cancel-dispatch {
  // max-width: 95px;
  // min-width: 95px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba($dark-grey, 0);
  &:active,
  &:focus,
  &:hover {
    font-weight: 600;
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important;
  }
}

.confirmation-modal-decline-btn {
  min-width: 175px;
  height: 40px !important;
  font-size: 15px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba($dark-grey, 0) !important;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important;
  }
}

.partner-btn-desktop-primary {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid $brown;
  background-color: $brown;
  font-size: 14px !important;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: #968669 !important;
    border: #968669 !important;
  }
  &:disabled {
    box-shadow: none !important;
    background-color: 1px solid rgb(167, 151, 121);
    border: 1px solid rgb(167, 151, 121);
    color: #ffffff;
    pointer-events: none;
  }
}

.partner-btn-mobile-primary {
  font-size: 16px !important;
  font-weight: 900 !important;
  text-shadow: 0.25px 0 #ffffff !important;
}

.partner-btn-desktop-primary-sm {
  min-width: 95px;
  height: 35px !important;
  font-weight: 500;
  margin: 0 !important;
  border: 1px solid $brown;
  background-color: $brown;
  font-size: 14px !important;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: #968669 !important;
    border: #968669 !important;
  }
  &:disabled {
    box-shadow: none !important;
    background-color: 1px solid rgb(167, 151, 121);
    border: 1px solid rgb(167, 151, 121);
    color: #ffffff;
  }
}

.partner-btn-desktop-tertiary {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #a79779;
  font-size: 14px !important;
  color: #a79779 !important;
  background-color: rgba($dark-grey, 0);
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important;
  }
}
.outsource-btn-partner {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #a79779;
  font-size: 14px !important;
  color: #a79779 !important;
  background-color: rgba($dark-grey, 0);
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important;
  }
}

.partner-link-desktop {
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.partner-btn-desktop-small {
  min-width: 95px;
  height: 35px !important;
  font-weight: 600 !important;
  // padding: 0 !important;
  margin: 0 !important;
  font-size: 14px !important;
  border: 1px solid $brown;
  background-color: $brown;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: #968669 !important;
    // border: #968669 !important;
    border: 1px solid #968669 !important;
  }
}

.partner-btn-desktop-secondary {
  height: 30px !important;
  font-weight: bold !important;
  background-color: rgba(165, 151, 124, 0.15) !important;
  border: none;
  color: $brown !important;
  padding: 10px 20px 25px 20px;
  font-size: 14px !important;
  font-weight: 600 !important;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.4) !important;
  }
}

.partner-btn-desktop-secondary-assign {
  font-weight: bold !important;
  background-color: rgba(165, 151, 124, 0.15) !important;
  border: none;
  color: $brown !important;
  padding: 10px 20px 25px 20px;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 50px !important;
  display: flex;
  padding-top: 16px;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.4) !important;
  }
}

.child-seat-sub-header {
  margin-bottom: 0px !important;
  width: 82%;
  max-width: 505px;

  &.expandable {
    cursor: pointer;
    padding-right: 40px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 5px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url('/images/icons/caret-down.svg');
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat;
    }

    &.expanded {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.child-seat-desc {
  font-size: 14px;
  color: #8b959e;
  padding-top: 2px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.child-seat-div {
  margin-bottom: 10px;
  font-size: 15px;
}
.child-seat-img {
  width: 20px;
  margin-left: 15px;
  margin-right: 7px;
}

.alert-avatar {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  width: fit-content;
  margin-left: 10px;

  &.user-online {
    border: 1px solid $success;
  }

  img {
    display: block;
    width: 30px;
    height: 30px;
    object-fit: cover;
    object-position: center center;
  }
}

.assign-stepper {
  padding-top: 1em;
  display: flex;

  .completed {
    background: url('/images/icons/icon-checklist-black.png');
    background-position: top 9px left 4px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    padding-left: 28px;
    margin-bottom: 4px;
    border-bottom: 0;
  }

  .not-completed {
    background: url('/images/icons/icon-checklist-grey.png');
    background-position: top 9px left 4px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    padding-left: 28px;
    margin-bottom: 4px;
    color: $dark-grey;
    border-bottom: 0;
  }
}

.assign-card {
  width: calc(100vw - 80%);
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.assign-select-card {
  width: calc(100vw - 80%);
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.assign-select-card-multiple {
  width: calc(100vw - 60%);
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.assign-select-card-confirm {
  width: calc(100vw - 0%);
  margin-top: 2em !important;
  border-radius: 10px !important;
}

.assign-confirm-container {
  width: calc(100vw - 35%);
  column-gap: 1em;
}

.partner-grid {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
  overflow-y: scroll;
  max-height: 18em;
}

.partner-grid-acc {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
}

.assign-card-top-half {
  width: calc(100vw - 80%);
  margin-top: 2em !important;
  border-radius: 10px 10px 0px 0px !important;
}

.assign-card-half {
  width: calc(100vw - 80%);
  margin-top: 0 !important;
  border-radius: 0px 0px 10px 10px !important;
  border-top: none !important;
}

.setWidth {
  width: 10px;
}

.chauffeur-card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba($dark-grey, 0.1);
  padding: 1em 1em;
  margin-top: 1em;
  max-height: 70px;

  .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid rgba($dark-grey, 1);
    justify-content: center;
    border-radius: 50%;
  }

  .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
  }

  .car-img {
    width: 70%;
  }

  .car-img-chauffeur {
    width: 55%;
  }

  .car-edit-img {
    width: 17%;
    margin-right: 15px;
    align-self: flex-start;
  }
  .shift-image {
    transform: translateY(-0.8rem);
  }
  .profile-img {
    width: 40px;
    height: 40px;
  }

  .add-img {
    width: 9%;
  }

  .edit-img {
    img {
      width: 23px;
    }
  }

  .add-span {
    flex: 0 0 50%;
    padding-top: 5px;
    color: $dark-brown;
    font-weight: 700;
  }

  .info-span-small {
    white-space: pre !important;
    flex: 0 0 50%;
    padding-top: 5px;
  }

  .info-span-chauffeur {
    flex: 0 0 75%;
  }

  .info-span {
    flex: 0 0 65%;
  }

  .info-span-company {
    flex: 0 0 75%;
  }
}

.selected-item {
  border-radius: 5px;
  border: 1px solid black;
}

.assigned-item {
  border-radius: 5px;
  border: 1px solid #a79779;
}

.chauffeur-card-item-confirm {
  display: flex;
  align-items: center;
  background-color: rgba($dark-grey, 0.1);
  padding: 1em 1em;
  margin-top: 1em;
  max-height: 70px;
  grid-gap: 1em;
  justify-content: center;

  .add-pic-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid rgba($dark-grey, 1);
    justify-content: center;
    border-radius: 50%;
  }

  .add-pic-container-uploaded {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
  }

  .car-img {
    width: 70%;
  }

  .car-img-chauffeur {
    width: 55%;
  }

  .car-edit-img {
    width: 17%;
    margin-right: 15px;
    align-self: flex-start;
  }

  .profile-img {
    width: 140%;
  }

  .add-img {
    width: 9%;
  }

  .edit-img {
    img {
      width: 23px;
    }
  }

  .add-span {
    flex: 0 0 50%;
    padding-top: 5px;
    color: $dark-brown;
    font-weight: 700;
  }

  .info-span-small {
    white-space: 'pre' !important;
    flex: 0 0 50%;
    padding-top: 5px;
  }

  .info-span {
    flex: 0 0 74%;
  }
}

.chauffeur-title {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.extra-spacing {
  margin-bottom: 10rem;
}

.grey-background {
  padding: 1em 1em;
  margin-top: 1em;
  background-color: rgba($dark-grey, 0.1);
}

.partner-bookingType {
  font-family: $font-family-base;
  line-height: 12px;
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 0.3rem;
}
.support-card {
  border-radius: 8px;
  border: 1.5px solid rgba($dark-grey, 0.2);
  background-color: $light-2;
  padding: 1.5em 1.5em 1.5em 1.5em;
  font-size: 14px;
  margin-top: 1em;

  .support-icon-container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(165, 151, 124, 1);
    justify-content: center;
    border-radius: 50%;

    .support-img {
      width: 60%;
    }
  }
  .support-title {
    font-size: 16px;
    font-weight: 700;
  }

  .support-message {
    display: inline-block;
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 400;
    color: rgba($dark-grey, 0.8);
    display: block;
    margin-bottom: 0.3rem;
  }
}

.partner-search-container {
  display: flex;
  justify-content: space-between;
}

// diff height values for cross-browser compatibility
.client-search-container {
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
}

.driverApp-notifications-wrap {
  display: flex;
  justify-content: end;
  align-items: flex-start;
  z-index: 9;

  .vehicle-title {
    max-width: max-content;
  }
}

.partner-sidebar {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.partner-number-field {
  min-width: 7em;
}

.partner-ellipsis-field {
  text-overflow: ellipsis;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.client-ellipsis-field {
  text-overflow: ellipsis;
  max-width: 9rem;
  overflow: hidden;
  white-space: nowrap;
}

.partner-field-extended {
  white-space: nowrap;
}

.partner-action {
  display: flex;
  justify-content: center;
}

.driver-panel-titlebar {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 10px;
  // align-items: center;
  height: 51px;
  // margin-top: 2rem;
  border-bottom: 1px solid rgba($dark-grey, 0.2);
}
.chauffeur-count-container {
  display: flex;
  column-gap: 2em;
  margin-left: 1em;

  span {
    display: block;
    color: $dark-grey;
    font-size: 13px;
    font-weight: 400;
  }

  span:first-child {
    display: block;
    font-size: 24px;
    height: 28px;
    font-weight: bold;
    color: $black;
    margin-bottom: 1px;
  }
}

.noWrap-whiteSpace {
  white-space: nowrap !important;
}

.admin-users-default-logo {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid rgba(12, 12, 12, 0.1);
  overflow: hidden;
}
.admin-users-booking {
  width: 34px !important;
  height: 34px !important;
  border-radius: 50%;
  overflow: hidden;
}
.admin-users-booking {
  width: 34px !important;
  height: 34px !important;
  border-radius: 50%;
  overflow: hidden;
}

.partner-accepted-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: calc(100vh - 20rem);
  justify-content: center;

  p {
    margin-top: 1rem;
  }
}
.mobile-logout {
  padding-top: 1.4rem !important;
  padding-right: 2.1rem;

  img {
    width: 28px;
  }
}

.partner-delete-btn {
  display: block;
  color: $red;
  margin-bottom: 5px;
  &:hover {
    text-decoration: none;
    color: $red;
  }
}
.partner-dot {
  width: 4px;
  height: 4px;
  background: rgba($dark-grey, 0.1);
  border-radius: 50%;
}
.delete-icon-desktop {
  max-width: 17px;
  margin-right: 0.6rem !important;
  &:hover {
    cursor: pointer;
  }
}

.partner-banner-mobile {
  width: 100%;
  background-color: rgba($danger, 0.1);
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid $danger;

  padding: 16px;

  .select-chauffeur {
    align-self: center;
    // margin-right: 2em;
    width: 170px;
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .imageContainer {
    align-self: center;
    // margin-left: 10px;
    margin-right: 10px;
    img {
      width: 17px;
    }
  }

  .partnerContainer {
    display: inline-block;
    font-size: 14px;
    // margin: 20px;
    margin-bottom: 16px;
    color: #0c0c0c;
    .mobile {
      font-size: 13px !important;
    }

    .header {
      font-weight: 800 !important;
    }

    span {
      display: inline-block;
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
      color: $danger;
      font-weight: bold;
    }
  }
}

.switch-client-banner {
  display: flex;
  justify-content: space-between;
  background-color: rgba($brown, 0.05);
  border: 1px solid $brown;
  border-radius: 2px;
  margin: 20px 15px;
  padding: 20px;
  align-items: center;

  .banner-header {
    display: flex;
    align-items: center;
  }

  p:first-child {
    margin-bottom: 2px;
    font-size: 20px;
    color: black;
  }

  p:last-child {
    margin-bottom: 0;
    color: #636d77;
    max-width: 635px;
    width: 95%;
  }

  .right-div {
    display: flex;
    align-items: center;
  }

  .close-icon {
    width: 12px;
    height: 12px;
    margin-left: 2rem;
    cursor: pointer;
  }

  .globe-icon {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
}

.switch-client-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid $brown !important;
  background-color: transparent;
  color: $brown !important;
  height: 35px !important;
  width: 9rem;
  font-size: 14px !important;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px !important;
  box-shadow: none !important;

  &:hover {
    background-color: rgba($brown, 0.2) !important;
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:target {
    background-color: rgba($brown, 0.3) !important;
  }

  img {
    width: 15px;
    height: 15px;
    padding-bottom: 2px;
  }
}
.home .switch-client-btn-mob {
  border-radius: 2px !important;
  width: 100%;
  margin-bottom: 25px;
  justify-content: center;
  gap: 10px;
}
.switch-client-btn-mob {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid $brown !important;
  background-color: transparent;
  color: $brown !important;
  height: 38px !important;
  width: 10rem;
  font-size: 14px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  border-radius: 20px !important;
  box-shadow: none !important;
  margin-right: 10px;

  &:hover {
    background-color: rgba($brown, 0.2) !important;
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:target {
    background-color: rgba($brown, 0.3) !important;
  }

  img {
    width: 18px !important;
    height: 20px !important;
    padding-bottom: 3px;
  }
}

.partner-btn-mobile-decline {
  width: 100%;
  margin-left: 15px;
  height: 42px !important;
  font-size: 14px !important;
  font-weight: 600;
  border: 1px solid #a79779;
  color: #a79779 !important;
  background-color: rgba($dark-grey, 0);
  // margin-left: 1.25rem !important;
  &:active,
  &:focus,
  &:hover {
    box-shadow: none !important;
    background-color: rgba(165, 151, 124, 0.15) !important;
    border: rgba(165, 151, 124, 0.15) !important;
    color: #a79779 !important;
  }
}
.apiBadgeList {
  color: $white;
  background: $black;
  border-radius: 10px;
  max-width: 30px;
  min-width: 30px;
  text-align: center;
  font-family: $font-family-base;
  font-size: 10px;
  font-weight: bold;
  height: 15px;
  // margin-top: 0.22rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.apiBadgeBooking {
  color: #ffffff;
  // background: #a79779;
  background: $black;
  border-radius: 10px;
  max-width: 40px;
  min-width: 40px;
  text-align: center;
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  padding-top: 2px;
  justify-content: center;
}
.alignDraggable {
  position: fixed;
  left: 100px;
  z-index: 99;
  border: 1px solid lightgrey;
  top: 50px;
  min-width: 750px;
  height: 90%;
  overflow: auto;
  overflow-x: hidden;
  background: white;
  box-shadow: 0px 5px 15px 0px rgba(#000000, 0.2) !important;
  border-radius: 5px;
  .form-group {
    margin-bottom: 1rem !important;
  }
}
.alignDraggable-model {
  background-color: $white;
  .modal-header {
    .close {
      outline: none !important;
    }
  }
}

.client-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;

  img {
    width: 30px;
    height: 15px;
  }
}

.client-filter-modal {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
  z-index: 2;
}

.payment-card-fields {
  // max-width: 66.5% !important;
  max-width: 50.2% !important;
}
.new-info-icon {
  width: 13px;
  padding-bottom: 3px;
  margin-left: 12px;
  cursor: pointer;
}
.coverage-info-icon {
  width: 13px;
  padding-bottom: 3px;
  margin-left: 9px;
  cursor: pointer;
  margin-top: 3px;
  height: 15px;
}
.coverage-info-icon-two {
  width: 13px;
  padding-bottom: 3px;
  margin-left: 9px;
  cursor: pointer;
  margin-top: 5px;
  height: 15px;
}
.info-icon {
  width: 12px;
  padding-bottom: 3.5px;
  margin-left: 2px;
  cursor: pointer;
}
.info-icon-rentals {
  width: 14.5px;
  padding-bottom: 1.5px;
  margin-left: 7px;
  cursor: pointer;
}
.info-icon-vip {
  width: 11.5px;
  padding-bottom: 4px;
  margin-left: 6px;
  cursor: pointer;
}
.info-icon-total {
  width: 14px;
  padding-bottom: 4.5px;
  margin-left: 4px;
  cursor: pointer;
}
.info-icon-details {
  width: 11.5px;
  padding-bottom: 3.5px;
  margin-left: 5px;
  cursor: pointer;
}
.info-icon-multi-stop {
  position: absolute;
  left: 7.3em;
  top: 0%;
  transform: translateX(-50%);
  width: 10px;
  padding-bottom: 3px;
  margin-left: 2px;
  cursor: pointer;
}
.info-icon-multi-stop-hourly {
  position: absolute;
  left: 98px;
  bottom: 69.5px;
  width: 10px;
  padding-bottom: 3.5px;
  margin-left: 2px;
  cursor: pointer;
}
.info-icon-itinerary {
  position: absolute;
  left: 95px;
  bottom: 5px;
  width: 13px;
  padding-bottom: 3.5px;
  margin-left: 2px;
  cursor: pointer;
}
.info-icon-change-req {
  position: absolute;
  left: 15em;
  top: 0%;
  transform: translateX(-50%);
  width: 10px;
  padding-bottom: 3px;
  margin-left: 2px;
  cursor: pointer;
}
.partner-star-container {
  display: inline-block;
  margin-left: 1rem;
}

.rating {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 35px;
  height: 27px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23bdb9b9' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  transition: 0.3s;
}

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating-list {
  display: flex;
  width: 100%;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  justify-content: flex-end;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating-list > input {
  display: none;
}

.rating-list > label {
  width: 24px;
  height: 24px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23bdb9b9' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 65%;
  transition: 0.3s;
  margin-bottom: 0;
}

.rating-list > input:checked ~ label,
.rating-list > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating-dispatch {
  display: flex;
  width: unset;
  justify-content: center;
  overflow: hidden;
  flex-direction: row-reverse;
  position: relative;
  margin-left: 0.5rem;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating-dispatch > input {
  display: none;
}

.rating-dispatch > label {
  width: 22px;
  height: 25px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23bdb9b9' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 69%;
  transition: 0.3s;
  margin-bottom: 0.5rem;
}

.rating-dispatch > input:checked ~ label,
.rating-dispatch > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.supplier-icons {
  width: 2rem;
  margin-left: 10px;
  margin-right: 5px;
  padding-bottom: 10px;
  cursor: pointer;
}

.quote-follow-up {
  width: 2rem;
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
}

.twenty-four-dispatch {
  width: 1.5rem;
  margin-left: 10px;
  padding-bottom: 5px;
}

.twenty-four-service-list {
  padding-bottom: 20px;
  margin-left: 5px;
  width: 1.3rem;
}

.important-client-list {
  padding-bottom: 3px;
  margin-left: 10px;
  width: 1rem;
}

// .gm-style-iw + div {display: none;}

.gm-ui-hover-effect {
  display: none !important;
}
.edit-company {
  border-bottom: 1.5px solid rgba($dark-grey, 0.2);
  background-color: $light-2;
  padding: 0em 1.5em 1em 1.5em;
  font-size: 15px;
}

.fontSize18 {
  font-size: 1.1 rem; // 18px
}
.tableau-border-bottom {
  border-bottom: 1.5px solid rgba($dark-grey, 0.5);
}

.ClickOnCall {
  color: #0c0c0c;
  text-decoration: underline;
  &:hover {
    color: rgba($dark-grey, 0.5);
  }
}

fieldNumber::-webkit-inner-spin-button,
fieldNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;

  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
  }
}
.edit-client-subtitle {
  font-size: 15px;
  margin-top: 0.75rem;
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .edit-client-subtitle {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.edit-client-document-name {
  margin-bottom: 1.5rem;
}

.agent-list-main {
  width: 100%;
  padding: 0 9px;
  border: 1px solid blue;
  border-radius: 2px;
  display: inline-block;
  text-decoration: none;
}

.agent-list-select {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.1rem;
  font-size: 12px;
  font-family: $font-family-base;
  color: black;
  text-decoration: none;
}

.agent-list-item {
  padding-left: 0.2rem;
  padding-top: 0.1rem;
  font-size: 12px;
  font-family: $font-family-base;
}
.stepTwoHeight {
  height: calc(100vh - 68px) !important;
}

.remove-button-border {
  outline: none !important;
  box-shadow: none !important;
}

@media (min-width: 768px) {
  /* Adjust to match Bootstrap's md breakpoint */
  .flex-fill {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333%;
  }
}

@media (max-width: 767.98px) {
  .flex-fill {
    display: none;
  }
}

.small-textarea {
  textarea.form-control {
    min-height: 50px !important;
  }
}
.profile-pic-border {
  border: 1px solid rgba($dark, 0.25);
}

.benefit-text-column {
  padding-left: 0.3rem;
  margin-top: 0.188rem;
  min-width: 280px;
  p {
    color: #636d77;
    font-size: 15px;
  }
}

.benefit-icon-column {
  padding-left: 0px;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.rolzo-first-mobile-tabs {
  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 20px;
    justify-content: space-between;

    // .nav-link {
    //   background-color: transparent;
    //   color: rgba($dark-grey, 0.5);
    //   font-weight: 500;
    //   padding: 9px 18px;
    //   border-radius: 3px;

    //   &:hover {
    //     color: rgba($dark-grey, 0.7);
    //   }

    //   &.active {
    //     background-color: $white;
    //     color: $dark-3;
    //     box-shadow: $default-shadow;
    //   }
    //   &:focus {
    //     box-shadow: none;
    //   }
    // }

    &.light-tabs {
      border-bottom: 1px solid rgba($dark-grey, 0.3);
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 16px;
        font-weight: 500;
        color: #8b959e;
        opacity: 0.55;
        padding: 10px 20px 15px 2%;
        border: none;

        &.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid $black;
          box-shadow: none;
          color: $dark-2;
          opacity: 1;
          // margin-bottom: -2px;
          font-weight: 600;
        }

        &:not(:last-child) {
          margin-right: 35px;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.rolzo-first-subheading {
  font-size: 15px;
  color: #636d77;
}

.rolzo-first-eligibility {
  height: 15rem;
  background-color: #fafafa;
  margin-bottom: 1.875rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;

  p {
    font-size: 15px;
    margin-bottom: 0.938rem;
    font-weight: 400;
  }

  ul {
    font-size: 15px;
    color: #636d77;
    padding: 0 0 0 1rem;
  }
}

.rolzo-first-production {
  height: 23.313rem;
  background-color: #fafafa;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  margin-left: 0.085rem;

  .heading-text {
    font-weight: 400;
    font-size: 15px;
    color: #636d77;
    margin-bottom: 0;
  }

  .local-revenue-text {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 0;
  }

  .usd-revenue-text {
    font-weight: 400;
    font-size: 18px;
    color: #636d77;
    margin-bottom: 2.5rem;
  }
}

.rolzo-first-production-tabs {
  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 0px;

    &.light-tabs {
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 16px;
        font-weight: 500;
        color: #8b959e;
        opacity: 0.55;
        padding: 10px 20px 15px 2%;
        border: none;

        &.active {
          background-color: #fafafa;
          box-shadow: 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
          border: none;
          color: $dark-2;
          opacity: 1;
          font-weight: 600;
          border-radius: 1px;
        }

        &:not(:last-child) {
          margin-right: 0px;
        }
        &:focus {
          // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.referral-box {
  height: 15.25rem;
  background-color: #fafafa;
  margin-bottom: 1.875rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem 1.25rem 2.5rem;

  p {
    font-size: 15px;
    margin-bottom: 0.5rem;
    color: #636d77;
    font-weight: 400;
  }
}

.link-invite {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.563rem;
  padding: 0 0.313rem 0 0.625rem;
  justify-content: space-between;
  background-color: white;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .copy-btn {
    margin: 5px 0px !important;
  }

  @media (max-width: 768px) {
    .copy-btn {
      margin: 5px 0px !important;
      width: 60%;
      padding: 0 !important;
    }
  }

  .form-group {
    margin-bottom: 0 !important;
    background-color: white;
    width: 77%;
  }

  input.form-control {
    background-color: white !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
  input.form-control {
    border: 0px !important;
  }
}

.email-invite {
  .btn-div {
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: white !important;
  }

  .submit-btn {
    margin: 5px 0px 5px 10px !important;
  }

  @media (max-width: 768px) {
    .submit-btn {
      margin: 5px 0px !important;
      width: 95%;
      padding: 0 !important;
    }
  }

  input.form-control {
    background-color: white !important;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
    padding: 0 0.313rem 0 0.625rem !important;
  }
  input.form-control {
    border: 0px !important;
  }
}

/* Hides the up/down arrows for number inputs in Chrome, Edge, and Safari */
.hide-number-spinners::-webkit-inner-spin-button,
.hide-number-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hides the up/down arrows for number inputs in Firefox */
.hide-number-spinners {
  -moz-appearance: textfield;
}

.remove-member-btn-wrap {
  align-self: center;
  position: absolute;
  right: -34px;
  width: fit-content;
  top: 32px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.internal-bookingid-container {
  .form-group {
    margin-bottom: 0;
  }
}

.h-fit {
  height: fit-content !important;
}
.py-12 {
  padding-block: 12px !important;
}
input[type='text'].show-pin,
input[type='text'].show-close-icon {
  text-overflow: ellipsis !important;
  background-image: url('/images/mapIcons/Icon_Location.png');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center right 5px;
  padding: 0 35px 0 10px !important;
}
input[type='text'].show-close-icon {
  background-image: none !important;
}

.padding-left-partner {
  padding-left: 0.75rem !important;
}
.h-0 {
  height: 0px !important;
}

.referral-banner {
  display: flex;
  justify-content: space-between;
  background-color: rgba(#a79779, 0.2);
  border-bottom: 2px solid #a79779;
  padding: 10px 60px;
  align-items: center;

  .banner-header {
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 0;
    margin-top: 0.25rem;
    font-size: 14px;
  }

  span {
    text-shadow: 0px 0px 0 black;
  }

  .right-div {
    display: flex;
    align-items: center;
  }

  .close-icon {
    width: 15px;
    height: 15px;
    margin-left: 2rem;
    cursor: pointer;
  }

  .referral-icon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
}

.referees-table-mobile {
  &.expandable {
    cursor: pointer;
    padding-right: 40px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url('/images/icons/caret-down.svg');
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat;
    }

    &.expanded {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.px-5px {
  padding-inline: 5px !important;
}
.rotate-180 {
  rotate: 180deg;
}
// global rate POC css start
.combined-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  align-items: center; /* Align items vertically in the center */
}

.inline-form-row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 0;
  margin-left: 0;
}

.inline-form-row .col {
  flex: 1; /* Ensure equal spacing for columns */
  padding: 0 5px; /* Maintain spacing between fields */
}
// global rate POC css ends
.avg-distance-duration-fields {
  .form-group {
    margin-bottom: 0;
  }
}
.ag-theme-alpine .ag-cell {
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
.ag-theme-alpine .ag-header-cell {
  font-size: 14px !important;
}

.ag-theme-alpine .ag-row:last-child .ag-cell {
  border-bottom: none;
}

.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #ccc !important;
}

.ag-theme-alpine {
  .export-import-btns {
    position: absolute;
    right: 25px;
    top: 0;
  }
}
