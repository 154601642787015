.form-wrap {
  max-width: 850px;
  &.reset-password {
    max-width: 806px;
  }
  .search-vehicle {
    .submit-btn {
      margin-top: 0;
    }
  }

  .book-vehicle {
    margin-left: 23px;
    margin-right: 3px;
    margin-top: 5px;

    .submit-btn {
      margin-top: 0;
      width: 100%;
      border-radius: 20px;
    }
  }

  .b2c-empty {
    margin-top: 10px;

    .submit-btn {
      margin-top: 0;
      width: 150px;
      border-radius: 20px;
    }
  }

  .submit-block {
    padding-right: 50px;
    margin-bottom: 20px;
  }

  button.forgot-password {
    width: 100%;
    margin-top: 0;
  }
  .team-member {
    margin: 0 0 50px;
    position: relative;
  }
  .team-member.last {
    margin: 0;
  }
}

.form-wrap-supplier {
  width: 109%;
  float: left;

  .search-vehicle {
    .submit-btn {
      margin-top: 0;
    }
  }

  .submit-block {
    padding-right: 50px;
    margin-bottom: 20px;
  }

  button.forgot-password {
    width: 100%;
    margin-top: 0;
  }
  .form-block {
    margin: 0px;
  }

  .chauffeur-card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba($dark-grey, 0.1);
    padding: 1em 1em;
    margin-top: 1em;
    max-height: 70px;

    .add-pic-container {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid rgba($dark-grey, 1);
      justify-content: center;
      border-radius: 50%;
    }

    .add-pic-container-uploaded {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid black;
    }

    .info-span {
      flex: 0 0 65%;
    }
  }
  .vehicle-card-item {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    background-color: rgba($dark-grey, 0.1);
    padding: 1em 1em;
    margin-top: 1em;
    max-height: 70px;

    .car-edit-img {
      width: 24%;
      margin-right: 15px;
      align-self: flex-start;
    }
    .vehicle-brand {
      display: block;
      font-weight: bolder;
    }
  }

  .form-row-vehicle {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(21em, 23.3em));

    @media (min-width: 992px) {
      grid-template-columns: repeat(auto-fill, minmax(21em, 1fr));
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr));
    }
  }
  .form-row-chauffeur {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.625em, 18.65em));

    @media (min-width: 992px) {
      grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr));
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr));
    }
  }

  .flex-container-cities {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .grid-item-cities {
    flex: 0 0 50%; /* Two items per row */

    @media (max-width: 768px) {
      flex: 0 0 100%; /* One item per row on smaller screens */
    }

    .form-row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px; /* Adjust as needed */

      .col {
        flex: 0 0 30%; /* Three items beside each other */
        /* Add margin-right for spacing */
      }
    }
  }

  .grid-hidden-col {
    flex: 0 0 30%; /* Adjust the width as needed */
    visibility: hidden;
  }

  .flex-container-vehicles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .flex-item-vehicles {
    flex: 0 0 calc(50% - 8px);

    @media (max-width: 992px) {
      flex: 0 0 calc(100% - 16px);
    }
  }

  .add-item-button {
    width: 100%; /* Button takes full width */
    margin-top: 16px;
  }
  .customCol-vehicles {
    flex: 0 0 60%;
    max-width: 60%;

    @media (max-width: 992px) {
      flex: 0 0 55%;
      max-width: 55%;
    }
  }

  .supplier-save-button {
    margin-right: 7rem !important;
  }
}

.form-block {
  padding: 50px 0 50px 0;
  &.invite-new-members {
    padding-inline: 0 !important;
    @media (max-width: 769px) {
      padding-inline: 24px !important;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba($dark, 0.25);
  }
  &.no-border {
    border: none;
  }
  .currency-select-col {
    .form-group {
      max-width: 115px;
    }
  }
}
.form-block-Modal {
  padding: 20px 0 0px 0 !important;
  &:not(:last-child) {
    border-bottom: 0px solid rgba($dark, 0.25) !important;
  }
  &.no-border {
    border: none;
  }
  .sub-header {
    font-size: 20px;
    // font-weight: lighter;
    margin-bottom: 10px !important;
  }
  .sub-header-text {
    font-size: 14px;
    font-weight: lighter;
    margin-bottom: 10px !important;
    color: #8b959e;
  }
  .currency-select-col {
    .form-group {
      max-width: 115px;
    }
  }
}
.form-block-custom {
  padding: 50px 0 30px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba($dark, 0.25);
  }
  &.no-border {
    border: none;
  }
  .currency-select-col {
    .form-group {
      max-width: 115px;
    }
  }
}
// .book-form {
//   .Booking-Passenger-Info {
//     // .form-block,
//     .form-row {
//       width: auto !important;
//       margin-right: 10px;
//     }
//   }
// }

// .book-form {
//   .form-block {
//     .form-row {
//       width: 98%;
//     }
//   }
// }

.form-block-supplier {
  padding: 5px 0 0 5px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba($dark, 0.25);
  }
  &.no-border {
    border: none;
  }
  .currency-select-col {
    .form-group {
      max-width: 115px;
    }
  }
}

.form-cities {
  padding: 50px 0 50px 0;
  border-top: 1px solid rgba($dark, 0.25);
  border-bottom: 1px solid rgba($dark, 0.25);
}

.form-contact {
  padding: 50px 0 50px 0;
  border-top: 1px solid rgba($dark, 0.25);
}

.form-service {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid rgba($dark, 0.25);
}

.phone-button {
  display: flex;
  bottom: 3.5em;
  pointer-events: none;
  justify-content: flex-end;
  padding-right: 1rem !important;
  position: relative;
  a {
    position: absolute;
    pointer-events: auto;
  }
}
.phone-button-form {
  display: flex;
  bottom: 2.5em;
  pointer-events: none;
  justify-content: flex-end;
  padding-right: 1rem !important;
  position: relative;
  a {
    position: absolute;
    pointer-events: auto;
  }
}

.form-vehicle {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid rgba(139, 149, 158, 0.25);
}

.kpi-dispatch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.kpi-dispatch-end {
  display: flex;
  justify-content: end;
  margin-bottom: 5px;
}
.kpi-bookings {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 10px;
}
.kpi-revenue {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.kpi-score {
  border: 1px solid black;
  border-radius: 50%;
  width: 4em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kpi-score-admin {
  border: 1px solid black;
  border-radius: 50%;
  padding: 7px;
  margin-left: 18px;
  font-size: 16px;
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: middle;
}
.new-bookings-car-rentals {
  .form-block {
    .form-row {
      width: 100% !important;
    }
  }
}
.max-w-494px {
  max-width: 494px !important;
}
.driver-card {
  .react-select__control {
    padding-inline: 10px;
  }
  &.px-10px {
    padding-inline: 10px !important;
  }
  .last-driver-section {
    border-bottom: none;
  }

  .border-container-line {
    border-bottom: 1px solid rgba(139, 149, 158, 0.25);
  }
  .driver-submit {
    border-bottom: 1px solid rgba($dark, 0.25);

    .submit-btn {
      margin-top: 0;
      margin-bottom: 50px;
    }
  }

  .remove-file-btn {
    display: block;
    color: $red;
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    &:hover {
      text-decoration: none;
      color: $red;
    }
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.book-form {
  .form-block {
    .form-row {
      width: 82%;
      max-width: 505px;
      .form-benefits {
        width: 100%;
        max-width: 505px;
      }
      .form-benefits-carRental {
        width: 89%;
        max-width: 505px;
      }
    }
  }
  .form-block {
    .form-benefits {
      width: 100%;
      max-width: 505px;
    }
  }

  .remove-card-btn {
    display: block;
    color: $red;
    margin-bottom: 5px;
    &:hover {
      text-decoration: none;
      color: $red;
    }
  }

  .add-driver-link {
    display: block;
  }
}

.is-book {
  margin-right: -10px !important;
}

.phone-field-row {
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: nowrap;
  & > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .phone-number-input,
  .phone-number-flag {
    padding: 0;
  }
  .dropdown-btn {
    display: flex;
    align-items: center;
    span {
      width: 100% !important;
      flex-shrink: 1;
    }
    img.dropdown-svg,
    img.icon-flag {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .phone-input .is-invalid {
    border: 1px solid red !important;
  }

  .phone-input {
    width: 100% !important;
    border: 0.5px solid transparent !important;
    border-radius: 2px !important;
    background-color: #fbfbfb !important;
    background: #fbfbfb !important;
    color: #0c0c0c !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    &:hover {
      border: 0.5px solid #0c0c0c !important;
    }

    &:focus {
      // box-shadow: none !important;
      border: 0.5px solid #0c0c0c !important;
    }
    &:active {
      // box-shadow: none !important;
      color: #0c0c0c !important;
    }
  }
}
.phone-field-equal {
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: nowrap;
  & > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .phone-number-input,
  .phone-number-flag {
    padding: 0;
  }
  .dropdown-btn {
    display: flex;
    align-items: center;
    span {
      width: 100% !important;
      flex-shrink: 1;
    }
    img.dropdown-svg,
    img.icon-flag {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .phone-input .is-invalid {
    border: 1px solid red !important;
  }

  .phone-input {
    width: 100% !important;
    border: 0.5px solid transparent !important;
    border-radius: 2px !important;
    background-color: #fbfbfb !important;
    background: #fbfbfb !important;
    color: #0c0c0c !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    &:hover {
      border: 0.5px solid #0c0c0c !important;
    }

    &:focus {
      box-shadow: none !important;
      border: 0.5px solid #0c0c0c !important;
    }
    &:active {
      box-shadow: none !important;
      color: #0c0c0c !important;
    }
  }
}

.pointer-link {
  cursor: pointer;
}

.custom-height {
  height: 62%;
}
.benefits-member-message {
  box-sizing: content-box;
  background-color: $white;
  //border-radius: 3px;
  //box-shadow: 9px 9px 9px 9px rgba($black, 0.03);
  //box-shadow: $default-shadow;
  //box-shadow: $card-shadow;
  box-shadow: 0px 10px 15px 0px rgba(#000000, 0.1);
  padding: 20px;
  //min-width: auto;
  max-width: 600px;
  border-radius: 2px;
  border: 1px solid rgba($dark-grey, 0.1);
  // border-top-color: #a79779;
  // border-right-color: #a79779;
  // border-bottom-color: #a79779;
  // border-left-color: #a79779;

  border-top-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: solid !important;
  border-left-style: solid !important;

  border-top-width: 0.5px !important;
  border-right-width: 0.5px !important;
  border-bottom-width: 0.5px !important;
  border-left-width: 0.5px !important;

  border-image-width: initial !important;
  border-image-source: initial !important;
  border-image-repeat: initial !important;
  border-image-outset: initial !important;
  border-image-slice: initial !important;
  .benefits-header {
    font-size: 14px !important;
    font-weight: 600 !important;
    word-break: break-word;

    // padding-bottom: 0% !important;
  }
  .benefits-body {
    font-size: 13px !important;
    //padding-top: 2% !important;
    //padding-top: 1%;
  }
  .benefits-confirm {
    width: 100% !important;
    margin-bottom: 7% !important;
  }
  .close-icon {
    // position: relative !important;
    // top: 20px;
    // right: 22px;
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.promoCode-mobile {
  margin-bottom: 0.3rem !important;
}
.promoCode-icon-width {
  margin-left: 1px !important;
}
.promoCode-x-icon-width {
  margin-right: 0px !important;
}
.promoCode-x-icon-desktop {
  margin-bottom: 0.2rem !important;
}
.add-global-rate {
  .form-block {
    max-width: 850px;

    &.wide {
      max-width: 100%;
    }
  }

  .rates-block {
    .left-side,
    .right-side {
      background-color: $white;
      border-radius: 6px;
      box-shadow: 0px 2px 9px 0px rgba($black, 0.03);
      padding: 30px;
      width: 100%;
    }

    .left-side {
      padding: 30px 20px;
    }

    .locations-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .location-item {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid $light-grey-2;
        padding: 20px;
        padding-top: 10px;
        padding-right: 35px;
        font-size: 14px;
        color: $dark;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        margin-bottom: 20px;

        .dropdown {
          position: absolute;
          right: 5px;
          top: calc(50% - 17px);

          .dots-toggle {
            opacity: 0.5;
          }
        }

        &:hover {
          border-color: rgba($dark-grey, 0.25);
          color: $black;

          .dropdown {
            .dots-toggle {
              opacity: 1;
            }
          }
        }

        &.disabled {
          background-color: $light-2;
          color: rgba($dark, 80%);

          &:hover {
            border-color: rgba($dark-grey, 0.25);
            color: rgba($dark, 80%);
          }
        }

        @include media-breakpoint-up(xl) {
          width: calc(50% - 10px);
        }

        .location-state {
          position: absolute;
          margin-top: 15px;
          font-size: 10px;
        }
        .location-state-country {
          display: block;
          font-size: 10px;
          margin-top: -3px;
          margin-bottom: -3px;
        }
      }
    }

    .rate-form {
      label {
        display: inline-block;
        min-height: 12px;
      }

      .remove-btn {
        margin-top: 36px;
      }

      .rate-form-section {
        padding: 20px;
        border: 1px solid rgba($dark-grey, 0.3);
        border-radius: 5px;
      }
      .rate-form-section-red {
        padding: 20px;
        border: 1px solid rgba($red, 0.3);
        border-radius: 5px;
      }
      .rate-form-section-yellow {
        padding: 20px;
        border: 2px solid rgba($warning, 0.6);
        border-radius: 5px;
      }
      .rate-form-smart-status {
        border: none;
        padding-left: 0px;
        padding-top: 25px;
        padding-bottom: 0px;
      }

      .remove-section {
        display: block;
        text-align: right;

        .btn-link {
          font-size: 12px;
          padding-right: 0;
        }
      }
    }
  }
}
.meet-greet-rate {
  .form-block {
    max-width: 95%;
  }
}

.spinner {
  display: inline;
}

.edit-link {
  color: $dark-2;

  &:hover {
    text-decoration: none;
    color: $dark-2;
  }
}

.passenger-information-special {
  padding-top: 9px !important;
  padding-bottom: 10px !important;
}

.description-exclusive-benefits-desktop {
  background: url('/images/icons/loyalty-icon-2.png');
  background-position: top 1px left 0px;
  background-size: 16px 16px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 28px;
  font-weight: 300;
  margin-bottom: 4px;
}
.description-exclusive-benefits-mobile {
  background: url('/images/icons/loyalty-icon-2.png');
  background-position: top 0px left 0px;
  background-size: 16px 16px;
  font-size: 15px;
  background-repeat: no-repeat;
  padding-left: 28px;
  font-weight: 300;
  margin-bottom: 4px;
}

.description-benefits-desktop {
  background: url('/images/icons/loyalty-icon-2.png');
  background-position: top 0.8px left 0px;
  background-size: 15px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 26px;
  margin-bottom: 4px;
}
.description-benefits-mobile {
  background: url('/images/icons/loyalty-icon-2.png');
  background-position: top 1px left 0px;
  background-size: 15px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 30px;
  margin-bottom: 4px;
  margin-left: 3rem;
}

.additional-charge-edit-btn {
  // margin-left: 11rem;
  display: flex;
  align-items: center;
  //padding-right: 2%;
}

.flightTracking-info {
  // background-color: $light-2;
  // box-shadow: $default-shadow;
  padding-left: 1.1rem;
  padding-right: 0.1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border: none;
  // border: 1.5px solid rgba($dark, 0.1);
  // border-radius: 2px;

  .flightTitle {
    // font-size: 20px;
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    color: $black;
  }
  .flightCityName {
    // font-size: 13px !important;
    // color: $dark-2;
    font-size: 13px !important;
    text-align: left;
    color: $black;
    font-weight: 400;
    margin-bottom: 0.3rem;
  }
  .flightSubTitle {
    // font-size: 13px !important;
    // color: $dark-2;
    font-size: 14px !important;
    text-align: left;
    color: $black;
    font-weight: 400;
  }
  .flightStatus-delayed {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: $red;
    padding: 0.25rem 0.3rem 0.1rem 0.3rem;
    margin-bottom: 1.3rem;
    text-transform: uppercase;
  }
  .flightStatus-onTime {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    // background-color: $aquamarine;
    background-color: $success;
    padding: 0.25rem 0.3rem 0.1rem 0.3rem;
    margin-bottom: 1.3rem;
  }
  .flight-iata-departure {
    // font-size: 25px !important;
    // max-width: 8%;
    font-size: 20px !important;
    font-weight: lighter;
    color: $black;
    margin-right: 1.75rem;
  }
  .flight-iata-arrival {
    // font-size: 25px !important;
    // max-width: 8%;
    font-size: 20px !important;
    font-weight: lighter;
    color: $black;
    margin-left: 1rem;
  }
  .flight-time-green {
    // font-size: 20px !important;
    // color: $aquamarine;
    // padding-top: 0rem !important;
    font-size: 18px !important;
    font-weight: lighter !important;
    color: $success;
  }
  .flight-time-red {
    // font-size: 20px !important;
    // padding-top: 0rem;
    font-size: 18px !important;
    font-weight: lighter !important;
    color: $red;
  }
  .flight-inner-title {
    // font-size: 10px !important;
    // padding-top: 0.5rem;
    display: block;
    line-height: 10px;
    font-size: 9px !important;
    color: $dark-grey !important;
    text-transform: uppercase;
  }

  .flight-arrival-section {
    padding-left: 2rem !important;
  }
  .flight-section-border {
    border-right: 1px solid rgba(139, 149, 158, 0.25);
  }
  .flight-align-row {
    padding-left: 0.1rem;
  }
  .flight-gate {
    padding-left: 2rem !important;
  }
  .terminal-gate-num {
    // font-size: 18px !important;
    font-size: 16px !important;
  }

  .progressBar {
    width: 100%;
    height: 0.75em;
    border: 0.125em solid darken(#3f3f3f, 3);
    background: darken(#3f3f3f, 5);
    overflow: hidden;
    // @include border-radius(25px);
  }

  .iata-width {
    max-width: 10% !important;
  }
  .myProgress {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    border-radius: 3px;
    background-color: $dark-grey;
  }
  .margin-top {
    margin-top: 0.7rem !important;
  }
  .myBar-red {
    position: absolute;
    width: 1%;
    height: 100%;
    border-radius: 2px;
    background-color: $red;
    transition: width 2s;
  }

  .myBar-green {
    position: absolute;
    width: 1%;
    height: 100%;
    border-radius: 2px;
    // background-color: $aquamarine;
    background-color: $success;
    transition: width 2s;
  }

  .progress-dot-grey {
    height: 8px;
    width: 8px;
    background-color: $dark-grey;
    border-radius: 50%;
    display: inline-block;
  }
  .progress-dot-red {
    height: 8px;
    width: 8px;
    background-color: $red;
    border-radius: 50%;
    display: inline-block;
  }

  .progress-dot-green {
    height: 8px;
    width: 8px;
    // background-color: $aquamarine;
    background-color: $success;
    border-radius: 50%;
    display: inline-block;
  }

  .plane-position {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .plane-size {
    max-width: 20px !important;
    max-height: 20px !important;
    border-left: 1rem;
    border-color: white;
    border-width: 1rem;
  }
  .invalid-flight {
    font-size: 18px;
    text-align: left;
    font-weight: lighter;
    color: $black;
  }
  .dot-size {
    font-size: 7px;
  }
  .progress-time {
    font-size: 9px !important;
    color: $dark-grey !important;
  }
  .time-bar {
    flex-wrap: wrap;
    padding-bottom: 1.3rem;
  }
}

// .flightTracking-loader {
//   background-color: $light-2;
//   box-shadow: $default-shadow;
//   height: 17rem;
// }
.flightTracking-loader {
  padding-right: 30px;
  // background-image: url('/images/icons/pin.png');
  background-image: url('/images/planeIcons/planeLoader.svg');
  background-repeat: no-repeat;
  background-size: 15px 20px;
  background-position: center right 8px;
  color: $black;
}
.flightTracking-delete {
  position: absolute;
  display: block;
  top: 5px;
  right: 0px;
  width: 16px;
  height: 16px;
  background: url('http://cdn.sstatic.net/stackoverflow/img/sprites.png?v=4') 0 -690px;
  cursor: pointer;
}
.markAsPaid {
  padding-left: 0.2rem !important;
  font-style: italic !important;
  &:hover {
    text-decoration: underline;
  }
}
.selectButton {
  padding-left: 0.3rem !important;
  font-size: 14px !important;
  color: $black;
  // font-style: italic !important;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    box-shadow: none;
  }
}

.SOA-total-title {
  font-size: 15px;
  font-weight: bold;
  color: $black;
}

.SOA-total-value {
  font-size: 13px;
  font-weight: 300;
  color: $black;
}

.SOA-Button {
  height: fit-content;
  background-color: $black;
  color: white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-color: $black;
}

.SOA-border {
  border-top: 1px solid rgba(139, 149, 158, 0.25);
}

.SOA-text {
  font-weight: bold;
}
.SOA-type {
  font-size: 10px;
  color: #8b959e;
}
.meet-and-assist-container {
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.1);
  // border-color: $brown;
  box-shadow: $default-shadow;
  background-color: $light-2;
  padding: 1.5em 1.5em;
  margin-top: 1em;

  .meet-and-assist-outer {
    .outer-title {
      font-size: 18px;
      text-align: left;
      font-weight: lighter;
      color: $black;
      display: block;
    }
    .outer-description {
      font-size: 14px;
      text-align: left;
      font-weight: lighter;
      color: $black;
      padding-top: 7px;
      width: 85%;
    }
  }
  .meet-and-assist-inner {
    background-color: $white;
    border-radius: 2px;
    border: 1.5px solid rgba($dark-grey, 0.1);
    border-color: $brown;
    box-shadow: $default-shadow;
    padding: 15px 45px 20px 25px;
    margin-top: 2em;

    .inner-title {
      font-size: 14px;
      color: $dark-2;
      display: block;
      padding-top: 3px;
      padding-left: 8px;
    }
    .price {
      font-size: 15px;
      color: $dark-2;
      font-weight: normal;
      display: block;
      text-align: right;
      margin-top: 1rem;
    }
    .inner-description {
      font-size: 11px;
      color: $dark-2;
      padding-left: 2em;
      display: block;
    }
    .commission {
      color: #8b959e;
      font-size: 12px;
      display: block;
      text-align: right;
    }
    .meet-and-assist-features {
      padding-top: 5px;
      div {
        background: url('/images/Tick_Icon.png');
        font-size: 14px;
        background-position: top 3px left 0px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        padding-left: 28px;
        margin-bottom: 4px;
      }
    }
    .meet-and-assist-payment {
      padding-top: 10px;
    }

    .custom-radio {
      margin-bottom: 10px;
      .custom-control-label {
        &:after {
          background-image: url('/images/icons/radio-golden.svg');
        }
      }
      .custom-control-input {
        &:checked {
          ~ .custom-control-label {
            &:after {
              background-image: url('/images/icons/radio-checked.svg');
            }
          }
        }
      }
    }
  }
}
.flight-tracking-prompt {
  border: 1.5px solid rgba($dark, 0.1);
  padding: 1rem 1rem;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  padding-top: 20px;
  border-radius: 2px;

  p {
    font-weight: 900;
  }

  span {
    color: $dark-grey-2;
  }
}
.planeSpinner {
  padding-right: 30px;
  // background-image: url('/images/icons/pin.png');
  background-repeat: no-repeat;
  background-size: 15px 20px;
  background-position: center left 28px;
  color: $black;
  background-image: url('/images/planeIcons/plane_green.png');

  animation: spin_main 2s infinite linear;
}
@-webkit-keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.inlineColClass {
  display: inline-block;
  width: max-content;
}

.viewButton {
  &:hover {
    text-decoration: underline;
  }
}

.notesBorder {
  border-radius: 2px;
  border: 1.5px solid $red;
  padding-top: 0.3rem;
}
.notesBorder-black {
  border-radius: 2px;
  border: 1.5px solid $black;
  padding-top: 0.3rem;
}

.blackBorder {
  border-color: $black !important;
}
.marginBottom {
  margin-bottom: 0rem !important;
}
.headSectionPadding {
  padding-right: 2rem !important;
}
.topPaddingConfirm {
  padding-top: 2px !important;
}
.withoutBadge {
  margin-top: 32px !important;
  padding: 26px 33px 26px 20px !important;
  font-size: 12px !important;
}
.withBadge {
  margin-top: 26px !important;
  padding: 26px 33px 26px 20px !important;
  font-size: 12px !important;
}
.blackFont {
  color: $black;
}
.warning-text {
  color: $warning;
}
.success-text {
  color: $success;
}
.quote-req-text {
  color: #1e2b8e;
}
.dispute-text {
  color: $purple-2;
}
.refund-text {
  color: $dark-grey-2;
}

.messageContainer {
  box-sizing: content-box;
  background-color: $white;
  padding-left: 0.4rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 1px;
  border: 1px solid rgba($dark-grey, 0.3);
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;

  .message {
    background-color: $white;
    background-position: top 0.8px left 0px;
    background-size: 20px 15px;
    font-size: 14px;
    font-weight: 400;
    background-repeat: no-repeat;
    padding-left: 32px;
    border-radius: 4px;
  }
}

.service-card {
  margin: 6.5px 0;
  padding: 30px;
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  width: 700px;
  &.home-layout {
    padding: 24px;
    overflow: visible;
    margin-bottom: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
    .card-body p {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px;

    .nav-link {
      background-color: transparent;
      color: rgba($dark-grey, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;

      &:hover {
        color: rgba($dark-grey, 0.7);
      }

      &.active {
        background-color: $white;
        color: $dark-3;
        box-shadow: $default-shadow;
      }
    }

    &.light-tabs {
      border-bottom: 1px solid rgba($dark-grey, 0.3);
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none;

        &.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid $black;
          box-shadow: none;
          color: $dark-2;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600;
        }

        &:focus {
          box-shadow: none;
        }

        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
  }

  .card-body {
    padding: 0;
    padding-left: 6px;
    label.expand-title {
      margin-bottom: 10px;
      font-size: 10px;
    }
    label.expand-title-service {
      font-size: 10px;
    }
    p {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.tandc-card {
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  width: 880px;
}

.tandc-text {
  // line-height: 15px;
  font-size: 15px;
  // font-weight: 400;
  // letter-spacing: 0px;
  color: black;
  display: block;
  text-transform: none;
  margin-bottom: 0.3rem;
  padding-left: 24.5px;
  font-family: $font-family-base;
}

.tandc-text-two {
  // line-height: 15px;
  font-size: 15px;
  // font-weight: 400;
  // letter-spacing: 0px;
  color: black;
  display: block;
  text-transform: none;
  margin-bottom: 0.3rem;
  font-family: $font-family-base;
}

.tandc-rental-card {
  margin: 6.5px 0;
  padding: 30px;
  border-radius: 2px;
  border: 1.5px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  width: 700px;
}

.vehicle-card-service {
  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px;

    .nav-link {
      background-color: transparent;
      color: rgba($dark-grey, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;

      &:hover {
        color: rgba($dark-grey, 0.7);
      }

      &.active {
        background-color: $white;
        color: $dark-3;
        box-shadow: $default-shadow;
      }
      &:focus {
        box-shadow: none;
      }
    }

    &.light-tabs {
      border-bottom: 1px solid rgba($dark-grey, 0.3);
      border-radius: 0;
      width: 100%;
      // max-width: 1000px;
      max-width: 100%;
      background: transparent;
      font-size: 15px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none;

        &.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid $black;
          box-shadow: none;
          color: $dark-2;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600;
        }

        &:not(:last-child) {
          margin-right: 25px;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.paymentType {
  .title {
    line-height: 12px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #8b959e;
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.3rem;
  }
  .expanded {
    margin-bottom: 0.8rem;
  }
  .label {
    font-family: $font-family-base;
    color: $black;
    font-size: 15px !important;
    font-weight: 400 !important;
  }
}
.payment-cancel-btn {
  padding-top: 2rem !important;
  padding-left: 0px !important;
}
.refund-btn {
  padding-left: 3.6rem !important;
  padding-right: 3.6rem !important;
}
.payment-invoice {
  padding-left: 1.6rem !important;
  margin-top: 0.6rem !important;
}
.payment-booking-confirmation {
  padding-left: 1.6rem !important;
  margin-top: 0 !important;
}
.payment-receipt {
  padding-left: 1.7rem !important;
  margin-top: 0.8rem !important;
}

.payment-newLabel {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #8b959e;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
  .benefits {
    color: #47dc95 !important;
  }
}
.payment-newLabel-mg {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #8b959e;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
  position: relative;
  float: right;
  .benefits {
    color: #47dc95 !important;
  }
}

.most-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: $success;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
.more-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: blue;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
.mid-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: #ffcc00;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
.less-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: darkorange;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
.least-margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: red;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}
.margin-label {
  line-height: 12px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  color: $black;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.3rem;
}

.change-request-label {
  label {
    color: $red-2 !important;
  }
}

.change-request-p {
  color: $red-2 !important;
  font-size: 10px !important;
}

.decline-change-request {
  color: $red !important;
  border-color: unset !important;
  &:hover {
    color: $red !important;
  }

  &:after {
    background-color: $red !important;
    color: $red !important;
    transition: all ease 0.5s;
  }
}

.close-btn-wrap {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .close-icon-partner-modal {
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.partner-name {
  font-size: 14px !important;
  color: $black !important;
  //width: 225px;
}
.mismatch-text {
  color: $danger;
  font-size: 12px;
  float: right;
}
.partner-status-opened {
  color: $black;
  font-size: 14px !important;
}
.partner-status-unopened {
  color: $dark-grey;
  font-size: 14px !important;
}
.close-icon-partners {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  img {
    width: 10px;
    height: 10px;
  }
}
.banners-message {
  line-height: 12px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b959e;
  display: block;
}

.admin-bookings-tab {
  // margin: 6.5px 0;
  // padding: 30px;
  // border-radius: 2px;
  // border: 1.5px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  // width: 700px;

  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px;

    .nav-link {
      background-color: transparent;
      color: rgba($dark-grey, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;

      &:hover {
        color: rgba($dark-grey, 0.7);
      }

      &.active {
        background-color: $white;
        color: $dark-3;
        box-shadow: $default-shadow;
      }
    }

    &.light-tabs {
      // border-bottom: 1px solid rgba($dark-grey, 0.3);
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none;

        &.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid $black;
          box-shadow: none;
          color: $dark-2;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600;
        }

        &:focus {
          box-shadow: none;
        }

        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
  }

  .card-body {
    padding: 0;
    padding-left: 6px;
    label.expand-title {
      margin-bottom: 10px;
      font-size: 10px;
    }
    label.expand-title-service {
      font-size: 10px;
    }
    p {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}
.partner-modal-tab {
  // margin: 6.5px 0;
  // padding: 30px;
  // border-radius: 2px;
  // border: 1.5px solid rgba($dark-grey, 0.1);
  overflow: hidden;
  // width: 700px;

  .custom-tabs-nav {
    background: rgba($dark-grey, 0.15);
    padding: 7px 15px;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 35px;

    .nav-link {
      background-color: transparent;
      color: rgba($dark-grey, 0.5);
      font-weight: 500;
      padding: 9px 18px;
      border-radius: 3px;

      &:hover {
        color: rgba($dark-grey, 0.7);
      }

      &.active {
        background-color: $white;
        color: $dark-3;
        box-shadow: $default-shadow;
      }
    }

    &.light-tabs {
      // border-bottom: 1px solid rgba($dark-grey, 0.3);
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      max-width: 1000px;
      background: transparent;
      font-size: 15px;
      padding: 0;
      .nav-link {
        background: transparent;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: #8b959e;
        opacity: 0.55;
        padding: 7px 7px 0px 7px;
        border: none;

        &.active {
          background: transparent;
          border: none;
          border-bottom: 2px solid $black;
          box-shadow: none;
          color: $dark-2;
          opacity: 1;
          margin-bottom: -2px;
          font-weight: 600;
        }

        &:focus {
          box-shadow: none;
        }

        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
  }

  .card-body {
    padding: 0;
    padding-left: 6px;
    label.expand-title {
      margin-bottom: 10px;
      font-size: 10px;
    }
    label.expand-title-service {
      font-size: 10px;
    }
    p {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.vehicle-passenger-select-Async {
  background: url('/images/icons/team.svg');
  background-position: top -2.5px left 0px;
  background-size: 15px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 20px;
  margin-right: 5px;
  margin-bottom: 4px;
}
.vehicle-luggage-select-Async {
  background: url('/images/icons/portfolio.svg');
  background-position: top -2.5px left 0px;
  background-size: 13px 15px;
  font-size: 13px;
  font-weight: 400;
  background-repeat: no-repeat;
  padding-left: 20px;
  margin-bottom: 4px;
}
.decline-reason-span {
  cursor: pointer;
  font-size: 14px;
}
.decline-reason-span:hover {
  color: #a79779;
}
.decline-comment-modal-title {
  display: flex;
  justify-content: space-between;
  div {
    padding: 17px;
    font-size: 1.2rem;
  }
}
.decline-comment-modal-btn {
  max-height: 61px;
  img {
    width: 60px;
    padding: 10px;
  }
}
.decilne-comment-modal-body {
  height: 170px;
  display: flex;
  justify-content: center;
  // align-items: center;
  div {
    margin-top: 6%;
  }
}

.grey-background-payout {
  background-color: $grey;
  margin-top: 0em !important;
}

.payout-container {
  padding: 1.5rem 3.5625rem 3.75rem 3.5625rem !important;
  .payout-card {
    border-radius: 10px;
    border: 1.5px solid rgba($dark-grey, 0.2);
    background-color: $light-2;
    padding: 1.5em 1.5em;
    font-size: 15px;
    margin-top: 1em;

    .payout-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px;

      .select-title {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .payout-card-body {
      margin-top: 1.5em;

      div:not(:first-child) {
        margin-top: 0.5em;
      }
    }
  }
}

.payout-table-title {
  text-align: left !important;
  border-bottom: 1px solid rgba(139, 149, 158, 0.3);
  text-transform: uppercase;
}

.payout-expandable {
  cursor: pointer;
  padding-right: 40px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    // transform: translateY(-50%);
    background-image: url('/images/icons/caret-down.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 1rem;
    // transition: transform 0.3s ease;
  }

  &.expanded {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.payout_checkBox {
  margin-bottom: 0.3rem;
}
.global-rate-text {
  color: $dark-grey;
  font-family: $font-family-base !important;
  font-weight: bolder;
  font-size: 13px !important;
  font-style: italic !important;
}

.caret-gold {
  position: absolute;
  top: calc(50% - 10px);
  width: 49px;
  height: 20px;

  filter: invert(73%) sepia(3%) saturate(2935%) hue-rotate(360deg)
    brightness(83%) contrast(86%);
}
.caret-black {
  position: absolute;
  top: calc(50% - 10px);
  width: 49px;
  height: 20px;
}

.flight-header {
  // background-color: $light-2;
  // box-shadow: $default-shadow;
  font-size: 14px;
  // line-height: 43px;
  // margin-bottom: 40px;
  font-weight: 400;
  display: grid;
  grid-template-columns: 20% 80%;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  // margin-top: 1em;
  text-align: left;
  font-weight: bolder;
  color: $black;

  border-bottom: 1.5px solid rgba($dark, 0.1);
  border-top: 1.5px solid rgba($dark, 0.1);
  // border-radius: 2px;
  .flightStatus {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: #417505;
    padding: 0.25rem 0.3rem 0rem 0.3rem;
    height: 15px;
    // margin-bottom: 1.3rem;
  }

  .flight-delayed {
    font-size: 8px !important;
    font-weight: 700 !important;
    display: block;
    box-sizing: content-box;
    border-radius: 2px;
    max-width: fit-content;
    color: white;
    background-color: $red;
    padding: 0.25rem 0.3rem 0rem 0.3rem;
    height: 15px;
    text-transform: uppercase;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    // border-bottom: none;
    // border: 1.5px solid rgba($dark, 0.1);
    // border-radius: 2px;
  }

  .flight-header-iata {
    font-size: 12px;
    margin-left: 0.2rem;
    color: $dark-grey;
    padding-top: 0.13rem;
    font-weight: bolder;
  }
  &.expandable {
    cursor: pointer;
    // padding-right: 40px;
    // margin-right: 0.5px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: calc(2%);
      // padding-right: 40px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url('/images/icons/caret-down.svg');
      background-size: contain;
      background-position: right 0 center;
      background-repeat: no-repeat;
    }

    &.expanded {
      &:after {
        right: calc(2%);
        transform: rotate(180deg);
      }
    }
  }
}
.payment-link-rolzo {
  display: block;
  text-align: center;
  padding-right: 100px;
  padding-top: 40px;
  margin-bottom: 3rem;
  img {
    width: 140px;
  }
  @media (max-width: 768px) {
    padding-right: 10px;
    margin-bottom: 2rem;
  }
}
.payment-link-body {
  padding: 20px 35px 20px 38px;
  @media (min-width: 992px) {
    margin-right: 6.7rem;
  }
}
.payment-link-header {
  display: block;
  text-align: center;
  margin-bottom: 3rem;
}
.payment-link-footer {
  font-size: 13px;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.payment-link-prompt {
  background-color: $white;
  border: 1.5px solid rgba($dark, 0.1);
  padding: 0.5rem 0.5rem;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  // padding-top: 20px;
  border-radius: 2px;

  span {
    color: $black;
  }
}

.payment-link-container {
}
.payment-link-image {
}
.payment-link-radio {
  .custom-control-label {
    flex-grow: 1;
    padding-top: 2px;
    cursor: pointer;
    text-transform: none;
    letter-spacing: initial;
    padding-top: 0.5rem;
    &:before,
    &:after {
      top: 1.2px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }

    &:after {
      background-size: 15px 15px;
    }
  }
}
.flight-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1.5px solid rgba(139, 149, 158, 0.1);
  border-bottom: none;
  border-top: none;
  border-radius: 2px;
  // border-bottom: none;
  // border: 1.5px solid rgba($dark, 0.1);
  // border-radius: 2px;
}

.flight-border-bottom {
  border-bottom: 1.5px solid rgba($dark, 0.1);
}
.flight-border-top-none {
  border-top: none;
}
.card-margin {
  margin-bottom: 0.5em;
}

.createdBy-text {
  color: $dark-grey;
  font-family: $font-family-base !important;
  font-weight: bolder;
  font-size: 13px !important;
  font-style: italic !important;
  padding-right: 16px;
  float: right;
  margin-top: 0.3rem;
  margin-right: 0.2rem;
}

.payment-badge-paid {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: $success;
  padding: 6px 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
}

.payment-badge-unPaid {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  color: $warning;
  padding: 6px 10px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
}
