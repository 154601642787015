.app-content {
  //display: flex;
  //align-items: stretch;
  //height: 100vh;
  position: relative;
  top: 0;
  height: 100vh;

  .sidebar-container {
    //background: $black;
    //color: $light-grey;
    ////height: 100%;
    ////width: 255px;
    ////min-width: 255px;
    overflow: hidden;
    //position: fixed;
    //top: 0;
    //bottom: 0;
    //left: 0;
    //width: 260px;
    //display: block;
    //z-index: 1;
    ////color: #fff;
    //font-weight: 200;
    //background-size: cover;
    //background-position: center center;
    z-index: 2;

    .sidebar-content {
      height: 100%;
      width: fit-content;
      padding: 20px 60px 20px 75px;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .sidebar-content::-webkit-scrollbar {
      display: none;
    }

    .sidebar-content-minimized {
      padding: 20px 20px 20px 45px !important;
    }
    .sidebar-content-minimized-new {
      padding: 20px 20px 20px 35px !important;
    }

    .logo-wrap {
      display: block;
      text-align: center;
      margin: 0 auto 110px;
      padding-right: 25px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .client-logo-wrap {
      display: block;
      text-align: center;
      padding-right: 25px;
      img {
        width: 140px;
      }
    }

    .partner-client-logo-wrap {
      display: block;
      text-align: center;
      padding-right: 100px;
      padding-top: 40px;
      img {
        width: 110px;
      }
    }

    .client-logo-wrap-minimized {
      opacity: 0;
      img {
        margin-left: -36px;
      }
    }

    .client-logo-wrap-expanded {
      opacity: 0;
      img {
        margin-left: -6px;
      }
    }

    .rolzo-logo-wrap-minimized {
      opacity: 0;
    }

    .client-info {
      padding-top: 50px;
      margin-bottom: 35px;
      font-weight: 500;
      font-size: 16px;
      //opacity: 0.5;
      .company-logo {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $dark-grey;
        color: #ffffff;
        opacity: 0.5;
        text-transform: uppercase;
        padding: 4px 3px 0 3px;
        &.isCompanyLogoPage {
          padding: 0 !important;
        }
        &.has-logo {
          background-color: rgba(250, 250, 250, 255);
          opacity: 1;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          //border: 1px solid $brown;
          border: 1px solid rgba($dark-grey, 0.25);
          margin-top: 1em;
          // margin-right: 1em;
        }
      }

      .company-logo-minimized {
        margin-left: -20px;
        transition: all 0.28s ease-in-out;
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-top: -1em;
        }
      }

      .company-logo-expanded {
        transition: all 0.28s ease-in-out;
        img {
          margin-right: -1em;
          margin-top: -1em;
        }
      }

      .user-name {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 2px;
        // white-space: nowrap;
        white-space: initial;
        padding-top: 5px;
      }

      .company-name {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        // white-space: nowrap;
        white-space: initial;
        padding-top: 0 !important;
      }
      .size-zero {
        font-size: 0px;
      }
    }

    .nav-link {
      font-size: 14px;
      font-weight: 400;
      color: $dark-grey;
      padding: 0;
      margin-bottom: 19px;
      display: flex;
      align-items: center;

      .icon {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 10px;
        display: block;

        &-active {
          display: none;
        }
      }

      &:hover,
      &.active {
        color: $white;
        font-weight: 500;
        .icon {
          display: none;

          &-active {
            display: block;
          }
        }
      }

      span {
        padding-top: 10px;
        padding-left: 5px;
      }
    }

    &.sidebar-light {
      background: $light-3;
      color: $black;

      .nav-link {
        color: $dark-grey;
        font-weight: 600;
        font-size: 16px;

        &:hover,
        &.active {
          color: $black;
          font-weight: 600;
        }
      }

      .business_logo {
        position: fixed;
        bottom: 50px;
        left: 70px;
        pointer-events: none;
        img {
          width: 125px;
        }
      }
    }
    &.sidebar-white {
      background: $white;
      color: $black;

      .nav-link {
        color: $dark-grey;
        font-weight: 600;
        font-size: 16px;

        &:hover,
        &.active {
          color: $black;
          font-weight: 600;
        }
      }

      .business_logo {
        position: fixed;
        bottom: 50px;
        left: 70px;
        pointer-events: none;
        img {
          width: 125px;
        }
      }
    }
  }

  .page-wrap {
    .page-with-panel {
      @media (min-width: 768px) {
        width: calc(100% - 380px);
      }

      main {
        padding-right: 20px !important;
      }
    }
    //flex-grow: 1;
    //height: 100%;
    //background: $light;
    //background: rgba(203, 203, 210, 0.15);
    //position: relative;
    //float: right;
    //width: calc(100% - 260px);
    //height: -webkit-fill-available;
    .scrollable-page-content {
      height: 100vh;
      overflow: auto;

      &.no-title {
        //display: flex;
        align-items: flex-start;
      }
      &.booking-detail-layout {
        overflow: hidden;

        main {
          padding-right: 40px;
        }

        .card-body .vehicle-title {
          max-width: 160px;

          @media (max-width: 1180px) {
            max-width: 100px;
          }

          @media (max-width: 1070px) {
            max-width: 80px;
          }
        }
      }
      &.system-booking-detail-layout {
        main {
          padding-left: 40px;
          padding-right: 40px;
        }

        .input-message-block {
          background: white;
          border-top: 1px solid rgba(139, 149, 158, 0.3);
        }

        .preview-files-block {
          margin-top: 10px !important;
          padding-left: 60px !important;
          padding-right: 90px !important;
        }

        .file-preview {
          .form-group {
            margin-bottom: 0;
          }
        }

        //.input-message-wrap {
        //width: calc(100% - 150px) !important;
        // flex-grow: unset !important;
        //}

        .input-message-block .input-message-area .input-message-wrap textarea {
          padding-left: 50px !important;
        }

        .chat-paperclip .custom-file-label {
          padding-left: 25px;
        }

        .card-body .vehicle-title {
          max-width: 160px;

          @media (max-width: 1180px) {
            max-width: 100px;
          }

          @media (max-width: 1070px) {
            max-width: 80px;
          }
        }
      }
    }

    .scrollable-page-content.light-theme {
      height: calc(100vh - 60px);
    }
    .scrollable-page-content.lightThemeHeight {
      height: calc(100vh - 10px);
    }

    .scrollable-page-content::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    .scrollable-page-content::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }

    .scrollable-page-content::-webkit-scrollbar-thumb {
      background-color: rgba(120, 128, 134, 0.5) !important;
      border-radius: 10px;
    }

    .scrollable-page-content-without-supportbar {
      height: 100vh !important;
    }

    header {
      background-color: $grey;
      padding: 40px;
      position: relative;
      margin: 15px 17px 0;

      &.no-title-header {
        padding: 0 !important;
        // padding-top: 15px !important;
        &.support-admin {
          padding: 4px 10px 4px 20px !important;

          border-right: 1px solid rgba(12, 12, 12, 0.05);
          width: 431px;
          @include media-breakpoint-down(sm) {
            border-right: none;
            width: fit-content;
          }
        }
        background-color: transparent !important;
        .no-title-back-btn-wrap {
          padding: 0;

          &.only-btn {
            padding: 0 !important;
            height: 120px;
          }
        }

        @media (max-width: 765px) {
          width: 100px;
          a {
            position: static;
            padding-left: 30px;
          }
        }
      }

      .parent-title {
        color: $dark;
        font-size: 11px;
        margin-bottom: 55px;
        text-transform: uppercase;
      }

      .page-title {
        font-size: 34px;
        font-weight: 400;
        margin-bottom: 0;
        height: 40px;
      }

      .partner-page-title {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 0;
        height: 40px;
      }

      .statementOfAccount {
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 0;
        height: 40px;
      }
      .company-info-title {
        float: right;
        margin-top: 15px;
        h1 {
          margin-bottom: 1rem;
        }
        h4 {
          text-align: end;
        }
      }
      .company-info-title-admin {
        display: flex;
        justify-content: flex-end;
        h1 {
          bottom: 90px;
          position: absolute;
          font-size: 32px;
          margin-bottom: 1rem;
        }
        h4 {
          bottom: 60px;
          position: absolute;
        }
      }
      .company-info-title-bookings {
        display: flex;
        justify-content: flex-end;
        h1 {
          margin-bottom: 1rem;
        }
        h4 {
          text-align: end;
        }
      }
      .add-btn {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        top: 30px;
        // right: 25px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $default-shadow;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          opacity: 0.9;
        }
      }

      .clone-btn {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        top: 45px;
        right: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $default-shadow;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          opacity: 0.9;
        }
      }
      .account-header {
        .layout-btn {
          width: fit-content;
          height: fit-content;
        }
        .back-btn {
          img {
            width: fit-content;
          }
        }
      }
      .layout-btn {
        width: 60px;
        height: 60px;
        position: absolute;
        z-index: 10;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .close-btn {
        top: 45px;
        right: 25px;
      }

      .back-btn {
        top: calc(50% - 30px);
        left: 90px;
      }

      .light-close-btn {
        top: calc(50% - 30px);
        right: 70px;
      }

      @include media-breakpoint-down(md) {
        padding: 30px;
        padding-left: 30px;
        margin: 0;
      }
    }

    main {
      padding: 75px 10% 60px 10%;
    }
    .isAdminMain {
      // padding: 2% 1.5% 60px 1.5% !important;
      padding: 34px 20px 22px 20px !important;
      &.isAddClient {
        padding: 34px 20px 120px 20px !important;
      }
      &.p-0 {
        padding: 0 !important;
      }
    }
    .isAdminPayout {
      padding: 2% 1.5% 20px 0 !important;
    }
    .m-17-20 {
      margin: 17px 20px;
    }
    .isRatesGrid {
      padding: 0 1.5% 20px 0 !important;
    }

    .nested-layout {
      header {
        margin: 20px 17px 0 0;
        padding: 35px 90px 40px 140px;
        .page-title {
          display: flex;
          align-items: center;
          .layout-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: 80px;
            min-width: 80px;
            background: $dark-grey;
            opacity: 0.5;
            border-radius: 50%;
            margin-right: 60px;
            color: $white;
            text-align: center;
            text-transform: uppercase;
            font-size: 33px;
          }

          img.layout-logo {
            object-fit: cover;
            opacity: 1;
          }
        }
        .service-label {
          position: absolute;
          bottom: 25px;
        }
      }
    }

    .light-theme {
      background-color: $white;
      &.reset-password-layout {
        header {
          @include media-breakpoint-up(lg) {
            max-width: 806px !important;
          }
        }
      }
      header {
        background-color: $white;
        font-size: 40px !important;
        padding-bottom: 0;
        padding-left: 60px;
        // padding-left: 20px;
        // padding-left: 0;
        margin-left: 0px;

        @include media-breakpoint-down(lg) {
          padding-left: 40px;
          // padding-left: 20px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 30px;
        }
      }

      main {
        padding-top: 34px;
        padding-left: 60px;
        &.home-page {
          padding-inline: 60px;
          max-width: 1060px;
          @include media-breakpoint-down(lg) {
            padding-inline: 40px;
          }
        }
        &.hide-sidebar {
          //padding-left: 260px;
          &.no-left-padding {
            padding-left: 40px;
          }
          @media (min-width: 765px) and (max-width: 1330px) {
            padding-left: 11vw;
          }
        }

        @include media-breakpoint-down(lg) {
          padding-left: 40px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 30px;
        }
      }

      .hide-booking-title {
        transform: translateY(100px);
        opacity: 0;
        transition: transform 0.7s ease-in-out, opacity 0.3s ease-in-out; /* Delay opacity */
      }

      .show-booking-title {
        transform: translateY(0);
        opacity: 1;
        transition: transform 1s ease-in-out, opacity 1s ease-in-out 0.5s;
      }

      &.nested-layout {
        &.reset-password-layout {
          h4 {
            white-space: pre-wrap;
            line-height: 32px;
          }
          .confirm-message-container {
            margin-top: 50px;
            @include media-breakpoint-up(lg) {
              max-width: 806px;
            }
          }
          p.confirm-message {
            font-size: 20px;
          }
          .navbar {
            background-color: $light-3;
            padding: 70px 0;
            @include media-breakpoint-down(xs) {
              padding: 10px 0 12px;
              background-color: white;
              border-bottom: 1px solid rgba($dark-grey, 0.3);
            }
          }
          header {
            margin: 0;
            padding: 0;
            background-color: inherit !important;
            @include media-breakpoint-down(xs) {
              padding-inline: 15px;
            }
            .light-close-btn {
              width: 60px;
              height: 60px;
            }
          }
          .success-msg-verification {
            height: calc(100vh - 140px);
            color: $white;
            z-index: 10;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            top: 0;
            left: 0;
            background: $white;
            overflow-y: auto;

            img {
              width: 80px;
              height: 80px;
              margin-bottom: 15px;
            }

            .success-msg-header {
              font-size: 16px;
              font-weight: 400;
            }

            .success-msg-description {
              font-size: 16px;
              font-weight: 400;
            }

            .content-wrap {
              display: flex;
              max-width: 500px;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
    .grey-theme {
      min-height: calc(100vh - 4rem);
      background-color: rgba($dark-grey, 0.1);
      header {
        background-color: transparent;
        font-size: 40px;
        padding-bottom: 0;
        padding-left: 60px;
        margin-left: 0px;

        @include media-breakpoint-up(lg) {
          max-width: 806px;
        }
        @include media-breakpoint-down(lg) {
          padding-left: 40px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 30px;
        }
      }

      main {
        padding-top: 34px;
        padding-left: 60px !important;

        &.hide-sidebar {
          //padding-left: 260px;
          &.no-left-padding {
            padding-left: 40px;
          }
          @media (min-width: 765px) and (max-width: 1330px) {
            padding-left: 11vw;
          }
        }

        @include media-breakpoint-down(lg) {
          padding-left: 40px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 30px;
        }
      }

      &.nested-layout {
        &.reset-password-layout {
          h4 {
            white-space: pre-wrap;
            line-height: 32px;
          }
          .confirm-message-container {
            margin-top: 50px;
            @include media-breakpoint-up(lg) {
              max-width: 806px;
            }
          }
          p.confirm-message {
            font-size: 20px;
          }
          .navbar {
            background-color: $light-3;
            padding: 70px 0;
          }
          header {
            margin: 0;
            padding: 0 !important;
            background-color: inherit !important;
          }
          .success-msg-verification {
            height: calc(100vh - 140px);
            color: rgba($dark-grey, 0.1);
            z-index: 10;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            top: 0;
            left: 0;
            background: rgba($dark-grey, 0.1);
            overflow-y: auto;

            img {
              width: 80px;
              height: 80px;
              margin-bottom: 15px;
            }

            .success-msg-header {
              font-size: 16px;
              font-weight: 400;
            }

            .success-msg-description {
              font-size: 16px;
              font-weight: 400;
            }

            .content-wrap {
              display: flex;
              max-width: 500px;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
    &.w-100 {
      main {
        padding-left: 260px;
      }
    }
  }

  .remove-overflow {
    overflow: hidden !important;
  }

  .supportbar-wrap {
    height: 60px;
    width: 100%;
    background: white;
    border-bottom: 1px solid rgba($dark, 0.3);
    display: flex;
    z-index: 9;

    .vehicle-title {
      // max-width: 120px;
      max-width: max-content;
    }
  }

  //.supportbar-wrap-expaned {
  // width: calc(100% - 380px) !important;
  //}

  .supportbar-gap {
    display: flex;
    padding-left: 50px;
    width: calc(100% - 100px);

    @include media-breakpoint-down(lg) {
      padding-left: 30px;
    }

    @include media-breakpoint-down(md) {
      padding-left: 20px;
    }
  }

  .supportbar-btn-wrap {
    margin: 20px 15px;
    cursor: pointer;
  }
  .supportbar-back-btn-wrap {
    margin: 20px 10px;
    cursor: pointer;
  }

  .supportbar-btn-splitter {
    width: 1px;
    margin: 14px 0 12px 0;
    border-left: 1px solid rgba($dark, 0.3);
  }

  .supportbar-img {
    width: 20px;
    height: 20px;
  }

  .supportbar-back-img {
    width: 18px;
    height: 15px;
  }

  .supportbar-back-text {
    font-size: 14px;
    color: $dark-grey;
    font-weight: bold;
    margin-left: 9px;
  }

  .supportdetails-img {
    height: 20px;
    margin-bottom: 10px;
  }

  .event-supportbar-img {
    width: 18px;
    height: 15px;
  }
  .event-supportbar-company {
    padding-top: 0.1em;
    margin: 20px 12px;
    width: fit-content;
    white-space: nowrap;
  }

  .nav-link-img {
    width: 20px;
    margin-right: 10px;
  }

  .nav-link-img-partner {
    width: 18px;
    margin-right: 10px;
    margin-top: 12px;
  }

  .nav-link-img-partner-home {
    width: 17px;
    margin-right: 10px;
    margin-top: 11px;
  }

  .partner-tabs {
    display: flex;
    align-items: baseline;
  }
  .partner-tab {
    font-size: 12px;
    padding: 5px;
    width: 125px;
    text-align: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .tab-active {
    background-color: black;
    color: white;
    border: 1px solid black;
  }
  .tab-inactive {
    background-color: white;
    color: rgba($dark, 1);
    border: 1px solid rgba($dark, 0.5);
  }

  .supportbar-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -23px;
    right: -11px;
  }
  .missing-com-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: $warning;
    color: $warning;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    bottom: 25px;
    left: 11px;
  }
  .missing-earning-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: $warning;
    color: $warning;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    bottom: 22px;
    left: 9px;
  }
  .admin-support-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -28px;
    right: -18px;
  }
  .admin-bookings-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -26px;
    right: -16px;
  }
  .admin-alerts-unread-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: red;
    color: red;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -28px;
    right: -12px;
  }
  .admin-without-indicator {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    background: transparent;
    color: transparent;
    font-size: 6px;
    font-weight: 600;
    text-align: center;
    top: -28px;
    right: -12px;
  }
}
.company-sm-logo {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $dark-grey;
  color: #ffffff;
  opacity: 0.5;
  text-transform: uppercase;
  padding: 4px 3px 0 3px;
}
#logo-tooltip {
  top: 100px !important;
  left: 30px !important;
}
#logo-tooltip > .tooltip-inner {
  background-color: rgba($dark, 0.1);
  color: #000000;
  padding: 15px;
  padding-left: 23px;
  max-width: 190px;
  text-align: left;
}
#logo-tooltip .arrow:before {
  display: none !important;
}
#logo-tooltip > .tooltip-arrow {
  display: none !important;
}

.text-dark {
  color: $dark-grey-2 !important;
}
.text-brown {
  color: $brown;
}
.text-incl-grey {
  margin-top: 2px;
  color: $dark-grey !important;
}
.text-incl-light-green {
  margin-top: 2px;
  color: #47dc95 !important;
}
.text-incl-grey-2 {
  margin-top: 2px;
  color: $dark-grey !important;
  font-size: 0.75rem !important;
}
.text-incl-green {
  margin-top: 2px;
  color: #47dc95 !important;
  font-size: 0.75rem !important;
}
.text-brown-sub {
  color: $dark-grey;
  font-size: 12px !important;
  display: block !important;
}
.text-grey-sub {
  color: $dark-grey;
  font-size: 12px !important;
  display: block;
}
.text-green-sub {
  color: #47dc95;
  font-size: 12px !important;
}
.text-green {
  color: #417505 !important;
}
.text-brown-sub-left {
  color: $dark-grey;
  float: left;
  font-size: 12px !important;
  display: block !important;
}
.text-brown-sub-small {
  color: $dark-grey;
  font-size: 13px !important;
  display: block !important;
}

.text-grey-sub-small {
  color: $dark-grey;
  font-size: 0.76rem !important;
  display: block !important;
}
.text-green-sub-small {
  color: #47dc95;
  font-size: 0.76rem !important;
  display: block !important;
}

.btn-booking-download {
  font-size: 14px;
}

.booking-preview-add-payment {
  font-size: 14px;
}

.booking-select-card {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  &.another-card {
    margin-left: 10px;
    margin-top: 8px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
}
.payment-options {
  display: flex;
  align-items: center;
  z-index: 0;
  .custom-control-label {
    color: $black;
    font-size: 16px !important;
  }
  @media (max-width: 768px) {
    .custom-control-label {
      color: $black;
      font-size: 16px !important;
    }
  }
}
.notification {
  background-color: #fff !important;
  box-shadow: unset !important;
  border: 1px solid #dbdee1;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding: 15px 10px !important;

  .notification-message {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
  }

  .notification-dismiss {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.8) !important;
    top: 12px !important;
    font-weight: 100 !important;
  }
}

.notification-error .notification-title {
  color: #d0021b !important;
  font-weight: 500 !important;
  font-size: 14px;
}

.notification-success .notification-title {
  color: #417505 !important;
  font-weight: 500 !important;
  font-size: 14px;
}

.spinner_wrapper {
  position: absolute;
  left: 50%;
  top: 42px;
  margin-left: -17px;
  background-color: #fff;
  border: 1px rgba($dark, 0.25) solid;
  border-radius: 50%;
  z-index: 5;
}

.main_spinner {
  display: block;
  height: 18px;
  width: 18px;
  border: 2px transparent solid;
  border-top: 2px rgba($dark-grey, 0.5) solid;
  border-right: 2px rgba($dark-grey, 0.5) solid;
  border-bottom: 2px rgba($dark-grey, 0.5) solid;
  border-radius: 50%;
  animation: spin_main 1s 0.5s infinite linear;
  margin: 8px;
}
.align-center {
  align-self: center;
}
@-webkit-keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin_main {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.payment-method-page {
  .payment-radio-group {
    .custom-control-label {
      &:after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.custom-datepicker-calendar .react-datepicker__navigation--previous {
  left: 45px;
}
.custom-datepicker-calendar .react-datepicker__navigation--next {
  right: 45px;
}
.last-driver-section .custom-file-label .file-name {
  margin-top: 2px;
}

.app-content-partner {
  display: flex;
  overflow-x: hidden;
}

.warning-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid $warning;
  border-radius: 2px;
  background: #fff;
  margin-bottom: 1.7rem;
  max-width: 92%;

  .warning-text {
    display: flex;
    margin-left: 10px;
    color: $dark-grey;
    font-size: 14px !important;
    margin-right: 20px;
  }

  .pdf-icon {
    margin-right: 10px;
    height: 1.2em;
  }
  span {
    max-width: 75%;
  }

  @media (max-width: 992px) {
    .warning-text {
      margin-right: 15px;
    }
  }

  @media (max-width: 768px) {
    .warning-text {
      margin-right: 10px;
    }
  }
}
.warning-partner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 0.5em;
  border: 1px solid $warning;
  border-radius: 2px;
  background: #fff;
  margin-bottom: 1.7rem;
  max-width: calc(100vw - 80%);

  .warning-text {
    display: flex;
    margin-left: 10px;
    color: $dark-grey;
    font-size: 14px !important;
    margin-right: 20px;
    span {
      flex-grow: 1;
    }
  }

  .pdf-icon {
    margin-right: 10px;
    height: 1.2em;
  }
  span {
    flex-grow: 1;
    // max-width: 75%;
  }

  @media (max-width: 992px) {
    .warning-text {
      margin-right: 15px;
    }
  }

  @media (max-width: 768px) {
    .warning-text {
      margin-right: 10px;
    }
  }
}

.home-calendar-booking-container {
  display: grid;
  gap: 24px;
  grid-template-columns: minmax(343px, 1fr) minmax(343px, 1fr);
  @include media-breakpoint-down(md) {
    grid-template-columns: 100%;
  }
}

.home {
  display: flex;
  flex-direction: column;
  gap: 30px;
  .show-booking-modal {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .hide-booking-modal {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s 0.5s ease-in-out;
  }
  @include media-breakpoint-down(sm) {
    gap: 0;
  }

  .warning-container {
    border: none;
    max-width: 100%;
    margin-bottom: 0;
    background-color: #fefaf4;
    padding: 24px;
    .warning-text {
      color: $black;
      font-size: 15px;
      margin: 0;
    }
    .pdf-icon {
      height: 20px;
      width: 20px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
    .collapsible-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        max-width: 100%;
        padding-left: 30px;
        color: $black;
      }
    }

    .expanded {
      max-height: 500px;
    }
    span {
      max-width: 100%;
      &.responsive-header {
        font-weight: 500;
        font-size: 15px;
        color: $black;
      }
    }
    .inline-edit-btn {
      align-self: flex-end;
      margin-left: 30px;
    }
  }
  .api-integration-banner {
    position: relative;
    overflow: hidden;
    z-index: 0;
    padding: 24px;
    padding-right: 30px;
    // background-color: #fafaf8;
    background: #a797790d;
    font-size: 14px;
    border: 1px solid $brown;
    border-radius: 2px;
    background-image: url(/images/api_integration_banner_dots.png);
    background-repeat: no-repeat;
    background-position: center right 0;
    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 38px;
    }

    .banner-close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 12px;
      height: 12px;
    }
    .header {
      margin-bottom: 5px;
      font-size: 20px;
      color: $black;
    }
    .text {
      color: $dark-grey-2;
      margin-bottom: 0;
    }
    .learn-more-text {
      display: flex;
      align-items: center;
      width: fit-content;
      color: $brown;
      list-style-type: none;
      text-decoration: none;
      margin-top: 15px;
      &:hover {
        text-decoration: none;
      }
    }

    .banner-image {
      position: absolute;
      right: 50.8px;
      top: 50%;
      width: 350px;
      height: 100%;
      transform: translateY(-50%);
    }
    .banner-ellipse {
      position: absolute;
      right: 0;
      top: 0;
      width: fit-content;
      height: 300px;
      z-index: -1;
    }
  }
  @media (max-width: 1030px) {
    .api-integration-banner {
      background-image: none;
      .banner-image {
        display: none;
      }
    }
    .banner-ellipse {
      display: none;
    }
  }
  .home-cards-container {
    // display: grid;
    display: flex;
    flex-direction: column;
    // grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    @include media-breakpoint-down(md) {
      // grid-template-columns: 1fr;
      margin-bottom: 24px;
    }
    .home-card {
      &.earnings-container {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
      padding: 24px;
      border-radius: 4px;
      background-color: $light-3;
      border: 1px solid rgba($dark-grey, 0.3);
      .progress {
        height: 8px;
        border-radius: 2px;
        background-color: #d9d9d9;
        .progress-bar {
          background-color: #29ae11;
        }
      }
      .card-head {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($dark-grey, 0.3);
        margin-bottom: 16px;
        font-size: 18px;
      }
      .text {
        font-size: 14px;
      }
      .commissions-or-credits-USD {
        font-size: 24px;
        margin-bottom: 8px;
      }
      .credits-eur {
        font-size: 18px;
        margin-bottom: 0;
        color: $dark-grey-2;
      }
      .goal-achieved-message {
        font-size: 12px;
        color: $dark-grey-2;
        margin-top: 16px;
        margin-bottom: 0;
      }
      .referral-link-container {
        margin-top: 16px;
        .title {
          color: $dark-grey-2;
          font-size: 15px;
          margin-bottom: 10px;
        }
        .link {
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 2px;
          font-size: 14px;
          color: $brown;
          .copy-btn {
            cursor: pointer;
          }
          p {
            text-overflow: ellipsis;
            flex-grow: 1;
            overflow: hidden;
            margin-bottom: 0;
            white-space: nowrap;
            max-width: 264px;
            border: 1px solid rgba($dark-grey, 0.3);
            padding: 8px 10px;
            background-color: #fff;
          }
          .btn {
            color: $brown;
            font-size: 14px;
            height: fit-content !important;
            @include media-breakpoint-down(md) {
              // grid-template-columns: 1fr;
              display: none;
            }
          }
        }
      }
      .commission-rate {
        color: $green;
        margin-bottom: 0;
        font-size: 12px;
      }
      .info-icon {
        margin-left: 10px;
        padding: 0;
        width: 15px;
        height: 15px;
      }
    }
  }
  .quote-alert-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .quote-alert {
    background-color: #1e2b8e0d;
    padding: 16px 24px 16px 24px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    // opacity: 0;
    // transform: translateY(-20px);
    // animation: fadeInSlide 0.5s ease-out forwards;
    @keyframes fadeInSlide {
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.3s}; // Stagger effect
      }
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: 24px;
      align-items: start;
    }
    .quote-alert-btns {
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-top: 16px;
      }
      display: flex;
      .view {
        display: flex;
        align-items: center;
        color: $brown;
        gap: 4px;
        @include media-breakpoint-down(sm) {
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
    // .quote-id {
    //   color: $brown;
    // }
    .text {
      margin-bottom: 0;
    }
    button {
      padding: 0;
      border: none;
      background: transparent;
    }
    .view {
      color: $brown;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
    }
  }

  .home-calendar-container {
    background-color: $light-3;
    height: fit-content;
    // min-height: 100%;
    border: 1px solid rgba($dark-grey, 0.3);
    border-radius: 4px;
    .datepicker-section {
      border-bottom: 1px solid rgba(139, 149, 158, 0.3);
      padding: 25px;

      .calendar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .header {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
        // position: relative;
      }
      .days {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 18.5px;
        .selected-underline {
          position: absolute;
          bottom: 2px;
          height: 1px;
          background-color: $brown;
          transition: all 0.2s ease-in-out;
        }
        @include media-breakpoint-down(sm) {
          gap: 6.67px;
        }

        overflow-x: auto;
        scroll-behavior: smooth;
      }
      .days::-webkit-scrollbar {
        display: none;
      }
      .day-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .day-name {
        font-size: 14px;
        color: $dark-grey-2;
        padding: 4px;
      }
      .day {
        cursor: pointer;
        padding: 4px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.selected {
          // border-bottom: 1px solid $brown;
          color: $brown;
        }
      }

      .custom-dropdown {
        display: inline-block;
        display: flex;
        align-items: center;
        position: relative;
      }
      .dropdown-trigger {
        // cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 12px;
      }
      .dropdown-menu {
        position: absolute;
        width: 100%;
        // width: 246px;
        top: 26px;
        right: 0;
        // @include media-breakpoint-down(sm) {
        // left: 83px;
        //  }
        overflow-y: auto;
        background-color: $white;
        border-radius: 4px;
        z-index: 10;
        display: block;
      }
      .dropdown-item {
        padding: 10px;
        cursor: pointer;
      }
      .dropdown-item:hover {
        background-color: #f0f0f0;
      }
      .prev-button {
        all: unset;
        margin-right: 6px;
        cursor: pointer;
      }
      .next-button {
        all: unset;
        margin-left: 6px;
        cursor: pointer;
      }
    }
    .calendar-bookings {
      padding: 25px;
      display: flex;
      flex-direction: column;
      .booking-container {
        // border-bottom: 1px dashed $dark-grey-2;
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        font-size: 14px;
        color: $black;
        text-decoration: none;
        padding-block: 8px;
        cursor: pointer;
        .booking {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          transition: background-color 0.15s ease-in-out;
          &:hover,
          &.selected {
            background-color: rgba(167, 151, 121, 0.15);
          }
          .time {
            padding: 12px;
            margin-bottom: 0;
          }
          .booking-details {
            padding: 12px;
            // border-left: 1px solid $dark-grey-2;
            border-left: 1px solid rgba(139, 149, 158, 0.3);
            .booking-type {
              color: $dark-grey-2;
              text-transform: capitalize;
            }
            p:first-child {
              margin-bottom: 4px;
            }
          }
        }
      }
    }

    .world-events-container {
      display: flex;
      flex-direction: column;
      padding: 25px;
      .world-event-title {
        font-size: 18px;
        border-bottom: 1px solid rgba(139, 149, 158, 0.3);
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        gap: 12px;
        .Worlds-events-text {
          font-size: 18px;
          color: black;
        }
      }
      .world-event-cards-container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        // margin-bottom: 16px;
        .world-event-card {
          padding: 16px;
          background-color: $white;
          border-radius: 4px;
          border: 1px solid rgba(139, 149, 158, 0.3);
          text-decoration: none;
          color: #000;
          font-weight: 500;
          font-size: 14px;
          .event-location {
            font-weight: 400;
            color: $dark-grey-2;
            margin-left: 6px;
          }
        }
      }
    }
  }
  .no-bookings-img-wrap {
    margin: 0 auto 16px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid $dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .no-bookings-text {
    color: $black;
    font-size: 14px;
    display: flex;
    padding-block: 12px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($dark, 0.3);
  }
  .no-events-text {
    color: $black;
    font-size: 20px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner-close-btn {
    border: 0;
    background: transparent;
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.home-booking-details-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;

  .home-booking-details-modal {
    display: flex;
    flex-direction: column;
    color: $black;
    width: 800px;
    height: 660px;
    background-color: #fff;
    overflow-y: auto;
    // padding: 40px 40px 16px 40px;
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar */
    }
    p {
      margin-bottom: 0;
    }
    .header {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
      padding: 40px 40px 16px 40px;
      background-color: white;
      z-index: 1;
      border-bottom: 1px solid transparent;
      transition: border-color 0.5s ease-in-out;
      &.show-border {
        border-color: rgba($dark-grey, 0.3);
      }
      &.hide-border {
        border-color: transparent;
      }
      .number-status {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    .book-transportation-text {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;
      gap: 4px;
      font-size: 14px;
      color: $brown;
      cursor: pointer;
    }
    .home-responsive-view-booking-btn {
      padding: 16px 40px 16px 40px;
      border-top: 1px solid rgba($dark-grey, 0.3);
      transition: border-color 0.5s ease-in-out;
      bottom: 0;
      position: sticky;
      line-height: 20px;
      background-color: white;
      &.show-border {
        border-color: rgba($dark-grey, 0.3);
      }
      &.hide-border {
        border-color: transparent;
      }
      button {
        padding: 10px 48px 10px 48px;
        background-color: $brown;
        border-radius: 2px;
        border: none;
        font-size: 15px;
        float: right;
        color: white;
      }
    }
    .service-card {
      &.home-layout {
        display: block;
      }
      .card-body {
        label {
          &.expand-title {
            font-size: 12px;
          }
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 89px;
        }
      }
    }
  }
}
.home-booking-details-card,
.home-booking-details-modal {
  .smart-suggestions-container {
    padding: 24px;
    border-radius: 4px;
    background-color: rgba($brown, 0.1);
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      font-size: 18px;
      margin-bottom: 16px;
    }
    .section-header {
      &.expandable {
        cursor: pointer;
        padding-right: 40px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          width: 20px;
          height: 20px;
          background-image: url('/images/icons/caret-down.svg');
          background-size: contain;
          background-position: right 0 center;
          background-repeat: no-repeat;
        }

        &.expanded {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }
    .smart-sugg-icon {
      position: relative;
      bottom: 2px;
    }
    .smart-sugg-car-card {
      background-color: $white;
      border-radius: 2px;
      border: 0.5px solid rgba($dark-grey, 0.3);
      padding: 16px;
      .car-details-container {
        display: flex;
        align-items: flex-end;
        gap: 16px;
      }
      .car-img-container {
        display: flex;
        align-items: end;
        height: 75%;

        .car-img {
          width: 160px;
        }
      }
      .card-price-div {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
      .card-price {
        display: flex;
        align-items: center;
      }
      .text-category {
        font-size: 12px;
        text-transform: uppercase;
        color: $dark-grey;
      }
      .empty {
        visibility: hidden;
      }
      .add-top {
        margin-top: 8px;
      }
    }
  }
}
.book-transportation-text {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: flex-end;
  text-decoration: none;
  list-style-type: none;
  gap: 4px;
  font-size: 14px;
  color: $brown;
  width: fit-content;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    color: $brown; /* Keeps the color the same on hover */
    text-decoration: none; /* Ensures no underline appears on hover */
  }
}
.associated-bookings {
  padding: 24px;
  border-radius: 4px;
  background-color: $light-3;

  p {
    font-size: 20px;
  }
  .title {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 43px;
  }
  .section-header {
    &.expandable {
      cursor: pointer;
      padding-right: 40px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        background-image: url('/images/icons/caret-down.svg');
        background-size: contain;
        background-position: right 0 center;
        background-repeat: no-repeat;
      }

      &.expanded {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
  .bookings-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .booking-row {
    display: flex;
    align-items: end;
    padding-bottom: 8px;

    .booking-cell:nth-child(1) {
      flex: 0.7; // number
    }

    .booking-cell:nth-child(2) {
      flex: 1.2; // pick-up date
    }

    .booking-cell:nth-child(3) {
      flex: 1; // type
    }

    .booking-cell:nth-child(4) {
      flex: 1; // status
    }
  }

  .booking-cell {
    flex: 1;
    font-size: 14px;
    color: $black;

    &:last-child {
      text-align: right;
      font-weight: bold;
    }
  }

  .booking-number {
    color: $brown !important;
    span {
      margin-right: 10px;
    }
  }

  hr {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.more-page-card-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .more-page-card {
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($dark-grey, 0.3);
    }
    font-size: 15px;
    color: #000;
    .desc {
      font-size: 14px;
      color: $dark-grey-2;
    }
    .icon {
      padding: 10px;
      border: 1px solid rgba($dark-grey, 0.3);
      border-radius: 50%;
      width: fit-content;
      height: fit-content;
    }
  }
}
